import React, { useState, useEffect } from "react";
import { SidePanel, LargeButton } from "../.."
import { Form, Row, Col, Image } from "react-bootstrap";
import { getAPI, postAPI } from "../../../../Api";
import { logger, toaster } from "../../../../Utils";
import "../style.scss";
import { useSelector, useDispatch } from "react-redux";
import { setCreditCheckPaymentId } from "../../../../Store/actions/MessageDetailAction";

const log = logger("PurchaseReport");
const PurchaseReport = (props) => {
  const { isShow, handleClose, setCertnPaymentMethod } = props
  const [discount, setDiscount] = useState(0);
  const [isPromoCode, setIsPromoCode] = useState(false);
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [paymentSummary, setPaymentSummary] = useState(null);
  const [coupon_code, setCoupon_code] = useState('')
  const [couponErrorMessage, setCouponErrorMessage] = useState('')

  const dispatch = useDispatch();
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const headerContent = useSelector(state => state.messageDetailsReducer.headerContent);
  const tenant_id = headerContent && headerContent.tenant_id;
  const creditCheckRequestId = useSelector(state => state.messageDetailsReducer.creditCheckRequestId);
  
  const OnChangeInput = (event, state) => {
    // if(event.target.name === 'discount') {
      setCouponErrorMessage('')
      setDiscount(Number(event.target.value));
      state(event.target.value)
    // }  
  }

  const getDiscount = (type) => {
    let url = `/landlord/get-payment-summary`;
    if(coupon_code && type !=='remove'){
      url = `/landlord/get-payment-summary/${coupon_code}`;
    }
    getAPI(url).then(response => {
      if (response.status !== 200) {
        const res = response.response.data;
        setCouponErrorMessage(res.message)
      } else if (response.status === 200) {
        const res = response.data;
        setPaymentSummary(res.data);
        console.log(res.data)
        if(coupon_code && type !== 'remove') toaster('success', 'Promo Applied Successfully!');
      }
    })
  }

  useEffect(() => {
    getDiscount();
    setCouponErrorMessage('')
  }, []);

  const onSubmit = () => {
    const url = '/landlord/purchase-certn-report';
    const data = {
      "property_details_id": property_details_id,
      "property_id": property_id,
      "tenant_id": tenant_id,
      "credit_check_request_id": creditCheckRequestId,
      'discount_id': paymentSummary.discount_id
    }
    postAPI(url, data).then(response => {
      if (response.status !== 200) {
        const res = response.response.data;
        toaster('error', res.message)
      } else if (response.status === 200) {
        const res = response.data.data;
        dispatch(setCreditCheckPaymentId(res.credit_check_payment_id));
        handleClose();
        setCertnPaymentMethod(true);
      }
    })
  }

  // useEffect(() => {
  //   if (isPromoCode) {
  //     const promoCodeUrl = '/landlord/coupon/promo-code-list';
  //     const data = {
  //       "status": 1, //0: Inactive List, 1: Active List
  //     }
  //     getAPI(promoCodeUrl, data).then(response => {
  //       if (response.status !== 200) {
  //         toaster('error', 'Something went wrong!');
  //       } else if (response.status === 200) {
  //         const res = response.data.data;
  //         setPromoCodeList(res);
  //       }
  //     })
  //   }
  // }, [isPromoCode])

  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title="Safety and security at your finger tips">
      <div className='sidepanel-body purchase-report mt-2'>
        <Row>
          <Col md='12'>
            <Image src={require("../../../../_assets/images/certn.jpeg")} alt='certn logo' className='certn-logo'/>
            <p>A tenant profile is a great start, interviewing a tenant is the right thing to do and for ultimate peace of mind, make sure to get a credit and background check.</p>
            <h3 className='summary-title'>What You Will Get:</h3>
            <ul className="facility-list">
              <li>- Background & Criminal History Check</li>
              <li>- Full Credit Check </li>
            </ul>

            <h2>Payment Summary</h2>
            <p>Review the detailed price</p>
            <ul className='list-display'>
              <li><span>Background and Credit Check</span><span>${paymentSummary && paymentSummary.background_and_credit_check}</span>
              </li>
            </ul>
            <ul className='list-display'>
              <li><span>Sub Total</span><span>${paymentSummary && paymentSummary.subtotal}</span></li>
              <li><span>Discount</span><span>${(paymentSummary && paymentSummary.discount_amount) || 0}</span></li>
              <li>
                <span>HST (+{paymentSummary && paymentSummary.hst_fee}%)</span>
                <span>${(paymentSummary && paymentSummary.hst)}</span>
              </li>
            </ul>
            <h3 className='price-display'>Total ${(paymentSummary && paymentSummary.grand_total)}</h3>
            {!isPromoCode ?
              <span className='promo-code-display' onClick={()=>setIsPromoCode(true)}>+ Apply Promo Code</span>:
              // <Form.Group>
              //   <Form.Label className="d-flex justify-content-between">
              //     <span>Select Promo Code</span>
              //     <span className='promo-code-display' onClick={()=>{
              //       setIsPromoCode(false);
              //       setDiscount(0);
              //     }}>Cancel</span>
              //   </Form.Label>
              //   <Form.Control
              //     as="select"
              //     className="my-1 mr-sm-2"
              //     id="professional-promo"
              //     custom
              //     name = "discount" 
              //     value = {discount}
              //     onChange = {(e) => OnChangeInput(e)}
              //   >
              //   <option value="0">Select one</option>
              //   {promoCodeList && promoCodeList.map(coupon => {
              //     return <option key={coupon.id} value={coupon.id}>{coupon.coupon_name}</option>
              //   })}
              //   </Form.Control>
              // </Form.Group>
              <>
                <Row>
                  <Form.Group as={Col} md="12">
                    <Row className='d-flex flex-row' style={{ justifyContent: 'space-between', padding: '0 13px' }}>
                      <Form.Label>Enter Coupon Code</Form.Label>
                      <span className='promo-code-display' onClick={()=>{setIsPromoCode(false); setCoupon_code(''); setCouponErrorMessage(''); getDiscount('remove')}}>Cancel</span>
                    </Row>
                    <Form.Control
                        className = {couponErrorMessage && 'is-invalid'}
                        type="text"
                        placeholder="RP20"
                        name="coupon_code"
                        id="coupon_code"
                        value={coupon_code}
                        onChange={(e) => OnChangeInput(e, setCoupon_code)}
                        // maxLength={8}
                    />
                    <div style={{ display: 'block' }} class='invalid-feedback'>{couponErrorMessage}</div>
                  </Form.Group>
                </Row>
                <Row style={{ display: 'flex', flexDirection:'row-reverse', paddingRight: '25px' }}>
                  <LargeButton
                    style={{width: '50%'}}
                    title="Apply Coupon"
                    onClick={() => getDiscount()}
                  />
                </Row>
              </>
            }
          </Col>
        </Row>
      </div>

      <div className="fixed-bottom">
        <LargeButton
            title="purchase the report now"
            onClick={() => onSubmit()} />

        <LargeButton
          variant="secondary"
          title="cancel"
          onClick={handleClose} />
      </div>
    </SidePanel>
  </>);
}

export default PurchaseReport;