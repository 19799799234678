import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import Cookies from "js-cookie";
import "./style.scss";
import { getAPI, postAPI } from "../../../Api";
import { logger, toaster } from "../../../Utils";
import TenantListForMessage from "../../components/Message/TenantListForMessage";
import ChatBox from "../../components/Message/ChatBox";
import { Col, Image, Row } from "react-bootstrap";
import { validate } from "../../../Utils/Validation/validation";
import { MessageValidationSchema } from "../../components/Message/MessageValidationSchema";
import { Loader } from "../../components";
import { useDispatch, useSelector } from 'react-redux';
import { setMessageData, setLeaseData, setConversationId, setExistingShowingRequestId, setExistingShowingSentBy, setExistingTenancyApplicationId, setIsBlocked, setHeaderContent, setTenantListData, setTenantId, setIsTenancyApplicationReceived, setViewingRequestId, setIsLeaseExists, clearMessageData } from "../../../Store/actions/MessageDetailAction";
import { useLocation } from 'react-router-dom';

import io from 'socket.io-client';
import { SOCKET_URL } from "../../../Constants/endPoints";
import useWindowDimensions from "../../hooks/WindowSize";

import { removeFromContact } from './helper'

const log = logger("LandlordMessages");
const LandlordMessages = (props) => {
    const { setShowingRequest, setConfirmShowing, callTenantprofile, setInvite_apply, setLeaseOptionsScreen, setCertnPurchaseReport, setLeasePdfViewScreen, setReSchedule, is_reSchedule } = props;
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [is_showingProfile] = useState(false);
    const [messageLoader, setMessageLoader] = useState(false);
    const role = Number(Cookies.get("role"));
    const dispatch = useDispatch();
    let socket = useState(null);
    const [activeKey, setActiveKey] = useState('new_to_old');
    const [is_fetching_data, setFetching_data] = useState(true);
    const [messages, setMessages] = useState([])
    const [tenants, setTenants] = useState([])
    // const [windowSize, setWindowSize] = useState(window.innerWidth)
    const { height, width } = useWindowDimensions();
    log.info('width ===>', width)

    const property_id = props && Number(props.match.params.property_id);
    const property_details_id = props && Number(props.match.params.property_details_id);
    const conversation_id = useSelector(state => state.messageDetailsReducer.conversationId);
    const listData = useSelector(state => state.messageDetailsReducer.tenantListData);
    const tenant_id = useSelector(state => state.messageDetailsReducer.tenantId);
    const user_id = useSelector(state => state.auth.id);
    const is_blocked = useSelector(state => state.messageDetailsReducer.isBlocked);
    const messageData = useSelector(state => state.messageDetailsReducer.messageData);
    const headerContent = useSelector(state => state.messageDetailsReducer.headerContent);
    const [activeTenantConvId, setActiveTenantConvId] = useState(conversation_id)
    const [showTenantList, setShowTenantList] = useState(true)
    const [isMobile] = useState(width < 750)
    log.info('Width', isMobile)
    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        socket = io(SOCKET_URL);
        log.info('socket', socket)

        socket.on("connect", () => {
            // log.info('socket connected', socket.connected); // true
            log.info('socket connected', socket.connected); // true
        });

        socket.on('reconnect', (attemptNumber) => {
            log.info("socket reconnected -", attemptNumber)
        });

        socket.on("disconnect", (reason) => {
            log.info('socket disconnected', reason, '===>', socket.disconnected); // false
            socket.open();
        });

        if (user_id) {
            socket.on("message-" + user_id + ":ChatEvent", () => {
                log.info('socket conversation', socket)
                getTenantList(activeKey)
            });
        }
        socket.on("conversation-" + conversation_id + ":MessageEvent", () => {
            setActiveTenantConvId(conversation_id)
            getMessages(conversation_id)
        });
        setActiveTenantConvId(conversation_id)
        getMessages(conversation_id)

    }, [conversation_id])

    useEffect(() => {
        socket = io(SOCKET_URL);
        dispatch(setMessageData([]))

        listData?.length > 0 && !isMobile && tenantListClick(listData[0])
        return () => {
            dispatch(clearMessageData())
            socket.on("disconnect", (reason) => {
                log.info('socket disconnected', reason, '===>', socket.disconnected); // false
            });
            socket.close()
        }
    }, [])

    useEffect(() => {
        if (currentPath && property_details_id && !isMobile) {
            getTenantListToSetData(activeKey);
        }
    }, [currentPath, property_details_id])

    useEffect(() => {
        if (listData?.length === 1) {
            getTenantListToSetData('new_to_old')
        }
    }, [listData?.length])

    useEffect(() => {
        if (activeKey) {
            getTenantList(activeKey);
        }
    }, [activeKey])

    useEffect(() => {
        getTenantList(activeKey);
    }, [messageData])

    const tenantListClick = (data) => {
        log.info("listData inside tenantListClick ", data);
        if (isMobile) {
            setShowTenantList(false)
        }
        if (data.tenant_id !== tenant_id) {
            setMessageLoader(true);
            setActiveTenantConvId(data.conversation_id)
            dispatch(setConversationId(data.conversation_id));
            dispatch(setIsBlocked(data.is_blocked));
            dispatch(setHeaderContent(data));
            dispatch(setTenantId(data.tenant_id));
            dispatch(setMessageData([]))
            setMessage([])
            // getMessages(data.conversation_id);
        }
    }


    const getTenantListToSetData = (sort) => {
        let key = (sort === 'new_to_old') ? 'desc' : 'asc';
        const url = `/landlord/get-tenants/${property_id}/${property_details_id}?sort=${key}`;
        getAPI(url).then(response => {
            const res = response.data.data;
            const data = res && res[0];
            setIsLoading(false);
            dispatch(setTenantListData(res));
            setTenants(res)
            dispatch(setConversationId(data.conversation_id));
            dispatch(setIsBlocked(data.is_blocked));
            dispatch(setHeaderContent(data));
            dispatch(setTenantId(data.tenant_id));
            getMessages(data.conversation_id);
            setFetching_data(false);
        })
            .catch(error => {
                setFetching_data(false);
            });
    }


    const getTenantList = (sort) => {
        let key = (sort === 'new_to_old') ? 'desc' : 'asc';
        const url = `/landlord/get-tenants/${property_id}/${property_details_id}?sort=${key}`;
        getAPI(url).then(response => {
            const res = response.data.data;
            setIsLoading(false);
            log.info('listData', res)
            setTenants(res)
            dispatch(setTenantListData(res));
            setFetching_data(false);
        })
            .catch(error => {
                setFetching_data(false);
                setIsLoading(false);
            });
    }


    const getMessages = (conversationId) => {
        const url = "/landlord/get-messages/" + conversationId;

        setTimeout(() => {
        getAPI(url).then(response => {
            const res = response.data.data;
            dispatch(setMessageData(res.messageData));
            setMessages(res.messageData)
            dispatch(setLeaseData(res.leaseDetail));
            dispatch(setExistingShowingRequestId(res.existingShowingRequestId));
            dispatch(setExistingShowingSentBy(res.existingShowingSentBy));
            dispatch(setExistingTenancyApplicationId(res.existingTenancyApplicationId));
            dispatch(setIsTenancyApplicationReceived(res.isTenancyApplicationReceived));
            dispatch(setIsLeaseExists(res.isLeaseExists));
            dispatch(setViewingRequestId(null));
            setMessage("");
            setFetching_data(false);
            setMessageLoader(false);
        })
            .catch(error => {
                setFetching_data(false);
                setMessageLoader(false);
            })
        }
            , 500)
    }

    const handleChange = (event) => {
        setMessage(event.target.value);
    }

    const sendMessage = (event, props) => {
        event.preventDefault();
        if (validate(MessageValidationSchema)) {
            const data = {
                "conversation_id": conversation_id,
                "message": message
            }
            const url = "/landlord/send-message";
            postAPI(url, data).then(response => {
                if (response.status !== 200) {
                    toaster('error', response.response.data.message)
                }else if(response.status === 200){
                    getMessages(conversation_id);
                    setMessage('')
                }
            })
                .catch(error => {
                });
        }
    }
    const viewProfile = () => {
        callTenantprofile(!is_showingProfile);
        log.info("view profile", tenant_id)
        dispatch(setTenantId(tenant_id))
    }

    const blockUser = (status) => {
        let url = '/landlord/block-tenant';
        if (status === 'unblock') {
            url = '/landlord/unblock-tenant';
        }
        const data = {
            "conversation_id": conversation_id
        }
        postAPI(url, data).then(response => {
            dispatch(setIsBlocked(!is_blocked));
            getTenantList(activeKey);
        })
            .catch(error => {
            });
    }


    const removeFromContactHandler = async () => {
        const newTenantList = await removeFromContact(conversation_id, listData)
        dispatch(setTenantListData(newTenantList));
        getTenantListToSetData(activeKey)
    }

    useEffect(() => {
        log.info("listData ===> ", listData);
        if (listData?.length > 0 && !isMobile) {
            log.info("listData inside if ===> ", listData);
            tenantListClick(listData[0])
        }
    }, [])

    log.info("messages => ", messages);

    return (
        <>
            <div className="profile-container">
                <div className="profile-container-box property-details">
                    {listData ?
                        listData.length > 0 ?
                            <Row className={conversation_id !== null && "tenant-list-outer"}>
                                {showTenantList && <TenantListForMessage
                                    tenantListClick={(data) => tenantListClick(data)}
                                    isLoading={isLoading}
                                    activeKey={activeKey}
                                    setActiveKey={(data) => setActiveKey(data)}
                                />}
                                {/* {is_fetching_data ?
                                    <Col md={9}>
                                        <div className='chat-wrapper' style={{ display: 'flex', alignItems: 'center' }}><Loader /></div>
                                    </Col>
                                    :
                                    (  */}
                                {(!isMobile || !showTenantList) &&
                                    <ChatBox
                                        showTenantList={showTenantList}
                                        setShowTenantList={setShowTenantList}
                                        messageData={messages}
                                        headerContent={headerContent}
                                        conversation_id={activeTenantConvId}
                                        setReSchedule={(flag) => setReSchedule(flag)}
                                        is_reSchedule={is_reSchedule}
                                        grid={9}
                                        role={role}
                                        sendMessage={(data) => sendMessage(data)}
                                        message={message}
                                        handleChange={(data) => handleChange(data)}
                                        setShowingRequest={setShowingRequest}
                                        setConfirmShowing={setConfirmShowing}
                                        viewProfile={(data) => viewProfile(data)}
                                        set_tenancy={setInvite_apply}
                                        messageLoader={messageLoader}
                                        blockUser={(data) => blockUser(data)}
                                        removeFromContact={(data) => removeFromContact(data)}
                                        setLeaseOptionsScreen={setLeaseOptionsScreen}
                                        setCertnPurchaseReport={setCertnPurchaseReport}
                                        setLeasePdfViewScreen={setLeasePdfViewScreen}
                                    />
                                }

                                {/* } */}
                            </Row>
                            :
                            <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '60px 0' }}>
                                <Image src={require("../../../_assets/images/no-data.svg")} />
                                <p className="empty-tenant-list-screen">You haven’t received any inquiries for this property yet</p>
                            </Col>
                        : <Loader />}

                </div>
            </div>
        </>
    );
}

export default withRouter(LandlordMessages);