import React from "react";
import { GoogleApiWrapper } from "google-maps-react";
import "./style.scss";
import { Form } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { Google_Map_API } from "../../../Constants/endPoints";

const AutoCompleteAddress = (props) => {
  const { state, updateField, addressTitle, handlePlaceChanged, id, name, placeholder,className } = props
  const onPasteData = (e) => {
    e.preventDefault();
    return false;
  }
  
  const debounce = (func, timeout = 1000) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }

  return (<>
    <Form.Label className={`custom-lable`}>{addressTitle}</Form.Label>
    <Autocomplete
      onPlaceSelected={(place) => debounce(handlePlaceChanged(place))}
      types={[]}
      componentRestrictions={{ country: ["ca"] }}
      value={state}
      onChange={(e) => debounce(updateField(e))}
      className={`form-control form-control-user  ${className}`}
      placeholder={placeholder ? placeholder : "Type or Select Your Address"}
      id={id}
      name={name ? name : "address"}
      onPaste={(e) => onPasteData(e)}
    />
  </>);
}
export default GoogleApiWrapper({
  apiKey: (Google_Map_API),
  // LoadingContainer: Loader
})(AutoCompleteAddress)


