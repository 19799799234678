// is_ownedData
export const is_ownedData = [
    { value: 1, title: 'Own' },
    { value: 0, title: 'Rent' }
]

// employment_statusData
export const employment_statusData = [
    { value: 1, title: 'Full time' },
    { value: 2, title: 'Part time' },
    { value: 3, title: 'Student' },
    { value: 4, title: 'Social Assistance' }
]

// tenant_statusData
export const tenant_statusData = [
    { value: 1, title: 'Yes' },
    { value: 0, title: 'No' },
    // { value: null, title: '' }
]

// pet_typeData
export const pet_typeData = [
    { value: 1, title: 'Dog' },
    { value: 2, title: 'Cat' },
    { value: 3, title: 'Others' }
]

// filterPropertyType
export const property_typeData = [
    { value: 1, title: 'Full Property' },
    { value: 2, title: 'Unit in A Building Or House' },
    { value: 3, title: 'Room' }
]

// is_furnishedData
export const is_furnishedFilter = [
    { value: 1, title: 'Yes', display: 'Furnished' },
    { value: 0, title: 'No', display: 'Not-Furnished' },
    { value: 2, title: 'Any', display: 'Any' }
]

// is_furnishedData
export const is_parkingFilter = [
    { value: 1, title: 'Yes', display: 'Parking' },
    { value: 0, title: 'No', display: 'Parking not allowed' },
    { value: 2, title: 'Any', display: 'Parking at extra cost' }
]