export const RentedHomeValidationSchema = {
    current_form_address_2: [
        { rule: "required", message: "Current address required" },
    ],
    current_form_start_date: [
        { rule: "required", message: "Start date required" },
    ],
    landlord_email_current: [
        { rule: "required", message: "Landlord email required" },
        { rule: "email", message: "Landlord email is invalid" }
    ],
    landlord_phone_current: [
        { rule: "required", message: "Landlord phone number required" },
        { rule: "phone", length: 10, message: "Phone number must be of 10 digits" },
    ],
    monthly_rent_current: [
        { rule: "required", message: "Monthly rent required" },
    ],
    landlord_name_current: [
        { rule: "required", message: "Landlord name required" },
    ],
}