import React, { useState } from "react";
import { Button, Image, Row, Col } from "react-bootstrap";
import { ModalBox } from "../../../components";
import { useDispatch } from "react-redux";
import { getCurrentPage } from "../../../../Store/actions/PropertyDetailAction";
import ProfessionalPhotoShoot from "./ProfessionalPhotoShoot";

const StepFive = (props) => {
  const{ setPhotoType } = props

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

    return (<>
      <div className="inner-wrapper cu-step-form-body" style={{minHeight:"500px"}}>
        <div className="body-container">
          <div className="body-header">
              <h3>it’s time for the pictures</h3>
          </div>

          <Row>
            <Col md="6">
              <div className='image-listing-wrapper' onClick={() => setModalShow(true)}>
                <div className='image-listing-inner'>
                  <Image className="icon" src={require("../../../../_assets/images/photo-icon.png")} alt="add"/>
                  <h4>Upload Your Own Photos</h4>
                  <p>Use photos you have or take them on your phone right now!</p>
                </div>
                <Image src={require("../../../../_assets/images/path.svg")} alt="path"/>
              </div>
            </Col>
            <Col md="6">
              <div className='image-listing-wrapper' onClick={() => setPhotoType('professional')}>
                <div className='image-listing-inner'>
                  <Image className="icon" src={require("../../../../_assets/images/camera-icon.png")} alt="add"/>
                  <h4>Get Professional Photos, Free!</h4>
                  <p>We’ll do the photos for you and you’ll get your listing verified at the same time!</p>
                </div>
                <Image src={require("../../../../_assets/images/path.svg")} alt="path"/>
              </div>
            </Col>
          </Row>          
        </div>
      </div>

      <div className="container-footer cu-step-form-footer">
        <Button variant="secondary" onClick={()=> dispatch(getCurrentPage(3))}>Back</Button>
        <Button variant="success" onClick={()=> dispatch(getCurrentPage(5))}>Next</Button>
      </div>

      <ModalBox
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        actionbuttontitle={'get verified now'}
        buttonaction={() => {
          setModalShow(false)
          setPhotoType('professional')
        }}
        backbuttontitle={'get verified later'}
        backaction={() =>{
          setModalShow(false)
          dispatch(getCurrentPage(5))			
        }}
      >
        <h4>want to get your property <br/>verified?</h4>
        <p>by uploading your own photos, your listing won't receive the "verified" badge.<br/>
         getting your property verified is free, takes less than 30 mins and involves our team coming by to take professional photos.</p>
      </ModalBox>
   </> );
}

export default StepFive;
