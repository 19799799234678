import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { AppLayout, PageTitleHeader, ModalBox, PDFViewerComponent } from '../../../components';
import CompleteLeaseDetails from './CompleteLeaseDetails';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "react-bootstrap";
import { getLeaseHeading } from '../../../../Store/actions/Action';
import { logger } from '../../../../Utils';
import { getAPI } from "../../../../Api";

const log = logger("ReviewLeaseDetails");
const ReviewLeaseDetails = (props) => {
    const [user_id, setUserId] = useState(false);
    const [lease_id, setLeaseId] = useState(false);
    const [user_type, setUserType] = useState('');
    const [leaseInfoPage, setLeaseInfoPage] = useState(false);
    const lease_data = useSelector(state => state.propertyDetailsReducer.leaseFetchedData)
    const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const leaseData = unitDetails && unitDetails.lease;
    const [is_pdfModal, setPdfModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const dispatch = useDispatch();
    const back = () => {
        if (leaseInfoPage) {
            props.history.goBack();
        } else {
            props.history.push({ pathname: `/lease-detail/${user_type.toLowerCase()}/${lease_id}/${user_id}` })
        }
    }
    useEffect(() => {
        setLeaseInfoPage(props.location.state.showLeaseInfoPage);
        setUserId(props.match.params.user_id);
        setLeaseId(props.match.params.lease_id);
        setUserType(props.match.params.user_type);
        dispatch(getLeaseHeading('edit'));
        const fetchData = async () => {
            if (props.match.params.property_details_id && props.match.params.lease_id) {
                const url = `/admin/listing/lease/view-appendix/${props.match.params.property_details_id}/${props.match.params.lease_id}`;
                await getAPI(url).then(res => {
                    if (res.status === 200) {
                        const fetchedData = res.data.data;
                        setPdfUrl(fetchedData.lease_pdf_url)
                        log.info('resp ', fetchedData.lease_pdf_url);
                    } else {
                        log.info("error", res);
                    }
                })
            }
        }
        fetchData();
    }, [
        props.match.params.user_id,
        props.match.params.lease_id,
        props.match.params.user_type,
        props.location.state.showLeaseInfoPage,
        props.match.params.property_details_id,
        dispatch
    ]);

    return (
        <div className={`${leaseInfoPage ? 'leaseInfoPage' : ''}`}>
            <AppLayout>
                <div>
                    <PageTitleHeader
                        isArrow={true}
                        onClick={() => back()}
                        title={leaseInfoPage || lease_data ? "Lease Information" : (`Lease for ${leaseData && leaseData.unit_no}, ${leaseData && leaseData.address} `)}
                        subTitle={leaseInfoPage || lease_data ? (`${leaseData && leaseData.unit_no}, ${leaseData && leaseData.address} `) : 'Review lease details'}
                        extraClass={`${leaseInfoPage ? 'leaseInfoTitle' : 'title-custom'}`}
                        extraElm={leaseInfoPage &&
                            <Button
                                variant="link"
                                className="btnDownloadLease"
                                disabled={pdfUrl === '' ? true : false}
                                onClick={() => setPdfModal(true)}> Download Lease
                            </Button>}
                    />
                </div>
                {(!leaseInfoPage && lease_data) &&
                    <>
                        {lease_data.is_signed === true ?
                            <div className="top-status delivered">
                                <p>Lease Has Been Signed</p>
                            </div>
                            : (lease_data.is_sent_to_tenant === true ?
                                <div className="top-status delivered">
                                    <p>Lease Has Not Been Signed Yet, Please Upload Your Lease</p>
                                </div>
                                : <div className="top-status in-progress">
                                    <p>Please Review The Information Below Before Signing The Lease</p>
                                </div>)}
                    </>
                }
                <div className='profile-container'>
                    <div className={`profile-container-box ${leaseInfoPage ? 'leaseInfoPageContent' : ''}`}>
                        <CompleteLeaseDetails
                            component='review-n-edit'
                            leaseInfoPage={leaseInfoPage}
                        />
                    </div>
                </div>
            </AppLayout>
            <ModalBox
                show={is_pdfModal}
                onHide={() => setPdfModal(false)}
                size="xl"
                className="cu-full-modal"
            >
                <PDFViewerComponent PDFUrl={pdfUrl && pdfUrl} />
            </ModalBox>
        </div>
    );
}

export default withRouter(ReviewLeaseDetails);
