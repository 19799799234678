import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { requesttypeSorting, roleSorting, dateTimeSorting, requestStatusSorting } from "../../../Constants/adminConstant";
import SearchInputTwo from "../../Admin/ProfileDetail/ListUsableComponent/SearchInputTwo";
import DropDown from "../DropDown";

const RequestManagementHeader = (props) => {
    const { data, component, sort_by_type } = props;
    const [status_filter, setStatusFilter] = useState(false);
    const showStatusFilter = () => {
        setStatusFilter(!status_filter);
    }
    const [status_filter_datetime, setFilterDatetime] = useState(false);
    const showFilterDatetime = () => {
        setFilterDatetime(!status_filter_datetime);
    }
    const [status, setStatus] = useState(false);
    const showStatus = () => {
        setStatus(!status);
    }

    const [displayType, setDisplayType] = useState("View All");
    const onClickRole = (value, display) => {
        setDisplayType(display);
        props.setSeachRole(value);
    }
    return (
        <>
            {(component === "requestManagement") &&
                <>
                    {(() => {
                        switch (data.header) {
                            case 'NAME OF USER':
                                return (
                                    <>
                                        <SearchInputTwo placeHolder={'Search'} idOrName={'searchName'} searchLocation={props.searchName} setSearch={props.setSearchName} />
                                    </>
                                )
                            case 'ROLE':
                                return (
                                    <>
                                        <DropdownButton
                                            // alignLeft
                                            title={displayType}
                                            id="dropdown-menu-align-left"
                                            className='arrow_adjustment'
                                        >
                                            {roleSorting.map((options, i) => {
                                                return <Dropdown.Item eventKey={options.value} onClick={() => onClickRole(options.value, options.display)} key={i}>{options.display}</Dropdown.Item>
                                            })}
                                        </DropdownButton>
                                    </>
                                )
                            case 'REQUEST TYPE':
                                return (
                                    <span className="d-flex justify-content-between request-type">
                                        <DropDown close={() => setStatusFilter(false)} open={status_filter} position={"right"} enableTopArrow={false} style={{ width: "235px", top: "55px" }}
                                            content={
                                                <div className="row checkbox-left check-status">
                                                    {requesttypeSorting.map((data, i) => {
                                                        return <div className="col-12" key={i}>
                                                            <div className="form-group">
                                                                <div className="custom-control custom-checkbox large">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                        name={data.value}
                                                                        checked={props.sort_by_type.includes(data.value)} onChange={props.sortType}
                                                                    />
                                                                    <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        >
                                            <Dropdown onClick={showStatusFilter} className="multiselect-dropdown">
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    {requesttypeSorting.filter((data) => {
                                                        let status = '';
                                                        if (sort_by_type.includes(data.value)) {
                                                            status = data;
                                                        }
                                                        return status;
                                                    }).map(function (obj) {
                                                        return obj.display;
                                                    }).join(", ")}
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </DropDown>
                                    </span>
                                )
                            case 'CITY':
                                return (
                                    <>
                                        <SearchInputTwo placeHolder={'Search'} idOrName={'searchCity'} searchLocation={props.searchCity} setSearch={props.setSearchCity} />
                                    </>
                                )
                            case 'DATE AND TIME':
                                return (
                                    <span className="d-flex justify-content-between request-type">
                                        <DropDown close={() => setFilterDatetime(false)} open={status_filter_datetime} position={"left"} enableTopArrow={false} style={{ width: "235px", top: "55px" }}
                                            content={
                                                <div className="row checkbox-left check-status">
                                                    {dateTimeSorting.map((data, i) => {
                                                        return <div className="col-12" key={i}>
                                                            <div className="form-group">
                                                                <div className="custom-control custom-checkbox large">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                        name={data.value}
                                                                        checked={props.searchdateTime.includes(data.value)} onChange={props.dateTime}
                                                                    />
                                                                    <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        >
                                            <Dropdown onClick={showFilterDatetime} className="multiselect-dropdown">
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    {dateTimeSorting.filter((data) => {
                                                        let status = '';
                                                        if (props.searchdateTime.includes(data.value)) {
                                                            status = data;
                                                        }
                                                        return status;
                                                    }).map(function (obj) {
                                                        return obj.display;
                                                    }).join(", ")}
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </DropDown>
                                    </span>
                                )
                            case 'ACTIONS':
                                return (
                                    <span className="d-flex justify-content-between request-type">
                                        <DropDown close={() => setStatus(false)} open={status} position={"left"} enableTopArrow={false} style={{ width: "235px", top: "55px" }}
                                            content={
                                                <div className="row checkbox-left check-status">
                                                    {requestStatusSorting.map((data, i) => {
                                                        return <div className="col-12" key={i}>
                                                            <div className="form-group">
                                                                <div className="custom-control custom-checkbox large">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                        name={data.value}
                                                                        checked={props.searchRequestStatus.includes(data.value)} onChange={props.requestStatus}
                                                                    />
                                                                    <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            }
                                        >
                                            <Dropdown onClick={showStatus} className="multiselect-dropdown">
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    {requestStatusSorting.filter((data) => {
                                                        let status = '';
                                                        if (props.searchRequestStatus.includes(data.value)) {
                                                            status = data;
                                                        }
                                                        return status;
                                                    }).map(function (obj) {
                                                        return obj.display;
                                                    }).join(", ")}
                                                </Dropdown.Toggle>
                                            </Dropdown>
                                        </DropDown>
                                    </span>
                                )
                            default:
                                return null
                        }
                    })()} </>}
        </>
    );
}
export default RequestManagementHeader;