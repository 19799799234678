import React from 'react';
import { withRouter } from "react-router-dom";
import Layout from '../components/Layout';
import { Referral, FormForRealtors, ForLeasingAgent } from './components';
import { logger } from '../../Utils';

import './style.scss';

const log = logger("ForRealtors");
const ForRealtors = (props) => {
	log.info("props", props);

	return (
		<div className="forRealtorsPage">
			<Layout newHeader={true}>
				<section className="sec-website-page-title how-it-work-title-bg cu-container-wrap">
                    <div className="container">
                        <h1 className="website-page-title text-center">rent panda loves taking care of you and your clients.</h1>
                    </div>
                </section>

				<Referral />
				<FormForRealtors />
				<ForLeasingAgent />
			</Layout>
		</div >
	)
}

export default withRouter(ForRealtors);
