export const ContactSchema = {
    full_name_contact: [
        { rule: "required", message: "Full name required" },
    ],
    emailAddress_contact: [
        { rule: "required", message: "Email address required" },
        { rule: "email", message: "Email is invalid" }
    ],
    phone_number_contact: [
        { rule: "required", message: "Phone number required" },
    ],
    current_address: [
        { rule: "required", message: "address required" },
    ],
    province_contact: [
        { rule: "required", message: "Province required" },
    ],
    postal_code_contact: [
        { rule: "required", message: "Postal code required" },
    ],
    postal_code: [
        { rule: "required", message: "Postal code required" },
    ],
    // unit_no_contact: [
    //     { rule: "required", message: "Unit number required" },
    // ],
    city_contact: [
        { rule: "required", message: "City required" },
    ],
    agree_to_received_notices_email: [
        { rule: "required", message: "Email required" },
        { rule: "email", message: "The email must be a valid email address." },
    ],
    day_to_day_communication_email: [
        { rule: "required", message: "Email required" },
        { rule: "email", message: "The email must be a valid email address." },
    ],
    day_to_day_communication_phone_number: [
        { rule: "required", message: "Phone number required" },
        { rule: "phone", length: 10, message: "Phone number is not valid" }
    ],
    
    mailing_current_address: [
        { rule: "required", message: "Address required" },
    ],
    // mailing_unit_no: [
    //     { rule: "required", message: "Unit no required" },
    // ],
    mailing_city: [
        { rule: "required", message: "City required" },
    ],
    mailing_province: [
        { rule: "required", message: "Province required" },
    ],
    mailing_postal_code: [
        { rule: "required", message: "Postal code required" },
    ],
}