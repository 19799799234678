import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Map, InfoWindow, Marker } from 'google-maps-react';
import Autocomplete from "react-google-autocomplete";

export const AutoCompleteWithMap = (props) => {
    const { displayTitle, address, place, mapPosition, showingInfoWindow, selectedPlace, activeMarker, updateField, handlePlaceChanged, onMarkerClick, onInfoWindowClose, onMarkerDrag } = props
    const onPasteData = (e) => {
        e.preventDefault();
        return false;
    }

    return (
        <>
            <Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>{displayTitle ? displayTitle : "Address"}</Form.Label>
                    <Autocomplete
                        onPlaceSelected={(place) => handlePlaceChanged(place)}
                        types={[]}
                        componentRestrictions={{ country: ["ca"] }}
                        value={place.formatted_address}
                        onChange={(e) => updateField(e)}
                        className="form-control form-control-user"
                        placeholder="Type or Select Your Address"
                        id="address"
                        name="address"
                        onPaste={(e) => onPasteData(e)}
                        maxLength={125}
                        onKeyDown={(e)=>{
                            if(e.keyCode===13)
                            e.preventDefault()
                        }}
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} md="10" style={{ height: '35vh' }} className="map-display-elm">
                    <Map google={props.google}
                        zoom={14}
                        center={mapPosition}
                        centerAroundCurrentLocation={false}
                        containerStyle={{
                            height: '35vh',
                            position: 'relative',
                            width: '100%'
                        }}
                    >
                        <Marker
                            onClick={onMarkerClick}
                            position={mapPosition}
                            name={address}
                            draggable={true}
                            onDragend={onMarkerDrag}
                        />

                        <InfoWindow
                            marker={activeMarker}
                            onClose={onInfoWindowClose}
                            visible={showingInfoWindow}>
                            <h4 className="mb-1">{selectedPlace.name}</h4>
                        </InfoWindow>
                    </Map>
                </Form.Group>
            </Row>
        </>
    );
}