import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { cmsClickedData } from '../../../../../Store/actions/Action';
import { FILE_API_URL, IMAGE_API_URL } from "../../../../../Constants/endPoints"
import Cookies from "js-cookie";
import { getAPI, postAPI } from '../../../../../Api';
import { AppLayout, PageTitleHeader, FileBrowse, DropDown, ModalBox, PDFViewerComponent } from '../../../../components';
import Footer from "../../../../../Website/components/Footer";
import { Container, Form, Row, Col, Button, Dropdown, DropdownButton, InputGroup, Image } from 'react-bootstrap';
import { validate } from '../../../../../Utils/Validation/validation';
import { FormsValidationSchema } from './FormsValidationSchema';
import ImageComponent from '../../../ProfileDetail/ListUsableComponent/ImageComponent';

import './style.scss';

const Forms = (props) => {
    const [componentLoad, setComponentLoad] = useState('');
    const clickedData = useSelector(state => state.CMSReducer.clickedData);
    const [displayType, setDisplayType] = useState('');
    const [viewData, setViewData] = useState(null);
    const [action, setAction] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(false);
    const [errorClass, setErrorClass] = useState(false);
    const [tagList, setTagList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [catIdErr, setErrCatId] = useState('');
    const [title, setTitle] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const [description, setDescription] = useState('');
    const [pdfModal, setPdfModal] = useState(false);
    const [tagDropdown, setTagDropdown] = useState(false);
    const [draftModal, setDraftModal] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [publishLoading, setPublishLoading] = useState(false);
    const [modalContent, setModalContent] = useState('');
    
    const [formDoc, setFormDoc] = useState({ file: '', percent: 0 });
    const [thumbnail, setThumbnail] = useState({ file: '', percent: 0 });
    const [uploadData, setUploadData] = useState(null);

    const dispatch = useDispatch();

    const showTagDropdown = () => {
        setTagDropdown(!tagDropdown);
    }
    
    const back = () => {
        return props.history.push('/cms/content-management');
    }

    const onClickCategory = (value, display) => {
        setDisplayType(display);
        setCategoryId(value);
        setErrCatId('');
    }

    const handleDrop = (file, setImage) => {
        const token = `Bearer ${Cookies.get('access_token')}`
        const headers = {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        }

        file.map((dataFile) => {
            setUploadData(dataFile);
            const url = (dataFile.type === "application/pdf") ? FILE_API_URL : IMAGE_API_URL;
            const fileType = (dataFile.type === "application/pdf") ? 'file' : 'image';
            let formdata = new FormData();
            formdata.append(fileType, dataFile);

            axios.post(
                url, formdata, {
                headers: headers,
                onUploadProgress: progressEvent => {
                    var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    if (percent >= 100) {
                        setImage({ percent: percent });
                        return file;
                    } else {
                        setImage({ percent: percent });
                        return null;
                    }
                }
            })
            .then(response => {
                setImage({ file: response.data.data.url })
                setErrorClass(false)
            })
            return dataFile;
        })
    }
    const removeFile = (index, setImage) => setImage('')

    const onSubmit = (e, stts) => {
        e.preventDefault();
        if (validate(FormsValidationSchema) && categoryId !== '') {
            let tag = [];
            selectedData.length !== 0 && selectedData.map(data => tag.push(data.id))
            
            const data = {
                "cms_category_id": categoryId,
                "title": title,
                "tags": tag,
                "description": description,
                "form_url": formDoc.file,
                "thumbnail_url": thumbnail.file,
                "status": stts,
            }

            let url = '';
            if (componentLoad === 'edit') {
                data.id = clickedData.id;
                url = '/admin/cms/forms/update';
            } else {
                url = '/admin/cms/forms/create';
            }

            stts === 0 ? setSaveLoading(true) : setPublishLoading(true);

            postAPI(url, data).then(response => {
                setSaveLoading(false);
                setPublishLoading(false);
                if (response.status === 200) {
                    const res = response.data.data;
                    if (res.status === 0) {
                        setDraftModal(true)
                        setModalContent('draft')
                    } else if (res.status === 1) {
                        setDraftModal(true)
                        setModalContent('published')
                    }
                }
            })
        } else if (categoryId === '') {
            setErrCatId('category is required')
        }
    }

    const onValueChange = (e, data) => {
        const isChecked = e.target.checked;
        isChecked === true ?
            setSelectedData((state) => ([...state, data]))
            :
            setSelectedData((state) => {
                return state.filter(filterData => filterData.id !== data.id)
            })
    }

    useEffect(() => {
        const data = {
            'name': ''
        }
        const urlCategory = `/admin/cms/get-categories`;
        postAPI(urlCategory, data).then(res => {
            if (res.status === 200) {
                setCategoryList(res.data.data)
            }
        })
        const urlTag = `/admin/cms/get-tags`;
        postAPI(urlTag, data).then(res => {
            if (res.status === 200) {
                setTagList(res.data.data)
            }
        })
    }, [])

    useEffect(() => {
        if (clickedData !== null) {
            getAPI(`/admin/cms/forms/view/${clickedData.id}`).then(resData => {
                if (resData.status === 200) {
                    const resDataView = resData.data.data;
                    setViewData(resDataView)
                }
            })
        }
    }, [clickedData]);

    useEffect(() => {
        if (viewData !== null) {
            setCategoryId(viewData.cms_category_id);
            setTitle(viewData.title);
            setSelectedData(viewData.cms_tags);
            setDescription(viewData.description);
            setFormDoc({file: viewData.form_url});
            setThumbnail({file: viewData.thumbnail_url});
            categoryList.map((data) => (data.id === viewData.cms_category_id) && setDisplayType(data.name));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewData]);

    useEffect(() => {
        const slug = props.match.params.slug;
        setComponentLoad(slug);
    }, [props]);

    const openConfirmationModal = (val) => {
        setAction(val)
        setDeleteModal(true);
    }
    const onClickYes = () => {
        if (action === 'delete') {
            const data = {
                'id': viewData.id
            }
            postAPI(`/admin/cms/forms/delete`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true)
                }
            })
        }
        if (action === 'statusChange') {
            const data = {
                "id": viewData.id,
                "status": (viewData.status_info.status_id === 0 || viewData.status_info.status_id === 2) ? '1' : '2'
            }
            postAPI(`/admin/cms/forms/change-status`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true)
                    getData();
                }
            })
        }
    }
    const onClickNo = () => {
        setDeleteModal(false)
    }

    const getData = () => {
        getAPI(`/admin/cms/forms/view/${viewData.id}`).then(resData => {
            const resDataView = resData.data.data;
            if (resData.status === 200) {
                setViewData(resDataView)
            }
        })
    }

    const togggleDraftModal = () => {
        setDraftModal(false);
        if (clickedData !== null) {
            getData();
        } else {
            dispatch(cmsClickedData(null));
            props.history.push(`/cms/content-management`);
        }
    }
    const toggleConfirm = () => {
        if (action === 'delete') {
            setConfirmAction(false)
            dispatch(cmsClickedData(null));
            props.history.push(`/cms/content-management`);
        } else if (action === 'statusChange') {
            setConfirmAction(false)
        }
    }

    let status = '';
    if (viewData) {
        if (viewData.status_info.status_name === 'Publish') {
            status = 'published'
        } else if (viewData.status_info.status_name === 'Unpublish') {
            status = 'unpublished'
        } else if (viewData.status_info.status_name === 'Draft') {
            status = 'draft'
        }
    }
    return (
        <div className="createEditForms">
            <AppLayout noSidebar={true}>
                <PageTitleHeader
                    isArrow={true}
                    onClick={() => back()}
                    title={componentLoad === 'edit' ? 'edit Form content' : 'creating new form content'}
                    status={status}
                    extraElm={
                        clickedData !== null && <div className='header-right'>
                            <Button onClick={() => openConfirmationModal('delete')} className='btn-cancel delete ml-3' type='button' >delete</Button>
                            <Button className={`ml-3 ${(viewData && viewData.status_info.status_id === 1) ? 'btn-cancel' : 'btn-publish' }`}
                                type='button'
                                onClick={() => openConfirmationModal('statusChange')}>
                                {viewData && (viewData.status_info.status_id === 0 || viewData.status_info.status_id === 2) ? 'publish' : 'unpublish'}
                            </Button>
                        </div>
                    }
                />
                
                <Container>
                    <Row className="mt-4 mb-1">
                        <Col md='9'>
                            <Form.Label className='custom-lable'>upload form</Form.Label>
                            
                            {(formDoc && formDoc.file) ?
                                <Col className='confirm-section mb-2 d-flex justify-content-between'>
                                    <span className='d-flex justify-content-start pdfBox'>
                                        <Image className="icon" src={require("../../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
                                        <div className='file-details d-flex flex-column'>
                                            {formDoc.file.split('/').pop()}
                                            <Button variant="link" onClick={() => setPdfModal(true)}>View</Button>
                                        </div>
                                        <Image className="icon cursor-pointer" title='Delete' onClick={(e) => removeFile(e, setFormDoc)} src={require("../../../../../_assets/images/red-delete-icon.svg")} alt="Delete" />
                                    </span>
                                </Col>
                            :
                                <FileBrowse
                                    handleDrop={(e) => handleDrop(e, setFormDoc)}
                                    size={'sm'}
                                    percent={formDoc.percent}
                                    multiple={false}
                                    fileType={'.pdf'}
                                    disable={false}
                                    imageData={formDoc.file && formDoc.file}
                                    removeFile={(e) => removeFile(e, setFormDoc)}
                                    section={'upload-lease'}
                                    uploadData={uploadData && uploadData}
                                    errorClass={errorClass === true ? "error-upload-box" : ""}
                                />
                            }
                        </Col>
                        <Col md='3'>
                            <Form.Label className='custom-lable'>thumbnail</Form.Label>
                            <FileBrowse
                                handleDrop={(e) => handleDrop(e, setThumbnail)}
                                size={'sm'}
                                percent={thumbnail.percent}
                                multiple={false}
                                fileType={'.jpg,.png,.jpeg'}
                                disable={false}
                                imageData={thumbnail.file && thumbnail.file}
                                removeImage={(e) => removeFile(e, setThumbnail)}
                            />
                        </Col>
                    </Row>
                    
                    <Form id='Form'>
                        <Row>
                            <Form.Group as={Col} md='5'>
                                <DropdownButton
                                    data-label="Category"
                                    title={displayType}
                                    name={'category'}
                                    id={'dropdown-menu-align-left'}
                                    style={{ width: '100%' }}
                                    className={catIdErr !== '' ? 'is-invalid2' : ''}
                                >
                                    {categoryList && categoryList.map((options, i) => {
                                        return <Dropdown.Item eventKey={options.id} onClick={() => onClickCategory(options.id, options.name)} key={i}>{options.name}</Dropdown.Item>
                                    })}
                                </DropdownButton>
                                {catIdErr !== '' && <div class="invalid-feedback2">{catIdErr}</div>}
                            </Form.Group>
                            <Form.Group as={Col} md='7'>
                                <div className='d-flex justify-content-between extra-class-dropdown'>
                                    <span>Tags:
                                        <strong>
                                        {selectedData.map(function (obj) {
                                            return obj.name;
                                        }).join(", ")}
                                        </strong>
                                    </span>
                                    <DropDown close={() => setTagDropdown(false)} open={tagDropdown} position={"right"} enableTopArrow={false} style={{ width: "208px" }}
                                        content={
                                            <div className="row checkbox-left check-status">
                                                {tagList.map((data, i) => {
                                                    return <div className="col-12" key={i}>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-checkbox large">
                                                                <input type="checkbox" className="custom-control-input" id={"customCheck" + data.id}
                                                                    name={data.id}
                                                                    checked={selectedData.find(elm => elm.id === data.id)}
                                                                    onChange={(e) => onValueChange(e, data)}
                                                                />
                                                                <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.id}>{data.name}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        }
                                    >
                                        <div onClick={showTagDropdown}><ImageComponent /></div>
                                    </DropDown>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md={12}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className='title-inside'>Title of Form:</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type='text'
                                        name={'title'}
                                        id={'title'}
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md={12}>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Description:"
                                    rows={10}
                                    name={'description'}
                                    id={'description'}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        <Row className='buttons-container'>
                            <Col md='12' className='d-flex justify-content-between'>
                                <Button className={`btn-cancel ${componentLoad === 'edit' && 'noCancleOnEditPage'}`}
                                    type='button'
                                    onClick={() => togggleDraftModal()}
                                    disabled={saveLoading === true || publishLoading === true ? true : false}>cancel</Button>
                                <div className='d-flex justify-content-between'>
                                    <Button className='btn-save-draft mr-3' type='submit' onClick={(e) => onSubmit(e, 0)} disabled={publishLoading === true ? true : false}>save as draft {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                                    <Button className='btn-publish' type='submit' onClick={(e) => onSubmit(e, 1)} disabled={saveLoading === true ? true : false}>publish {publishLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <Footer />
            </AppLayout>
            <ModalBox
                show={draftModal}
                onHide={() => togggleDraftModal(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => togggleDraftModal(false)}
            >
                <h4>{modalContent === 'draft' ? 'content saved' : 'Content is published'}</h4>
            </ModalBox>
            <ModalBox
                show={deleteModal}
                closeButtonHeader={false}
                size='sm'
                type='condition'
                actionbuttontitle={'Yes'}
                buttonaction={() => onClickYes()}
                backbuttontitle={'Not now'} // {action === 'statusChange' ? 'Not now' : 'Cancel'}
                backaction={() => onClickNo()}
            >
                <p className='order-one-modal'>
                    {action === 'delete' && 'are you sure you want to delete content? This cannot be undo.'}
                    {action === 'statusChange' &&
                        <>
                            {(viewData.status_info.status_id === 0 || viewData.status_info.status_id === 2) ?
                                'Are you sure you want to publish content? ' :
                                'Are you sure you want to unpublish content?'}
                        </>
                    }
                </p>
            </ModalBox>
            <ModalBox
                show={confirmAction}
                onHide={() => toggleConfirm(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => toggleConfirm(false)}
            >
                <h4>
                    {action === 'delete' && 'Article has been deleted.'}
                    {action === 'statusChange' &&
                        <>
                            {viewData.status_info.status_id === 1 ?
                                'Content published' :
                                'Content unpublish'}
                        </>
                    }
                </h4>
            </ModalBox>
            <ModalBox
                show={pdfModal}
                onHide={() => setPdfModal(false)}
                size="xl"
                className="cu-full-modal"
            >
                <PDFViewerComponent PDFUrl={formDoc && formDoc.file} />
            </ModalBox>
        </div>
    )
}

export default withRouter(Forms)