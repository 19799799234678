import { logger, toaster } from "../Utils";
import BaseApi from "./BaseApi";
import history from '../history';
import { updateRawData } from "../Store/actions/RawDataAction";
import store from "../configureStore";


const log = logger("BaseApi");

const excludedUrls = [
    '/tenant/search-property',
    '/tenant/send-message',
    '/landlord/send-message'
]

const doCustomOperations = (url, data) => {
    const isTermsAgree = store.getState().rawData.agree
    if (url === "/landlord/notice/store-notice" ||
        url === '/tenant/notice/store-notice' ||
        url === '/landlord/notice/serve-notice' ||
        url === '/tenant/notice/serve-notice' ||
        url.includes('/landlord/notice/details/') ||
        url.includes('/tenant/notice/details/')) {
        if (!isTermsAgree &&
            data.notice_type !== 16 &&
            data.notice_type !== 17 &&
            data.notice_type !== 4 &&
            !(url === '/landlord/notice/serve-notice' || url === '/tenant/notice/serve-notice')) {

            toaster("error", 'Please Accept Terms And Condition');
            return {};
        }
        if (document.getElementById('notice_loader'))
            document.getElementById('notice_loader').scrollIntoView()
        updateRawData({ allCleared: true })
    }
    return false
}

export async function postAPI(url, data) {
    if (!navigator.cookieEnabled) {
        updateRawData({ coockieError: 'To continue using Rent Panda, please enable cookies on your browser.' })
        //toaster("error", 'We use cookies to improve user experience, and analyze website traffic. For these reasons, we may share your site usage data with our analytics partners. By clicking “Accept Cookies,” you consent to store on your device all the technologies described in our Privacy Policy.');
        return false;
    } else {
        updateRawData({ coockieError: null })
    }
    try {
        log.info("Api URL ", url);
        const customResult = doCustomOperations(url, data)
        if (customResult) {
            return customResult
        }
        // if (!excludedUrls.includes(url)) {
        //     updateRawData({ showLoader: true })
        // }
        if (!excludedUrls.find(urlData => {
            return url.includes(urlData)
        })) {
            updateRawData({ showLoader: true })
        }

        const res = await BaseApi.post(url, data).then(response => { return response }).catch(error => { return error });
        log.info(res)
        if (res.data && res.data.status_code === 401) {
            history.push({
                pathname: '/login?unauthorized',
                state: { auth: 'unauthorized' }
            });
            history.go(0)
        }
        updateRawData({ showLoader: false })
        return res;

    } catch (error) {
        updateRawData({ showLoader: false })
    }

}

export async function getAPI(url) {
    if (!navigator.cookieEnabled) {
        updateRawData({ coockieError: 'To continue using Rent Panda, please enable cookies on your browser.' })
        //toaster("error", 'We use cookies to improve user experience, and analyze website traffic. For these reasons, we may share your site usage data with our analytics partners. By clicking “Accept Cookies,” you consent to store on your device all the technologies described in our Privacy Policy.');
        return false;
    } else {
        updateRawData({ coockieError: null })
    }
    const res = await BaseApi.get(url).then(response => { return response }).catch(error => { return error });
    if (res.data && res.data.status_code === 401) {
        history.push({
            pathname: '/login?unauthorized',
            state: { auth: 'unauthorized' }
        });
        history.go(0)
    }
    return res;
}