import React, { useState } from "react";
import { Col, Image, Navbar, NavDropdown } from "react-bootstrap";
import { logger } from "../../../Utils";
import MessageTenantListLoader from "../ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/MessageTenantListLoader/Index";
import "./style.scss";
import { useSelector, useDispatch } from "react-redux";

const log = logger('TenantListForMessage');
const TenantListForMessage = (props) => {
	const { tenantListClick, isLoading, activeKey, setActiveKey } = props;
	const handleDropdownSelect = (key) => {
		setActiveKey(key);
	}

	log.info('props', props);

	const headerContent = useSelector(state => state.messageDetailsReducer.headerContent);
	const listData = useSelector(state => state.messageDetailsReducer.tenantListData);

	return (
		<Col md="3" className="message-list-scroll pl-0 pr-0">
			<Navbar className="filter-nav mb-3">
				<Navbar.Collapse className="d-flex justify-content-between">
					<Navbar.Text>posted date:</Navbar.Text>
					<NavDropdown title={activeKey.split('_').join(' ')} id="nav-dropdown">
						<NavDropdown.Item onClick={(e) => handleDropdownSelect('new_to_old')}>posted date: new to old</NavDropdown.Item>
						<NavDropdown.Item onClick={(e) => handleDropdownSelect('old_to_new')}>posted date: old to new</NavDropdown.Item>
					</NavDropdown>
				</Navbar.Collapse>
			</Navbar>
			{isLoading === true ? <MessageTenantListLoader /> :
				<>
					{listData?.length !== 0 ?
						listData && listData.map((data, i) => {
							const {new_tenancy_application,new_viewing_request,new_message}=data
							console.log({new_tenancy_application})
 							return <div className={((headerContent && headerContent.conversation_id !== null) && (headerContent.conversation_id === data.conversation_id)) ? "message-list-card highlight cursorPointer" : "message-list-card cursorPointer"} key={'tenant' + i} onClick={() => tenantListClick(data)}>
								{data.is_verified === true && <div className={"verified status"}>verified</div>}
								<h4 className="tenant-name">{data.name}</h4>
								{data.contacted_on !== null && <p className="tenant-contact-date">Contacted on: {data.contacted_on}</p>}
								<div className="list-inline">
									<div className="list-li">
										<div className="icon-wrap">
											<Image
												width="20px"
												height="14px"
												alt="list-grid-icon"
												src={require(`../../../_assets/images/messages/messages.svg`)}
											/>
											{new_message && <div className="message-active"></div>}
										</div>
									</div>
									<div className="list-li">
										<div className="icon-wrap">
											<Image
												width="20px"
												height="14px"
												alt="list-grid-icon"
												src={require(`../../../_assets/images/messages/calender.svg`)}
											/>
											{new_viewing_request && <div className="message-active"></div>}
										</div>
									</div>
									<div className="list-li">
									<div className="icon-wrap">
										<Image
											width="20px"
											height="14px"
											alt="list-grid-icon"
											src={require(`../../../_assets/images/messages/application.svg`)}
										/>
										{new_tenancy_application && <div className="message-active"></div>}
										</div>
									</div>
								</div>
							</div>
						}) :
						<div className="no-data">Tenant not present</div>
					}
				</>}
		</Col>
	);
}
export default TenantListForMessage;