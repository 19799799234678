import React from 'react';
import DatePicker from 'react-datepicker';
import './style.scss';

const DatePickerComponent = (props) => {
    const { popperPlacement, handleDateChange, clear, extraClassDatePicker, extraClass, value, name, id, minDate, maxDate, displayText, required, format, disableValue, openToDate, highlightDates } = props;
    return (
        <div className={'rdt reactdatepicker ' + extraClassDatePicker}>
            <DatePicker
                selected={value}
                onChange={(e) => handleDateChange(e)}
                name={name}
                id={id}
                className={'form-control cursor-pointer ' + extraClass}
                maxDate={maxDate}
                placeholderText={displayText}
                minDate={minDate}
                required={required}
                dateFormat={(format) ? format : 'yyyy-MM-dd'}
                popperPlacement={popperPlacement ? popperPlacement : 'bottom-end'}
                autoComplete='off'
                popperModifiers={{
                    flip: {
                        behavior: ['bottom']
                    },
                    preventOverflow: {
                        enabled: false
                    },
                    hide: {
                        enabled: false
                    }
                }}
                disabled={disableValue}
                isClearable={clear}
                openToDate={openToDate}
                highlightDates={highlightDates}
            />
        </div>
    );
}
export default DatePickerComponent;