import React, { useEffect, useState } from 'react';
import { SidePanel, LargeButton, CancelButton } from '../../../components'
import { Form, Row, Col, Image } from 'react-bootstrap';
import { logger, toaster } from '../../../../Utils'
import { postAPI } from '../../../../Api';
import { validate } from '../../../../Utils/Validation/validation';
import { ReferenceValidationSchema } from './ValidationSchema/ReferenceValidationSchema';
import NumberFormat from 'react-number-format';
import { phoneNumberSanitizer } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import Cookies from 'js-cookie';
import './style.scss';
import { Numbering } from '../../../../Utils/Numbering/Numbering';

const log = logger('PersonalReferencesInfo');
const PersonalReferencesInfo = (props) => {
  const { personal_reference, isShow, handleClose, setPetsInfo, user_id } = props;
  const role = Number(Cookies.get('role'));
  const [personal_info, setPersonal_info] =
    useState([
      {
        id: 0,
        name: '',
        relationship: '',
        phone_number: ''
      }
    ]);
  const [isSubmitClicked,setIsSubmitClicked]=useState(false)

  useEffect(() => {
    if (personal_reference && personal_reference.length > 0) {
      setPersonal_info(personal_reference)
    }
  }, [personal_reference])

  const addMore = () => {
    const data = {
      id: 0,
      name: '',
      relationship: '',
      phone_number: ''
    }
    setPersonal_info(personal_info => [...personal_info, data])
  }

  // const removeError = (e, i) => {
  //   let errorElement = e.target.id
  //   let eles = document.getElementsByClassName('is-invalid')
  // }

  const OnChangeInputArray = (event, index) => {
    log.info('name', event.target.id)
    // removeError(event, index)
    let name = event.target.name;
    let items = [...personal_info];
    let item = { ...items[index] };
    if (name === 'phone_number') {
      item[name] = phoneNumberSanitizer(event.target.value);
    } else {
      item[name] = event.target.value;
    }
    items[index] = item;
    setPersonal_info(items);
    isSubmitClicked && setTimeout(()=>{
      validate(ReferenceValidationSchema)
    },300)
  }

  const OnRemove = (index) => {
    let items = [...personal_info];
    items.splice(index, 1);
    setPersonal_info(items);
  }

  const onSubmit = () => {
    setIsSubmitClicked(true)
    if (validate(ReferenceValidationSchema)) {
      let url = '';
      if (user_id) {
        url = '/admin/users/tenant/references-info/' + user_id;
      } else {
        url = '/tenant/profile/references-info';
      }
      const data = personal_info;
      postAPI(url, data).then(response => {
        if (response === undefined) {
          toaster('error', 'Something went wrong!');
        } else if (response.status === 200) {
          handleClose()
          if (role !== 1) {
            setPetsInfo(true)
          }
          toaster('success', 'Data Saved Successfully!')
        }
      })
    }
  }

  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={role !== 1 ? 'My Profile' : 'edit tenant profile'}
      subTitle={role !== 1 ? 'Personal References Goes Here' : 'Reference'}>

      <div className='sidepanel-body'>
        <Form id='Form'>
          {personal_info ?
            personal_info.map((data, i) => {
              return (
                <div key={'personal_info_' + i}>
                  {i > 0 ?
                    <>
                      <hr />
                      <div className='sub-title d-flex justify-content-between align-items-center'>
                        <h3>{Numbering(i + 1) + ' Reference'}</h3>
                        <Image
                          className='icon'
                          width='20px'
                          height='20px'
                          title='Remove'
                          onClick={() => OnRemove(i)}
                          src={require('../../../../_assets/images/delete-icon.svg')}
                        />
                      </div>
                    </> : ''}

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">Personal reference name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Full Name'
                        name='name'
                        id={'name' + i}
                        value={data.name}
                        onChange={(e) => OnChangeInputArray(e, i)}
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">Relationship</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Supervisor'
                        name='relationship'
                        id={'relationship' + i}
                        value={data.relationship}
                        onChange={(e) => OnChangeInputArray(e, i)}
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">Phone number</Form.Label>
                      <NumberFormat
                        format='###-###-####'
                        mask='_'
                        placeholder='123-123-123'
                        name='phone_number'
                        id='phone_number'
                        className='form-control'
                        value={data.phone_number ? data.phone_number : ''}
                        onChange={(e) => OnChangeInputArray(e, i)}
                      />
                    </Form.Group>
                  </Row>
                </div>)
            }) : ''}
        </Form>

        <Row className='d-flex justify-content-center'>
          <Col md='10'>
            <LargeButton
              variant='dark'
              title='add more'
              isIcon={true}
              onClick={() => addMore()} />
          </Col>
        </Row>
      </div>

      <div className={role !== 1 ? 'fixed-bottom d-flex justify-content-between' : 'fixed-bottom'}>
        {role !== 1 ?
          <>
            <CancelButton onClick={() => handleClose()} title='Back' />
            <LargeButton onClick={() => onSubmit()} title={'continue'} style={{ width: '200px', float: 'right' }} />
          </> :
          <Row>
            <Col md={12}>
              <LargeButton onClick={() => onSubmit()} title={'save changes'} style={{ width: '200px', float: 'right' }} />
            </Col>
          </Row>}
      </div>
    </SidePanel>
  </>);
}

export default PersonalReferencesInfo;