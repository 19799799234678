import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { logger, toaster } from '../../../../Utils';
import { postAPI } from '../../../../Api';
import { SingleOptionCheck, LargeButton, ModalBox, Loader } from '../../';
import { rentDeposite } from '../../../../Constants/LeaseConstants';
import { validate } from '../../../../Utils/Validation/validation';
import { TenantDetailSchema } from './CreateLeaseSchema/TenantDetailSchema';
import { Prompt } from 'react-router';

const log = logger("CreateLeaseTenantDetails");
const CreateLeaseTenantDetails = (props) => {
	const { callAddTenant, setCurrentStep, property_id, property_details_id, leaseData, lease_id, selectedTenant, setSelectedTenant } = props;
	const [required_tenant_insurance, setTenantInssurance] = useState(0);
	const [selectedTenantData, setSelectedTenantData] = useState(selectedTenant);
	const [saveLoading, setBtnDisable] = useState(false);
	const [saveState, setSaveState] = useState('');
	const [is_lease_updated, setLease_updated] = useState(false);
	const [isSaveEnable, setSaveEnable] = useState(false);
	const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);


	const backToPrevPage = () => {
		setCurrentStep(1);
		props.history.push({
			pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
			state: {
				lease_id: lease_id,
				step: 1
			}
		});
	}
	const OnSingleOptionCheck = (event) => {
		const Value = event.target.value;
		const name = event.target.getAttribute('name');
		if (name === 'required_tenant_insurance') {
			setTenantInssurance(Value);
		}
		setSaveEnable(true);
		setShouldBlockNavigation(true);
	}

	const addtenant = () => {
		callAddTenant(true);
		setSaveEnable(true);
		setShouldBlockNavigation(true);
	}

	const OnRemove = (index, data) => {
		console.log('selectedTenantData', selectedTenantData);
		let items = [...selectedTenantData];
		items.splice(index, 1);
		setSelectedTenantData(items);
		setSelectedTenant(items)
		setSaveEnable(true);
		setShouldBlockNavigation(true);
	}

	log.info('leaseData', leaseData);

	const onSubmit = (event) => {
		event.preventDefault();
		setShouldBlockNavigation(false);
		if (validate(TenantDetailSchema)) {
			setBtnDisable(true)
			const data = {
				'lease_id': lease_id,
				'property_id': property_id,
				'property_detail_id': property_details_id,
				'required_tenant_insurance': required_tenant_insurance,
				'tenants': selectedTenantData,
				'action': saveState
			}
			const url = '/landlord/listing/lease/tenant-details';
			postAPI(url, data).then(res => {
				setBtnDisable(false)
				if (res.status === 200) {
					saveState !== 'update' && isSaveEnable && toaster('success', 'Data Saved Successfully!');
					if (saveState === 'next') {
						setCurrentStep(3);
						props.history.push({
							pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
							state: {
								lease_id: lease_id,
								step: 3
							}
						});
					} else if (saveState === 'update') {
						setLease_updated(true);
					}
				} else {
					toaster('error', 'Please add tenant!');
				}
			})
		}

	}


	useEffect(() => {
		const fetchedData = leaseData;
		if (fetchedData && fetchedData.tenant_details) {
			setTenantInssurance(fetchedData.tenant_details.required_tenant_insurance);
			// setSelectedTenantData(fetchedData.tenant_details.tenants.length !== 0 ? fetchedData.tenant_details.tenants : [])
		}
	}, [leaseData]);

	useEffect(() => {
		setSelectedTenantData(selectedTenant)
	}, [selectedTenant])

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const handleClickInside = (option) => {
		setIsPopoverOpen(option === 'yes' ? true : false);
	}
	console.log('selectedTenantData', selectedTenantData, selectedTenant, leaseData);
	return (<>
		<Prompt
			when={shouldBlockNavigation}
			message="your information will be lost do you want to leave this page?"
		/>
		{!leaseData ? <Loader /> : <Form id='Form' onSubmit={onSubmit}>
			<div className='inner-wrapper'>
				<div className='body-container'>
					<div className='body-header'>
						<h3 className='text-left lease-heading'>tenant details</h3>
						{leaseData && leaseData.is_sent_to_tenant ?
							<button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>
							: <button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>}
					</div>

					<Row>
						<Form.Group as={Col} md='12'>
							<Form.Label>Your tenant(s)</Form.Label>
							<ul className='select-options'>
								{selectedTenantData && selectedTenantData.map((tenant, i) => {
									return (<li className="active">{tenant.name}
										<Image
											className="icon cursorPointer ml-3"
											width="14px"
											height="15px"
											title="Remove"
											onClick={() => OnRemove(i, tenant.tenant_id)}
											src={require("../../../../_assets/images/delete.svg")}
										/>
									</li>)
								})}
							</ul>
							<LargeButton isIcon={true} title='Add Tenant Info' variant='dark' onClick={() => addtenant()} style={{ display: 'block', maxWidth: "300px", height: "48px", padding: "0px" }} />
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='7'>
							<Form.Label>Would you like to require your tenant to have tenant’s insurance?</Form.Label>
							<span className='warning-popover'>
								<img className='cursorPointer ml-2 mb-1'
									onMouseEnter={() => handleClickInside('yes')}
									onMouseLeave={() => handleClickInside('no')}
									src={require('../../../../_assets/images/exclaim.svg')} width={16} height={16} alt='warning' />
								{(isPopoverOpen === true) && <div className="custom-popover extra">
									<p className='popover-content' style={{ height: 'auto', minHeight: 'auto' }}>You can make tenant insurance mandatory as part of your lease, protecting you from a tenant assuming your homeowners insurance covers them for relocation costs, content loss, property damage or an unnecessary lawsuit in the event of a dispute.</p>
								</div>}
							</span>
							<SingleOptionCheck
								mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'required_tenant_insurance'} setParam={required_tenant_insurance}
							/>
						</Form.Group>
					</Row>
				</div>
			</div>

			<div className='container-footer'>
				<Button variant='secondary' onClick={() => backToPrevPage()}>Back</Button>
				{leaseData && leaseData.is_sent_to_tenant ?
					<Button disabled={saveLoading === false ? false : true} variant='success' type='submit' onClick={() => setSaveState('update')}>Update {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
					: <Button disabled={saveLoading === false ? false : true} variant='success' type='submit' onClick={() => setSaveState('next')}>Next {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
				}
			</div>
		</Form>}

		<ModalBox
			show={is_lease_updated}
			onHide={() => setLease_updated(false)}
			size="sm"
			actionbuttontitle={"got it"}
			buttonaction={() => {
				setLease_updated(false);
				setCurrentStep(3);
			}}
		>
			<h4>your lease has been updated</h4>
			<p>the tenant has been <br /> notified of the changes. </p>
		</ModalBox>
	</>);
}

export default withRouter(CreateLeaseTenantDetails);
