import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { logger, toaster } from '../../../../Utils';
import { phoneNumberSanitizer } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import NumberFormat from 'react-number-format';
import { postAPI } from '../../../../Api';
import { AutoCompleteAddress, SingleOptionCheck, ModalBox } from '../../';
import { rentDeposite } from '../../../../Constants/LeaseConstants';
import { ContactSchema } from '../CreateLeaseSteps/CreateLeaseSchema/ContactSchema';
import { validate } from '../../../../Utils/Validation/validation';
import { useSelector } from "react-redux";
import { updateRawData } from '../../../../Store/actions/RawDataAction';

const condo = [{ display: 'Yes', value: 1 }, { display: 'No', value: 0 }]
const log = logger('CreateLeaseContactInfo');
const CreateLeaseContactInfo = (props) => {
	const { setCurrentStep, property_id, property_details_id, leaseData, lease_id, setLeaseId } = props;
	const [full_name_contact, setFullName] = useState('');
	const [emailAddress_contact, setEmail] = useState('');
	const [phone_number_contact, setPhoneNumber] = useState('');
	const [agree_to_received_notice, setAgree_to_received_notice] = useState(0);
	const [agree_to_received_notices_email, setAgree_to_received_notice_email] = useState('');
	const [day_to_day_communication, setDay_to_day_communication] = useState(0);
	const [day_to_day_communication_email, setDay_to_day_communication_email] = useState('');
	const [day_to_day_communication_phone_number, setDay_to_day_communication_phone_number] = useState('');
	const [full_address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [province, setProvince] = useState('');
	const [country, setCountry] = useState('');
	const [postal_code, setPostal_code] = useState('');
	const [latitude, setLatitude] = useState(34.059849);
	const [longitude, setLongitude] = useState(-118.455551);
	const [unit_no, setUnit_no] = useState('');
	const [is_condo, setCondo] = useState(0);
	const [mailing_full_address, setMailing_Address] = useState('');
	const [mailing_city, setMailing_City] = useState('');
	const [mailing_province, setMailing_Province] = useState('');
	const [mailing_country, setMailing_Country] = useState('');
	const [mailing_postal_code, setMailing_Postal_code] = useState('');
	const [mailing_latitude, setMailing_Latitude] = useState(34.059849);
	const [mailing_longitude, setMailing_Longitude] = useState(-118.455551);
	const [mailing_unit_no, setMailing_Unit_no] = useState('');
	const [phoneError, setPhoneError] = useState(false);
	const [communicationPhoneError, setCommunicationPhoneError] = useState(false);
	const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
	const profileDetails = useSelector(state => state.editProfile.editProfileList);
	const isLeaseFromTenantApplication = useSelector(state => state.messageDetailsReducer.isLeaseFromTenantApplication);
	const [saveLoading, setBtnDisable] = useState(false);
	const [saveState, setSaveState] = useState('');
	const [is_lease_updated, setLease_updated] = useState(false);
	const [isSaveEnable, setSaveEnable] = useState(false);


	log.info('profileDetails', profileDetails);

	const OnChangeInput = (event) => {
		if (event.target.name === 'phone_number_contact') {
			const change = phoneNumberSanitizer(event.target.value);
			if (isNaN(Number(change))) {
				setPhoneError(true)
			} else {
				setPhoneError(false)
			}
			setPhoneNumber(change)
		} else if (event.target.name === 'day_to_day_communication_phone_number') {
			const change = phoneNumberSanitizer(event.target.value);
			if (isNaN(Number(change))) {
				setCommunicationPhoneError(true)
			} else {
				setCommunicationPhoneError(false)
			}
			setDay_to_day_communication_phone_number(change)
		}
		setSaveEnable(true);
	}

	const backToPrevPage = () => {
		props.history.push({
			pathname: `/property-details/${property_id}/${property_details_id}/leases`
		})
	}

	const OnSingleOptionCheck = (event) => {
		const Value = event.target.value;
		const name = event.target.getAttribute('name');
		if (name === 'is_condo') {
			setCondo(Value);
		} else if (name === 'agree_to_received_notice') {
			setAgree_to_received_notice(Value);
		} else if (name === 'day_to_day_communication') {
			setDay_to_day_communication(Value);
		}
		setSaveEnable(true);
	}

	const handlePlaceChanged = (place) => {
		log.info("place ===>", place);
		let address_components = [];
		if (place.address_components && place.address_components.length > 0) {
			place.address_components.map(ac => {
				if (ac.types.includes('street_number') || ac.types.includes('route')) {
					address_components.push(ac.long_name)
				}
			})
		}
		const address = address_components.join(' ');
		setAddress(address);
		getPlaceDetails(place.address_components, 'rental');
		setLatitude(place.geometry.location.lat())
		setLongitude(place.geometry.location.lng())
		setSaveEnable(true);
	}

	const handleChange = event => {
		setAddress(event.target.value);
		setSaveEnable(true);
	}

	const handlePlaceChangedMailing = (place) => {
		log.info("handlePlaceChangedMailing place ===>", place);
		let address_components = [];
		if (place.address_components && place.address_components.length > 0) {
			place.address_components.map(ac => {
				if (ac.types.includes('street_number') || ac.types.includes('route')) {
					address_components.push(ac.long_name)
				}
			})
		}
		const address = address_components.join(' ');
		setMailing_Address(address);
		getPlaceDetails(place.address_components, 'mailing');
		setMailing_Latitude(place.geometry.location.lat())
		setMailing_Longitude(place.geometry.location.lng());
		setSaveEnable(true);
		document.getElementById('mailing_city').click();
	}

	const handleChangeMailing = event => {
		log.info("handleChangeMailing", event.target.value)
		setMailing_Address(event.target.value);
		setSaveEnable(true);
	}

	const getPlaceDetails = (addressArray, state) => {
		for (let i = 0; i < addressArray.length; i++) {
			if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
				if (state === 'mailing') {
					setMailing_City(addressArray[i].long_name)
				} else {
					setCity(addressArray[i].long_name)
				}
			} else if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
				if (state === 'mailing') {
					setMailing_Province(addressArray[i].long_name)
				} else {
					setProvince(addressArray[i].long_name)
				}
			} else if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
				if (state === 'mailing') {
					setMailing_Country(addressArray[i].long_name)
				} else {
					setCountry(addressArray[i].long_name)
				}
			} else if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
				if (state === 'mailing') {
					setMailing_Postal_code(addressArray[i].long_name)
				} else {
					setPostal_code(addressArray[i].long_name)
				}
			}
		}
		setSaveEnable(true);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		if (validate(ContactSchema)) {
			setBtnDisable(true)
			const data = {
				'lease_id': lease_id,
				'property_id': property_id,
				'property_detail_id': property_details_id,
				'full_name': full_name_contact,
				'email': emailAddress_contact,
				'phone_number': phone_number_contact,
				'agree_to_received_notice': agree_to_received_notice,
				'agree_to_received_notices_email': agree_to_received_notices_email,
				'day_to_day_communication': day_to_day_communication,
				'day_to_day_communication_email': day_to_day_communication_email,
				'day_to_day_communication_phone_number': day_to_day_communication_phone_number,
				'latitude': latitude,
				'longitude': longitude,
				'address': full_address,
				'unit_no': unit_no,
				'province': province,
				'country': country,
				'city': city,
				'postal_code': postal_code,
				'mailing_address': mailing_full_address,
				'mailing_latitude': mailing_latitude,
				'mailing_longitude': mailing_longitude,
				'mailing_unit': mailing_unit_no,
				'mailing_province': mailing_province,
				'mailing_country': mailing_country,
				'mailing_city': mailing_city,
				'mailing_postal_code': mailing_postal_code,
				'is_condo': is_condo,
				'is_tenancy_application': isLeaseFromTenantApplication,
				'action': saveState
			}
			const url = '/landlord/listing/lease/contact-details';
			postAPI(url, data).then(res => {
				setBtnDisable(false)
				if (res.status === 200) {
					const leaseId = res.data.data.lease_id;
					updateRawData({ leaseId: res.data.data.lease_id })
					setLeaseId(leaseId);
					if (saveState === 'next') {
						setCurrentStep(1);
						props.history.push({
							pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + leaseId,
							state: {
								lease_id: leaseId,
								step: 1
							}
						});
					} else if (saveState === 'update') {
						setLease_updated(true);
					} else {
						props.history.push({
							pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + leaseId,
							state: {
								lease_id: leaseId,
								step: 0
							}
						});
					}

					saveState !== 'update' && isSaveEnable && toaster('success', 'Data Saved Successfully!');
				} else {
					toaster('error', res.response.data.message);
				}
			})
		}
	}

	useEffect(() => {
		const fetchedData = leaseData;
		if ((fetchedData && fetchedData.contact_details) || propertyDetails) {
			setFullName(profileDetails ? profileDetails.data.name : fetchedData && fetchedData.contact_details.full_name);
			setEmail(profileDetails ? profileDetails.data.email : fetchedData && fetchedData.contact_details.email);
			setPhoneNumber(profileDetails ? profileDetails.data.phone : fetchedData && fetchedData.contact_details.phone_number);
		}
		if (fetchedData && fetchedData.notices_and_documents) {
			setAgree_to_received_notice(fetchedData.notices_and_documents.agree_to_received_notice);
			setAgree_to_received_notice_email(fetchedData.notices_and_documents.agree_to_received_notices_email);
			setDay_to_day_communication(fetchedData.notices_and_documents.day_to_day_communication);
			setDay_to_day_communication_email(fetchedData.notices_and_documents.day_to_day_communication_email);
			setDay_to_day_communication_phone_number(fetchedData.notices_and_documents.day_to_day_communication_phone_number);
		}



		if (fetchedData && fetchedData.landlord_mailing_address) {
			setMailing_Address(fetchedData.landlord_mailing_address.mailing_address);
			setMailing_City(fetchedData.landlord_mailing_address.mailing_city);
			setMailing_Province(fetchedData.landlord_mailing_address.mailing_province);
			setMailing_Country(fetchedData.landlord_mailing_address.mailing_country);
			setMailing_Postal_code(fetchedData.landlord_mailing_address.mailing_postal_code !== null && fetchedData.landlord_mailing_address.mailing_postal_code);
			setMailing_Unit_no(fetchedData.landlord_mailing_address.mailing_unit_no !== null ? fetchedData.landlord_mailing_address.mailing_unit_no : "");
			setMailing_Latitude(fetchedData.landlord_mailing_address.mailing_latitude);
			setMailing_Longitude(fetchedData.landlord_mailing_address.mailing_longitude);
		}

		if (propertyDetails) {
			setAddress(propertyDetails.address_line_1);
			setUnit_no(propertyDetails.unit_no);
			setCity(propertyDetails.city);
			setPostal_code(propertyDetails.postal_code);
			setProvince(propertyDetails.province);
			setLatitude(propertyDetails.latitude);
			setLongitude(propertyDetails.longitude);
			setCondo(propertyDetails.is_condo ? 1 : 0);
		}
		if (fetchedData && fetchedData.property_address) {
			log.info("fetchedData.property_address", fetchedData.property_address);
			setAddress(fetchedData.property_address.address);
			setUnit_no(fetchedData.property_address.unit_no);
			setCity(fetchedData.property_address.city);
			setPostal_code(fetchedData.property_address.postal_code);
			setProvince(fetchedData.property_address.province);
			setLatitude(fetchedData.property_address.latitude);
			setLongitude(fetchedData.property_address.longitude);
			setCondo(fetchedData.property_address.is_condo);
		}
	}, [leaseData, profileDetails, propertyDetails]);
	useEffect(() => {
		if (mailing_full_address === '') {
			setMailing_City('')
			setMailing_Province('')
			setMailing_Postal_code('')
		}
		if (full_address === '') {
			setCity('')
			setProvince('')
			setPostal_code('')
		}
	}, [mailing_full_address, full_address])
	log.info("full_address", propertyDetails);
	return (<>

		<Form id='Form' onSubmit={onSubmit}>
			<div className='inner-wrapper cu-step-form-body'>
				<div className='body-container'>
					<div className='body-header'>
						<h3 className='text-left'>landlord contact information</h3>
						{leaseData && leaseData.is_sent_to_tenant ?
							<button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>
							: <button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>}
					</div>

					<Row>
						<Form.Group as={Col} md='4'>
							<Form.Label>Your Full Name</Form.Label>
							<Form.Control
								type='text'
								name='full_name_contact'
								id='full_name_contact'
								value={full_name_contact}
								placeholder='Type here'
								onChange={(e) => setFullName(e.target.value)}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='4'>
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type='text'
								name='emailAddress_contact'
								id='emailAddress_contact'
								value={emailAddress_contact}
								placeholder='Type here'
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='4'>
							<Form.Label>Phone Number</Form.Label>
							<NumberFormat
								format='###-###-####'
								mask='_'
								placeholder='Enter Phone Number'
								name='phone_number_contact'
								id='phone_number_contact'
								className={phoneError === true ? 'form-control is-invalid' : 'form-control'}
								value={phone_number_contact ? phone_number_contact : ''}
								onChange={(e) => OnChangeInput(e)}
							/>
							{phoneError === true && <div id="error_unit_no" className="invalid-feedback">Phone number should be 10 digits</div>}
						</Form.Group>
					</Row>

					<Row>
						<Form.Group as={Col} md='12'>
							<Form.Label>Both the landlord and tenant agree to receive notices and documents by email, where allowed by the Landlord and Tenant Board’s Rules of Procedure</Form.Label>
							<div className='d-flex justify-content-flex-start align-items-center'>
								<SingleOptionCheck
									mapData={rentDeposite}
									OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
									name={'agree_to_received_notice'}
									setParam={agree_to_received_notice}
									extraClass={'d-inline-flex mb-0'}
								/>
								{agree_to_received_notice ?
									<Form.Group as={Col} md='4' className='mb-0'>
										<Form.Control
											type='text'
											name='agree_to_received_notices_email'
											id='agree_to_received_notices_email'
											value={agree_to_received_notices_email}
											placeholder='name@mail.com'
											onChange={(e) => setAgree_to_received_notice_email(e.target.value)}
										/>
									</Form.Group> : ''}
							</div>
						</Form.Group>
					</Row>

					<Row>
						<Form.Group as={Col} md='12'>
							<Form.Label>The landlord is providing phone and/or email contact information for emergencies or day-to-day communications</Form.Label>
							<div className='d-flex justify-content-flex-start align-items-center'>
								<SingleOptionCheck
									mapData={rentDeposite}
									OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
									name={'day_to_day_communication'}
									setParam={day_to_day_communication}
									extraClass={'d-inline-flex mb-0'}
								/>
								{day_to_day_communication ?
									<>
										<Form.Group as={Col} md='4' className='mb-0'>
											<Form.Control
												type='text'
												name='day_to_day_communication_email'
												id='day_to_day_communication_email'
												value={day_to_day_communication_email}
												placeholder='name@mail.com'
												onChange={(e) => setDay_to_day_communication_email(e.target.value)}
											/>
										</Form.Group>
										<Form.Group as={Col} md='4' className='mb-0'>
											<NumberFormat
												format='###-###-####'
												mask='_'
												placeholder='Enter Phone Number'
												name='day_to_day_communication_phone_number'
												id='day_to_day_communication_phone_number'
												className={communicationPhoneError === true ? 'form-control is-invalid' : 'form-control'}
												value={day_to_day_communication_phone_number ? day_to_day_communication_phone_number : ''}
												onChange={(e) => OnChangeInput(e)}
											/>
											{communicationPhoneError === true && <div id="error_unit_no" className="invalid-feedback">Phone number should be 10 digits</div>}
										</Form.Group>
									</> : ''}
							</div>
						</Form.Group>
					</Row>

					<hr />
					<h3 className='text-left mt-4'>landlord mailing address</h3>
					<Row>
						<Form.Group as={Col} md='6'>
							<AutoCompleteAddress
								md='12'
								as={Col}
								id='mailing_current_address'
								name='mailing_current_address'
								state={mailing_full_address}
								updateField={(data) => handleChangeMailing(data)}
								handlePlaceChanged={(data) => handlePlaceChangedMailing(data)}
								addressTitle={'Address'}
							/>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Unit</Form.Label>
							<Form.Control
								type='text'
								name='mailing_unit_no'
								id='mailing_unit_no'
								value={mailing_unit_no}
								placeholder='Type here'
								onChange={(e) => { setSaveEnable(true); setMailing_Unit_no(e.target.value); }}
								maxLength={15}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='4'>
							<Form.Label>City</Form.Label>
							<Form.Control
								type='text'
								name='mailing_city'
								id='mailing_city'
								value={mailing_city}
								placeholder='Type here'
								onChange={(e) => setMailing_City(e.target.value)}
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Province</Form.Label>
							<Form.Control
								type='text'
								name='mailing_province'
								id='mailing_province'
								value={mailing_province}
								placeholder='Type here'
								onChange={(e) => setMailing_Province(e.target.value)}
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Postal Code</Form.Label>
							<Form.Control
								type='text'
								name='mailing_postal_code'
								id='mailing_postal_code'
								value={mailing_postal_code}
								placeholder='Type here'
								onChange={(e) => setMailing_Postal_code(e.target.value)}
								readOnly
							/>
						</Form.Group>
					</Row>

					<hr />
					<h3 className='text-left mt-4'>rental property address</h3>
					<Row>
						<Form.Group as={Col} md='6'>
							<AutoCompleteAddress
								md='12'
								as={Col}
								id='current_address'
								name='current_address'
								state={full_address}
								updateField={(data) => handleChange(data)}
								handlePlaceChanged={(data) => handlePlaceChanged(data)}
								addressTitle={'Address'}
							/>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Unit</Form.Label>
							<Form.Control
								type='text'
								name='unit_no'
								id='unit_no'
								value={unit_no}
								placeholder='Type here'
								onChange={(e) => { setSaveEnable(true); setUnit_no(e.target.value) }}
								maxLength={15}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='4'>
							<Form.Label>City</Form.Label>
							<Form.Control
								type='text'
								name='city'
								id='city'
								value={city}
								placeholder='Type here'
								onChange={(e) => setCity(e.target.value)}
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Province</Form.Label>
							<Form.Control
								type='text'
								name='province'
								id='province'
								value={province}
								placeholder='Type here'
								onChange={(e) => setProvince(e.target.value)}
								readOnly
							/>
						</Form.Group>
						<Form.Group as={Col} md='2'>
							<Form.Label>Postal Code</Form.Label>
							<Form.Control
								type='text'
								name='postal_code'
								id='postal_code'
								value={postal_code}
								placeholder='Type here'
								onChange={(e) => setPostal_code(e.target.value)}
								readOnly
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md='6'>
							<Form.Label>Is this rental unit a condo?</Form.Label>
							<ul className='select-options'>
								{condo.map((data) =>
									<li onClick={(e) => OnSingleOptionCheck(e, this)} key={'condo_' + data.value} value={data.value} name='is_condo' id='is_condo'
										className={(is_condo === data.value) ? 'active' : ''}>{data.display}</li>)}
							</ul>
						</Form.Group>
					</Row>
				</div>
			</div>
			<div className='container-footer cu-step-form-footer'>
				<Button variant='secondary' onClick={() => backToPrevPage()}>Back</Button>
				{leaseData && leaseData.is_sent_to_tenant ?
					<Button variant='success' type='submit' disabled={saveLoading === false ? false : true} onClick={() => setSaveState('update')}>Update {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
					: <Button variant='success' type='submit' disabled={saveLoading === false ? false : true} onClick={() => setSaveState('next')}>Next {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
				}
			</div>
		</Form>

		<ModalBox
			show={is_lease_updated}
			onHide={() => setLease_updated(false)}
			size="sm"
			actionbuttontitle={"got it"}
			buttonaction={() => {
				setLease_updated(false);
				setCurrentStep(3);
			}}
		>
			<h4>your lease has been updated</h4>
			<p>the tenant has been <br /> notified of the changes. </p>
		</ModalBox>
	</>);
}

export default withRouter(CreateLeaseContactInfo);
