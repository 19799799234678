import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { LargeButton } from "../../../components"
import { logger, toaster } from "../../../../Utils"
import moment from 'moment';
import {
  pet_policyData,
  // amenitiesData,
  getUtilitiesAndAmenities,
  laundryForHouseData,
  laundryForUnitData,
  isSharedBathroomData,
  bathroomsData,
  bedroomsData
} from "../../../../Constants/propertyDetails"
import { postAPI } from "../../../../Api";
// import { validate } from "../../../../Utils/Validation/validation";
// import { StepFourValidationSchema } from "./ValidationSchema/StepFourValidationSchema";
import { Prompt } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentPage } from "../../../../Store/actions/PropertyDetailAction";
import { useLocation } from 'react-router-dom';

const log = logger("StepFour");
const StepFour = (props) => {
  const { backToPrevPage, setSuccessModalShow } = props

  const dispatch = useDispatch();
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  // const property_type = propertyDetails && propertyDetails.property_type;
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];
  log.info("currentPath",currentPath);

  const [description, setDescription] = useState('');
  const [isPrePopulate, setIsPrePopulate] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
	const [amenitiesData, setAmenitiesData] = useState([]);

  useEffect(() => {
    if (unitDetails) {
      setDescription(unitDetails.description)
    }
    (async() => {
      const {amenitiesData=[]}=await getUtilitiesAndAmenities()
      setAmenitiesData(amenitiesData)
    })()
  }, [unitDetails])

  const OnChangeInput = (event) => {
    setDescription(event.target.value)
    setShouldBlockNavigation(true);
  }

  const PrePopulateData = () => {
    const bedrooms = bedroomsData.map((data) => {
      let title = '';
      if (unitDetails.bedrooms === data.value) {
        title = data.title;
      }
      return title;
    }).join("") + ' bedroom'

    const bathrooms = bathroomsData.map((data) => {
      let title = '';
      if (unitDetails.bathrooms === data.value) {
        title = data.title;
      }
      return title;
    }).join("") + ' bathroom'

    const is_shared_bathroom = isSharedBathroomData.map((data) => {
      let title = '';
      if (unitDetails.is_shared_bathroom === data.value) {
        title = data.title;
      }
      return title;
    }).join('')+' bedroom'

    const shortAddress = propertyDetails.address_line_1
    const parking_slot = unitDetails.parking_slot
    const available_date = moment(unitDetails.available_date).format("MMMM D, YYYY")
    const price_per_month = unitDetails.price_per_month
    log.info("amenitiesData", amenitiesData)
    const amenity = amenitiesData.filter((data) => {
      let title = '';
      if (unitDetails.amenities.includes(data.value)) {
        title = data.title;
      }
      return title;
    }).map(function (obj) {
      return obj.title;
    }).join(", ")

    const laundryData = (propertyDetails.property_type === 1) ? laundryForHouseData : laundryForUnitData;
    const laundry = laundryData.map((data) => {
      let title = '';
      if (unitDetails.laundry === data.value) {
        title = 'Laundry ' + data.title;
      }
      return title;
    }).join("")

    const pet_policy = pet_policyData.map((data) => {
      let title = '';
      if (unitDetails.pet_policy === data.value) {
        title = data.title;
        if (data.value === 2) {
          title = "pets are " + data.title;
        }
      }
      return title;
    }).join("")

    console.log(is_shared_bathroom)

    const preDescription = "This beautiful "
      + ((propertyDetails.property_type === 3) ? is_shared_bathroom : bedrooms + ", " + bathrooms) +
      ((propertyDetails.property_type !== 3) ? ' place' : '') +" could be your perfect place to call home! \n\n" +
      "Located on " + shortAddress + ", this home is in a great neighbourhood that is close to schools, grocery stores, transit and tons of amenities.\n\n" +
      "The home features lots of space, great natural light, " + (parking_slot !== 0 ? parking_slot : 'no') + " parking " + (parking_slot > 1 ? 'spaces, ' : 'space, ') +
      (amenity ? amenity.toLowerCase() + ", " : "") + laundry.toLowerCase() + ", " + pet_policy.toLowerCase() + " and could be yours as of " +
      available_date + " for $" + price_per_month + "/month.\n\n" +
      "If you are interested, please make sure your profile is complete and reach out to schedule a showing. We look forward to hearing from you!\n"
    setIsPrePopulate(true)
    setDescription(preDescription)
  }

  const onSubmit = (type) => {
    //if (validate(StepFourValidationSchema)) {
      const url = '/landlord/create-listing/step-4';
      const data = {
        property_id: property_id,
        property_details_id: property_details_id,
        description: description
      }
      postAPI(url, data).then(response => {
        if (response.status !== 200) {
          if(response.response){
            toaster('error', response.response.data.message);
          }
        } else if (response.status === 200) {
          if (currentPath === 'edit-listing') {
            setSuccessModalShow(true)
          } else {
            toaster('success', 'Data Saved Successfully!')
          }
          if (type === 'next') {
            dispatch(getCurrentPage(4));
          }
          setShouldBlockNavigation(false);
        }
      })
    //}
  }
  // useEffect(() => {
  //   if (shouldBlockNavigation) {
  //     window.onbeforeunload = () => true;
  //   } else {
  //     window.onbeforeunload = undefined;
  //   }
  // });

  return (<>
  <Prompt
        when={shouldBlockNavigation}
        message="your information will be lost do you want to leave this page?"
      />
    <div className="inner-wrapper cu-step-form-body" style={{ minHeight: "500px" }}>
      <div className="body-container">
        <div className="body-header">
          <h3>tell us more about the place</h3>
          {!(currentPath === 'edit-listing') && <span className="save-link" onClick={() => onSubmit()}>Save</span>}
        </div>
        <Form id="Form">
          <Row>
            <Form.Group as={Col} md="8">
              <Form.Control
                as="textarea"
                rows="8"
                placeholder="here is where you can talk about the unique features of the home, the neighbourhood, the amenities and what you are looking for in a tenant."
                name="description"
                id="description"
                value={description}
                onChange={(e) => OnChangeInput(e)} />
              <Row>
                {isPrePopulate ?
                  <Col md={{ span: 8, offset: 4 }}>
                    <LargeButton
                      title="delete pre-populated description"
                      variant="secondary"
                      onClick={() => {
                        setDescription('')
                        setIsPrePopulate(false)
                      }}
                    />
                  </Col> :
                  <Col md={{ span: 6, offset: 6 }}>
                    <LargeButton
                      title="pre-populate"
                      variant="dark"
                      onClick={() => PrePopulateData()}
                    />
                  </Col>}
              </Row>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </div>

    <div className="container-footer cu-step-form-footer">
      {currentPath === 'edit-listing' ?
        <>
          <Button variant="secondary" onClick={() => backToPrevPage()}>Back</Button>
          <Button variant="success" onClick={() => onSubmit('save')}>Save</Button>
        </> :
        <>
          <Button variant="secondary" onClick={() => dispatch(getCurrentPage(2))}>Back</Button>
          <Button variant="success" onClick={() => onSubmit('next')}>Next</Button>
        </>
      }
    </div>
  </>);
}

export default StepFour;
