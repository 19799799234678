import React, { useEffect, useState } from 'react';
import { SidePanel, LargeButton, CancelButton } from '../../../components'
import { Form, Row, Col } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { tenant_statusData, pet_typeData } from '../../../../Constants/tenantDetails'
import { toaster } from '../../../../Utils'
import { postAPI } from '../../../../Api';
import { PetInfoValidationSchema } from './ValidationSchema/PetInfoValidationSchema';
import { validate } from '../../../../Utils/Validation/validation';
import CostNumberFormat from '../../CostNumberFormat';
import { numberFormatSanitizerInt } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import Cookies from 'js-cookie';
import './style.scss';

const PetsInfo = (props) => {
  const { pets_info, isShow, handleClose, setVehicleInfo, user_id } = props;
  const role = Number(Cookies.get('role'));
  const [id, setId] = useState(0);
  const [pet_status, setPet_status] = useState(null);
  const [pet_type, setPet_type] = useState([]);
  const [pet_count, setPet_count] = useState('');
  const [pet_description, setPet_description] = useState('');
  const [pay_pet_deposit, setPay_pet_deposit] = useState(0);
  const [pet_deposit_amount, setPet_deposit_amount] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  
  useEffect(() => {
    if (isSubmitClicked) {
      validate(PetInfoValidationSchema)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pet_status, pet_type, pet_count, pet_description, pay_pet_deposit, pet_deposit_amount])

  useEffect(() => {
    if (pets_info && pets_info['pet_info']) {
      setId(pets_info['pet_info'].id)
      setPet_status(pets_info.pet_status)
      setPet_type(pets_info['pet_info'].pet_type)
      setPet_count(pets_info['pet_info'].pet_count)
      setPet_description(pets_info['pet_info'].pet_description)
      setPay_pet_deposit(pets_info['pet_info'].pay_pet_deposit)
      setPet_deposit_amount(pets_info['pet_info'].pet_deposit_amount)
    }
    setIsSubmitClicked(false)
  }, [pets_info])

  useEffect(() => {
    pets_info && setPet_status(pets_info.pet_status)
  },[pets_info])


  const OnChangeInput = (event, setInputState) => {
    if (event.target.name === 'pet_deposit_amount') {
      const change = numberFormatSanitizerInt(event.target.value)
      setInputState(change)
    } else {
      setInputState(event.target.value)
    }

  }

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute('name');
    if (name === 'pet_status') {
      setPet_status(Value);
    } else if (name === 'pay_pet_deposit') {
      setPay_pet_deposit(Value);
    }
  }

  const OnMultipleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute('name');
    if (name === 'pet_type') {
      if (pet_type.includes(Value)) {
        const index = pet_type.indexOf(Value);
        pet_type.splice(index, 1);
        setPet_type(pet_type => [...pet_type])
      } else {
        setPet_type(pet_type => [...pet_type, Value])
      }
    }
  }

  const onSubmit = () => {
    setIsSubmitClicked(true);
    if (validate(PetInfoValidationSchema)) {
      let url = '';
      if (user_id) {
        url = '/admin/users/tenant/pet-info/' + user_id;
      } else {
        url = '/tenant/profile/pet-info';
      }
      const data = {
        pet_status: pet_status,
        pet_info: {
          id: id ? id : 0,
          pet_type: pet_type,
          pet_count: pet_count,
          pet_description: pet_description,
          pay_pet_deposit: pay_pet_deposit,
          pet_deposit_amount: pet_deposit_amount
        }
      }
      // !isSubmitClicked && postData(url, data)
      postData(url, data)
    }
  }

  const postData = (url, data) => {
    postAPI(url, data)
    .then(response => {
      if (response === undefined || (response.response && response.response.status === 422)) {
        response.response  ? toaster('error', 'Please select one of the two options') : toaster('error', 'Something went wrong!');
      } else if (response.status === 200) {
        handleClose()
        if (role !== 1) {
          setVehicleInfo(true)
        }
        toaster('success', 'Data Saved Successfully!')
      }
    })
    .catch(error => {

    })
  }

  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={role !== 1 ? 'My Profile' : 'edit tenant profile'}
      subTitle={role !== 1 ? 'Let’s Talk About Furry Friends' : 'Pet Info'}>

      <div className='sidepanel-body'>
        <Form id='Form'>
          <Row>
            <Form.Group as={Col} md='12' className='mb-0'>
              <Form.Label className="custom-lable">Do you have any pets?</Form.Label>
              <ul className='select-options'>
                {tenant_statusData.map((data) =>
                  <li onClick={(e) => OnSingleOptionCheck(e, this)} key={'pet_status_' + data.value} value={data.value} name='pet_status'
                   className={ pet_status === null ? '' : data.value === pet_status ? 'active' : '' } >{data.title}</li>)}
                    {/* className={(pet_status === data.value) ? 'active' : ''} */}
              </ul>
            </Form.Group>
          </Row>

          {pet_status === 1 ?
            <>
              <Row>
                <Form.Group as={Col} md='12' className='mb-0'>
                  <Form.Label className="custom-lable">What type?</Form.Label>
                  <ul className='select-options'>
                    {pet_typeData.map((data) =>
                      <li onClick={(e) => OnMultipleOptionCheck(e, this)} key={'pet_type_' + data.value} value={data.value} name='pet_type'
                        className={(pet_type.includes(data.value)) ? 'active' : ''}>{data.title}</li>)}
                  </ul>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md='12'>
                  <Form.Control
                    type='text'
                    placeholder='How many in total?'
                    name='pet_count'
                    id='pet_count'
                    value={pet_count}
                    onChange={(e) => OnChangeInput(e, setPet_count)}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label className="custom-lable">Tell Us About Your Pets (optional)</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='My pet is cute'
                    name='pet_description'
                    id='pet_description'
                    value={pet_description}
                    onChange={(e) => OnChangeInput(e, setPet_description)}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md='12' className='mb-0'>
                  <Form.Label className="custom-lable">Are you willing to voluntarily pay a pet deposit?</Form.Label>
                  <ul className='select-options'>
                    {tenant_statusData.map((data) =>
                      <li onClick={(e) => OnSingleOptionCheck(e, this)} key={'pay_pet_deposit_' + data.value} value={data.value} name='pay_pet_deposit'
                        className={(pay_pet_deposit === data.value) ? 'active' : ''}>{data.title}</li>)}
                  </ul>
                </Form.Group>
              </Row>

              {pay_pet_deposit ?
                <Row>
                  <Form.Group as={Col} md='12'>
                    <Form.Label className="custom-lable">How much pet deposit you would like to pay?</Form.Label>
                    <CostNumberFormat
                      name={'pet_deposit_amount'}
                      id={'pet_deposit_amount'}
                      classname='form-control'
                      value={pet_deposit_amount ? pet_deposit_amount : ''}
                      updateField={(e) => OnChangeInput(e, setPet_deposit_amount)}
                      pre='$'
                      placeholder={'$1,000'}
                    />
                  </Form.Group>
                </Row> : ''}

            </> : ''}
        </Form>
      </div>

      <div className={role !== 1 ? 'fixed-bottom d-flex justify-content-between' : 'fixed-bottom'}>
        {role !== 1 ?
          <>
            <CancelButton onClick={() => handleClose()} title='Back' />
            <LargeButton onClick={() => onSubmit()} title={'continue'} style={{ width: '200px', float: 'right' }} />
          </> :
          <Row>
            <Col md={12}>
              <LargeButton onClick={() => onSubmit()} title={'save changes'} style={{ width: '200px', float: 'right' }} />
            </Col>
          </Row>}
      </div>
    </SidePanel>
  </>);
}

export default PetsInfo;