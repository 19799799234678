import React, { useState, useEffect } from "react";
import "./style.scss";
import { Nav } from "react-bootstrap";
import { AppLayout, PageTitleHeader, Calender, RequestShowing, ConfirmShowing } from "../../components";
import { NavLink, withRouter } from "react-router-dom";
import MyListing from "./MyListing";
import MyHome from "./MyHome";
import { logger } from "../../../Utils";

const log = logger("TenantDashboard");
const TenantDashboard = (props) => {
  const [componentLoad, setComponentLoad] = useState(null);
  const [is_showingRequest, setShowingRequest] = useState(false);
  const [is_confirmShowing, setConfirmShowing] = useState(false);
  const [is_reSchedule, setReSchedule] = useState(false);
  const [viewingRequestId, setViewingRequestId] = useState(null);

  useEffect(() => {
    const slug = props.match.params.slug;
    setComponentLoad(slug)
  }, [props])

  return (<>
    <AppLayout>
      <div className="profile">
        <PageTitleHeader
          title='your dashboard'
        />

        <Nav fill variant="tabs" className="rp-tab-views">
          <NavLink to={{ pathname: "/dashboard/my-listing" }} className={componentLoad === "my-listing" || componentLoad === undefined ? "nav-link active" : "nav-link"} >Places I've Messaged</NavLink>
          <NavLink to={{ pathname: "/dashboard/my-home" }} className={"nav-link"} >My Home</NavLink>
          <NavLink to={{ pathname: "/dashboard/showings" }} className={"nav-link"} >Showings</NavLink>
        </Nav>
        {log.info("component load", componentLoad)}
        {componentLoad === "my-listing" || componentLoad === undefined ? <MyListing /> : ""}
        {componentLoad === "my-home" ? <MyHome /> : ""}
        {componentLoad === "showings" ?
          <Calender
            user_type={'tenant'}
            setConfirmShowing={(flag) => setConfirmShowing(flag)}
            setViewingRequestId={(data) => setViewingRequestId(data)} /> : ""}
      </div>
    </AppLayout>


    <RequestShowing
      isShow={is_showingRequest}
      title={is_reSchedule ? 'submit a different schedule' : 'creating showing invitation'}
      handleClose={() => setShowingRequest(false)}
      is_reSchedule={is_reSchedule}
      setReSchedule={(flag) => setReSchedule(flag)}
      viewingRequestId={viewingRequestId}
    />

    <ConfirmShowing
      isShow={is_confirmShowing}
      title={'showing invitation'}
      handleClose={() => setConfirmShowing(false)}
      setShowingRequest={(flag) => setShowingRequest(flag)}
      setReSchedule={(flag) => setReSchedule(flag)}
      viewingRequestId={viewingRequestId}
    />
  </>);
}

export default withRouter(TenantDashboard);
