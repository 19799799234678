import moment from "moment"
import { postAPI } from "../../../Api"

export const setRelistingProperty = async (property_id, property_details_id, nextAvailableDate) => {
    const url = `/landlord/relist-property`
    const data ={
        property_id: property_id,
        property_details_id: property_details_id,
        available_date: moment(nextAvailableDate).format('YYYY-MM-DD')
    }
    try {
        const response = await postAPI(url,data)
        if(response.status === undefined || response.status !== 200){
            return {status: 'error', message: response.response.data.errors}
        }else{
            // return response.data.message
            return {status: 'success', message: response.data.message}
        }
    } catch (error) {
        return 'Something went wrong!'
    }
    
}