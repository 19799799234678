import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { ProfileCard, PhoneNumberFormat } from "../../../components";
import { useHistory } from 'react-router-dom';
import "./style.scss";
import Cookies from "js-cookie";

const BasicInfoDisplay = (props) => {
  const { profileDetails, editaction } = props
  const history = useHistory();
  const role = Number(Cookies.get("role"));
  return (
    <ProfileCard
      title="Basic Info"
      editaction={editaction}>

      <Container fluid>
        {profileDetails.basic_info ?
          <Row>
            <Col md='2'>
              <label>First Name</label>
              <h3>{profileDetails.basic_info.first_name}</h3>
            </Col>
            <Col md='2'>
              <label>Last Name</label>
              <h3>{profileDetails.basic_info.last_name}</h3>
            </Col>
            <Col md='2'>
              <label>Email Address</label>
              <h3 className={role === 1 && "blue-heading"}>{profileDetails.basic_info.email}</h3>
            </Col>
            <Col md='2'>
              <label>Phone number</label>
              <h3 className={role === 1 && "blue-heading"}> <PhoneNumberFormat phoneNumber={profileDetails.basic_info.phone} /></h3>
            </Col>
            <Col md='2'>
              <label>ID</label>
              {profileDetails.basic_info.front_image || profileDetails.basic_info.back_image ?
                <div>
                  {profileDetails.basic_info.front_image && <Image src={profileDetails.basic_info.front_image} rounded width='40' height='40' className="mr-2" />}
                  {profileDetails.basic_info.back_image && <Image src={profileDetails.basic_info.back_image} rounded width='40' height='40' />}
                </div> :
                <h3>None &nbsp;&nbsp;&nbsp;<u onClick={editaction} style={{ cursor: 'pointer' }}>Upload</u></h3>}
            </Col>
            <Col md='2'>
              <label>Security</label>
              <h3><u onClick={() => history.push('/changepassword')} style={{ cursor: 'pointer' }}>Change Password</u></h3>
            </Col>
          </Row> :
          <Row>
            <Col md='12' className="d-flex justify-content-center">
              <label>No Information Provided</label>
            </Col>
          </Row>}
      </Container>
    </ProfileCard>
  );
}

export default BasicInfoDisplay;