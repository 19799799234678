import React from 'react';
import { Image } from 'react-bootstrap';
import { PhoneNumberFormat } from '..';
import './style.scss';

const AdminUserDetails = (props) => {
    const { userDetails, user_type } = props

    return (
        <>
            <span className='user admin-section'>{user_type}</span>
            <span className='user-name'>{userDetails && userDetails.name}</span>
            <span className='user-contact-title d-sm-inline d-block'><img className='user-icon' src={require('../../../_assets/images/mobile-icon.svg')} alt='mobile-icon' />
                <span className='user-contact-heading'>Phone:</span> <span className='title-text'>
                    {userDetails && <PhoneNumberFormat phoneNumber={userDetails.phone ? userDetails.phone : userDetails.phone_number} />}
                </span>
            </span>
            <span className='user-contact-title d-sm-inline d-block'><img className='user-icon' src={require('../../../_assets/images/message-icon.svg')} alt='message-icon' />
                <span className='user-contact-heading'>Email:</span> <span className='title-text'>
                    {userDetails && userDetails.email}
                </span>
            </span>
            {user_type === 'tenant' &&
                <span className='user-contact-title d-sm-inline d-block'><img className='user-icon' src={require('../../../_assets/images/id-icon.svg')} alt='id-icon' />
                    <span className='user-contact-heading'>ID:</span> <span className='title-text'>
                        {(userDetails && (userDetails.front_image || userDetails.back_image)) ?
                            <>
                                {userDetails.front_image && <Image src={userDetails.front_image} rounded width='40' height='40' className='mr-2' />}
                                {userDetails.back_image && <Image src={userDetails.back_image} rounded width='40' height='40' />}
                            </> : 'None'}
                    </span>
                </span>}
        </>
    );
}

export default AdminUserDetails;