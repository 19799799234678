import React, { useState, useEffect } from "react";
import "./style.scss";
import { AppLayout, PageTitleHeader, RentOverview } from "../../components";
import { Nav } from "react-bootstrap";
import { logger, toaster } from "../../../Utils";
import { withRouter, NavLink } from "react-router-dom";
import Repairs from "./Repairs/Repairs";
import Inspection from "./Inspection/Inspection";
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';
import Notices from "../Notices/Notices";
import LeaseOverviewPdfView from "../../components/RentOverview/LeaseOverviewPdfView";
import CompleteWarningMessage from "../../components/CompleteWarningMessage/CompleteWarningMessage";
import { setRelistingProperty } from './helper'
import RelistForm from "./RelistForm";

const log = logger("MaintainanceRepair");
const MaintainanceRepair = (props) => {
  const [componentLoad, setProperty_component_load] = useState('');
  const [property_id, setProperty_id] = useState('');
  const [property_details_id, setProperty_details_id] = useState('');
  const [is_ViewPdf, setViewPdf] = useState(false);
  const [rentDetails, setRentDetails] = useState(null);
  const [showRelist, setShowRelist] = useState(true)
  const [is_Relist, setIsRelist] = useState(false)
  const role = Number(Cookies.get('role'));
  const address = useSelector(state => state.maintenanceRepairReducer.address)

  const backToDashboard = () => {
    if (role === 2) {
      props.history.push(`/rented-listing`);
    } else if (role === 3) {
      props.history.push(`/dashboard/my-home`);
    }
  }

  const viewDownloadPdf = () => {
    setViewPdf(true);
  }

  const viewRelist = () => {
    setIsRelist(true)
  }

  useEffect(() => {
    setProperty_id(Number(props.match.params.property_id));
    setProperty_details_id(Number(props.match.params.property_details_id));
    setProperty_component_load(props.match.params.slug);
  }, [props]);
  
  useEffect(() => {
    setShowRelist(rentDetails?.is_relist === 1 ? false : true)
  },[rentDetails])

  return (
    <>
      <AppLayout>
        <PageTitleHeader
          title={address}
          isArrow={true}
          onClick={() => backToDashboard()}
          threeDotNav={(role === 2  || role === 1) && (rentDetails || componentLoad==='repairs' || componentLoad==='inspections' || componentLoad==='notices') && 'property-relist'}
          relistingProperty={(role === 2  || role === 1) && viewRelist}
          propertyId = {(role === 2  || role === 1) && property_id}
          propertyDetailsId={(role === 2  || role === 1) && property_details_id}
          showRelist={componentLoad === 'overview' && showRelist}
        />

        {/* {role===2 && 
          <>
            <Nav fill variant="tabs" className="rp-tab-views">
              <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/my-panda' }} className={componentLoad === "my-panda" || componentLoad === undefined ? "nav-link active" : "nav-link"} >My Panda</NavLink>
              <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/inspections' }} className={"nav-link"} >Inspection</NavLink>
              <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/notices' }} className={"nav-link"} >Notices</NavLink>              
              <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/repairs' }} className={"nav-link"} >Repairs</NavLink>
          </Nav>
          {componentLoad === "my-panda" ?
              <RentOverview 
              viewDownloadPdf={()=>viewDownloadPdf()}
              rentDetails={rentDetails}
              setRentDetails={(data)=>setRentDetails(data)}/> : ""}
          {componentLoad === "repairs" ?
              <Repairs role={role}/> : ""}
              {componentLoad === "inspections" ?
              <Inspection role={role}/> : ""}
              </> } */}
        {/* {role===3 &&  */}
        <>
          <Nav fill variant="tabs" className="rp-tab-views">
            <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/overview' }} className={componentLoad === "overview" || componentLoad === undefined ? "nav-link active" : "nav-link"} >Overview</NavLink>
            <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/inspections' }} className={"nav-link"} >Inspection</NavLink>
            <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/notices' }} className={"nav-link"} >Notices</NavLink>
            <NavLink to={{ pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/repairs' }} className={"nav-link"} >Repairs</NavLink>
          </Nav>
          
          { (role === 2  || role === 1) && showRelist && rentDetails && componentLoad === 'overview' && <CompleteWarningMessage onClick={() => viewRelist()} colorStyle={'success'} message={'This Property Is Currently Tenanted, '} ctaText={'Click Here To Relist'} />}
          
          {componentLoad === "overview" ?
            <RentOverview
              viewDownloadPdf={() => viewDownloadPdf()}
              rentDetails={rentDetails}
              setRentDetails={(data) => setRentDetails(data)} /> : ""}
          {componentLoad === "repairs" ?
            <Repairs role={role} /> : ""}
          {componentLoad === "inspections" ?
            <Inspection role={role} /> : ""}
        </>
        {/* } */}
        {componentLoad === "notices" ?
          <Notices property_id={property_id}
            property_details_id={property_details_id}
            role={role} /> : ""}
      </AppLayout>

      <LeaseOverviewPdfView
        isShow={is_ViewPdf}
        handleClose={() => setViewPdf(false)}
        address={address}
        rentDetails={rentDetails} />

      {is_Relist &&
        <RelistForm
          isShow={is_Relist}
          handleClose={() => setIsRelist(false)}
          leaseData = {rentDetails}
          propertyId = {property_id}
          propertyDetailsId = {property_details_id}
          setShowRelist = {setShowRelist}
      />}
    </>
  );
}

export default withRouter(MaintainanceRepair);
