import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { LargeButton, CancelButton, SidePanel, DatePickerComponent, CostNumberFormat, SingleOptionCheck, ModalBox, Loader, FileBrowse } from '../../../components';
import { getAPI, postAPI } from '../../../../Api';
import { withRouter } from 'react-router-dom';
import { validate } from '../../../../Utils/Validation/validation';
import { numberFormatSanitizerInt } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import { dateFormat } from '../../../../Utils/DateSanitizer/DateSanitizer';
import { logger, toaster } from '../../../../Utils';
import { leaseType } from '../../../../Constants/LeaseConstants';
import { subDays, addMonths, addYears } from "date-fns";
import Axios from 'axios';
import Cookies from 'js-cookie';
import { FILE_API_URL } from '../../../../Constants/endPoints';
import moment from "moment";

const LeaseDurationSchema = {
	lease_start_date: [
		{ rule: "required", message: "Lease start date is required" },
	],
	lease_end_date: [
		{ rule: "required", message: "Lease end date is required" },
	],
	lease_rent_amount: [
		{ rule: "required", message: "Rent per month is required" },
	],
}

const log = logger("LeaseDuration");
const LeaseDuration = (props) => {
	const { isShow, handleClose, setUploadLease, setSearchTenant, singleReasonData, selectedTenant, setSelectedTenant, pdf = '', setPdf, lease_id } = props;
	const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
	const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
	const isLeaseFromTenantApplication = useSelector(state => state.messageDetailsReducer.isLeaseFromTenantApplication);
	const [property_id, setProperty_id] = useState('');
	const [showImageLoading, setShowImageLoading] = useState(false)
	const [property_details_id, setProperty_details_id] = useState('');
	const [leaseId, setLeaseId] = useState('');
	const [lease_type, setLeaseType] = useState(propertyDetails?.details ? propertyDetails?.details[0]?.lease_duration : 1);
	const [lease_start_date, setStartDate] = useState(new Date());
	const [lease_end_date, setEndDate] = useState(new Date());
	const [lease_rent_amount, setAmount] = useState(unitDetails ? unitDetails.price_per_month : '');
	const [selectedTenantData, setSelectedTenantData] = useState(null);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [lease_pdf, setLease_pdf] = useState({ file: pdf, percent: 0 });
	const [errorClass, setErrorClass] = useState(false);
	const [uploadData, setUploadData] = useState(null);
	const [disableBtn, setBtnDisable] = useState(false);


	const handleDrop = (file, setFile) => {
		const token = `Bearer ${Cookies.get('access_token')}`
		const headers = {
			'Authorization': token,
			'Content-Type': 'multipart/form-data'
		}
		setShowImageLoading(true)

		file.map((dataFile) => {
			setUploadData(dataFile);
			let formdata = new FormData();
			formdata.append('file', dataFile);
			let url = FILE_API_URL;
			Axios.post(
				url, formdata, {
				headers: headers,
				onUploadProgress: progressEvent => {
					var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
					if (percent >= 100) {
						setFile({ percent: percent });
						return file;
					} else {
						setFile({ percent: percent });
						return null;
					}
				}
			})
				.then(response => {
					setFile({ file: response.data.data.url })
					setPdf(response.data.data.url);
					setErrorClass(false)
					setShowImageLoading(false)

				})
				.catch(error => {
					setShowImageLoading(false)
				});
			return dataFile;
		})
	}
	const removeFile = (index, setFile) => {
		setFile('')
	}

	const OnRemove = (index, data) => {
		let items = [...selectedTenantData];
		items.splice(index, 1);
		setSelectedTenantData(items);
		setSelectedTenant(items);
	}

	const OnChangeInput = (event) => {
		if (event.target.name === 'lease_rent_amount') {
			const sanitizeValue = numberFormatSanitizerInt(event.target.value);
			setAmount(sanitizeValue)
		}
	}

	const OnSingleOptionCheck = (event) => {
		const Value = event.target.value;
		const name = event.target.getAttribute('name');
		if (name === 'lease_type') {
			setLeaseType(Value);
		}
	}

	const handleDate = (eventDate, setState, setNextDate) => {
		setState(eventDate);
		if (lease_type === 1) {
			setNextDate(subDays(addYears(eventDate, 1), 1))
		} else if (lease_type === 2) {
			setNextDate(subDays(addMonths(eventDate, 1), 1))
		}
	}

	const submit = (type) => {
		if (lease_pdf && lease_pdf.file === '' && type === 1) {
			setErrorClass(true)

		}
		if ((!selectedTenantData || selectedTenantData.length === 0) && type === 1) {
			toaster('error', 'Tenants Required')
		}

		if ((validate(LeaseDurationSchema) && !errorClass && (selectedTenantData && selectedTenantData.length > 0)) || type === 0) {
			setBtnDisable(true)
			const url = '/landlord/listing/lease/manual-upload';
			const data = {
				"lease_id": leaseId,
				"property_id": property_id,
				"property_detail_id": property_details_id,
				"lease_pdf": lease_pdf && lease_pdf.file,
				"lease_type": lease_type,
				"lease_start_date": dateFormat(lease_start_date),
				"lease_end_date": dateFormat(lease_end_date),
				"rent": lease_rent_amount,
				"tenants": selectedTenantData,
				'is_tenancy_application': isLeaseFromTenantApplication,
				"send_to_tenant": type //"0: Save, 1: Send To Tenant"        
			}
			setBtnDisable(false)
			postAPI(url, data).then(res => {
				setBtnDisable(true)
				if (res.status === 200) {
					if (type === 1) {
						handleClose();
						setOpenConfirm(true);
					} else {
						toaster('success', 'Lease has been saved successfully');
					}
				} else if (res.response.status === 400) {
					log.info(res.response)
					toaster('error', res.response.data.message);
				} else if (res.response.status === 422 && type === 1) {
					toaster('error', res.response.data.message);
				}
			})

		}
		// else {
		// 	// handleClose();
		// 	// setSearchTenant(true);
		// 	toaster('error', 'Tenants Required')
		// }
	}
	const handleCloseConfirm = () => {
		handleClose();
	}

	const sendToRental = () => {
		setOpenConfirm(false);
		props.history.push({
			// pathname: `/maintainance-repair/${property_id}/${property_details_id}/my-panda`,
			pathname: `/maintainance-repair/${property_id}/${property_details_id}/overview`,
		});
	}

	useEffect(() => {
		if (property_details_id && lease_id !== 0) {
			const url = `/landlord/listing/lease/get-manual-upload-lease-data/${property_details_id}/${lease_id}`;

			getAPI(url).then(res => {
				if (res.status === 200) {
					const fetchedData = res.data.data;
					setLeaseType(fetchedData.lease_type);
					setStartDate(moment(fetchedData.lease_start_date).toDate());
					setEndDate(moment(fetchedData.lease_end_date).toDate());
					setAmount(fetchedData.rent);
					setSelectedTenantData(fetchedData.tenants);
					setLease_pdf({ file: fetchedData.lease_pdf });

					log.info('resp', fetchedData);
				}
			})
		}
	}, [lease_id]);
	const oepnTenant = () => {
		handleClose();
		props.addtenant(true);
	}
	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
	}, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id]);


	useEffect(() => {
		setLeaseId(lease_id);
	}, [lease_id])

	useEffect(() => {
		setSelectedTenantData(selectedTenant)
	}, [selectedTenant])

	useEffect(() => {
		if (lease_type === 1) {
			setEndDate(subDays(addYears(lease_start_date, 1), 1))
		}
	}, [lease_start_date, lease_type])


	let maxLength = 25;
	return (<>
		<SidePanel
			isShow={isShow}
			isHide={() => handleCloseConfirm()}
			title='UPLOAD LEASE AND DETAILS'
			subTitle='please provide a few key details'
		>
			<div className='sidepanel-body mt-1 lease-pdf-view'>
				<label>Lease</label>
				{(lease_pdf && lease_pdf.file) ?
					<Col className='confirm-section mb-2 d-flex justify-content-between'>
						<span className='d-flex justify-content-start'>
							<Image className="icon" src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
							<div className='file-details d-flex'>
								{lease_pdf.file.split('/').pop().length > maxLength ? lease_pdf.file.split('/').pop().substring(0, maxLength) + '...' : lease_pdf.file.split('/').pop()}
							</div>
						</span>
						<Image className="icon cursor-pointer" title='Delete' onClick={(e) => removeFile(e, setLease_pdf)} src={require("../../../../_assets/images/red-delete-icon.svg")} alt="Delete" />
					</Col>
					: <Col><FileBrowse
						handleDrop={(e) => handleDrop(e, setLease_pdf)}
						size={'sm'}
						title={'Upload Documents'}
						percent={lease_pdf.percent}
						multiple={false}
						fileType={'.pdf'}
						disable={false}
						loaderStyle={{ height: '14%', width: '91%' }}
						imageData={lease_pdf.file && lease_pdf.file}
						removeFile={(e) => removeFile(e, setLease_pdf)}
						lableClass={'custom-lable'}
						section={'upload-lease'}
						showImageLoading={showImageLoading}
						loaderStyle={{ width: '100%', height: '100%' }}
						uploadData={uploadData && uploadData}
						errorClass={errorClass === true ? "error-upload-box" : ""}
					/></Col>}
				<Form id={Form}>
					<Row>
						<Form.Group as={Col} md="12">
							<Form.Label>Rent per month</Form.Label>
							<CostNumberFormat
								name={"lease_rent_amount"}
								id={"lease_rent_amount"}
								classname="form-control"
								value={lease_rent_amount ? lease_rent_amount : ''}
								updateField={(e) => OnChangeInput(e)}
								pre="$"
								placeholder={"Enter your desired pricing"}
							/>
						</Form.Group>
						<Form.Group as={Col} md='12'>
							<Form.Label>How long is the lease</Form.Label>
							<SingleOptionCheck
								mapData={leaseType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'lease_type'} setParam={lease_type}
							/>
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} md="12">
							<Form.Label>Please indicate the start date {(lease_type === 0 || lease_type === 1) ? ' and end date' : ''}</Form.Label>
							<DatePickerComponent
								name="lease_start_date"
								id="lease_start_date"
								value={lease_start_date}
								handleDateChange={(date) => handleDate(date, setStartDate, setEndDate)}
								displayText={"Select or type"}
								minDate={new Date()}
								popperPlacement='bottom-start'
							/>
						</Form.Group>
						{(lease_type === 0 || lease_type === 1) &&
							<Form.Group as={Col} md="12">
								<DatePickerComponent
									name="lease_end_date"
									id="lease_end_date"
									value={lease_end_date}
									handleDateChange={(end_date) => handleDate(end_date, setEndDate)}
									displayText={"Select or type"}
									minDate={lease_start_date}
									popperPlacement='bottom-end'
									disableValue={lease_type === 0 ? false : true}
								/>
							</Form.Group>}
					</Row>
					<hr />
					<Form.Label>Your tenant(s)</Form.Label>
					{log.info("selectedTenantData", selectedTenantData)}
					{selectedTenantData &&
						<ul className="select-options">
							{selectedTenantData.map((tenant, i) => {
								return (<li className="active" key={tenant.id}>{tenant.name}
									<Image
										className="icon cursorPointer ml-3"
										width="14px"
										height="15px"
										title="Remove"
										onClick={() => OnRemove(i, tenant.id)}
										src={require("../../../../_assets/images/delete.svg")}
									/>
								</li>)
							})}
						</ul>
					}
					<LargeButton isIcon={true} type='button' title='add tenant(s)' variant='dark' style={{ width: '300px' }} onClick={() => oepnTenant()} />
				</Form>

			</div>
			<div className='fixed-bottom d-flex justify-content-between'>
				<CancelButton onClick={() => handleCloseConfirm()} title='back' />
				<span className='d-flex justify-content-between align-items-center'>
					<button className='save-link mr-2' style={{ color: '#8DC541', background: 'none', border: '0', padding: '0' }} onClick={() => submit(0)}>Save</button>
					<LargeButton title='send to tenant' onClick={() => submit(1)} style={{ width: '200px' }} />
				</span>
			</div>
		</SidePanel>

		<ModalBox
			show={openConfirm}
			onHide={() => setOpenConfirm(false)}
			size='sm'
			actionbuttontitle={'got it'}
			buttonaction={() => sendToRental()}
		>
			{/* {props.isDeactivation ? */}
				<>
					<h4>Congrats on renting your place!</h4>
					<p style={{ fontSize: '19px' }}>You have uploaded a lease and sent it to the tenant for their records. make sure that you and the tenant have copies of the signed lease for your records.</p>
				</>
				{/* : <p className='deactive-confirm'>your lease has been uploaded and your property is now rented</p>
			} */}
		</ModalBox>
	</>);
}
export default withRouter(LeaseDuration);