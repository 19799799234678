import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { PropertyCard, Loader } from "../../components";
import { withRouter } from 'react-router-dom';
import { logger } from "../../../Utils";
import { getAPI } from "../../../Api";
import Cookies from "js-cookie";
import { landlordInfo, rentedAddress } from "../../../Store/actions/MaintenanceRepairAction";
import { useDispatch } from "react-redux";

const log = logger("MyHome");
const MyHome = (props) => {
  const [propertyLists, setPropertyLists] = useState(null);
  const accessToken = Cookies.get("access_token") ? Cookies.get("access_token") : null;
  const dispatch = useDispatch();
  useEffect(() => {
    const url = '/tenant/my-rented-listing';
    if (accessToken) {
      getData(url);
    }
  }, [props, accessToken])

  const getData = (url) => {
    getAPI(url).then(response => {
      const res = response.data.data;
      log.info(res);
      setPropertyLists(res)
    })
      .catch(error => {
      });
  }

  const propertyDetails = (details, path) => {
    props.history.push({
      pathname: '/maintainance-repair/' + details.property_id + '/' + details.property_details_id + '/' + path,
    });
    dispatch(rentedAddress(details.address));
    dispatch(landlordInfo(details.landlord)); //This store info is being used while creating N9 form.
  }

  const formattedDetails = (data) => {
    const {bedroom, property_type} = data;
		const bedRoom = bedroom && (bedroom > 1) ? (bedroom + ' Bedrooms') : (bedroom + ' Bedroom');
		// property_type {
		//   1 : 'Full Property',
		//   2 : 'Unit',
		//   3 : 'Room for Rent'
		// }
		let details = '';
		if (property_type === 3) {
			details = 'Room for Rent';
		} else if (property_type === 2) {
			details = `Unit • ${bedRoom}`;
		} else if (property_type === 1) {
			details = `Full Property • ${bedRoom}`;
		}
		return details;
  }

  return (
    <div className="profile-container">
      <div className="profile-container-box">
        <Container fluid>
          <Row>
            {propertyLists ?
              propertyLists.length > 0 ?
                propertyLists.map((list, i) => {
                  return (
                    <PropertyCard
                      key={"propery_" + i}
                      cardType="rentedList"
                      // inquiries={list.new_inquiry}
                      image_url={list.image_url}
                      price={'$'+list.price_per_month+'/month'}
                      approval={list.formatted_status ? list.formatted_status : 'in progress'}
                      isVerifed={list.is_verified}
                      title={list.address}
                      details={formattedDetails(list)}
                      onClick={() => propertyDetails(list, 'overview')}
                      onClickRepairs={() => propertyDetails(list, 'repairs')}
                      onClickNotice={() => propertyDetails(list, 'notices')}
                      landlord_name={list.landlord.name}
                    />
                  )
                }) :
                <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '60px 0' }}>
                  <Image src={require("../../../_assets/images/no-data.svg")} />
                  <p style={{ fontSize: '25px', color: '#979797' }}>You aren't currently renting a home through the system. </p>
                </Col>
              : <Loader />}

          </Row>
        </Container>
      </div>
    </div>
  );
}

export default withRouter(MyHome);