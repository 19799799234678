import React, { Component } from "react";
import { Nav, Image } from "react-bootstrap";
import "./style.scss";
class ToolsSection extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="sec-tools-section">
            <div className="text-center">
                <div className="landing-sub-title">DO IT YOURSELF</div>
                <h3 className="landing-title f-secondary-b">rent panda likes lending tools to neighbours.</h3>
            </div>
            <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 mb-xl-4 mb-3">
                    <div className="icon-box-list">
                      <div className="icon-box-list-left">
                      <Image className="" src={require("../../../../../_assets/images/landing-pages/lending-tools1.svg")} alt="image" />
                      </div>
                      <div className="icon-box-list-right">Pro photos &amp; virtual tours</div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-xl-4 mb-3">
                    <div className="icon-box-list">
                      <div className="icon-box-list-left">
                      <Image className="" src={require("../../../../../_assets/images/landing-pages/lending-tools2.svg")} alt="image" />
                      </div>
                      <div className="icon-box-list-right">Tenant profiles &amp; credit checks</div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-xl-4 mb-3">
                    <div className="icon-box-list">
                      <div className="icon-box-list-left">
                      <Image className="" src={require("../../../../../_assets/images/landing-pages/lending-tools3.svg")} alt="image" />
                      </div>
                      <div className="icon-box-list-right">Book showings online</div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-xl-4 mb-3">
                    <div className="icon-box-list">
                      <div className="icon-box-list-left">
                      <Image className="" src={require("../../../../../_assets/images/landing-pages/lending-tools4.svg")} alt="image" />
                      </div>
                      <div className="icon-box-list-right">Post automatically to Kijiji &amp; Facebook</div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-xl-4 mb-3">
                    <div className="icon-box-list">
                      <div className="icon-box-list-left">
                      <Image className="" src={require("../../../../../_assets/images/landing-pages/lending-tools5.svg")} alt="image" />
                      </div>
                      <div className="icon-box-list-right">Build &amp; sign leases</div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-xl-4 mb-3">
                    <div className="icon-box-list">
                      <div className="icon-box-list-left">
                      <Image className="" src={require("../../../../../_assets/images/landing-pages/lending-tools6.svg")} alt="image" />
                      </div>
                      <div className="icon-box-list-right">Repair &amp; maintenance tracking</div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-xl-4 mb-3">
                    <div className="icon-box-list">
                      <div className="icon-box-list-left">
                      <Image className="" src={require("../../../../../_assets/images/landing-pages/lending-tools7.svg")} alt="image" />
                      </div>
                      <div className="icon-box-list-right">Build &amp; serve notices</div>
                    </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 mb-xl-4 mb-3">
                    <div className="icon-box-list">
                      <div className="icon-box-list-left">
                      <Image className="" src={require("../../../../../_assets/images/landing-pages/lending-tools8.svg")} alt="image" />
                      </div>
                      <div className="icon-box-list-right">Online rent payment (coming soon)</div>
                    </div>
                </div>
            </div>
            <div className="text-center get-start-area">
              <Nav.Link href='/signup/landlord' className="btn btn-primary text-transform-none border-0">get started for free</Nav.Link>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ToolsSection;
