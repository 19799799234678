import React, { useEffect, useState } from "react";
import { Row, Col, Nav, Image } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";
import "./style.scss";
import { getAPI } from "../../../Api";
import { logger } from "../../../Utils";
import { AppLayout, PageTitleHeader, LargeButton, ModalBox } from "../../components";
import { BasicInfo, CurrentHomeInfo, PastHomeInfo, EmploymentInfo, PersonalReferencesInfo, PetsInfo, VehicleInfo, RoommateInfo } from '../../components/TenantProfile/ProfileInfo';
import AdminUserDetails from "../../components/AdminUserDetils";
import FullProfile from "./ProfileComponent/FullProfile";
import Leases from "./ProfileComponent/Leases";
import Notices from "./ProfileComponent/Notices";
import Maintainance from "./ProfileComponent/Maintainance";
import Showings from "./ProfileComponent/Showings";
import AdminShowings from "./ProfileComponent/AdminShowings";
import Inspection from "./ProfileComponent/Inspection";
import UserDetailContentLoader from "../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/UserDetailContentLoader/UserDetailContentLoader";
import { useSelector } from "react-redux";

const log = logger("ProfileDetail");
const AdminTenantProfile = (props) => {
	const [user_id, setUserId] = useState('');
	const [user_type, setUser_type] = useState('');
	const [path] = useState('');
	const [profileDetails, setProfileDetails] = useState([])
	const [basicInfo, setBasicInfo] = useState(false)
	const [currentHomeInfo, setCurrentHomeInfo] = useState(false)
	const [newCurrentHomeInfo, setNewCurrentHomeInfo] = useState(false)
	const [pastHomeInfo, setPastHomeInfo] = useState(false)
	const [employmentInfo, setEmploymentInfo] = useState(false)
	const [personalReferencesInfo, setPersonalReferencesInfo] = useState(false)
	const [petsInfo, setPetsInfo] = useState(false)
	const [vehicleInfo, setVehicleInfo] = useState(false)
	const [roommateInfo, setRoommateInfo] = useState(false)
	const [openSwitchModal, setOpenSwitchModal] = useState(false);
	const [profile, setProfile] = useState(null);
	const [action, setAction] = useState("");
	const [buttonTitle, setButtonTitle] = useState("");
	const [is_showingRequest, setShowingRequest] = useState(false);
	const [showingDetails, setShowingDetails] = useState(false);
	const [showingRequestdata, setShowigRequestData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const backPath = useSelector(state => state.propertyDetailsReducer.backPath);
	const back = () => {
		if (backPath === 'active') {
			props.history.push({ pathname: '/dashboard/all-user' });
		} if (backPath === 'inactive') {
			props.history.push({ pathname: '/dashboard/inactive-user' });
		}
	}

	const userDetails = user_type && user_type === 'tenant' ? profileDetails.basic_info : profileDetails.user;

	const toggleModal = (data, action) => {
		setOpenSwitchModal(!openSwitchModal);
		setAction(action);
		if (action === "switch") {
			onClickSwitch(data)
			setButtonTitle("yes, switch profile");
		} else if (action === "suspend" || action === "active") {
			suspendUser(data);
			if (action === "suspend") {
				setButtonTitle("yes, suspend");
			} else if (action === "active") {
				setButtonTitle("yes, activate");
			}
		}
	}
	const onClickSwitch = (data) => {
		setProfile(data);
	}
	const suspendUser = (userData) => {
		setProfile(userData);
	}
	const goBack = () => {
		let url = "";
		if (action === "switch") {
			log.info(profile.user_type)
			url = '/admin/users/switch-to-landloard/' + profile.id;
			getAPI(url).then(response => {
				toggleModal();
				props.history.push("/profile-detail/landlord/" + profile.id + "/listings");
			})

		} else if (action === "suspend" || action === "active") {
			if (action === "suspend") {
				url = '/admin/users/suspend/' + user_id;
			} else if (action === "active") {
				url = '/admin/users/activate/' + user_id;
			}
			getAPI(url).then(res => {
				const url = '/admin/users/tenant-details/' + user_id;
				getAPI(url)
					.then(response => {
						setIsLoading(false);
						const res = response.data.data;
						setProfileDetails(res)
					})
				setOpenSwitchModal(false);
			})
		}

	}
	const [componentLoad, setComponentLoad] = useState('profile');

	const setOnClickShowings = (userData) => {
		setShowingRequest(true);
		setShowingDetails(userData);
		const urlRequest = "/admin/users/landlord/showing-request/" + userData.id;
		getAPI(urlRequest).then(res => {
			setShowigRequestData(res.data.data)
		})
	}

	useEffect(() => {
		const slug = props.match.params.slug;
		setComponentLoad(slug)
		// setPath(props.location.state.path)
	}, [props]);

	useEffect(() => {
		if (props.match.params.user_id) {
			const userType = props.match.params.user_type;
			setUserId(Number(props.match.params.user_id));
			setUser_type(userType);
			if (userType === "tenant") {
				const url = '/admin/users/tenant-details/' + Number(props.match.params.user_id);
				getAPI(url)
					.then(response => {
						setIsLoading(false);
						const res = response.data.data;
						setProfileDetails(res)
					})
			}

		}
	}, [props, basicInfo, currentHomeInfo, newCurrentHomeInfo, pastHomeInfo, employmentInfo, personalReferencesInfo, petsInfo, vehicleInfo, roommateInfo])

	return (<>

		<AppLayout>
			<div >
				<PageTitleHeader
					isArrow={true}
					onClick={() => back()}
					title={user_type.toUpperCase() + "'S DETAILS"}
				/>
			</div>
			{isLoading === true ? <UserDetailContentLoader section={'admin'} /> :
				<Row className="container-detail admin-section">
					<Col md="12" className="card-detail">
						<div className="detail-left">
							{userDetails && <AdminUserDetails userDetails={userDetails} user_type={'Tenant'} />}
						</div>
						<div className="detail-right">
							{ (profileDetails && profileDetails.basic_info && profileDetails.basic_info.is_active === "Active") &&
								<div className="switch-elm cursorPointer" onClick={() => toggleModal(userDetails, "switch")}><Image
									width="20px"
									height="20px"
									alt="switch"
									className="mb-1 mr-2"
									src={require(`../../../_assets/images/switch-icon.svg`)} /> Switch To {user_type === "landlord" ? "Tenant" : "Landlord"}
								</div>
							}
							{log.info("basicInfo", profileDetails)}
							{(profileDetails && profileDetails.basic_info && profileDetails.basic_info.is_active === "Active") &&
								<LargeButton onClick={() => toggleModal(profileDetails.basic_info, "suspend")} className="suspend-btn" variant={'dark'} title="suspend user" style={{ width: '170px', float: 'right' }} />}
							{(profileDetails && profileDetails.basic_info && profileDetails.basic_info.is_active === "Suspended") &&
								<LargeButton onClick={() => toggleModal(profileDetails.basic_info, "active")} className="suspend-btn" variant={'dark'} title="activate" style={{ width: '170px', float: 'right' }} />}
						</div>
					</Col>
				</Row>}

			<Row className="secoundary-container-detail admin-section">
				<Col md="12" className="secoundary-card-detail">
					<Nav
						fill
						variant="tabs"
						className="user-tab-views tab-space-hover pt-4 pr-4 pl-4">
						<NavLink
							style={{ flex: "1 1 auto" }}
							to={{ pathname: "/profile-detail/" + user_type + "/" + user_id + "/profile", state: { path: path } }}
							className="nav-link"
							activeclassname={"active"}
						>profile
                        </NavLink>
						<NavLink
							style={{ flex: "1 1 auto" }}
							to={{ pathname: "/profile-detail/" + user_type + "/" + user_id + "/leases", state: { path: path } }}
							className="nav-link"
							activeclassname="active"
						>leases</NavLink>
						<NavLink
							style={{ flex: "1 1 auto" }}
							to={{ pathname: "/profile-detail/" + user_type + "/" + user_id + "/notices", state: { path: path } }}
							className="nav-link"
							activeclassname="active"
						>notices</NavLink>
						<NavLink
							style={{ flex: "1 1 auto" }}
							to={{ pathname: "/profile-detail/" + user_type + "/" + user_id + "/maintainance", state: { path: path } }}
							className="nav-link"
							activeclassname="active"
						>maintainance</NavLink>
						<NavLink
							style={{ flex: '1 1 auto' }}
							to={{ pathname: '/profile-detail/' + user_type + '/' + user_id + '/inspection', state: { path: path } }}
							className='nav-link'
							activeclassname='active'
						>inspection</NavLink>
						<NavLink
							style={{ flex: "1 1 auto" }}
							to={{ pathname: "/profile-detail/" + user_type + "/" + user_id + "/showings", state: { path: path } }}
							className="nav-link"
							activeclassname="active"
						>showings</NavLink>
					</Nav>
					<div className="table-container admin-view">
						<div className="profile-container">
							{componentLoad === "profile" &&
								<FullProfile
									setBasicInfo={(data) => setBasicInfo(data)}
									profileDetails={profileDetails && profileDetails}
									setCurrentHomeInfo={(data) => setCurrentHomeInfo(data)}
									setPastHomeInfo={(data) => setPastHomeInfo(data)}
									setEmploymentInfo={(data) => setEmploymentInfo(data)}
									setPersonalReferencesInfo={(data) => setPersonalReferencesInfo(data)}
									setPetsInfo={(data) => setPetsInfo(data)}
									setVehicleInfo={(data) => setVehicleInfo(data)}
									setRoommateInfo={(data) => setRoommateInfo(data)}
								/>
							}
							{componentLoad === "leases" && <Leases role='tenant' user_id={user_id} />}
							{componentLoad === "notices" && <Notices role='tenant' user_id={user_id} />}
							{componentLoad === "maintainance" && <Maintainance role='tenant' user_id={user_id} />}
							{componentLoad === 'inspection' && <Inspection role='tenant' user_id={user_id} />}
							{componentLoad === "showings" && <Showings role="tenant" user_id={user_id} setOnClick={(data) => setOnClickShowings(data)} />}
						</div>
					</div>
				</Col>
			</Row>
		</AppLayout>

		<BasicInfo
			isShow={basicInfo}
			basic_info={profileDetails && profileDetails.basic_info}
			handleClose={() => setBasicInfo(false)}
			setCurrentHomeInfo={(flag) => setCurrentHomeInfo(flag)}
			user_id={user_id} />

		{currentHomeInfo &&
		<CurrentHomeInfo
			isShow={currentHomeInfo}
			current_home={profileDetails && profileDetails.current_home}
			handleClose={() => setCurrentHomeInfo(false)}
			setPastHomeInfo={(flag) => setPastHomeInfo(flag)}
			type={'edit'}
			user_id={user_id} />
		}
		
		{newCurrentHomeInfo &&
		<CurrentHomeInfo
			isShow={newCurrentHomeInfo}
			current_home={''}
			handleClose={() => setNewCurrentHomeInfo(false)}
			setPastHomeInfo={(flag) => setPastHomeInfo(flag)}
			newCurrentHomeInfo={newCurrentHomeInfo}
			type={'add'}
			user_id={user_id} />
		}
		
		<PastHomeInfo
			isShow={pastHomeInfo}
			id={profileDetails && profileDetails.id}
			past_home={profileDetails && profileDetails.past_home}
			handleClose={() => setPastHomeInfo(false)}
			setEmploymentInfo={(flag) => setEmploymentInfo(flag)}
			user_id={user_id} />

		<EmploymentInfo
			isShow={employmentInfo}
			id={profileDetails && profileDetails.id}
			employment_info={profileDetails && profileDetails.employment_info}
			handleClose={() => setEmploymentInfo(false)}
			setPersonalReferencesInfo={(flag) => setPersonalReferencesInfo(flag)}
			user_id={user_id} />

		<PersonalReferencesInfo
			isShow={personalReferencesInfo}
			id={profileDetails && profileDetails.id}
			personal_reference={profileDetails && profileDetails.personal_reference}
			handleClose={() => setPersonalReferencesInfo(false)}
			setPetsInfo={(flag) => setPetsInfo(flag)}
			user_id={user_id} />

		<PetsInfo
			isShow={petsInfo}
			id={profileDetails && profileDetails.id}
			pets_info={profileDetails && profileDetails.pets_info}
			handleClose={() => setPetsInfo(false)}
			setVehicleInfo={(flag) => setVehicleInfo(flag)}
			user_id={user_id} />

		<VehicleInfo
			isShow={vehicleInfo}
			id={profileDetails && profileDetails.id}
			vehicle_info={profileDetails && profileDetails.vehicle_info}
			handleClose={() => setVehicleInfo(false)}
			setRoommateInfo={(flag) => setRoommateInfo(flag)}
			user_id={user_id} />

		<RoommateInfo
			isShow={roommateInfo}
			id={profileDetails && profileDetails.id}
			roommate_info={profileDetails && profileDetails.roommate_info}
			handleClose={() => setRoommateInfo(false)}
			user_id={user_id} />

		<AdminShowings
			isShow={is_showingRequest}
			handleClose={() => setShowingRequest(false)}
			role={1}
			showingDetails={showingDetails}
			requestDetails={showingRequestdata}
		/>
		<ModalBox
			show={openSwitchModal}
			onHide={toggleModal}
			size="sm"
			actionbuttontitle={buttonTitle}
			buttonaction={goBack}
			extraBtnStyle='extraBtnStyle'
		>
			{action === "switch" &&
				<p className="switching-details">You are switching <span className="profile-name">{profile && profile.name}’s</span> Profile
			from a {user_type && user_type.toLowerCase()} to a {profile && profile.user_type === "Landlord" ? "tenant" : "landlord"} Continue?</p>}
			{action === "suspend" &&
				<p className="switching-details">Are you sure to suspend <span className="profile-name">{profile && profile.name}’s?</span> <br />
            They will be unable to apply to any properties within Rent Panda
            </p>}
			{action === "active" &&
				<p className="switching-details">Are you sure to activate <span className="profile-name">{profile && profile.name}’s?</span> <br />
            Their rented property will remain within Rent Panda but other listing will be activated automatically
            </p>}
		</ModalBox>

	</>);
}

export default withRouter(AdminTenantProfile);
