import React from "react";
import "./style.scss";
import Dropzone from "react-dropzone";

const FileUpload = (props) => {
  const { handleDrop, fileType, html_view, multipleFiles, disable, width } = props
  const sizeLimit = 5242880

  return (
    <Dropzone
      onDrop={(file) => file.length && handleDrop(file)}
      accept={fileType}
      minSize={1}
      maxSize={sizeLimit}
      maxFiles={20}
      multiple={multipleFiles}
      disabled={disable}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections }) => {
        const additionalClass = isDragAccept ? "accept" : isDragReject ? "reject-file" : "";
        const widthClass = width && width === "auto" ? "" : "width-100";
        const errorList = fileRejections[0] && fileRejections[0].errors
        return (<div
          {...getRootProps({
            className: `dragBox ${widthClass} cursor-pointer ${additionalClass}`
          })}
        >
          <input {...getInputProps()} />
          {html_view}
          <span style={{ display: errorList && errorList.length > 0 ? 'block' : 'none' }} className="file-upload-error">
            {errorList && errorList.length > 0 && errorList.map((error, i) => {
              return (
                <>
                  {error.message === `File is larger than ${sizeLimit} bytes` ? `File should not be larger than ${Math.round(sizeLimit / 1000000)}MB` : error.message}
                  {i !== (errorList.length - 1) && <br />}
                </>
              )
            }
            )}
          </span>
        </div>
        )
      }}
    </Dropzone>
  );
}

export default FileUpload;