import React, { Component } from 'react';
// import MobileHeader from '../../../Website/components/Header';
import DesktopHeader from '../Header';
import NoAuthHeader from '../../../Website/components/Header'
import HeaderLandingPage from '../../../Website/components/HeaderLandingPage'
import FooterLandingPage from '../../../Website/components/FooterLandingPage'
import Sidebar from '../Sidebar';
import './style.scss';
import Cookie from 'js-cookie';
import { logout } from '../../../Store/actions/AuthAction';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';
import { clearRawData } from '../../../Store/actions/RawDataAction';
import { setActiveUserFilters, setInactiveUserFilters } from '../../../Store/actions/UserAction';
import { Loader } from '..';
import { Alert } from 'react-bootstrap';

class AppLayout extends Component {
  onLogout = () => {
    Cookie.remove('access_token');
    Cookie.remove('role');
    this.props.dispatch(logout());
    this.props.history.push('/login');
  };
  componentDidMount() {
    this.props.history.listen((location) => {
      const pathsToIgnore = ['/view-notice/', 'edit-notice', 'notice-info', 'create-notice']

      if (location.pathname.indexOf("/profile-detail/") !== 0 && !pathsToIgnore.find((i) => location.pathname.includes(i))) {
        clearRawData()
      }

      const emptyFilter = {
        name: "",
        email: "",
        phone_number: "",
        user_type: "",
        displayType: "View All"
      }
      if (location.pathname.indexOf('/dashboard/') !== 0) {
        this.props.dispatch(setActiveUserFilters(emptyFilter));
        this.props.dispatch(setInactiveUserFilters(emptyFilter));
      }
    });
  }

  render() {
    const { children, noSidebar, customClass, newWebHeader } = this.props;
    const role = Number(Cookie.get('role'));
    return (
      <>
        {this.props.showLoader &&
          <div id="fullScreenLoader">
            <Loader />
          </div>
        }
        <div className={customClass}>
          {this.props.coockieError && <Alert variant={'danger'}>
            {this.props.coockieError}
          </Alert>}
          <div className='d-md-none d-lg-none d-xl-none'>
            {/* <MobileHeader staticHeader={false} userData='name' type='app' /> */}
          </div>

          <div className={`${newWebHeader && this.props.location.pathname.includes("/resources/blog/preview/") ? 'newWebHeader' : ''}`}>
            {newWebHeader && this.props.location.pathname.includes("/resources/blog/preview/") ? <HeaderLandingPage /> :
              Cookie.get('access_token') ?
                <DesktopHeader
                  hideNavBtn={noSidebar}
                  staticHeader={false}
                  userData='name'
                  onLogout={this.onLogout}
                />
                : <NoAuthHeader />}
          </div>

          <div className={role !== 1 ? 'main-layout container-fluid' : 'main-layout container-fluid admin-wrapper'}>
            <div className='row'>
              {(noSidebar || !Cookie.get('access_token')) ? null :
                <Sidebar />}
              <div className={(noSidebar || !Cookie.get('access_token')) ? 'main-wrapper left-zero' : 'main-wrapper'}>
                {children}

                {this.props.location.pathname.includes("/resources/blog/preview/") && <FooterLandingPage />}
              </div>
            </div>
          </div>

          <ToastContainer style={{ zIndex: '999999' }} />
        </div>
      </>
    );
  }
}

const select = (state) => ({
  auth: state.auth,
  showLoader: state.rawData.showLoader,
  coockieError: state.rawData.coockieError,
});

export default withRouter(connect(select)(AppLayout));
