import React, { useState, useEffect } from "react";
import { SidePanel, LargeButton, TimePickerComponent, ModalBox, DatePickerComponent } from "../../";
import './style.scss';
import NumberFormat from "react-number-format";
import { logger, toaster } from "../../../../Utils";
import { Col, Image, Row, Form } from "react-bootstrap";
import moment from 'moment';
import Cookies from 'js-cookie';
import "react-datepicker/dist/react-datepicker.css";
import { getAPI, postAPI } from "../../../../Api";
import { dateSanitizer } from "../../../../Utils/DateSanitizer/DateSanitizer";
import { useSelector, useDispatch } from "react-redux";
import { setShowingCalendarData, setShowingListData } from "../../../../Store/actions/ShowingDetailAction";

import { createEventId } from "../../Calender/event-utils";
import Loader from "../../Loader";

const log = logger("RequestShowing");
const RequestShowing = (props) => {
    const { isShow, handleClose, title, is_reSchedule, setReSchedule } = props;
    const role = Number(Cookies.get('role'));
    const [errors,setErrors]=useState({})
    const [primaryDate, setPrimaryDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [primaryTime, setPrimaryTime] = useState(moment(new Date()).format('HH:mm:ss'));
    const [alternate_date_and_time, setAlternate_date_and_time] = useState([]);
    const [timeslot_length, setTimeslot_length] = useState([]);
    const [notes, setNotes] = useState("");
    const [openConfirmation, setConfirmation] = useState(false);
    const [viewingRequestDetails, setViewingRequestDetails] = useState(null);
    const [loader, setLoader] = useState(false)

	const dispatch = useDispatch();
	const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
	const propertyUnitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
	const unitDetails = (role === 2 ? propertyUnitDetails : propertyDetails);
	const property_details_id = unitDetails ? unitDetails.id : (propertyDetails && propertyDetails.property_details_id);
	const viewingRequestId = useSelector(state => state.messageDetailsReducer.viewingRequestId);
	const tenant_id = useSelector(state => state.messageDetailsReducer.tenantId);
	const headerContent = useSelector(state => state.messageDetailsReducer.headerContent);
	const name = headerContent && headerContent.name;
	const conversation_id = useSelector(state => state.messageDetailsReducer.conversationId);

	log.info('props', props)
	log.info('viewingRequestDetails', viewingRequestDetails)

    useEffect(() => {
        // if(isShow){
            setNotes("");
            setPrimaryDate(moment(new Date()).format('YYYY-MM-DD'));
            setPrimaryTime(moment(new Date()).format('HH:mm:ss'));
            setAlternate_date_and_time([]);
            setErrors({})
        // }
    },[isShow])

	useEffect(() => {
		if (viewingRequestId && isShow) {
			let url = '';
			if (role === 2) {
				url = '/landlord/showing-request-details/' + viewingRequestId;
			} else if (role === 3) {
				url = '/tenant/showing-invitation-details/' + viewingRequestId;
			}
			getAPI(url).then(response => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					const res = response.data.data;
					setViewingRequestDetails(res);
				}
			})
				.catch(error => {
				});
		}
	}, [viewingRequestId, isShow])

	const getCalenderDetails = () => {
		let url = "/landlord/calendar-showings/calendar-view";
		if (property_details_id) {
			url = "/landlord/calendar-showings/calendar-view/" + property_details_id;
		}
		if (role === 3) {
			url = "/tenant/showing-requests/calendar-view";
		}
		getAPI(url)
			.then((response) => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					const res = response.data.data;
					log.info("Landlord Calender res", res);
					//log.info('sample data ',INITIAL_EVENTS);
					let resultArray = [];
					res.map((result, i) => {
						const date = result.start.replace(/\s/g, "");
						let backgroundColor = "#DEDEDE";
						let borderColor = "#DEDEDE";

						let status = result.data.status;
						//log.info('status',status,result)
						if (status && status.includes("Confirmed")) {
							backgroundColor = "#BFE7C3";
							borderColor = "#BFE7C3";
						} else if (status && status.includes("Attention")) {
							backgroundColor = "#F7BFBF";
							borderColor = "#F7BFBF";
						}

						resultArray.push({
							id: createEventId(),
							title: result.title,
							start: date,
							data: result.data,
							backgroundColor: backgroundColor,
							borderColor: borderColor,
						});

						return resultArray;
					});
					dispatch(setShowingCalendarData(resultArray));
				}
			})
			.catch((error) => { });
	}

	const getCalenderListDetails = () => {
		let calendarUrl = "/landlord/calendar-showings/list-view/";
		if (property_details_id) {
			calendarUrl = "/landlord/calendar-showings/list-view/" + property_details_id;
		}
		if (role === 3) {
			calendarUrl = "/tenant/showing-requests/list-view";
		}
		getAPI(calendarUrl)
			.then((response) => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					const res = response.data.data;
					//log.info('list view res',res);
					dispatch(setShowingListData(res));
				}
			})
			.catch((error) => { });
	}

	const addMore = () => {
		const data = {
			date: moment(new Date()).format('YYYY-MM-DD'),
			time: moment(new Date()).format('HH:mm:ss'),
			is_primary: 0
		}
		setAlternate_date_and_time(alternate_date_and_time => [...alternate_date_and_time, data])
	}

	const handleNotesChange = (e) => {
		setNotes(e.target.value)
	}

	const handleDateChange = (date, e) => {
		const dateValid = dateSanitizer(date);
		setPrimaryDate(moment(dateValid).format('YYYY-MM-DD'))
	}

    const handleChangeTime = (time) => {
        const theDate = new Date(Date.parse(time));
        const timeFormated = theDate.toLocaleTimeString();
        setPrimaryTime(timeFormated);
        checkValidTime(timeFormated)

    }

	const OnChangeDate = (date, index, name) => {
		const dateValid = dateSanitizer(date);
		let items = [...alternate_date_and_time];
		items[index][name] = moment(dateValid).format('YYYY-MM-DD');
		setAlternate_date_and_time(items);
	}
	const OnChangeTime = (date, index, name) => {
		const theDate = new Date(Date.parse(date));
		const timeFormated = theDate.toLocaleTimeString();
		let items = [...alternate_date_and_time];
		items[index][name] = timeFormated;
		setAlternate_date_and_time(items);
	}

	const OnRemove = (index) => {
		let items = [...alternate_date_and_time];
		items.splice(index, 1);
		setAlternate_date_and_time(items);
	}



    const checkValidTime = (time) => {
        const primaryDateMoment = moment().format("YYYY-MM-DD")
        // if (primaryDateMoment === primaryDate) {
        //     const primaryTimeMoment = moment(time, "HH:mm:ss")
        //     if (new Date().getHours() > primaryTimeMoment.hours()) {
        //         setErrors({ time: 'Please select valid time' })
        //         return false
        //     }
        //     else{
        //         setErrors({time:''})
        //     }
        // }
        return true
    }

    const onSubmit = () => {
        // const primaryDateMoment=moment().format("YYYY-MM-DD")
        setLoader(true)
        if(!checkValidTime(primaryTime))
        {
            return
        }
        const tempObj = {
            date: primaryDate,
            time: primaryTime,
            is_primary: 1
        }
        var viewing_date_time = [tempObj, ...alternate_date_and_time];
        setTimeslot_length(viewing_date_time.length);
        let data = {
            "viewing_date_time": viewing_date_time,
            "notes": notes
        }

		let url = '';
		if (role === 2) {
			if (viewingRequestId) {
				url = '/landlord/reschedule-showing-request';
				data.viewing_request_id = viewingRequestId;
				data.conversation_id = conversation_id;
			} else {
				url = '/landlord/showing-invitation';
				data.tenant_id = tenant_id;
				data.property_id = propertyDetails && propertyDetails.id;
				data.property_details_id = unitDetails && unitDetails.id;
			}
		} else if (role === 3) {
			if (viewingRequestId) {
				url = '/tenant/reschedule-showing-invitation';
				data.viewing_request_id = viewingRequestId;
				data.conversation_id = conversation_id;
			} else {
				url = '/tenant/request-showing';
				data.landlord_id = propertyDetails && propertyDetails.landlord_id;
				data.property_id = propertyDetails && propertyDetails.property_id;
				data.property_details_id = propertyDetails && propertyDetails.property_details_id;
			}
		}

		postAPI(url, data).then(response => {
			if (response.status !== 200) {
                setLoader(false)
				toaster('error', response.response.data.message)
			} else if (response.status === 200) {
                setLoader(false)
				log.info('data', response.data)
				handleClose(false);
				setConfirmation(true);
				getCalenderDetails();
				getCalenderListDetails();
			}
		})
			.catch(error => {
                setLoader(false)
			});
	}

    const goBack = () => {
        setConfirmation(false);
        setReSchedule(false)
    }
    return (<>
        <SidePanel isShow={isShow} isHide={handleClose} title={title}>
            {/* {loader ? 
                <Loader />
                : */}
                <>
                    <div className="sidepanel-body request-showing">
                        <Row>
                            {viewingRequestDetails ?
                                <>
                                    <Col md="4" className="pr-0">
                                        {viewingRequestDetails.image_url ?
                                            <Image className="img-responsive" rounded width="108px" height="99px" src={viewingRequestDetails.image_url} />
                                            : <Image className="img-responsive" rounded width="108px" height="99px" src={require("../../../../_assets/images/placeholder.jpg")} />}
                                    </Col>
                                    <Col md="7" className="pl-0">
                                        <div className="showing-address-unit">
                                            <h2 className="property-title">{viewingRequestDetails.address}</h2>
                                            <div className="details-wrapper">
                                                <div className="full-width">
                                                    <span className="specification">{viewingRequestDetails.unit_no && (isNaN(viewingRequestDetails.unit_no) ? viewingRequestDetails.unit_no + ' - '  : ('Unit ' + viewingRequestDetails.unit_no + ' - ' ))} 
                                                    <NumberFormat value={viewingRequestDetails.price_per_month} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per month</span>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </> :
                                <>
                                    <Col md="4" className="pr-0">
                                        {unitDetails && unitDetails.images && unitDetails.images[0] ?
                                            <Image className="img-responsive" rounded width="108px" height="99px" src={unitDetails.images[0]} />
                                            : <Image className="img-responsive" rounded width="108px" height="99px" src={require("../../../../_assets/images/placeholder.jpg")} />}
                                    </Col>
                                    <Col md="7" className="pl-0">
                                        {propertyDetails &&
                                            <div className="showing-address-unit">
                                                <h2 className="property-title">{propertyDetails.address}</h2>
                                                <div className="details-wrapper">
                                                    <div className="full-width">
                                                        <span className="specification">{unitDetails && unitDetails.unit_no && (isNaN(unitDetails.unit_no) ? unitDetails.unit_no + ' - '  : ('Unit ' + unitDetails.unit_no + ' - ' ))} 
                                                        <NumberFormat value={unitDetails && unitDetails.price_per_month} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per month</span>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>}
                                    </Col>
                                </>}
                        </Row>
                        <Form>
                            <Row className="mt-5 mt-mb-10">
                                <Form.Group as={Col} md="12" className="primaryTimeAndDate">
                                    <Form.Label>Primary viewing date and time</Form.Label>
                                    <Row>
                                        <Col xs='6' md="6" className="mr-0 pr-0">
                                            <DatePickerComponent
                                                name="date"
                                                id="date"
                                                value={primaryDate === "" ? primaryDate : moment(primaryDate).toDate()}
                                                handleDateChange={(e) => handleDateChange(e)}
                                                displayText={"Select Date"}
                                                maxDate={moment("9999-12-31").toDate()}
                                                minDate={new Date()}
                                                popperPlacement="bottom-start"
                                            />
                                        </Col>
                                        <Col xs='6' md="6">
                                            {isShow && <TimePickerComponent
                                                viewDateParam={primaryTime}
                                                handleChangeTime={(e) => handleChangeTime(e)}
                                                iconStyles={{marginLeft:-29}}
                                            />}
                                            <span style={{color:'red'}}> 
                                            {errors['time']}
                                            </span>

                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Row>
                            {alternate_date_and_time ?
                                alternate_date_and_time.map((data, i) => {
                                    return (
                                        <div key={'alternate_date_time' + i} className={"alternate_date_time" + i}>
                                            <Row>
                                                <Form.Group as={Col} md="12" className="primaryTimeAndDate">
                                                    <div className="display-flex-inline-elm">
                                                        <Form.Label>alternate date and time</Form.Label>
                                                        <div className='sub-title d-flex justify-content-between align-items-center pt-0'>
                                                            <Image
                                                                className="icon cursorPointer"
                                                                width="20px"
                                                                height="20px"
                                                                title="Remove"
                                                                onClick={() => OnRemove(i)}
                                                                src={require("../../../../_assets/images/delete-icon.svg")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Row>
                                                        <Col xs='6' md="6">
                                                            <DatePickerComponent
                                                                name="date"
                                                                id={"date_" + i}
                                                                value={moment(data.date).toDate()}
                                                                handleDateChange={(e) => OnChangeDate(e, i, "date")}
                                                                displayText={"Select Date"}
                                                                maxDate={moment("9999-12-31").toDate()}
                                                                minDate={new Date()}
                                                                popperPlacement="bottom-start"
                                                            />
                                                        </Col>
                                                        <Col xs='6' md="6">
                                                            <TimePickerComponent
                                                                viewDateParam={data.time}
                                                                handleChangeTime={(e) => OnChangeTime(e, i, "time")}
                                                                iconStyles={{marginLeft:-29}}
                                                        />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Row>
                                        </div>)
                                }) : ''}
                        </Form>

                        <Row className='d-flex justify-content-center'>
                            <Col md='10'>
                                <LargeButton
                                    variant='dark'
                                    title='Add another time'
                                    isIcon={true}
                                    onClick={() => addMore()} />
                            </Col>
                        </Row>
                        <hr />
                        <Row className='d-flex justify-content-center'>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    rows={10}
                                    name="notes"
                                    value={notes}
                                    placeholder="Leave blank if not needed"
                                    onChange={(e) => handleNotesChange(e)}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                    <div className="fixed-bottom d-flex justify-content-between">
                        <LargeButton saveLoading={loader} isDisable={loader}  onClick={() => onSubmit()} title={role === 2 ? 'submit to tenant' : 'submit request'} />
                    </div>
                </>
            {/* // } */}
        </SidePanel>
        <ModalBox show={openConfirmation} onHide={goBack} size="sm" actionbuttontitle={role === 3 ? 'keep searching for a home' : 'got it'} buttonaction={goBack} >
            {is_reSchedule ?
                role === 3 ?
                    <>
                        <p className="modal-confirmation-text">You have rescheduled the showing and given the landlord {timeslot_length} time {timeslot_length > 1 ? 'slots to choose from' : 'slot'}</p>
                        <p>Please stay tuned for their response</p>
                    </> :
                    <>
                        <p className="modal-confirmation-text">You have rescheduled the showing and given the applicant {timeslot_length} time {timeslot_length > 1 ? 'slots to choose from' : 'slot'}</p>
                        <p>Please stay tuned for their response</p>
                    </>

				: role === 3 ?
					<p className="modal-confirmation-text">your request for a showing<br /> has been sent to the<br /> landlord. </p> :
					<>
						<p className="modal-confirmation-text">{name} has been invited to view {propertyDetails && propertyDetails.address}</p>
						<p>check back soon for a confirmation of the appointment from the tenant.</p>
					</>}
		</ModalBox>
	</>);
}

export default RequestShowing;