import React, { useState, useEffect } from "react";
import { ModalBox } from './App/components';
import Cookies from "js-cookie";

const BetaNotification = () => {
    const [betaPop, setBetaPop] = useState(false);
  
    useEffect(() => {
      const isTenantHome = window.location.pathname === "/" || window.location.pathname === "/tenant";
      const isLandlordHome = window.location.pathname === "/landlord";
      
      if (isTenantHome && !Cookies.get("isBetaTenantPopShown")) {
        Cookies.set("isBetaTenantPopShown", true);
        setBetaPop(true);
      } else if (isLandlordHome && !Cookies.get("isBetaLandlordPopShown")) {
        Cookies.set("isBetaLandlordPopShown", true);
        setBetaPop(true);
      }
    }, []);
  
    return  <ModalBox
              show={betaPop}
              onHide={() => setBetaPop(false)}
              size='md'
              actionbuttontitle={'got it'}
              buttonaction={() => setBetaPop(false)}
            >
              <h4>welcome to the new rent panda beta!</h4>
              <p>
                You’re among the first to experience an even better<br />
                way to rent with comfort and ease.
              </p>
              <p>
                If you have any feedback throughout your experience,<br />
                let us know by sending an email to <a href={"mailto:hello@rentpanda.ca"}>hello@rentpanda.ca</a>.
              </p>
            </ModalBox>
  }

  export default BetaNotification;
  