import React, { useEffect, useState } from 'react';
// import Moment from 'react-moment';
import { withRouter } from "react-router-dom";
import { getAPI } from '../../../../../Api';
// import { logger } from '../../../../../Utils';
import { AppLayout } from '../../../../components';
import { Container } from 'react-bootstrap'; //, Row, Col
import Cookies from 'js-cookie';

// import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './style.scss';

// const log = logger('Preview Blog');
const PreviewBlog = (props) => {
    const [previewData, setPreviewData] = useState('');
    const isAdmin = Cookies.get('access_token') && parseInt(Cookies.get('role')) === 1;

    useEffect(() => {
        if (props.match.params.id) {
            let prep = isAdmin ? '/admin' : '';
            getAPI(`${prep}/cms/articles/view/${props.match.params.id}`).then(resData => {
                if (resData.status === 200) {
                    const resDataView = resData.data.data;
                    setPreviewData(resDataView)
                }
            })
        }
    }, [props.match.params.id, isAdmin])

    return (
        <AppLayout noSidebar={true} newWebHeader={true}>
            {previewData && 
                <div className="previewPage">
                    <div className="pageBanner" style={{background: `url(${previewData?.image_url ? previewData?.image_url : require("../../../../../_assets/images/placeholder.jpg")}) no-repeat center center`}}></div>
                    <section className="sec-blog-single">
                        <Container>
                            <h1 className="mainHead text-left">{previewData.headline}</h1>
                            {/* <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col md={6} className="mb-3">
                                            Category: <strong>{previewData.category && previewData.category.name}</strong>
                                        </Col>
                                        <Col className="mb-3">
                                            Tags:
                                            {
                                                previewData.cms_tags.map((tag, i) => {
                                                    return <strong key={`tag_${i}`}>{tag.name}</strong>
                                                })
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="lastCol">
                                    <Row>
                                        <Col>Created By: <span>{previewData.author}</span></Col>
                                        <Col>
                                            Last Edited On: 
                                            <Moment format="MMM D, YYYY">{previewData.updated_at}</Moment>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="pageExcerpt">Excerpt: {previewData.excerpt}</div> */}
                            {/* <div className="pageContent">{previewData.content}</div> */}
                            <div className="pageContent ql-editor" dangerouslySetInnerHTML={{__html: previewData.content}} />
                        </Container>
                    </section>                    
                </div>
            }
        </AppLayout>
    )
}

export default withRouter(PreviewBlog);