export const REVIEWS = [
  {
    _id: 1,
    name: "- Chantal, Landlord",
    meta: "Owner of Extreme Rental Company",
    description:
      "Very easy to find exactly what you are looking for. The staff go over and beyond what anyone would expect. Definitely recommend to anyone who wants to rent a home.",
  },
  {
    _id: 2,
    name: "- Jessi, Landlord",
    meta: "Prince, United Kingdom",
    description:
      "This is the only service I use when I list a unit. Professional, responsive, efficient and effective in not just taking you from getting your listing live, but helping you through to post leasing as well.",
  },
  {
    _id: 3,
    name: "- Rob, Landlord",
    meta: "Owner of Extreme Rental Company",
    description:
      "Rent Panda provides an exceptional link between the landlord and the tenant. The Rent Panda team is very responsive and act quickly to support landlords in the rental process.",
  },
  {
    _id: 4,
    name: "- Chantal, Landlord",
    meta: "Prince, United Kingdom",
    description:
      "Very easy to find exactly what you are looking for. The staff go over and beyond what anyone would expect. Definitely recommend to anyone who wants to rent a home.",
  },
];
