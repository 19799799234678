import React, { useEffect, useState } from "react";
import { Col, Row, InputGroup, Form, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { logger, toaster } from "../../../Utils";
import { SidePanel, LargeButton } from "../";
import { postAPI } from "../../../Api";
import "./style.scss";

const log = logger("ShareListing");
const ShareListing = (props) => {
    const { isShow, handleClose } = props;
    const base_url = window.location.origin;
    const [property_id, setProperty_id] = useState("");
    const [property_details_id, setProperty_details_id] = useState("");
    const [share_url, setShareUrl] = useState('');
    const [copySuccess, setCopySuccess] = useState('Copy url');
    const [email, setEmail] = useState(['']);
  
    const OnChangeInput = (event, index, setState) => {
        let items = [...email];
        items[index] = event.target.value;
        setState(items)
    }

    const addEmail = () => {
        let emailArry = [...email];
        emailArry.push('');
        setEmail(emailArry);      
    }

    const copyUrl = () => {
        navigator.clipboard.writeText(share_url);
        setCopySuccess('Copied!')
    }    

    const onSubmit = () =>{
        const url = `/landlord/share-listing`;
        if (email[0] === '') {
            toaster('error', 'Email is required!');
            return false;
        }
        const data = {
            "property_id": property_id,
            "property_detail_id": property_details_id,
            "share_url": share_url,
            "emails": email
        };
        postAPI(url, data).then((response) => {
            if (response.status === 200) {
                const resMsg = response.data.message;
                toaster('success', resMsg);
                handleClose();
            }else{                
                toaster('error', 'Something went wrong!');
            }
        })       
    }    

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
        setShareUrl(`${base_url}/property-details/${Number(props.match.params.property_id)}/${Number(props.match.params.property_details_id)}/unit-details`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.property_id, props.match.params.property_details_id]);

    const shareOnSocialMedia = () => {
        let urlParameters = [];
        if (share_url) {
            urlParameters.push('u=' + encodeURI(share_url));
        }
        const fb = 'https://www.facebook.com/sharer/sharer.php?';
        const url2share = fb + urlParameters.join('&');
    
        window.open(url2share, "_blank");
    };
    
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose()}
            title="Share Listing"
            subTitle="You can share your listing easily to any platform using any actions below">
            
            <div className='sidepanel-body pt-4'>
                <Form id='Form'>          
                    <Row>
                        <Form.Group as={Col} md='12'>
                            <Form.Label>Use this link to share the listing</Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><span><Image src={require('../../../_assets/images/link-active-icon.svg')} alt='link' /></span></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    type='text'
                                    placeholder='https://rentpanda.com/Gdft5g6qKjZTs5'
                                    name={'share_url'}
                                    id={share_url}
                                    defaultValue={share_url}
                                />
                                <InputGroup.Append className="no-bg">
                                    <InputGroup.Text>
                                        <span><Image src={require('../../../_assets/images/copy-active-icon.svg')} alt='copy' className={'cursor-pointer'} onClick={() => copyUrl()} title={copySuccess} /></span>
                                    </InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md='12'>
                            <Form.Label>Send your property by Email</Form.Label>
                            {email && email.map((data,i)=>{
                                return <Form.Control
                                        key={'email_'+i}
                                        type='email'
                                        placeholder='Input email here'
                                        name={'email'}
                                        id={'email_'+i}
                                        className='mb-3'
                                        value={data}
                                        onChange={(e) => OnChangeInput(e, i, setEmail)}
                                    />
                            })}                            
                            <span className='promo-code-display mt-2' onClick={()=>addEmail()}>+ Invite more by email</span>
                        </Form.Group>

                        <Form.Group as={Col} md='12' className="mt-2">
                            <Form.Label>Share To</Form.Label>
                            <div className='social-icons'>
                                <Image onClick={() => shareOnSocialMedia('facebook')}  src={require('../../../_assets/images/facebook-icon.svg')} alt='facebook' />
                                {/* <Image src={require('../../../_assets/images/instagram-icon.svg')} alt='instagram' /> */}
                            </div>
                        </Form.Group>
                    </Row>
                </Form>
            </div>

            <div className={'fixed-bottom d-flex justify-content-end'}>
                <LargeButton onClick={() =>{
                    onSubmit(); 
                }} title={'Share'} style={{ width: '200px'}} />
            </div>
        </SidePanel>        
    </>);
}
export default withRouter(ShareListing);