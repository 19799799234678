import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from 'react-router-dom';
import "./style.scss";
import { AppLayout, PageTitleHeader, ModalBox } from "../../components";
import { StepOne, StepTwo, StepThree, StepFour, StepFive, FreeRentAssessment, ProfessionalPhotoShoot, AddUnit, ImageUpload, PromoteListing, PaymentSummary, PaymentMethod } from "./Steps";
import { getAPI } from "../../../Api";
import { logger } from "../../../Utils";
import { useSelector, useDispatch } from "react-redux";
import { setPropertyDetail, getUnitDetail } from "../../../Store/actions/PropertyDetailAction";

const log = logger("CreateListing");
const CreateListing = (props) => {
  const location = useLocation();
  const [property_id, setProperty_id] = useState('');
  const [property_details_id, setProperty_details_id] = useState('');
  const [freeRentAssessment, setFreeRentAssessment] = useState(false);
  const [free_rent_assessment_id, setFree_rent_assessment_id] = useState('');
  const [addUnit, setAddUnit] = useState(true);
  const [photoType, setPhotoType] = useState('');
  const [isPaymentSummary, setIsPaymentSummary] = useState(false);
  const [advertisement_payment_id, setAdvertisement_payment_id] = useState('');
  const [successModalShow, setSuccessModalShow] = useState(false);

  const dispatch = useDispatch();
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_type = propertyDetails && propertyDetails.property_type;
  const currentStep = useSelector(state => state.propertyDetailsReducer.currentPage);

  const currentPath = location.pathname.split('/')[1];
  log.info("currentPath", currentPath);
  log.info("propertyDetails", propertyDetails);
  log.info("unitDetails", unitDetails);

  const handleClose = () => {
    setFreeRentAssessment(false)
    setPhotoType('')
    setIsPaymentSummary(false)
    setAdvertisement_payment_id('')
  }
  const backToPrevPage = () => {
    if (currentPath === 'create-listing') {
      props.history.push('/dashboard')
    } else {
      props.history.push('/property-details/' + property_id + '/' + property_details_id + '/unit-details');
    }
  }

  const getPropDetails = () => {
    if (property_id) {
      const url = '/landlord/' + property_id + '/property-details';
      getAPI(url).then(response => {
        const res = response.data.data;
        dispatch(setPropertyDetail(res));
        res.details.length > 0 &&
          res.details.map((data) => {
            if (data.id === property_details_id) {
              dispatch(getUnitDetail(data))
            }
            return data;
          })
      })
        .catch(error => {
        });
    }
  }


  //Edit Listing
  useEffect(() => {
    if (props.match.params.property_id) {
      setProperty_id(Number(props.match.params.property_id));
      setProperty_details_id(Number(props.match.params.property_details_id));
    } else if (propertyDetails) {
      setProperty_id(Number(propertyDetails.id));
      if (unitDetails) {
        setProperty_details_id(Number(unitDetails.id));
      }
    }
  }, [props, location.pathname, propertyDetails]);

  //Get Listing
  useEffect(() => {
    getPropDetails();
  }, [currentStep, addUnit, isPaymentSummary, props, photoType])

  useEffect(() => {
    document.getElementById('profileTop').scrollIntoView()
  }, [currentStep])
console.log({photoType})

  return (<>
    <AppLayout>
      <div className="profile" id="profileTop">
        <PageTitleHeader
          title={currentPath === 'create-listing' ? 'create new listing' : 'edit listing'}
          // mobileTitle={currentPath === 'create-listing' ? 'create new listing' : 'edit listing' }
          progress={
            (currentStep === 0) ? 10 :
              (currentStep === 1) ? 20 :
                (currentStep === 2) ? 50 :
                  (currentStep === 3) ? 60 :
                    (currentStep === 4) ? 70 :
                      (currentStep === 5) ? 80 :
                        (currentStep === 6) && 90
          }
          isArrow={true}
          onClick={() => backToPrevPage()}
        />

        <div className="profile-container">
          <div className="profile-container-box">
            {/* Initial Step-1 */}
            {(currentStep === 0) &&
              <StepOne
                setProperty_id={(data) => setProperty_id(data)} />
            }

            {/*  Step-2 for all 3 types of properties */}
            { // Full Property / House
              (currentStep === 1 && property_type === 1) &&
              <StepTwo
                setProperty_details_id={(data) => setProperty_details_id(data)}
                setFreeRentAssessment={(flag) => setFreeRentAssessment(flag)}
                free_rent_assessment_id={free_rent_assessment_id}
                backToPrevPage={() => backToPrevPage()}
                setSuccessModalShow={(flag) => setSuccessModalShow(flag)} />
            }

            { // Per Unit
              (currentStep === 1 && property_type === 2) &&
              // (addUnit && (currentPath !== 'edit-listing')) ?
              //   <AddUnit
              //     title="Unit For Rent"
              //     setAddUnit={(flag) => setAddUnit(flag)}
              //     setProperty_details_id={(data) => setProperty_details_id(data)} />
              // : 
              <StepTwo
                setProperty_details_id={(data) => setProperty_details_id(data)}
                setFreeRentAssessment={(flag) => setFreeRentAssessment(flag)}
                free_rent_assessment_id={free_rent_assessment_id}
                // setAddUnit={(flag) => setAddUnit(flag)}
                backToPrevPage={() => backToPrevPage()}
                setSuccessModalShow={(flag) => setSuccessModalShow(flag)} />
              // : ''
            }

            { // Per Room only (Room located in pvt house)
              (currentStep === 1 && property_type === 3) &&
              // (addUnit && (currentPath !== 'edit-listing')) ?
              //   <AddUnit
              //     title="Room For Rent"
              //     setAddUnit={(flag) => setAddUnit(flag)}
              //     setProperty_details_id={(data) => setProperty_details_id(data)} />
              //   : 
              <StepTwo
                setProperty_details_id={(data) => setProperty_details_id(data)}
                setFreeRentAssessment={(flag) => setFreeRentAssessment(flag)}
                free_rent_assessment_id={free_rent_assessment_id}
                // setAddUnit={(flag) => setAddUnit(flag)}
                backToPrevPage={() => backToPrevPage()}
                setSuccessModalShow={(flag) => setSuccessModalShow(flag)} />
              // : ''
            }

            {/* Step-3 */}
            {(currentStep === 2) &&
              <StepThree
                backToPrevPage={() => backToPrevPage()}
                setSuccessModalShow={(flag) => setSuccessModalShow(flag)} />
            }

            {/* Step-4 */}
            {(currentStep === 3) &&
              <StepFour
                backToPrevPage={() => backToPrevPage()}
                setSuccessModalShow={(flag) => setSuccessModalShow(flag)} />
            }

            {/* Step-5 - Image Upload */}
            {(currentStep === 4) &&
              <StepFive
                setPhotoType={(data) => setPhotoType(data)}
              />
            }

            {/* Step-6 - */}
            {/* {(currentStep === 5 || photoType === 'professional' || photoType === 'video') && */}
            {(currentStep === 5) &&
              <ImageUpload
                key={'imageUpload'}
                setPhotoType={(data) => setPhotoType(data)}
                backToPrevPage={() => backToPrevPage()}
                unitDetails={unitDetails}
                setSuccessModalShow={(flag) => setSuccessModalShow(flag)} />
            }

            {/* Step-7 */}
            {(currentStep === 6) &&
              <PromoteListing
                setAddUnit={(flag) => setAddUnit(flag)}
                setIsPaymentSummary={(flag) => setIsPaymentSummary(flag)} />
            }

          </div>
        </div>
      </div>
    </AppLayout>

    {/* Free Rent Assessment Form */}
    <FreeRentAssessment
      isShow={freeRentAssessment}
      handleClose={handleClose}
      setFree_rent_assessment_id={(data) => setFree_rent_assessment_id(data)} />

    {/* Professional Photo Shoot Form */}
    <ProfessionalPhotoShoot
      isShow={(photoType === 'professional' || photoType === 'video') && true}
      title={photoType === 'video' ? 'Professional video shoot' : 'Professional photo shoot'}
      subTitle={photoType === 'video' ? 'For only $30 you can get a 360 video added to your listing. And it only takes 15 mins to shoot!' : 'For a limited time you can have professional photos of your unit taken by for FREE!'}
      type={photoType === 'video' ? 2 : 1}
      handleClose={handleClose} />

    {/* Payment Summary Form */}
    <PaymentSummary
      isShow={isPaymentSummary}
      handleClose={handleClose}
      setAdvertisement_payment_id={(data) => setAdvertisement_payment_id(data)}
    />

    {/* Payment Method Form */}
    <PaymentMethod
      isShow={advertisement_payment_id && true}
      handleClose={handleClose}
      setAddUnit={(flag) => setAddUnit(flag)}
      advertisement_payment_id={advertisement_payment_id} />

    {/* Edit Sucess Model */}
    <ModalBox
      show={successModalShow}
      onHide={() => {
        setSuccessModalShow(false)
        backToPrevPage()
      }}
      size="sm"
      actionbuttontitle={'got it'}
      buttonaction={() => {
        setSuccessModalShow(false)
        backToPrevPage()
      }}
    >
      <h4>your changes have <br />been saved </h4>
      <p>after a quick review by our team, your listing will be updated.</p>
    </ModalBox>

  </>);
}

export default withRouter(CreateListing);