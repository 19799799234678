import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { getAPI, postAPI } from "../../../../Api";
import { logger, toaster } from "../../../../Utils";
import Cookies from "js-cookie";
import ChatBox from "../../../components/Message/ChatBox";
import { validate } from "../../../../Utils/Validation/validation";
import { MessageValidationSchema } from "../../../components/Message/MessageValidationSchema";
import { useDispatch, useSelector } from 'react-redux';
import { setMessageData, setLeaseData, setConversationId, setExistingShowingRequestId, setExistingShowingSentBy, setExistingTenancyApplicationId, setIsTenancyApplicationReceived, setIsBlocked, setIsProfileCompleted, setReceiverData, setIsLeaseExists, clearMessageData } from "../../../../Store/actions/MessageDetailAction";

import io from 'socket.io-client';
import { SOCKET_URL } from "../../../../Constants/endPoints";
import { Loader } from "../../../components";

const log = logger("MessageTenant");
const MessageTenant = (props) => {
    const { setShowingRequest, setConfirmShowing, setViewingRequestId, setApply_for_tenancy, setLeasePdfViewScreen, setReSchedule, is_reSchedule } = props;
    const [message, setMessage] = useState('');
    const [is_fetching_data, setFetching_data] = useState(true);
 
    const dispatch = useDispatch();
    const messageData = useSelector(state => state.messageDetailsReducer.messageData);
    const conversation_id = useSelector(state => state.messageDetailsReducer.conversationId);
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);

    const role = Number(Cookies.get("role"));
    const property_id = propertyDetails && propertyDetails.property_id;
    const property_details_id = propertyDetails && propertyDetails.property_details_id;
    const headerContent = useSelector(state => state.messageDetailsReducer.headerContent);


    let socket = useState(null);

    useEffect(() => {
         return () => {
            //  console.log('Unmounted')
        }
    }, [])

    useEffect(() => {
        socket = io(SOCKET_URL);
        log.info('socket', socket)

        socket.on("connect", () => {
            log.info('socket connected', socket.connected); // true
        });

        socket.on('reconnect', (attemptNumber) => {
            log.info("socket reconnected -", attemptNumber)
        });

        socket.on("disconnect", (reason) => {
            log.info('socket disconnected', reason, '===>', socket.disconnected); // false
            socket.open();
        });

        if (conversation_id) {
            socket.on("conversation-" + conversation_id + ":MessageEvent", () => {
                log.info('socket conversation', getSocketDataCall())
            });
        }

        return () => {
            // debugger
            // socket.on("disconnect", (reason) => {
            //     log.info('socket disconnected', reason, '===>', socket.disconnected); // false
            // });
            // socket.close()
            socket.removeAllListeners("conversation-" + conversation_id + ":MessageEvent")
            // console.log(socket.removeAllListeners("conversation-" + conversation_id + ":MessageEvent"))
        }

    }, [conversation_id]);

    // useEffect(() => {
    //     socket = io(SOCKET_URL);
    //     return () =>{
    //         debugger
    //         // socket.on("disconnect", (reason) => {
    //             //     log.info('socket disconnected', reason, '===>', socket.disconnected); // false
    //             // });
    //             // socket.close()
    //         socket.removeAllListeners("conversation-" + conversation_id + ":MessageEvent")
    //         console.log(socket.removeAllListeners("conversation-" + conversation_id + ":MessageEvent"))
    //         console.log('disconnected')
    //     }
    // },[])

    const getSocketDataCall = () => {
        // console.log(mounted)
        getMessages(false);
    }

    const getMessages = (clearAll = true) => {
        const url = "/tenant/get-messages/" + property_details_id;
        if (clearAll) {
            dispatch(clearMessageData())
        }

        getAPI(url).then(response => {
            const res = response.data.data;
            dispatch(setMessageData(res.messageData));
            dispatch(setLeaseData(res.leaseDetail));
            dispatch(setConversationId(res.conversationId));
            dispatch(setExistingShowingRequestId(res.existingShowingRequestId));
            dispatch(setExistingShowingSentBy(res.existingShowingSentBy))
            dispatch(setExistingTenancyApplicationId(res.existingTenancyApplicationId));
            dispatch(setIsTenancyApplicationReceived(res.isTenancyApplicationReceived));
            dispatch(setIsBlocked(res.is_blocked));
            dispatch(setIsProfileCompleted(res.is_profile_completed));
            dispatch(setIsLeaseExists(res.isLeaseExists));
            //dispatch(setReceiverData(null));
            dispatch(setViewingRequestId(null));
            setTimeout(() => setFetching_data(false), 500)

        })
            .catch(error => {
                setTimeout(() => setFetching_data(false), 500)
            });
    }

    const handleChange = (event) => {
        setMessage(event.target.value);
    }

    const sendMessage = (event, props) => {
        event.preventDefault();
        if (validate(MessageValidationSchema)) {
            const data = {
                "property_id": property_id,
                "property_details_id": property_details_id,
                "message": message
            };
            const url = "/tenant/send-message";
            postAPI(url, data).then(response => {
                if (response.status !== 200) {
                    toaster('error', response.response.data.message)
                }else if(response.status === 200){
                    getMessages(conversation_id);
                    setMessage('')
                }
            })
            .catch(error => {
            });
        }
    }

    const blockUser = (status) => {
        let url = '/tenant/block-landlord';
        if (status === 'unblock') {
            url = '/tenant/unblock-landlord';
        }
        const data = {
            "conversation_id": conversation_id
        }
        postAPI(url, data).then(response => {
            log.info('data', response.data)
            getMessages();
        })
            .catch(error => {
            });
    }

    useEffect(() => {
        if (property_details_id) {
            getMessages();
        }
    }, [property_id, property_details_id]);

    log.info("messageData", messageData);

    return (
        <div className="profile">
            <div className="profile-container">
                <div className="profile-container-box property-details">
                    <Row>
                        {is_fetching_data ?
                            <Col md={12}>
                                <div className='chat-wrapper' style={{ display: 'flex', alignItems: 'center' }}><Loader /></div>
                            </Col>
                            : <ChatBox
                                messageData={messageData}
                                headerContent={headerContent}
                                conversation_id={conversation_id}
                                setReSchedule={(flag) => setReSchedule(flag)}
                                is_reSchedule={is_reSchedule}
                                grid={12} role={role}
                                sendMessage={(data) => sendMessage(data)}
                                message={message}
                                handleChange={(data) => handleChange(data)}
                                blockUser={(data) => blockUser(data)}
                                setShowingRequest={setShowingRequest}
                                setConfirmShowing={setConfirmShowing}
                                setViewingRequestId={setViewingRequestId}
                                set_tenancy={setApply_for_tenancy}
                                setLeasePdfViewScreen={setLeasePdfViewScreen}
                            />}
                    </Row>
                </div>
            </div>
        </div>

    )
}

export default withRouter(MessageTenant);