import React, { useState, useEffect } from "react";
import "../style.scss";
import {
  Container,
  Row,
  Col,
  Navbar,
  NavDropdown,
  Image,
} from "react-bootstrap";
import {
  AppLayout,
  PageTitleHeader,
  PropertyCard,
  Loader,
} from "../../../components";
import { withRouter } from "react-router-dom";
import { logger } from "../../../../Utils";
import { getAPI } from "../../../../Api";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { rentedAddress } from "../../../../Store/actions/MaintenanceRepairAction";

const log = logger("RentedListing");
const RentedListing = (props) => {
  // const history = useHistory();
  const [propertyLists, setPropertyLists] = useState(null);
  const [activeKey, setActiveKey] = useState("new_to_old");
  const accessToken = Cookies.get("access_token")
    ? Cookies.get("access_token")
    : null;
  const dispatch = useDispatch();
  useEffect(() => {
    const url = "/landlord/rented-property-listing";
    if (accessToken) {
      getData(url);
    }
  }, [props, accessToken]);

  const getData = (url) => {
    getAPI(url)
      .then((response) => {
        const res = response.data.data;
        log.info(res);
        setPropertyLists(res);
      })
      .catch((error) => { });
  };

  const propertyDetails = (details, address, path) => {
    props.history.push({
      pathname:
        "/maintainance-repair/" +
        details.property_id +
        "/" +
        details.property_details_id +
        "/" + path,
    });
    dispatch(rentedAddress(address));
  };

  const handleDropdownSelect = (key) => {
    setActiveKey(key);
    let url =
      "/landlord/rented-property-listing?sort_by=post_date&sort_order=" + key;
    if (key === "low_to_high" || key === "high_to_low") {
      url = "/landlord/rented-property-listing?sort_by=price&sort_order=" + key;
    }
    getData(url);
  };

  const formattedDetails = (data) => {
    const {unit_no, bedroom, price_per_month, property_type, is_shared_bathroom} = data;
    const bedRoom = bedroom && (parseInt(bedroom) > 1 ? (bedroom + ' Bedrooms') : (bedroom + ' Bedroom'));
    const price = price_per_month && `$${price_per_month}/Month`;
    // property_type {
    //   1 : 'Full Property',
    //   2 : 'Unit',
    //   3 : 'Room for Rent'
    // }
    let details = '';
    if (property_type === 3) {
      if (is_shared_bathroom && is_shared_bathroom === 1) {
        details = `Room • Shared Bathroom`;
      } else {
        details = `Room • Private Bathroom`;
      }
      if (price) {
        details += ` • ${price}`;
      }
    } else {
      if (property_type === 2) {
        details = unit_no ? (isNaN(unit_no) ? unit_no : `Unit ${unit_no}`) : 'Unit';
      } else if (property_type === 1) {
        details = `Full Property`;
      }
      if (bedRoom && price) {
        details += ` • ${bedRoom} • ${price}`;
      } else if (bedRoom) {
        details += ` • ${bedRoom}`;
      } else if (price) {
        details += ` • ${price}`;
      }
    }
    return details;
  }

  return (
    <>
      <AppLayout>
        <div className="profile">
          <PageTitleHeader
            title="rented listing"
          />

          <div className="profile-container">
            <div className="profile-container-box">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  zIndex: "20",
                  padding: "0 5px",
                  marginBottom: "16px",
                }}
              >
                <Navbar className="filter-nav">
                  <Navbar.Collapse className="d-flex justify-content-between">
                    <Navbar.Text>posted date:</Navbar.Text>
                    <NavDropdown
                      title={activeKey.split("_").join(" ")}
                      id="nav-dropdown"
                    >
                      <NavDropdown.Item
                        onClick={(e) => handleDropdownSelect("new_to_old")}
                      >
                        posted date: new to old
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={(e) => handleDropdownSelect("old_to_new")}
                      >
                        posted date: old to new
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={(e) => handleDropdownSelect("low_to_high")}
                      >
                        price: low to high
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={(e) => handleDropdownSelect("high_to_low")}
                      >
                        price: high to low
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Navbar.Collapse>
                </Navbar>
              </div>

              <Container fluid>
                <Row>
                  {propertyLists ? (
                    propertyLists.length > 0 ? (
                      propertyLists.map((list, i) => {
                        return (
                          <PropertyCard
                            key={"propery_" + i}
                            // inquiries={list.new_inquiry}
                            image_url={list.image_url}
                            approval={
                              list.formatted_status
                                ? list.formatted_status
                                : "in progress"
                            }
                            cardType="rentedList"
                            title={list.address}
                            notice_count={list.notice_count}
                            request_count={list.request_count}
                            details={formattedDetails(list)}
                            onClick={() => propertyDetails(list, list.address, 'overview')}
                            onClickRepairs={() => propertyDetails(list, list.address, 'repairs')}
                            onClickNotice={() => propertyDetails(list, list.address, 'notices')}
                            tenant_name={list.tenant_name}
                            {...list}
                          />
                        );
                      })
                    ) : (
                        <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: "0 0 1rem" }}>
                          <Image src={require("../../../../_assets/images/RP-Illustration.svg")} style={{marginBottom: '1.5rem', maxHeight: '50vh', maxWidth: '60vw'}} />
                          <p style={{ fontSize: "25px", color: "#979797" }}>You currently have no rented properties.</p>
                        </Col>
                      )
                  ) : (
                      <Loader />
                    )}
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
};

export default withRouter(RentedListing);
