import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Image } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchLocation } from '../../../Store/actions/LandingPageAction';
import { postAPI } from '../../../Api';
import { logger } from '../../../Utils';
import { AppLayout, NavbarSortingDropdown, PropertyCard, ModalBox, AutoCompleteAddress, Loader } from '../../components';
import ListBlockContentLoader from '../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader';
import { FilterTenantProperty } from './Filter';
import SearchMapListing from './Filter/SearchMapListing';
import './style.scss';
import HorizontalPropertyCard from '../../components/PropertyCard/HorizontalPropertyCard';

const log = logger("SearchListing");
const SearchListing = (props) => {
	const horizontalRef = React.useRef()
	const [isMapVisible, setIsMapVisible] = useState(false);
	const [isDesktop, setIsDesktop] = useState(true);
	const searchLocation = useSelector(state => state.landingPageReducer.getSearchLocation) || '';
	const getSearchFilter = useSelector(state => state.landingPageReducer.getSearchFilter) || null;
	const dispatch = useDispatch();
	const [sortName, setSortName] = useState("new to old");
	const [tenantFilter, setTenantFilter] = useState(false);
	const [listData, setPropertyLists] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedId, setSelectedId] = useState(null);
	const [confirmAction, setConfirmAction] = useState(false);
	const [currentPage, setCurrentPage] = useState(1)
	const [showBottomLoader, setShowBottomLoader] = useState(false)
	const [selectedLocation, setSelectedLocation] = useState(null)

	// const [address, setAddress] = useState('');
	const sortOptions = [{ display: "new to old", key: "desc" }, { display: "old to new", key: "asc" }];
	const tokenCookie = Cookies.get('access_token');
	const isLoggedIn = tokenCookie;
	const handleMapVisibility = (setAs) => {
		if (!isDesktop) {
			setIsMapVisible(setAs);
		}
	}
	const checkIsDesktop = () => {
		let desktopWidth = window.innerWidth;
		if (desktopWidth > 600) {
			setIsDesktop(true);
		} else {
			setIsDesktop(false);
			if (isMapVisible === null) {
				setIsMapVisible(false)
			}
		}
	}
	useEffect(() => {
		checkIsDesktop();
		window.addEventListener("resize", checkIsDesktop, false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const getSignedUp = () => {
		setConfirmAction(false);
		props.history.push('/signup/tenant');
	}
	const getData = (url, search_data, page = 0) => {
		// setAddress(search_data);

		const data = {
			...getSearchFilter,
			"search_term": search_data
		}
		if (page) {
			setShowBottomLoader(true)
		} else {
			setIsLoading(true);
		}

		postAPI(page ? `${url}?page=${page + 1}` : url, data).then(response => {
			if (page) {
				setCurrentPage(page)
			}
			const res = response.data.data;
			setPropertyLists(page ? { next_page_url: res.next_page_url, data: [...listData.data, ...res.data] } : res);
			setIsLoading(false);
			setShowBottomLoader(false)

		}).catch(error => {
			log.info('error', error);
			setShowBottomLoader(false)
		});
	}

	useEffect(() => {
		// if (searchLocation !== null) {
		setSelectedId(null);
		setCurrentPage(0)
		setIsLoading(true);
		setPropertyLists({})

		const timeout = setTimeout(() => {

			const url = '/tenant/search-property';
			getData(url, searchLocation);
		}, 1000)
		return () => clearTimeout(timeout)
		// }
	}, [searchLocation]);

	const filter = () => {
		setTenantFilter(!tenantFilter);
	}
	const propertyDetails = (property_id, property_details_id) => {
		if (isLoggedIn && parseInt(Cookies.get('role')) === 3) {
			// props.history.push({
			// 	pathname: '/property-details/' + property_id + '/' + property_details_id + '/details',
			// 	state: { backpath: props.location.pathname }
			// });
			
			const win = window.open('/property-details/' + property_id + '/' + property_details_id + '/details')
			win.focus()	
		} else {
			props.history.push({
				pathname: '/property-details/' + property_id + '/' + property_details_id + '/details/tour',
				state: { backpath: props.location.pathname }
			});
			// window.open()
		}
	}

	const formattedDetails = (data) => {
		const { bedrooms, property_type } = data;
		const bedRoom = bedrooms && (bedrooms > 1) ? (bedrooms + ' Bedrooms') : (bedrooms + ' Bedroom');
		// property_type {
		//   1 : 'Full Property',
		//   2 : 'Unit',
		//   3 : 'Room for Rent'
		// }
		let details = '';
		if (property_type === 3) {
			details = 'Room for Rent';
		} else if (property_type === 2) {
			details = `Unit • ${bedRoom}`;
		} else if (property_type === 1) {
			details = `Full Property • ${bedRoom}`;
		}
		return details;
	}

	const apiCallBack = (res) => {
		setPropertyLists(res.data.data);
		filter();
	}

	const getClickedId = (id) => {
		setSelectedId(id);
		if (!isDesktop) {
			const seclectedItem = horizontalRef.current.children['card_item_' + id]
			seclectedItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
			return
		}
		const proprty_id = '#property_card_' + id;
		const section = document.querySelector(proprty_id);
		if (section) {
			section.scrollIntoView({ behavior: 'smooth', block: 'start' });
		} else {
			setIsLoading(true);
			const url = '/tenant/search-property';
			getData(url, searchLocation, currentPage + 1);
			if (!isLoading) {
				setTimeout(() => {
					getClickedId(id);
				}, 500);
			}
		}
	}

	const handleDropdownSelect = (name, key) => {
		setSortName(name);
		setSelectedId(null);
		const url = '/tenant/search-property?sort_order=' + key;
		getData(url, searchLocation);
	}

	const handlePlaceChanged = (place) => {
		const address = place.formatted_address;
		// setAddress(address);
		dispatch(setSearchLocation(address))
		const latValue = place.geometry?.location.lat(),
			lngValue = place.geometry?.location.lng();
		// setLat(latValue);
		// setLang(lngValue);
		setSelectedLocation({ lat: latValue, lng: lngValue })

	}
	const handleChange = event => {
		event.target.setAttribute('autocomplete', 'off');
		dispatch(setSearchLocation(event.target.value))
		// setAddress(event.target.value);
	}


	const handleScroll = (e) => {
		if (showBottomLoader || !listData.next_page_url) {
			e.preventDefault()
			return
		}
		const scrollHeight = e.target.scrollHeight;
		const scrollTop = e.target.scrollTop
		const clientHeight = e.target.clientHeight
		const c1 = parseInt((parseInt(scrollHeight) - parseInt(scrollTop))) === parseInt(clientHeight)
		const c2 = parseInt((parseInt(scrollHeight) - parseInt(scrollTop)) + 1) === parseInt(clientHeight)
		const c3 = parseInt((parseInt(scrollHeight) - parseInt(scrollTop)) - 1) === parseInt(clientHeight)

		const isBottom = c1 || c2 || c3

		if (isBottom  ) { 

			const url = '/tenant/search-property';

			getData(url, searchLocation, currentPage + 1)
		}

	}

	const handleBlur=(event)=>{
		if (!event.currentTarget.contains(event.relatedTarget)) {
			setSelectedId("")
		}
	}

	return (<>
		<AppLayout customClass={`${isLoggedIn ? 'search-listing-page' : 'search-listing-no-auth'}`}>
			<div className="search-listing">
				<div className="profile-header">
					<div className="row headRow mt-4">
						<Col md='4' xs='12' className="pr-0 pr-10">
							<Form.Group className="display-flex-inline-elm search-elm">


								<AutoCompleteAddress
									className="searchBar"
									placeholder="Type to search location"
									id="searchLocation"
									state={searchLocation === null ? "" : searchLocation}
									updateField={(data) => handleChange(data)}
									handlePlaceChanged={(data) => handlePlaceChanged(data)}

								// updateField={(e) => {
								// 	setAddress(e.target.value);
								// 	dispatch(setSearchLocation(e.target.value))
								// }}
								// handlePlaceChanged={(data) => {}}
								/>


								{/* <Form.Control
									type="text"
									placeholder="Type to search location"
									id="searchLocation"
									name="searchLocation"
									value={searchLocation === null ? "" : searchLocation}
									onChange={(e) => dispatch(setSearchLocation(e.target.value))}
									className="searchBar"
								/> */}
							</Form.Group>
						</Col>
						{/* Visible only on dekstop */}
						<Col md='1' xs='2' className="pl-0 pr-0 prl-10 text-center d-none d-sm-block">
							<div className="sorting cursorPointer" onClick={() => filter()}>
								<img className="img-reponsive t-sort" src={require("../../../_assets/images/sorting-icon.svg")} alt="sort-btn" />
							</div>
						</Col>
						{/* end Visible only on dekstop */}
						<Col md='5' lg='4' xs='8' className={`pl-0 pr-0 prl-10 ${(!isDesktop && isMapVisible === true) ? 'd-none' : 'd-block'}`}>
							<NavbarSortingDropdown handleDropdownSelect={(data, key) => handleDropdownSelect(data, key)} sortOptions={sortOptions} sortName={sortName} className="pl-mb-10" />
						</Col>
						{/* hide on dekstop */}
						<Col md='1' xs='2' className={`pl-0 pr-0 prl-10 text-center d-sm-none align-items-center ${(!isDesktop && isMapVisible === true) ? 'd-none' : 'd-flex'}`}>
							<div className="sorting cursorPointer" onClick={() => filter()}>
								<img className="img-reponsive t-sort" src={require("../../../_assets/images/sorting-icon.svg")} alt="sort-btn" />
							</div>
						</Col>
						<Col md='1' xs='2' className={`pl-0 pr-0 prl-10 text-center d-sm-none align-items-center ${(!isDesktop && isMapVisible === true) ? 'd-none' : 'd-flex'}`}>
							<div className="sorting cursorPointer">
								<Image
									className="img-reponsive t-sort"
									src={require("../../../_assets/images/map-icon.png")}
									alt="map-btn"
									onClick={() => handleMapVisibility(true)}
								/>
							</div>
						</Col>
						{/* end hide on dekstop */}

					</div>
				</div>
				<div onBlur={handleBlur} className={`search-listing-body ${(!isDesktop && isMapVisible === true) ? '' : 't-mb-44'}`} onScroll={handleScroll}>
					<div className={`search-listing-container ${(!isDesktop && isMapVisible === true) ? 'd-none' : 'd-block'}`}>
						<div className={(listData && listData?.data?.length === 0) ? "profile-container empty" : "profile-container"}>
							<div className={selectedId ? "profile-container-box outer-box-blur" : "profile-container-box"} >
								{isLoading === true ? <Row><ListBlockContentLoader grid={6} /></Row> :
									<>
										{listData && listData?.data?.length !== 0 ?
											<Row id={'searchList'}>
												{listData && listData.data?.map((data) => {
													return <React.Fragment key={"property" + data.id}>
														<PropertyCard
															price={data.price}
															id={data.property_details_id}
															isVerifed={data.is_verified}
															image_url={data.image_url}
															title={data.address}
															details={formattedDetails(data)}
															isDeleteIcon={data.isDeleteIcon}
															grid={6}
															onClick={() => propertyDetails(data.id, data.property_details_id)}
															isLoveIcon={data.is_favorite}
															selectedId={selectedId}
														/>
													</React.Fragment>
												})}
												{showBottomLoader &&
													<Loader />}

											</Row> :
											<div className="profile-container-box empty-data">
												<Row>
													<h2 className="errorMsg">There are no rentals currently available based on the filters you chose.</h2>
												</Row>
											</div>
										}
									</>
								}
							</div>
						</div>
					</div>
					<div className={`map-container ${(!isDesktop && isMapVisible === false) ? 'd-none' : 'd-block'}`}>
						<SearchMapListing 
						selectedId={selectedId}
						searchLocation={searchLocation} 
						selectedLocation={selectedLocation} 
						height={'80vh'} 
						details={listData && listData?.data} 
						getClickedId={(id) => getClickedId(id)} />
						
						<div className="d-block d-sm-none">
							<div className="row  iconpanel">
								<div className="listingicon cursorPointer" >
									<Image className="img-reponsive t-sort"
										src={require("../../../_assets/images/showlist.png")}
										alt="list-btn"
										onClick={() => handleMapVisibility(false)}
									/>
								</div>
								<div className="sorting cursorPointer" onClick={() => filter()}>
									<img className="img-reponsive t-sort" src={require("../../../_assets/images/whitefliter.png")} alt="sort-btn" />
								</div>
							</div>
						</div>
						{!isDesktop &&

							<div className={'horizontal_card_container'} style={{ top: selectedId ? '74%' : '60%' }} ref={horizontalRef} id='horizontal_card_container'>
								{listData && listData.data?.map((data) =>
									<div className={'horizontal_card_item'} id={`card_item_${data.property_details_id}`} >
										<HorizontalPropertyCard
											price={data.price}
											id={data.property_details_id}
											isVerifed={data.is_verified}
											image_url={data.image_url}
											title={data.address}
											details={formattedDetails(data)}
											isDeleteIcon={data.isDeleteIcon}
											grid={12}
											onClick={() => propertyDetails(data.id, data.property_details_id)}
											isLoveIcon={data.is_favorite}
											selectedId={selectedId}
										/>

									</div>)}
							</div>}
					</div>
				</div>
			</div>
		</AppLayout>
		<FilterTenantProperty
			isShow={tenantFilter}
			setSelectedId={(data) => setSelectedId(data)}
			handleClose={() => filter()}
			apiCallBack={(data) => apiCallBack(data)}
		/>
		<ModalBox
			show={confirmAction}
			onHide={() => setConfirmAction(false)}
			size='sm'
			actionbuttontitle={'get started'}
			buttonaction={() => getSignedUp()}
		>
			<h4>Sign up for free</h4>
			<p>create an account and get all the tools<br />you need to find the perfect home </p>
		</ModalBox>
	</>);
}

export default withRouter(SearchListing);
