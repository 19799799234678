import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import "./style.scss";
import { Row, Col } from "react-bootstrap";
import { MapContainer, LargeButton, UnitVerified, UnitAddress, UnitDescription, UnitDetails, UnitImageDisplay, Loader, ModalBox } from "../../components";
import { toaster, logger } from "../../../Utils";
import { postAPI } from "../../../Api";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentPage } from "../../../Store/actions/PropertyDetailAction";
import ImageGallery from 'react-image-gallery';

const log = logger("PropertyDetails");
const PropertyDetails = (props) => {
  const { setGetVerified, loader } = props;
  const [is_modelActive, setModelActive] = useState(false);
  const [is_publishSuccess, setPublishSuccess] = useState(false);
  const [is_showGallery, setShowGallery] = useState(false);
  const [imageGallary, setImageGallary] = useState(null)

  const dispatch = useDispatch();
  // const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const { propertyDetails = {}, unitDetails = {} } = useSelector(state => state.propertyDetailsReducer);
  const property_id = propertyDetails && propertyDetails.id;
  // const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const is_active = unitDetails && unitDetails.is_active;
  const imageList = unitDetails?.images || []

  // useEffect(() => {
  //   return () => {
  //     dispatch(getUnitDetail({}))
  //   }
  // },[property_id, property_details_id])

  useEffect(() => {
    // debugger
    let imageArray = []
    imageList.map((image, i) => {
      return imageArray.push({
        original: image,
        thumbnail: image,
      })
    })
    setImageGallary(imageArray)
  }, [imageList?.length])

  const editDetails = (step) => {
    let url = `/create-listing/${property_id}`;
    if (property_details_id) {
      if (unitDetails?.status === 2) {
        url = `/create-listing/${property_id}/${property_details_id}`;
      } else {
        url = `/edit-listing/${property_id}/${property_details_id}`;
      }
    }
    dispatch(getCurrentPage(step));
    props.history.push({
      pathname: url
    });
  }

  const activeListing = () => {
    const url = `/landlord/listing/active/${property_details_id}`;
    const data = null;
    postAPI(url, data).then(response => {
      if (response.status === 200) {
        dispatch(getCurrentPage(0));
        props.history.push({
          pathname: '/create-listing/' + property_id + '/' + property_details_id,
        });
        toaster('success', response.data.message);
      } else {
        toaster('error', 'Something went wrong!')
      }
    })
  }

  const viewGallery = () => {
    setShowGallery(true);
  }

  const onSubmit = () => {
    const url = '/landlord/publish-listing';
    const data = {
      property_id: property_id,
      property_details_id: property_details_id
    }
    postAPI(url, data).then(response => {
      if (response.status === 200) {
        setPublishSuccess(true);
        //toaster('success', response.data.message);
      } else {
        toaster('error', 'Something went wrong!')
      }
    })
  }

  return (

    <>
      <div className="profile-container">
        <div className="profile-container-box property-details">
          {is_active === 0 &&
            <div className="overlay-deactive-layer">
              <div className="status">Listing Deactivated, <span className='link' onClick={() => setModelActive(true)}>Click here to activate</span></div>
            </div>}
          {!loader ?
            <Row>

              <Col md='12' className='d-flex flex-wrap'>
                {(unitDetails && unitDetails?.images?.length !== 0) ?
                  <UnitImageDisplay
                    imagesList={unitDetails.images}
                    videosList={unitDetails.videos}
                    viewGallery={() => viewGallery()}
                    actionDetails={() => editDetails(5)} />
                  : <div className='no-image'>
                    <p>No Images, <span onClick={() => editDetails(5)}>Click here</span> to upload</p>
                  </div>}
              </Col>
              <Col md='7'>

                {(unitDetails && unitDetails.is_verified === 'unverified') &&
                  <UnitVerified setGetVerified={setGetVerified} />
                }

                <UnitAddress
                  propertyDetails={propertyDetails}
                  unitDetails={unitDetails}
                  editDetails={() => editDetails(0)} />

                {unitDetails &&
                  <UnitDetails
                    propertyDetails={propertyDetails}
                    unitDetails={unitDetails}
                    editDetails={() => editDetails(2)} />
                }

                {unitDetails &&
                  <UnitDescription
                    propertyDetails={propertyDetails}
                    unitDetails={unitDetails}
                    editDetails={() => editDetails(3)} />
                }

              </Col>

              <Col md='5'>
                {propertyDetails?.latitude && propertyDetails?.longitude &&
                  <div className="inner-wrapper" style={{ height: '70vh', padding: '0', overflow: 'hidden', border: '2px solid #ccc' }}>
                    <MapContainer
                      height='70vh'
                      latitude={propertyDetails?.latitude}
                      longitude={propertyDetails?.longitude} />
                  </div>
                }


                {/* Edit Property */}
                {!unitDetails ?
                  <LargeButton
                    title="Continue editing this property"
                    onClick={() => editDetails(0)} />

                  : unitDetails && unitDetails?.utilities?.length < 1 && unitDetails.status === 2 ?
                    <LargeButton
                      title="Continue editing this property"
                      onClick={() => editDetails(2)} />

                    : unitDetails && !unitDetails.description && unitDetails.status === 2 ?
                      <LargeButton
                        title="Continue editing this property"
                        onClick={() => editDetails(3)} />

                      : (unitDetails && unitDetails?.images?.length === 0 && unitDetails.status === 2) ?
                        <LargeButton
                          title="Continue editing this property"
                          onClick={() => editDetails(4)} />

                        : (unitDetails && unitDetails.status === 2) &&
                        <LargeButton
                          title={"publish now"}
                          onClick={() => onSubmit()} />
                }

                {/* Published & Pending for Approval */}
                {
                  (unitDetails && unitDetails.is_promoted)
                    ? (
                      unitDetails.status === 3
                        ? <div className='buttonLikeText grayButton'>
                          <p>promotion pending approval</p>
                        </div>
                        : (
                          unitDetails.days_left > 0 &&
                          <div className='buttonLikeText'>
                            <p>Listing Promoted</p>
                            <span>({unitDetails.days_left} {unitDetails.days_left > 1 ? 'days' : 'day'} remaining)</span>
                          </div>
                        )
                    )
                    : unitDetails && (unitDetails.status === 3 || unitDetails.status === 1) &&
                    <LargeButton
                      title={"promote your listing"}
                      onClick={() => editDetails(6)} />
                }
              </Col>
            </Row> : <Loader />}
        </div>
      </div>

      <ModalBox
        show={is_modelActive}
        onHide={() => {
          setModelActive(false)
        }}
        size="sm"
        actionbuttontitle={'got it'}
        buttonaction={() => {
          setModelActive(false)
          activeListing()
        }}
      >
        <h4>please edit the necessary details.</h4>
        <p>these details are lease start date, description, etc. and publish the listing. Once you hit “publish” our team will review and approve the listing to go live.</p>
      </ModalBox>

      <ModalBox
        show={is_publishSuccess}
        onHide={() => {
          setPublishSuccess(false)
        }}
        size="sm"
        actionbuttontitle={'got it'}
        buttonaction={() => {
          setPublishSuccess(false)
          props.history.push('/dashboard');
        }}
      >
        <h4>your listing is being reviewed by our team</h4>
        <p>you will get an email as soon as it’s approved and goes live.</p>
      </ModalBox>

      <ModalBox show={is_showGallery}
        onHide={() => setShowGallery(false)}
        size='lg'>
        {imageGallary ?
          <ImageGallery items={imageGallary} /> : <Loader />
        }
      </ModalBox>
    </>
  );
}

export default withRouter(PropertyDetails);