import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LargeButton, CancelButton, SidePanel, FileBrowse } from '../../../components';
import { postAPI } from '../../../../Api';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logger, toaster } from '../../../../Utils';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { FILE_API_URL } from '../../../../Constants/endPoints';

const log = logger('UploadLeaseDocument');
const UploadLeaseDocument = (props) => {
    const { isShow, handleClose, setOpenLeaseDuration, setLeaseId } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const reasonDeactivate = useSelector(state => state.deactiveReasonReducer.deactiveReason);
    const [lease_pdf, setLease_pdf] = useState({ file: '', percent: 0 });
    const [errorClass, setErrorClass] = useState(false);
    const [uploadData, setUploadData] = useState(null);
    const [showImageLoading,setShowImageLoading]=useState(false)

    const handleDrop = (file, setImage) => {
        const token = `Bearer ${Cookies.get('access_token')}`
        const headers = {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        }

        file.map((dataFile) => {
            setShowImageLoading(true)
            setUploadData(dataFile);
            let formdata = new FormData();
            formdata.append('file', dataFile);
            let url = FILE_API_URL;
            Axios.post(
                url, formdata, {
                headers: headers,
                onUploadProgress: progressEvent => {
                    var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    if (percent >= 100) {
                        setImage({ percent: percent });
                        return file;
                    } else {
                        setImage({ percent: percent });
                        return null;
                    }
                }
            })
                .then(response => {
                    setImage({ file: response.data.data.url })
                    setErrorClass(false)
                    setShowImageLoading(false)
                })
                .catch(error => {
                    setShowImageLoading(false)
                });
            return dataFile;
        })
    }
    const removeImage = (index, setImage) => {
        setImage('')
    }
    const submit = () => {
        if (lease_pdf.file === '') {
            setErrorClass(true)
        } else {
            const url = '/landlord/listing/lease/upload';
            const data = {
                'lease_id': 0,
                'property_id': property_id,
                'property_detail_id': property_details_id,
                "lease_pdf": lease_pdf.file
            }
            postAPI(url, data).then(res => {
                if (res.status === 200) {
                    handleClose();
                    const leaseId = res.data.data.id;
                    setLeaseId(leaseId);
                    setOpenLeaseDuration(true);
                    toaster('success', 'lease uploaded successfully')
                } else if (res.response.status === 422) {
                    toaster('error', 'The lease pdf field is required.');
                    setErrorClass(true)
                }
            })
        }

    }
    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props]);
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={() => handleClose('callBackApi')}
            title='UPLOAD LEASE'
            subTitle='please provide a few key details'
        >
            <div className='sidepanel-body mt-5'>
                <Row>
                    {log.info("file", lease_pdf.file)}
                    <Col md="12">
                        <FileBrowse
                            handleDrop={(e) => handleDrop(e, setLease_pdf)}
                            size={'sm'}
                            title={'Upload Documents'}
                            percent={lease_pdf.percent}
                            multiple={false}
                            fileType={'.jpg,.png,.jpeg,.pdf'}
                            disable={false}
                            showImageLoading={showImageLoading}
                            imageData={lease_pdf.file && lease_pdf.file}
                            removeImage={(e) => removeImage(e, setLease_pdf)}
                            lableClass={'custom-lable'}
                            section={'upload-lease'}
                            uploadData={uploadData && uploadData}
                            errorClass={errorClass === true ? "error-upload-box" : ""}
                        />
                    </Col>
                </Row>
            </div>
            <div className='fixed-bottom d-flex justify-content-between'>
                <CancelButton onClick={() => handleClose('callBackApi')} title='Back' />
                <LargeButton title='next' onClick={() => submit()} style={{ width: '200px' }} />
            </div>
        </SidePanel>
    </>);
}
export default withRouter(UploadLeaseDocument);