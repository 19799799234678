import React, { useState, useEffect } from "react";
import { Container, Row, Col, Navbar, NavDropdown, Image } from "react-bootstrap";
import { AppLayout, PageTitleHeader, PropertyCard, Loader } from "../../components";
import { withRouter } from 'react-router-dom';
import { logger } from "../../../Utils";
import { getAPI } from "../../../Api";

const log = logger("FavouritesList");
const FavouritesList = (props) => {
	const [propertyLists, setPropertyLists] = useState(null);
	const [activeKey, setActiveKey] = useState('new_to_old');

	useEffect(() => {
		const url = '/tenant/favorite-list';
		getData(url);
	}, [props])

	const getData = (url) => {
		getAPI(url).then(response => {
			const res = response.data.data;
			log.info(res);
			setPropertyLists(res)
		})
			.catch(error => {
			});
	}
	console.log(propertyLists && propertyLists.property_details_id)


	const propertyDetails = (details) => {
		props.history.push({
			pathname: '/property-details/' + details.property_id + '/' + details.property_details_id + '/details',
		});
	}

	const formattedDetails = (data) => {
		const {bedroom, property_type} = data;
		const bedRoom = bedroom && (bedroom > 1) ? (bedroom + ' Bedrooms') : (bedroom + ' Bedroom');
		// property_type {
		//   1 : 'Full Property',
		//   2 : 'Unit',
		//   3 : 'Room for Rent'
		// }
		let details = '';
		if (property_type === 3) {
			details = 'Room for Rent';
		} else if (property_type === 2) {
			details = `Unit • ${bedRoom}`;
		} else if (property_type === 1) {
			details = `Full Property • ${bedRoom}`;
		}
		return details;
    }

	const handleDropdownSelect = (key) => {
		setActiveKey(key);
		let url = '/tenant/favorite-list?sort_by=post_date&sort_order=' + key;
		if (key === 'low_to_high' || key === 'high_to_low') {
			url = '/tenant/favorite-list?sort_by=price&sort_order=' + key;
		}
		getData(url)
	}

	return (
		<AppLayout>
			<div className="profile">
				<PageTitleHeader
					title='favourite listings'

				/>
			</div>
			<div className="profile-container">
				<div className="profile-container-box">
					<div
						style={{
							position: "relative",
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							zIndex: "20",
							padding: "0 5px",
							marginBottom: '16px'
						}}
					>
						<Navbar className="filter-nav">
							<Navbar.Collapse className="d-flex justify-content-between">
								<Navbar.Text>posted date:</Navbar.Text>
								<NavDropdown title={activeKey.split('_').join(' ')} id="nav-dropdown">
									<NavDropdown.Item onClick={(e) => handleDropdownSelect('new_to_old')}>posted date: new to old</NavDropdown.Item>
									<NavDropdown.Item onClick={(e) => handleDropdownSelect('old_to_new')}>posted date: old to new</NavDropdown.Item>
									<NavDropdown.Item onClick={(e) => handleDropdownSelect('low_to_high')}>price: low to high</NavDropdown.Item>
									<NavDropdown.Item onClick={(e) => handleDropdownSelect('high_to_low')}>price: high to low</NavDropdown.Item>
								</NavDropdown>
							</Navbar.Collapse>
						</Navbar>
					</div>

					<Container fluid>
						<Row>
							{propertyLists ?
								propertyLists.length > 0 ?
									propertyLists.map((list, i) => {
										return (
											<PropertyCard
												key={"propery_" + i}
												onFavouriteChange={(isFavourite, id) => {
													setPropertyLists(propertyLists.filter((i) => i.property_details_id !== id))
												}}
												id={list.property_details_id}
												price={list.price_per_month}
												image_url={list.image_url}
												isVerifed={list.is_verified}
												title={list.address}
												details={formattedDetails(list)}
												onClick={() => propertyDetails(list)}
												isLoveIcon={true}
												isFavListPage={true}
											/>
										)
									}) :
									<Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '60px 0' }}>
										<Image src={require("../../../_assets/images/no-data.svg")} />
										<p style={{ fontSize: '25px', color: '#979797' }}>You’ve Got No Favorite Places. </p>
									</Col>
								: <Loader />}

						</Row>
					</Container>
				</div>
			</div>
		</AppLayout>

	);
}

export default withRouter(FavouritesList);