import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { getAPI } from "../../../Api";
import { logger, timeLeft } from "../../../Utils";
import Cookies from "js-cookie";
import "./style.scss";
import { Loader } from "..";
import { setOverviewDetails } from "../../../Store/actions/MaintenanceRepairAction";
import { useDispatch } from "react-redux";

const log = logger("RentOverview");
const RentOverview = (props) => {
  log.info("props", props);
  const { viewDownloadPdf, rentDetails, setRentDetails } = props;
  const role = Number(Cookies.get("role"));
  const [property_id, setProperty_id] = useState('');
  const [property_details_id, setProperty_details_id] = useState('');
  const [leaseId, setLeaseId] = useState(null);
  const daysLeft = rentDetails && moment(rentDetails.lease_end_date).diff(moment(new Date()).format('YYYY-MM-DD'), 'days') + 1;
  const dispatch = useDispatch();

  useEffect(() => {
    if (property_id && property_details_id) {
      let url = '';
      if (role === 2) {
        url = `/landlord/listing/lease/my-panda/${property_id}/${property_details_id}`;
      } else {
        if (leaseId !== null) {
          url = `/tenant/listing/lease/overview/${property_id}/${property_details_id}/${leaseId}`;
        } else {
          url = `/tenant/listing/lease/overview/${property_id}/${property_details_id}`;
        }
      }
      getAPI(url).then(res => {
        if (res.status === 200) {
          const fetchedData = res.data.data;
          setRentDetails(fetchedData);
          dispatch(setOverviewDetails(fetchedData));
          log.info('rentDetails', fetchedData);
        } else if (res.response.status === 400) {
          props.history.push({
            pathname: `/dashboard/my-listing`,
            //tenant_id: data.tenant_id
          });
          log.info('res ===> ', res);
        }
      })
    }
  }, [property_id, property_details_id])

  useEffect(() => {
    setProperty_id(Number(props.match.params.property_id));
    setProperty_details_id(Number(props.match.params.property_details_id));
    props.match.params.lease_id && setLeaseId(Number(props.match.params.lease_id))
  }, [props]);

  const redirectToMessage = () => {
    props.history.push({
      pathname: `/property-details/${property_id}/${property_details_id}/messages`,
      //tenant_id: data.tenant_id
    });
  }

  return (
    <>
      <div className="overview">
        {rentDetails ?
          <>
            <Row>
              <Col md={6} className="mb-2">
                <div className="rent-card d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <span className="rent-card-title">Rent</span>
                    {/* <span className="rent-card-title">Next Date: {moment(rentDetails.next_rent_date).format('MMM D, YYYY')}</span> */}
                  </div>
                  <div className="rent-amount">
                    <span>${rentDetails.rent}</span>
                  </div>
                  {/* <div className="rent-card-footer">
                <span>
                  <img
                    src={require("../../../_assets/images/green-calender-icon.svg")}
                  />
                  Rent Schedule
                </span>
              </div> */}
                </div>
              </Col>
              <Col md={6} className="mb-2">
                <div className="rent-card d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <span className="rent-card-title">Lease</span>
                    {<span className="rent-card-title">{timeLeft(daysLeft)}</span>}
                  </div>
                  <div className="rent-amount">
                    <span>{moment(rentDetails.lease_start_date).format('MMM DD, YYYY')} {' to ' + moment(rentDetails.lease_end_date).format('MMM DD, YYYY')}</span>
                  </div>
                  <div className="rent-card-footer">
                    <span onClick={() => {
                      viewDownloadPdf();
                    }} className='cursor-pointer'>
                      <img alt=""
                        src={require("../../../_assets/images/green-eye-icon.svg")}
                      />
                  View and Download
                </span>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mt-4'>
              <Col md={6}>
                <div className="rent-card d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    {role === 2 ?
                      <>
                        <span className="rent-card-title">Tenants</span>
                        <span className="mates-message" onClick={() => redirectToMessage()}>
                          <img src={require('../../../_assets/images/msg-icon.svg')} alt="" />message tenants
                  </span>
                      </>
                      : <span className="rent-card-title">Roommates</span>}
                  </div>
                  {rentDetails.tenants && rentDetails.tenants.map((tenant, i) => {
                    return <div className="roommates d-flex justify-content-between" key={'tenant' + i}>
                      <span className="mates-name">{tenant.name}</span>
                      <span className="mates-email">{tenant.email}</span>
                    </div>
                  })}
                </div>
              </Col>

              {role === 3 &&
                <Col md={6}>
                  <div className="rent-card d-flex flex-column">
                    <div className="d-flex justify-content-between">
                      <span className="rent-card-title">landlord</span>
                    </div>
                    <div className="roommates d-flex justify-content-between">
                      <span className="mates-name">{rentDetails.landlord_name}</span>
                      <span className="mates-message" onClick={() => redirectToMessage()}>
                        <img src={require('../../../_assets/images/msg-icon.svg')} alt="" />message
                </span>
                    </div>
                  </div>
                </Col>}
            </Row>
          </> : <Loader />}
      </div></>
  );
};
export default withRouter(RentOverview);
