import React, { useState } from 'react';
import { sort_notifiication } from '../../../../Constants/adminConstant';
import { colorNotification } from '../../../../Constants/colorStatus';
import { DropDown } from '../../../components';
import ImageComponent from '../ListUsableComponent/ImageComponent';
import "./style.scss";

const NotificationTable = (props) => {

    const { to, from, current_page, last_page, clickNext, clickPrev, columns, tableData, goToProfileDetail, total } = props;
    const [status, setStatus] = useState(false);
    const showStatus = () => {
        setStatus(!status);
    }
    return (
        <div className="table-component-wrapper" style={{ overflow: 'visible', boxShadow: 'none' }}>
            <h6 className='notification-header'>Notifications</h6>
            <div className='table-responsive'>
                <table className="table-component notify-table">
                    <thead>
                        <tr>
                            {columns.map((data, i) => {
                                return (<th key={'table_head_' + i} className={"display-flex"} width={data.width}>
                                    <>
                                        {(() => {
                                            switch (data.header) {
                                                case 'SECTION':
                                                    return (
                                                        <span className="d-flex justify-content-between">
                                                            {data.header}
                                                            <DropDown close={() => setStatus(false)} open={status} position={"left"} enableTopArrow={false} style={{ width: "208px", top: "20px" }}
                                                                content={
                                                                    <div className="row checkbox-left check-status">
                                                                        {sort_notifiication.map((data, i) => {
                                                                            return <div className="col-12" key={i}>
                                                                                <div className="form-group">
                                                                                    <div className="custom-control custom-checkbox large">
                                                                                        <input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
                                                                                            name={data.value}
                                                                                            checked={props.sort_by_section.includes(data.value)} onChange={props.sortSection}
                                                                                        />
                                                                                        <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.value}>{data.display}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                    </div>
                                                                }
                                                            >
                                                                <div onClick={showStatus}><ImageComponent /></div>
                                                            </DropDown>
                                                        </span>
                                                    )
                                                case 'TYPE':
                                                    return (
                                                        <>
                                                            {data.header}
                                                        </>
                                                    )
                                                case 'STATUS':
                                                    return (
                                                        <>
                                                            {data.header}
                                                        </>
                                                    )
                                                default:
                                                    return null
                                            }
                                        })()} </>
                                </th>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((data, i) => {
                            return (
                                <tr key={'table_body_' + i} className='cursorPointer' onClick={() => goToProfileDetail(data)}>
                                    <td>{data.section}</td>
                                    <td>{data.type !== null ? data.type : '--'}</td>
                                    <td><span className={colorNotification(data.status)}>{data?.status.toUpperCase()}</span></td>
                                </tr>)
                        })}
                    </tbody>
                </table>
                {tableData.length === 0 &&
                    <div className="empty-table-data" style={{ height: '260px' }}>
                        There is no data found

            </div>
				}
			</div>
			{tableData.length !== 0 && <div className="table-footer notification-footer">
				<div>
					<span className="left">
						{tableData.length} of {total}
					</span>
				</div>
				<div>
					{tableData.length !== 0 &&
						<>
							{from && to &&
								<span className="right">
									{from} to {to}
									{current_page !== from ? <img className="previous-arrow" onClick={clickPrev} src={require('../../../../_assets/images/next.svg')} alt="previous" /> : ''}
									{current_page !== last_page ? <img className="next-arrow" onClick={clickNext} src={require('../../../../_assets/images/next.svg')} alt="next" /> : ''}
								</span>}
						</>}
				</div>
			</div>}

		</div>
	)
}

export default NotificationTable;