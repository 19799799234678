import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import ReactPlayer from 'react-player/lazy'
import './style.scss';
import Cookies from "js-cookie";


const UnitImageDisplay = (props) => {
  const { imagesList, videosList, actionDetails, viewGallery } = props;
  const role = Number(Cookies.get("role"));
  const history = useHistory();
  const [isTour, setIsTour] = useState(false);
  // const lastImage = imagesList[imagesList.length - 1]

  const lastItemClick = (i, lastIndex) => {
    if(i === imagesList.slice(0,lastIndex).length-1 && role === 2){
      actionDetails()
    }else{
      if(role === 3 || !role){
        actionDetails()
      }else{
        viewGallery()
      }
    }
  }

  useEffect(() => {
    if ( history.location.pathname.indexOf("tour") > 0 ) {
      setIsTour(true);
    }
  }, [history.location.pathname]);

  let imgClass = 'img-wrap'
  if(role === 3 || !role || isTour){
    imgClass += ' img-wrap-tenant'
  }

  return (
    <>      
      <ul className="galleryimages">

        {videosList && videosList?.length > 0 && videosList.slice(0,1).map((video, i) => {
          return (
            
            // <li key={'video_'}><a href={video} target='_blank'><Image className='display-image' src={require("../../../../_assets/images/video-link.png")} alt={'video_link'} /></a></li>
            // <li key={'video_'}><iframe title='rp-video' src={video}></iframe></li>
            <li key={'video_'}><ReactPlayer url={video} id="ytplayer" title='test' width="100%" height="100%"/>
            </li>
          )
        })}

        {videosList?.length > 0 && imagesList.slice(0,4).map((img, i) => {
          return (
            <li key={'image_' + i}><div className={imgClass} onClick={() => lastItemClick(i,4)}><Image className='display-image' src={img} alt={'image_' + i} /></div></li>
            // <li key={'image_' + i}><div className={imgClass}><Image className='display-image' src={img} alt={'image_' + i} /></div></li>
          )
        })}

        {videosList?.length < 1 && imagesList.slice(0,5).map((img, i) => {
          return (
            <li key={'image_' + i}><div className={imgClass} onClick={() => lastItemClick(i,5)}><Image className='display-image' src={img} alt={'image_' + i} /></div></li>
            // <li key={'image_' + i}><div className={imgClass}><Image className='display-image' src={img} alt={'image_' + i} /></div></li>
          )
        })}

        {/* {actionDetails && imagesList.length > 1 &&
          // <li key={'image_link'}><span onClick={actionDetails}><Image className='display-image cursor-pointer' src={require(role === 2 ? "../../../../_assets/images/edit-image-link.png" : "../../../../_assets/images/view-image-link.png")} alt={'image_link'} /></span></li>
          // <li key={'image_link'}><div className={imgClass} onClick={actionDetails}><Image src={lastImage} alt={'image_link'} /></div></li>
        } */}
      </ul>
    </>);
}
export default UnitImageDisplay;