import React from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import Layout from '../components/Layout';

import './style.scss';

const aboutData = [
    {
        title: 'in the beginning',
        description: 'the rental industry was broken. The war between tenants and landlords was at an all time high as prices soared, scams ran rampant and foul play was everywhere. As tensions rose and names like ‘slumlord’ and ‘professional tenant’ were thrown around, the housing safety of tenants and investment opportunities of landlord had bleak futures.'
    }, {
        title: 'the panda was born',
        description: 'from a vision. A vision where harmony and understanding existed between tenants and landlords. A future full of tenants renting comfortably and landlords renting confidently, with everyone enjoying their rental experience and not just seeing renting as a stepping stone to ownership. Our founders, Richard and Hart, have lived the tenant life for years and had also experienced the stress of being an underserved landlord, but knew that there was a path to change that lay ahead.'
    }, {
        title: 'the journey began',
        description: 'with a conversation. One with a landlord led to two and then three, and before they knew it, Richard and Hart had talked to thousands of landlords and tenants, building a deep understanding of what tenants wanted and landlords needed. They trekked from the northern reaches of Thunder Bay to the southern tip of Lake Ontario to truly understand what was needed to reshape the rental industry forever.'
    }, {
        title: 'the solution took shape',
        description: 'one piece of code at a time. The team built for months on end, crafting products that would hopefully put an end to the war. They built a listing platform dedicated to rentals, so tenants wouldn’t search for a home in the same place as a used tennis racket. They built tools for landlords to find quality tenants and for great tenants to easily match with verified properties. They created a place for landlords to manage their investments, store their information and get education on the best way to be a successful investor and landlord. And they offered their personal service to any landlord or tenant who needed guidance through their journey.'
    }, {
        title: 'peace will come upon the land',
        description: 'when tenants and landlords feel comfortable and confident renting. The journey will not be easy, and there will be challenges at every turn, but Rent Panda will continue to work day and night for the rental community. It will provide a comfortable way for tenants to find the perfect home. It will make sure landlords can confidently choose tenants and manage their properties. It will take the hand of those who need guidance and lead them down the path toward a brighter future. And it will not rest until renting a home feels as comfortable as living in one.'
    },
];
const AboutUs = () => {
    return (
        <div className="aboutUsPage">
            <Layout newHeader={true}>
                <section className="sec-website-page-title how-it-work-title-bg cu-container-wrap">
                    <div className="container">
                        <h1 className="website-page-title text-center">rent panda likes telling stories, especially when they are true</h1>
                    </div>
                </section>
                <section className="about-container">
                    <Image className="shapabout-image" variant="top" src={require('../../_assets/images/icons/about-shap.svg')} />
                    <Container>
                        {aboutData && aboutData.map((data, i) => {
                            return  <Row className={'shap-box-' + i}>
                                        <Col lg={10}>
                                            <h2 className="subHead">{data.title}</h2>
                                            <p>{data.description}</p>
                                        </Col>
                                    </Row>
                        })}
                    </Container>
                </section>                
                <section className="founders cu-container-wrap">
                    <div className="container">
                        <Row className="">
                            <Col lg={8} className="offset-lg-2">
                                <h2 className="subHead">the rent panda founders</h2>
                                <Row className="founders-profile">
                                    <Col sm={6} md={6} lg={5} className="mb-4">
                                        <Card className="founders-profile-card">
                                            <Card.Img variant="top" src={require('../../_assets/images/about/user-im1.png')} />
                                            <Card.Body>
                                                <Card.Title className="mb-1">Hart Togman</Card.Title>
                                                <Card.Text>Co-Founder &amp; COO</Card.Text>
                                            </Card.Body>
                                            <Button variant="link" href="https://ca.linkedin.com/in/hart-togman-83359112" target="_blank">
                                                <Card.Img variant="bottom" src={require('../../_assets/images/icons/linkedin.svg')} />
                                            </Button>
                                        </Card>
                                    </Col>
                                    <Col sm={6} md={6} lg={5} className="mb-4">
                                        <Card className="founders-profile-card">
                                            <Card.Img variant="top" src={require('../../_assets/images/about/user-im2.png')} />
                                            <Card.Body>
                                                <Card.Title className="mb-1">Richard Togman</Card.Title>
                                                <Card.Text>Co-Founder &amp; CEO</Card.Text>
                                            </Card.Body>
                                            <Button variant="link" href="https://ca.linkedin.com/in/dr-richard-togman-10910862" target="_blank">
                                                <Card.Img variant="bottom" src={require('../../_assets/images/icons/linkedin.svg')} />
                                            </Button>
                                        </Card>
                                    </Col>
                                </Row>
                                <div className="contactBox">
                                    <h2 className="subHead">we built this product for you.</h2>
                                    <p className="text-center text-md-left">If you want to get in touch for any reason, please reach out.</p>
                                    <Button href="mailto:hello@rentpanda.ca" className="btnAction border-0">contact us</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>                    
                </section>                
            </Layout>
        </div>
    )
}

export default withRouter(AboutUs);
