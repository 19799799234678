import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { cmsClickedData } from '../../../../../Store/actions/Action';
import { IMAGE_API_URL } from "../../../../../Constants/endPoints"
import Cookies from "js-cookie";
import { getAPI, postAPI } from '../../../../../Api';
import { logger } from '../../../../../Utils';
import { AppLayout, PageTitleHeader, FileBrowse, DropDown, ModalBox } from '../../../../components';
import Footer from "../../../../../Website/components/Footer";
import { Container, Form, Row, Col, Button, Dropdown, DropdownButton, InputGroup } from 'react-bootstrap';
import { validate } from '../../../../../Utils/Validation/validation';
import { BlogValidationSchema } from './BlogValidationSchema';
import { emptyLeadingSpaceSanitizer } from '../../../../../Utils/DateSanitizer/DateSanitizer';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';

import './style.scss';
import ImageComponent from '../../../ProfileDetail/ListUsableComponent/ImageComponent';

const log = logger('Blog / Article');
const Blog = (props) => {
    
    const [imageUrlData, setImageUrlData] = useState('');
    const [errorClass, setErrorClass] = useState(false);
    const [headline, setHeadline] = useState('');
    const [author, setAuthor] = useState('');
    const [excerpt, setExcerpt] = useState('');
    const maxExcerpt = 225;
    const [remainExcerptChar, setRemainExcerptChar] = useState(maxExcerpt);
    const [content, setContent] = useState('');
    const [tagList, setTagList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [categoryId, setCategoryId] = useState('');
    const [catIdErr, setErrCatId] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [publishLoading, setPublishLoading] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [blogSubmitResponse, setResponse] = useState(null);
    const [draftModal, setDraftModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [componentLoad, setComponentLoad] = useState('');
    const [viewData, setViewData] = useState(null);
    const clickedData = useSelector(state => state.CMSReducer.clickedData);
    const [displayType, setDisplayType] = useState('');
    const [action, setAction] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(false);
    const [showImageLoading,setShowImageLoading]=useState(false)
    const [publishAgain, setPublishAgain]=useState(false)
    const dispatch = useDispatch();

    const back = () => {
        return props.history.push('/cms/content-management');
    }
    const onClickCategory = (value, display) => {
        setDisplayType(display);
        setCategoryId(value);
        setErrCatId('');
    }
    useEffect(() => {
        const data = {
            'name': ''
        }
        const urlCategory = `/admin/cms/get-categories`;
        postAPI(urlCategory, data).then(res => {
            if (res.status === 200) {
                setCategoryList(res.data.data)
            }
        })
        const urlTag = `/admin/cms/get-tags`;
        postAPI(urlTag, data).then(res => {
            if (res.status === 200) {
                setTagList(res.data.data)
            }
        })
    }, [])

    useEffect(() => {
        const slug = props.match.params.slug;
        setComponentLoad(slug);
    }, [props]);

    useEffect(() => {
        if (viewData !== null) {
            setCategoryId(viewData.cms_category_id);
            setSelectedData(viewData.cms_tags);
            setHeadline(viewData.headline);
            setAuthor(viewData.author);
            setExcerpt(viewData.excerpt);
            setContent(viewData.content);
            setImageUrlData(viewData.image_url);
            setTimeout(() => {
                document.querySelector(".image-outer-wrapper").style.background=(`url(${viewData.image_url}) no-repeat center center`);
            }, 100)
            categoryList.map((data) => (data.id === viewData.cms_category_id) && setDisplayType(data.name));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewData]);

    useEffect(() => {
        if (clickedData !== null) {
            getAPI(`/admin/cms/articles/view/${clickedData.id}`).then(resData => {
                if (resData.status === 200) {
                    const resDataView = resData.data.data;
                    setViewData(resDataView)
                }
            })
        }
    }, [clickedData])

    const removeImage = (index) => {
        setImageUrlData('');
    }
    const handleDrop = (file) => {
        const url = IMAGE_API_URL;
        const token = `Bearer ${Cookies.get("access_token")}`
        const headers = {
            'Authorization': token,
            "Content-Type": "multipart/form-data"
        }
        setShowImageLoading(true)

        file.map((dataFile) => {
            let formdata = new FormData()
            formdata.append('image', dataFile)

            axios.post(url, formdata, {
                headers: headers
            }).then(function (response) {
                setImageUrlData(response.data.data.url);
                setTimeout(() => {
                    document.querySelector(".image-outer-wrapper").style.background=(`url(${response.data.data.url}) no-repeat center center`);
                }, 100)
                setErrorClass(false);
                setShowImageLoading(false)
            })

            return dataFile;
        })
    }

    const onSubmit = (e, stts) => {
        e && e.preventDefault();
        if (validate(BlogValidationSchema) && categoryId !== '') {
            let tag = [];
            selectedData.length !== 0 && selectedData.map((data) => tag.push(data.id))
            
            const data = {
                "cms_category_id": categoryId,
                "headline": headline,
                "tags": tag,
                "author": author,
                "excerpt": excerpt,
                "content": content,
                "image_url": imageUrlData,
                "status": stts,
            }

            let url = '';
            if (componentLoad === 'edit') {
                data.id = clickedData.id;
                url = '/admin/cms/articles/update';
                if (stts === 1 && !publishAgain) {
                    setAction('publishAgain');
                    setDeleteModal(true);
                    return
                }
            } else {
                url = '/admin/cms/articles/create';
            }

            stts === 0 ? setSaveLoading(true) : setPublishLoading(true);

            postAPI(url, data).then(response => {
                setSaveLoading(false);
                setPublishLoading(false);
                if (response.status === 200) {
                    const res = response.data.data;
                    setResponse(res)
                    if (stts === 0) {
                        setDraftModal(true)
                        setModalContent('draft')
                    } else if (stts === 1) {
                        if (publishAgain) {
                            setConfirmAction(true);
                        } else {
                            setDraftModal(true)
                            setModalContent('published')
                        }
                    }
                }
            })
        } else if (categoryId === '') {
            setErrCatId('category is required')
        }
    }

    const [tagDropdown, setTagDropdown] = useState(false);
    const showTagDropdown = () => {
        setTagDropdown(!tagDropdown);
    }
    const onValueChange = (e, data) => {
        const isChecked = e.target.checked;
        isChecked === true ?
            setSelectedData((state) => ([...state, data]))
            :
            setSelectedData((state) => {
                log.info(state.indexOf(data))
                return state.filter(filterData => filterData.id !== data.id)
            })
        log.info('selectedData =========>', selectedData)
    }
    const openConfirmationModal = (val) => {
        setAction(val)
        setDeleteModal(true);
    }
    const onClickYes = () => {
        if (action === 'delete') {
            const data = {
                'id': viewData.id
            }
            postAPI(`/admin/cms/articles/delete`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true)
                }
            })
        } else if (action === 'statusChange') {
            const data = {
                "id": viewData.id,
                "status": (viewData.status_info.status_id === 0 || viewData.status_info.status_id === 2) ? '1' : '2'
            }
            postAPI(`/admin/cms/articles/change-status`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true)
                    getData();
                }
            })
        } else if (action === 'publishAgain') {
            setDeleteModal(false);
            setPublishAgain(true);
            onSubmit(null, 1);
        }
    }

    const onClickNo = () => {
        if (action === 'publishAgain') {
            setPublishAgain(false);
            back();
        }
        setDeleteModal(false)
    }
    const getData = () => {
        getAPI(`/admin/cms/articles/view/${viewData.id}`).then(resData => {
            log.info('resData================>',resData)
            const resDataView = resData.data.data;
            if (resData.status === 200) {
                setViewData(resDataView)
            }
        })
    }
    const togggleDraftModal = () => {
        setDraftModal(false);
        if (modalContent !== 'draft' && clickedData !== null) {
            getData();
        } else {
            dispatch(cmsClickedData(null));
            props.history.push(`/cms/content-management`);
        }
    }
    const toggleConfirm = () => {
        if (action === 'delete') {
            setConfirmAction(false)
            dispatch(cmsClickedData(null));
            props.history.push(`/cms/content-management`);
        } else if (action === 'statusChange') {
            setConfirmAction(false)
        } else if (action === 'publishAgain') {
            setConfirmAction(false);
            back();
        }
    }

    let status = '';
    if (viewData) {
        if (viewData.status_info.status_name === 'Publish') {
            status = 'published'
        } else if (viewData.status_info.status_name === 'Unpublish') {
            status = 'unpublished'
        } else if (viewData.status_info.status_name === 'Draft') {
            status = 'draft'
        }
    }

    const modules = {
		toolbar: [
            // [{ 'font': [] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['link'],
            ['image'],
            // [{ 'color': [] }, { 'background': [] }],
            ['clean']
	    ]
	};
    const formats = [
        // 'font',
        'size',
        'bold', 'italic', 'underline',
        'list', 'bullet',
        'indent',
        'align',
        'link',
        'image',
        // 'color', 'background'
    ];
    const rteChange = (content, delta, source, editor) => {
        log.info("onChange function content :", content); // rich text
		log.info("onChange function html    :", editor.getHTML()); // rich text
        setContent(editor.getHTML());
		// log.info("onChange plain text", editor.getText()); // plain text
		// log.info("onChange charCount", editor.getLength() - 1 ); // number of characters
	}
    const maxLength = (e, max) => {
        const txtWithLimit = emptyLeadingSpaceSanitizer(e.target.value).substring(0, max);
        setRemainExcerptChar(max - txtWithLimit.length);
        setExcerpt(emptyLeadingSpaceSanitizer(e.target.value));
    }
    return (
        <div className="createEditBlog">
            <AppLayout noSidebar={true}>
                <PageTitleHeader
                    isArrow={true}
                    onClick={() => back()}
                    title={componentLoad === 'edit' ? 'blog content' : 'creating new blog'}
                    status={status}
                    extraElm={
                        clickedData !== null && <div className='header-right'>
                            <Button onClick={() => openConfirmationModal('delete')} className='btn-cancel delete ml-3' type='button' >delete</Button>
                            <Button className={`ml-3 ${viewData && viewData.status_info.status_id === 1 && 'btn-cancel' }`}
                                type='button'
                                onClick={() => openConfirmationModal('statusChange')}>
                                {viewData && (viewData.status_info.status_id === 0 || viewData.status_info.status_id === 2) ? 'publish' : 'unpublish'}
                            </Button>
                        </div>
                    }
                />
                <div className="bannerImage" data-img-url={imageUrlData}>
                    <FileBrowse
                        size="sm"
                        title="Drag or Browse to upload Header Image"
                        handleDrop={(e) => handleDrop(e)}
                        percent={0}
                        multiple={true}
                        fileType={".jpg,.png,.jpeg"}
                        disable={false}
                        imageData={imageUrlData}
                        showImageLoading={showImageLoading}
                        removeImage={(e) => removeImage(e)}
                        errorClass={errorClass === true ? "error-upload-box" : ""}
                    />
                </div>
                <Container>
                    <Form id='Form'>
                        <Row>
                            <Form.Group as={Col} md='7'>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className='title-inside'>Headline:</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type='text'
                                        name={'headline'}
                                        id={'headline'}
                                        value={headline}
                                        onChange={(e) => setHeadline(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md='5'>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className='title-inside'>Author Name / Credit:</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type='text'
                                        name={'author'}
                                        id={'author'}
                                        value={author}
                                        onChange={(e) => setAuthor(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md='5'>
                                <DropdownButton
                                    data-label="Category"
                                    title={displayType}
                                    name={'category'}
                                    id={'dropdown-menu-align-left'}
                                    style={{ width: '100%' }}
                                    className={catIdErr !== '' ? 'is-invalid2' : ''}
                                >
                                    {categoryList && categoryList.map((options, i) => {
                                        return <Dropdown.Item eventKey={options.id} onClick={() => onClickCategory(options.id, options.name)} key={i}>{options.name}</Dropdown.Item>
                                    })}
                                </DropdownButton>
                                {catIdErr !== '' && <div id="error_dropdown-menu-align-left" class="invalid-feedback2">{catIdErr}</div>}
                            </Form.Group>
                            <Form.Group as={Col} md='7'>
                                <div className='d-flex justify-content-between extra-class-dropdown'>
                                    <span>Tags:
                                        <strong>
                                            {selectedData.map(function (obj) {
                                                return obj.name;
                                            }).join(", ")}
                                        </strong>
                                    </span>
                                    <DropDown close={() => setTagDropdown(false)} open={tagDropdown} position={"right"} enableTopArrow={false} style={{ width: "208px" }}
                                        content={
                                            <div className="row checkbox-left check-status">
                                                {tagList.map((data, i) => {
                                                    return <div className="col-12" key={i}>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-checkbox large">
                                                                <input type="checkbox" className="custom-control-input" id={"customCheck" + data.id}
                                                                    name={data.id}
                                                                    checked={selectedData.find(elm => elm.id === data.id)} onChange={(e) => onValueChange(e, data)}
                                                                />
                                                                <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.id}>{data.name}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        }
                                    >
                                        <div onClick={showTagDropdown}><ImageComponent /></div>
                                    </DropDown>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md='12'>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className='title-inside'>Excerpt: </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name={'excerpt'}
                                        id={'excerpt'}
                                        value={excerpt}
                                        maxLength={maxExcerpt}
                                        onChange={(e) => maxLength(e, maxExcerpt)}
                                    />
                                    <span className="charLimit">{`${remainExcerptChar} / ${maxExcerpt}`}</span>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} md='12'>
                                <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    formats={formats}
                                    value={content || ''}
                                    onChange={rteChange}
                                />
                            </Form.Group>
                            {/* <Form.Group as={Col} md='12'>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Content..."
                                    rows={10}
                                    name={'content'}
                                    id={'content'}
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                />
                            </Form.Group> */}
                        </Row>

                        <Row className='buttons-container'>
                            <Col md='12' className='d-flex justify-content-between'>
                                <Button className={`btn-cancel ${componentLoad === 'edit' && 'noCancleOnEditPage'}`}
                                    type='button'
                                    onClick={() => togggleDraftModal()}
                                    disabled={saveLoading === true || publishLoading === true ? true : false}>cancel</Button>
                                <div className='d-flex justify-content-between'>
                                    <Button className='btn-save-draft mr-3' type='submit' onClick={(e) => onSubmit(e, 0)} disabled={publishLoading === true ? true : false}>save as draft {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                                    <Button className='btn-publish' type='submit' onClick={(e) => onSubmit(e, 1)} disabled={saveLoading === true ? true : false}>publish {publishLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <Footer />
            </AppLayout>
            <ModalBox
                show={draftModal}
                onHide={() => togggleDraftModal(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => togggleDraftModal(false)}
            >
                <h4>{modalContent === 'draft' ? 'content saved' : 'Content is published'}</h4>
            </ModalBox>
            <ModalBox
                show={deleteModal}
                closeButtonHeader={false}
                size='sm'
                type='condition'
                actionbuttontitle={'Yes'}
                buttonaction={() => onClickYes()}
                backbuttontitle={'Not now'} // {action === 'statusChange' ? 'Not now' : 'Cancel'}
                backaction={() => onClickNo()}
            >
                <p className='order-one-modal'>
                    {action === 'delete' && 'are you sure you want to delete content? This cannot be undo.'}
                    {action === 'statusChange' &&
                        <>
                            {(viewData.status_info.status_id === 0 || viewData.status_info.status_id === 2) ?
                                'Are you sure you want to publish content? ' :
                                'Are you sure you want to unpublish content?'}
                        </>
                    }
                    {action === 'publishAgain' && "Are you sure you want to update content? the original content will be replaced" }
                </p>
            </ModalBox>
            <ModalBox
                show={confirmAction}
                onHide={() => toggleConfirm(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => toggleConfirm(false)}
            >
                <h4>
                    {action === 'delete' && 'Article has been deleted.'}
                    {action === 'statusChange' && `Content ${status}`}
                    {action === 'publishAgain' && "Content uploaded" }
                </h4>
            </ModalBox>
        </div>
    )
}

export default withRouter(Blog);