import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ProfileCard, DollorNumberDisplay, PhoneNumberFormat } from '../../../components';
import Moment from 'react-moment';
import Cookies from 'js-cookie';

const CurrentHomeInfoDisplay = (props) => {
  const { profileDetails, editaction, addaction } = props
  const role = Number(Cookies.get('role'));
  return (
    <ProfileCard
      title={role !== 1 ? 'Current Address' : 'Current Home Info'}
      editaction={editaction}
      addaction={profileDetails.current_home && addaction}>

      <Container fluid>
        {profileDetails.current_home ?
          <>
            <Row>
              <Col md='2'>
                <label>Full Address</label>
                <h3>{profileDetails.current_home.full_address}</h3>
              </Col>
              <Col md='1'>
                <label>Status</label>
                <h3>{profileDetails.current_home.is_owned === 1 ? 'Owned' : 'Rented'}</h3>
              </Col>
              <Col md='2'>
                <label>Starting From</label>
                <h3><Moment format='MMM D, YYYY'>{profileDetails.current_home.start_date}</Moment></h3>
              </Col>
              {profileDetails && profileDetails.current_home.monthly_rent &&
                <Col md='2'>
                  <label>Monthly Rent</label>
                  <h3><DollorNumberDisplay price={profileDetails.current_home.monthly_rent} /></h3>
                </Col>}
              {role !== 1 && profileDetails.current_home.landlord_name &&
                <Col md='5'>
                  <label>Owner’s Contact Info</label>
                  <h3>{profileDetails.current_home.landlord_name}
                    <span className='ml-4 highlight'>{profileDetails.current_home.landlord_email}</span>
                    <span className='ml-4 highlight'><PhoneNumberFormat phoneNumber={profileDetails.current_home.landlord_phone} /></span></h3>
                </Col>}
            </Row>
            {role === 1 && profileDetails.current_home.landlord_name &&
              <Row className='mt-4'>
                <Col md='8'>
                  <label>Owner’s Contact Info</label>
                  <h3>{profileDetails.current_home.landlord_name}
                    <span className='ml-4 highlight'>{profileDetails.current_home.landlord_email}</span>
                    <span className='ml-4 highlight'><PhoneNumberFormat phoneNumber={profileDetails.current_home.landlord_phone} /></span></h3>
                </Col>
              </Row>
            }
          </>
          :
          <Row>
            <Col md='12' className='d-flex justify-content-center'>
              <label>No Information Provided</label>
            </Col>
          </Row>}
      </Container>
    </ProfileCard>
  );
}

export default CurrentHomeInfoDisplay;