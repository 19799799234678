import React, { useEffect, useState } from "react";
import { Button, Col, Image, Row, Form } from "react-bootstrap";
import { LargeButton, Loader, ThreeDotsDropdown, ModalBox } from "..";
import { Link, useHistory, withRouter } from 'react-router-dom';
import "./style.scss";
import { logger, toaster } from "../../../Utils";
import { postAPI, getAPI } from '../../../Api';
import { useSelector, useDispatch } from "react-redux";
import { setReceiverData, setViewingRequestId, getCalenderData, setTenancyApplicationId, setCreditCheckRequestId, setIsProfileCompleted } from "../../../Store/actions/MessageDetailAction";
import { validate } from '../../../Utils/Validation/validation';
import DatePickerComponent from '../DatePickerComponent/DatePickerComponent';
import NumberFormat from "react-number-format";
import { DisclaimerText } from "../Tenancy/TenancyForm/TenancyForm";
import backArrow from '../../../_assets/images/arrowVector.png'


{/*
*************************
'request_type' Values
*************************
1 - Showing Request
2 - Showing invitation
3 - Lease
4 - Apply Tenancy Application
5 - Credit Check Request
6 - Complete Profile Request
7 - Accept lease
*/}

{/*
*************************
'lease_status' Values
*************************
0 => 'In progress',
1 => 'Delivered', // delivered to tenant / Pending
2 => 'Signed'    
*/}

{/*
*************************
'type' Values
*************************
0 => None,
1 => Showing invitation //only Landlord can do
2 => Showing invitation confirm
3 => Showing invitation reject
4 => Showing invitation reschedule
5 => Showing Request //only Tenant can do
6 => Showing Request confirm
7 => Showing Request reject
8 => Showing Request reschedule
9 => Showing Request cancel
10 => Invite to apply // From landlord
11 => Accept Tenancy Application // by tenant
12 => Reject application invitation // by tenant
13 => Tenancy application // from tenant
14 => Reject tenancy application // by landlord
15 => Credit check request
16 => Credit check request confirm
17 => Credit check request reject
18 => Credit check pulled
19 => Profile incomplete
20 => Create / signed lease // by landlord
21 => Update lease
22 => Signed lease / by tenant
*/}

const log = logger("ChatBox");

const creditCheckValidationSchema = {
	date_of_birth: [
		{ rule: "required", message: "Date Of Birth is required" },
	],
	sin: [
		{ rule: "required", message: "SIN is required" },
		{ rule: "sinNumber", message: "SIN is invalid" }
	]
}

const ChatBox = (props) => {
	const { messageData,headerContent, showTenantList, setShowTenantList, conversation_id, grid, sendMessage, message, handleChange, role, setShowingRequest, setConfirmShowing, viewProfile, set_tenancy, messageLoader, blockUser, removeFromContact, setLeaseOptionsScreen, setCertnPurchaseReport, setLeasePdfViewScreen, is_reSchedule, setReSchedule } = props;
	const [modalShow, setModalShow] = useState(false);
	const [modalBlockShow, setModalBlockShow] = useState(false);
	const [credit_check_request_id, setCredit_check_request_id] = useState(null);
	const [creditCheckModalShow, setCreditCheckModalShow] = useState(false);
	const [profileIncompleteModalShow, setProfileIncompleteModalShow] = useState(false);
	const [reportModalShow, setReportModalShow] = useState(false);
	const [getReportProfileIncompleteModalShow, setGetReportProfileIncompleteModalShow] = useState(false);

	const [property_id, setProperty_id] = useState('');
	const [property_details_id, setProperty_details_id] = useState('');

	const dispatch = useDispatch();

	const leaseData = useSelector(state => state.messageDetailsReducer.leaseData);
	const is_blocked = useSelector(state => state.messageDetailsReducer.isBlocked);
	const is_ProfileComplete = useSelector(state => state.messageDetailsReducer.isProfileCompleted);
	const listData = useSelector(state => state.messageDetailsReducer.tenantListData);
	const existingShowingRequestId = useSelector(state => state.messageDetailsReducer.existingShowingRequestId);
	const existingShowingSentBy = useSelector(state => state.messageDetailsReducer.existingShowingSentBy);
	const existingTenancyApplicationId = useSelector(state => state.messageDetailsReducer.existingTenancyApplicationId);
	const tenant_id = useSelector(state => state.messageDetailsReducer.tenantId);
	const isLeaseExists = useSelector(state => state.messageDetailsReducer.isLeaseExists);
	const isTenancyApplicationReceived = useSelector(state => state.messageDetailsReducer.isTenancyApplicationReceived);


	const [dateOfBirth, setDateOfBirth] = useState('');
	const [sin, setSin] = useState('');
	const [creditCheckModal, setCreditCheckModal] = useState(false);



	useEffect(() => {
		setProperty_id(Number(props.match.params.property_id));
		setProperty_details_id(Number(props.match.params.property_details_id));
		let params = new URLSearchParams(props.location.search)
		if (params?.get('profile') === "true") {
			viewProfile();
		}
	}, [props]);


	useEffect(() => {
		const section = document.querySelector('#messageBody');
		if (section) {
			section.scrollTop = section.scrollHeight;
		}
	}, [props, messageData])

	const handleDate = (date) => {
		setDateOfBirth(date)
	}

	const submitUserDOBAndSin = () => {
		if (validate(creditCheckValidationSchema)) {
			let data = {
				'date_of_birth': dateOfBirth,
				'sin_number': sin
			}
			postAPI('/tenant/profile/set-certn-details', data).then(response => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					log.info('data', response.data)
					creditCheckAction('accept')
					setCreditCheckModal(false);

				}
			})
				.catch(error => {
				});
		}
	}

	const creditCheckAction = (status) => {
		let url = '';
		if (status === 'accept') {
			url = '/tenant/accept-credit-check-request';
		} else if (status === 'reject') {
			url = '/tenant/reject-credit-check-request';
		}
		const data = {
			"credit_check_request_id": credit_check_request_id,
			"conversation_id": conversation_id
		}
		postAPI(url, data).then(res => {
			if (res.status !== 200) {
				toaster('error', res.response.data.message)
			} else if (res.status === 200) {
				setCreditCheckModalShow(false)
			}
		})
	}

	const viewLeaseAction = () => {
		if ((leaseData?.status === 2)) {
			props.history.push({
				pathname: `/maintainance-repair/${property_id}/${property_details_id}/overview`,
			});
		} else {
			onViewLease({ 'property_lease_id': leaseData?.id, 'is_offline_lease': leaseData?.is_signed_offline, 'is_updated': leaseData?.is_updated, 'is_sent_to_tenant': leaseData?.is_sent_to_tenant });
		}
	}

	const onCompleteProfileAction = () => {
		props.history.push({
			pathname: '/profile',
		});
	}

	const onApplyAction = () => {
		console.log("is_ProfileComplete && role", is_ProfileComplete, role)
		if (!is_blocked) {
			if (is_ProfileComplete === 0 && role === 3) {
				setProfileIncompleteModalShow(true);
			} else if (is_ProfileComplete === 0 && role === 2) {
				viewProfile();
			} else {
				onApplyTenancy();
			}
		}
	}

	const onApplyTenancy = () => {
		dispatch(setReceiverData(null));
		dispatch(getCalenderData(null));
		dispatch(setViewingRequestId(null));
		dispatch(setTenancyApplicationId(existingTenancyApplicationId));
		set_tenancy(true);
	}

	const onShowingAction = () => {
		if (!is_blocked) {
			if (existingShowingRequestId) {
				setConfirmShowing(true)
				dispatch(getCalenderData(null))
				dispatch(setViewingRequestId(existingShowingRequestId))
			} else {
				dispatch(setViewingRequestId(null));
				setShowingRequest(true);
				setReSchedule(false);
			}
		}
	}

	const onCreditCheckConfirmationFlow = (data) => {
		dispatch(setReceiverData(data));
		dispatch(getCalenderData(null));
		setCredit_check_request_id(data.credit_check_request_id);
		dispatch(setCreditCheckRequestId(data.credit_check_request_id));
		setCreditCheckModalShow(true);
	}

	const onReschedule = (data) => {
		setShowingRequest(true)
		setReSchedule(true)
	}

	const onActionCertnFlow = (data) => {
		// debugger
		dispatch(setReceiverData(data));
		dispatch(getCalenderData(null));

		const url = '/landlord/tenant-application-details/' + data.tenancy_application_id;
		getAPI(url).then(response => {
			if (response.status !== 200) {
				toaster('error', response.response.data.message)
			} else if (response.status === 200) {
				dispatch(setIsProfileCompleted(response.data.data.userDetails.basic_info.is_profile_completed))
				if (response.data.data.userDetails.basic_info.is_profile_completed !== 1) {
					setGetReportProfileIncompleteModalShow(true)
					return
				}
				const res = response.data.data.tenancyApplicationData;
				if (res.tenant_consent_to_credit_check === 1) {
					setCredit_check_request_id(data.credit_check_request_id);
					dispatch(setCreditCheckRequestId(data.credit_check_request_id));
					checkConcentReport(data);
				} else {
					onApplyAction();
				}
			}
		})
			.catch(error => {
			});
	}

	const getReport = () => {
		const url = `/landlord/check-certn-report/${tenant_id}/${conversation_id}`;
		getAPI(url).then(response => {
			if (response.status !== 200) {
				toaster('error', response.response.data.message);
			} else if (response.status === 200) {
				//log.info("response",response.data.data.report_url);
				window.open(response.data.data.report_url);
			}
		})
			.catch(error => {
			});
	}

	const checkConcentReport = (data) => {
		if (data.tenancy_application_id) {
			let url = '';
			if (role === 2) {
				url = `/landlord/tenant-application-details/${data.tenancy_application_id}`;
			} else if (role === 3) {
				url = `/tenant/tenancy-application-details/${data.tenancy_application_id}`;
			}
			getAPI(url).then(response => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					const res = response.data.data;
					dispatch(setIsProfileCompleted(response.data.data.userDetails.basic_info.is_profile_completed))
					const basic_info = res.userDetails.basic_info;
					if (basic_info.is_consent_payment_completed) {
						if (basic_info.is_certn_verified) {
							getReport();
						} else {
							setReportModalShow(true);
						}
					} else {
						setCertnPurchaseReport(true);
					}
				}
			})
				.catch(error => {
				});
		}
	}

	const onTenancyApplicationFlow = (data) => {
		dispatch(setReceiverData(data));
		dispatch(setTenancyApplicationId(data.tenancy_application_id));
		setCredit_check_request_id(data.credit_check_request_id);
		dispatch(setCreditCheckRequestId(data.credit_check_request_id));
		dispatch(getCalenderData(null));
		// if (is_ProfileComplete === 0 && role === 2) {
		// // if (role === 2) {
		// 	viewProfile();
		// }

		// if (role === 2) {
		set_tenancy(true);
		// }

	}

	const onViewLease = (data) => {
		log.info("onViewLease", data);
		dispatch(setReceiverData(data));
		dispatch(getCalenderData(null));
		setLeasePdfViewScreen(true);
	}


	return (<>
		<Col md={grid ? grid : 12}>
			<div className="chat-wrapper">
				<div className="chat-box-Header m-0">
					<div>
						{headerContent &&
							<div className='chat-header-name-wrapper'>
								{!showTenantList && <img
									alt="arrowVector"
									style={{ marginRight: '10px' }}
									src={backArrow}
									width={"18"}
									height={"13"}
									className={
										"message-back-button align-top mt-2"
									}
									onClick={() => setShowTenantList(true)}
								/>}
								<div className="chat-header-name">
									<h4 className="tenant-name text-left">{headerContent.name}</h4>
									{role === 2 && <Button className="link-btn" onClick={() => viewProfile()}>View Profile</Button>}
								</div>
							</div>
						}
					</div>
					<div>
						{headerContent &&
							<ThreeDotsDropdown
								icon={<Image
									width="6px"
									height="24px"
									alt="three-dots-vertical"
									src={require(`../../../_assets/images/three-dots-vertical.svg`)} />}
								actionsButton={
									<>
										{role === 2 &&
											<>
												{!isLeaseExists && <div className="dropdown-item cursor-pointer" onClick={() => setLeaseOptionsScreen(true)}> Create Lease</div>}
												{is_blocked ?
													<div className="dropdown-item cursor-pointer" onClick={() => blockUser('unblock')}> Unblock Tenant </div>
													: <div className="dropdown-item cursor-pointer" onClick={() => setModalShow(true)}> Block This Tenant </div>
												}
												<div className="dropdown-item cursor-pointer" onClick={() => setModalBlockShow(true)}> Remove From Contact List </div>
											</>}

										{role === 3 &&
											<>
												{is_blocked ?
													<div className="dropdown-item cursor-pointer" onClick={() => blockUser('unblock')}> Unblock Landlord </div>
													: <div className="dropdown-item cursor-pointer" onClick={() => setModalShow(true)}> Block This Landlord </div>
												}
											</>}
									</>
								}
							/>}
					</div>
				</div>
				{messageLoader ?
					<Loader /> :
					<Row className="chat-box-body">
						{((role === 2 && (listData && listData.length !== 0)) || role === 3) ?
							<Col md={12} id="messageBody" className={messageData && messageData.length !== 0 ? "message-body-scroll" : ""}>
								{/* <h4 className="chat-history">Today</h4> */}
								{(messageData && messageData.length !== 0) ?
									messageData.map((data, i) => {
										return <React.Fragment key={'message' + i}>
											<Row>
												<Col md={12}>
													<div className={data.is_message_received ? "message-recieved" : "message-send pull-right"}>
														<div className="message-card recieved">
															{data.title && <p className="request-message-title">{data.title}</p>}
															<div className="request-message-details">{data.message}</div>

															{(leaseData?.status !== 2) &&
																<>
																	{data.showing_request_id && ((data.type === 3 || data.type === 7 || data.type === 9) ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					onReschedule(data)
																				}}
																			>schedule a new time?</button>
																		</div>
																		: <div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					setConfirmShowing(true)
																					dispatch(setReceiverData(data))
																					dispatch(getCalenderData(null))
																					dispatch(setViewingRequestId(data.showing_request_id))
																				}}
																			>{(data.type === 2 || data.type === 6) ? 'view details' : 'see details'}</button>
																		</div>)}


																	{/* 3 - Lease */}
																	{data.tenancy_application_id && data.request_type === 3 ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					onTenancyApplicationFlow(data)
																				}}
																			>{(data.type === 10) ? 'see details' : 'view application'}</button>
																		</div> : ""}

																	{data.type === 21 &&
																		<div className='text-center'>
																			<button
																				type='button'
																				className='request-message-btn'
																				onClick={() => {
																					onViewLease({ ...data, 'is_updated': false, 'is_sent_to_tenant': true })
																				}}
																			>
																				view the lease
																			</button>
																		</div>
																	}

																	{/* 4 - Apply Tenancy Application */}
																	{data.request_type === 4 ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					onTenancyApplicationFlow(data)
																				}}
																			>view application</button>
																		</div> : ""}

																	{/* 5 - Credit Check Request */}
																	{((data.is_message_received && role === 2) || (role === 3))
																		&& data.request_type === 5 ?
																		<div className="text-center">
																			{data.is_message_received &&
																				(role === 2 ?
																					(data.type !== 17 &&
																						<button
																							type="button"
																							className="request-message-btn"
																							onClick={() => {
																								onActionCertnFlow(data)
																							}}
																						>get the report</button>)
																					: <button
																						type="button"
																						className="request-message-btn"
																						onClick={() => {
																							onCreditCheckConfirmationFlow(data)
																						}}
																					>see details</button>)
																			}
																		</div> : ""}

																	{/* 6 - Complete Profile Request */}
																	{(data.is_message_received && data.type === 19) ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					onCompleteProfileAction()
																				}}
																			>complete profile now</button>
																		</div> : ""}

																	{/* 7 - Accept lease */}
																	{(data.request_type === 7) ?
																		<div className="text-center">
																			<button
																				type="button"
																				className="request-message-btn"
																				onClick={() => {
																					onViewLease(data)
																				}}
																			>see details</button>
																		</div> : ""}
																		{/* 7 - Accept lease */}
																{(data.type === 18) && role===2 ?
																	<div className="text-center">
																		<button
																			type="button"
																			className="request-message-btn"
																			onClick={getReport}
																		>view the report </button>
																	</div> : ""}

																</>}

														</div>
														<span className="message-time">{data.time}</span>
													</div>
												</Col>
											</Row>
										</React.Fragment>
									})
									: <p className='start-convertation'>Start your conversation with the {role === 3 ? 'landlord' : 'tenant'}</p>}
							</Col> :
							<div className="no-messages width-100"><Loader /></div>}
					</Row>}


				<div className={(is_blocked) && "blur-box"}>
					{(leaseData?.status !== 2) &&
						<div className="action-buttons d-flex justify-content-center">
							<LargeButton isDisable={((role === 2 && (listData?.length !== 0)) || role === 3) ? false : true} type="button" onClick={() => onShowingAction()}
								title={(existingShowingRequestId
									? (existingShowingSentBy === 1 ? "see showing invitation" : "see showing request")
									: (role === 2 ? "invite to showing" : "request a showing"))} variant="dark" />
							<div className='mr-3 mobilertmar'></div>

							{leaseData?.status === 1 ?
								<LargeButton isDisable={((role === 2 && (listData?.length.length !== 0)) || role === 3) ? false : true} type="button" onClick={() => {
									viewLeaseAction()
								}} title={"view lease"} />
								: <LargeButton isDisable={((role === 2 && (listData?.length.length !== 0)) || role === 3) ? false : true} type="button" onClick={() => {
									onApplyAction()
								}} title={role === 2 ?
									((existingTenancyApplicationId) ? "create lease" : "invite to apply")
									: (existingTenancyApplicationId ? (isTenancyApplicationReceived ? "view invitation" : "view application") : "apply now")} />}

						</div>
					}

					<Row className="chat-box-footer">
						<Col md={12}>
							<form id="Form" className="msger-inputarea" onSubmit={sendMessage}>
								<input type="text" autoComplete="off" className="msger-input" placeholder="Your message…" id="message" name="message" value={message} onChange={handleChange} maxLength={250} disabled={(is_blocked) ? true : false} />
								<Button type="submit" disabled={(is_blocked) ? true : false} className="msger-send-btn">
									<Image
										width="18px"
										height="18px"
										alt="message-send-btn"
										src={require(`../../../_assets/images/message-send-btn.svg`)} />
								</Button>
							</form>
						</Col>
					</Row>
				</div>
			</div>
		</Col>

		<ModalBox
			show={modalShow}
			onHide={() => setModalShow(false)}
			size={role === 2 ? "md" : "sm"}
			type="condition"
			actionbuttontitle={'No'}
			buttonaction={() => {
				setModalShow(false)
			}}
			backbuttontitle={'Yes'}
			backaction={() => {
				blockUser('block')
				setModalShow(false)
			}}
		>
			<p className="modal-confirmation-text">are you sure you want to block {(role === 2 && headerContent) ? headerContent.name : 'this landlord'}? </p>
			{role === 2 ?
				<p>you won’t see messages from or notifications about this tenant anymore. are you sure you want to block this user? </p>
				: <p>You will not receive message from this landlord anymore</p>}
		</ModalBox>

		<ModalBox
			show={modalBlockShow}
			onHide={() => setModalBlockShow(false)}
			size={"sm"}
			type="condition"
			actionbuttontitle={'No'}
			buttonaction={() => {
				setModalBlockShow(false)
			}}
			backbuttontitle={'Yes'}
			backaction={() => {
				removeFromContact();
				setModalBlockShow(false)
			}}
		>
			<p className="modal-confirmation-text">Remove {(role === 2 && headerContent) ? headerContent.name : 'this landlord'} from your contact list? </p>
			<p>This will delete all your current conversation as well</p>
		</ModalBox>

		<ModalBox
			show={creditCheckModalShow}
			onHide={() => setCreditCheckModalShow(false)}
			size="sm"
			type="condition"
			actionbuttontitle={'Yes'}
			buttonaction={() => {
				setCreditCheckModal(true)
			}}
			backbuttontitle={'No'}
			backaction={() => {
				creditCheckAction('reject')
			}}
		>
			<p className="modal-confirmation-text">Credit Check Confirmation</p>
			<p>Do you want to allow the landlord to run a credit check?</p>
		</ModalBox>

		<ModalBox
			show={getReportProfileIncompleteModalShow}
			onHide={() => setGetReportProfileIncompleteModalShow(false)}
			size="md"
			actionbuttontitle={'got it'}
			buttonaction={() => {
				setGetReportProfileIncompleteModalShow(false)
			}}
		>
			<p className="modal-confirmation-text">Please ask tenant to complete profile in order to generate the Certn report</p>
		</ModalBox>

		<ModalBox
			show={profileIncompleteModalShow}
			onHide={() => setProfileIncompleteModalShow(false)}
			size="sm"
			actionbuttontitle={'complete profile'}
			buttonaction={() => {
				onCompleteProfileAction()
			}}
			backbuttontitle={'Not Now'}
			backaction={() => {
				setProfileIncompleteModalShow(false)
				onApplyTenancy()
			}}
		>
			<h4>your profile is your application for rentals on rent panda</h4>
			<p>No more filling out dozens of applications! <br />
                Please complete your profile to apply for this home.</p>
		</ModalBox>

		<ModalBox
			show={reportModalShow}
			onHide={() => setReportModalShow(false)}
			size="md"
			actionbuttontitle={'got it'}
			buttonaction={() => {
				setReportModalShow(false)
			}}
		>
			<p className="modal-confirmation-text">The credit report is currently being generated and may take a few moments. We will email you when it is ready to view.</p>
		</ModalBox>
		<ModalBox show={creditCheckModal}
			onHide={() => setCreditCheckModal(false)}
			size="md" actionbuttontitle={'Submit'}
			buttonaction={() => submitUserDOBAndSin()}>
			<form id="Form">
				<Row style={{ textAlign: 'left' }}>
					<Form.Group as={Col} md="12">
						<Form.Label className='bold'>Date of birth</Form.Label>
						<DatePickerComponent
							name="date_of_birth"
							id="date_of_birth"
							value={dateOfBirth}
							handleDateChange={(dob) => handleDate(dob)}
							displayText={"Date of Birth"}
							disableValue={false}
							maxDate={new Date()}
						/>
					</Form.Group>
					<Form.Group as={Col} md="12">
						<Form.Label className='bold'>SIN</Form.Label>
						<NumberFormat
							allowNegative={false}
							allowEmptyFormatting={false}
							value={sin ? sin : ''}
							onChange={(e) => setSin(e.target.value)}
							className='form-control'
							placeholder={''}
							name={'sin'}
							id={'sin'}
						/>
					</Form.Group>
					<DisclaimerText />
				</Row>
			</form>
		</ModalBox>
	</>);
}


const MemorizedComponent = React.memo(ChatBox, (prevProps, nextProps) => {

	// false - allow re-render 
	// if (nextProps.role === 3)
	// 	return false
	const isConversationIdChanged = prevProps.conversation_id === nextProps.conversation_id;

	// if(nextProps.message!==prevProps.message)
	// {	
	// return false
	// }
	if (nextProps.role === 3 && (prevProps?.property_details_id === nextProps?.property_details_id || prevProps?.conversation_id === nextProps?.conversation_id)) {
		return false
	}

	if (nextProps.role === 2 &&
		nextProps?.headerContent?.conversation_id &&
		nextProps?.conversation_id &&
		nextProps?.headerContent?.conversation_id === nextProps?.conversation_id) {
		return false
	}
	return true

})
export default withRouter(MemorizedComponent);