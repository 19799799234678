import React, { useState, useRef } from "react";
import {
  FormGroup,
  Input,
  CustomInput,
  FormFeedback,
  Button,
} from "reactstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthLayout from "../Authentication";
import { signUp } from "../../../Store/actions/AuthAction";
import { API_URL, Google_Captcha_API } from "../../../Constants/endPoints"
import { logger } from "../../../Utils";
import { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ModalBox } from "../../../App/components";
import TermsAndConditionContent from "../../TermsAndConditions/TermsAndConditionContent";
const DELAY = 1500;
const log = logger("SignUp");
const schema = yup.object().shape({
  email: yup.string().required().email("Enter valid email address"),
  firstName: yup.string()
    .matches(/^[^\s][A-Za-z0-9\s]*[^\s]$/, "Emoji's, Numbers, leading and trailing space not allowed")
    .matches(/^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, "Emoji's, Numbers, leading and trailing space not allowed")
    // .matches(/^[A-Za-z0-9\s]+[\s]+[A-Za-z0-9\s]/, "First name is required")
    .required('First name is required')
  ,
  lastName: yup.string()
    .matches(/^[^\s][A-Za-z0-9\s]*[^\s]$/, "Emoji's, Numbers, leading and trailing space not allowed")
    .matches(/^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, "Emoji's, Numbers, leading and trailing space not allowed")
    // .matches(/^[A-Za-z0-9\s]+[\s]+[A-Za-z0-9\s]/, "Last name is required")
    .required('Last name is required')
  ,
  phone: yup.string()
    .matches(/^[0-9]{10}$/, "Must be exactly 10 digits and only numbers")
    .required('Phone number is required'),
  password: yup
    .string()
    .required('Password is required'),
  repeat_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords do not match")
    .required("Confirm password is required"),
});

const SignUp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [duplicatePhone, setDuplicatePhone] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [componentLoad, setComponentLoad] = useState('tenant');
  const [showPass, setShowPass] = useState(false);
  const [showRepeatPass, setShowRepeatPass] = useState(false);
  const [value, setValue] = useState("[empty]");
  const [load, setLoad] = useState(false);
  const [termsAndService, setTermsAndService] = useState(false);
  const [importantUpdate, setImportantUpdate] = useState(false);
  const [modalTermsAndService, setModalTermsAndService] = useState(false);
  const [modalImpUpdates, setModalImpUpdates] = useState(false);
  const recaptcha = useRef(null);

  const togglePassword = (type) => {
    if (type === 'pass') {
      setShowPass(!showPass)
    } else if (type === 'repeat') {
      setShowRepeatPass(!showRepeatPass)
    }
  }
  const handleChangeCaptcha = (value) => {
    log.info("Captcha value:", value);
    setValue(value);
    // if value is null recaptcha expired
    if (value === null) {
      // setExpired(true)
    }
  };
  const asyncScriptOnLoad = () => {
    log.info("scriptLoad - reCaptcha Ref-", recaptcha);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoad(true);
    }, DELAY);
    return () => clearTimeout(timeout);
  }, []);


  const renderInput = ({ type, id, placeholder, emailError, phoneError, maxLength }) => {
    return (
      <FormGroup>
        <Field name={id}>
          {({
            field: { name, value, onChange, onBlur },
            form: { touched, errors },
            meta,
          }) => {
            let hasError = !!meta.touched && !!meta.error;
            let errorMessage = meta.error;
            if (name === "email" && emailError) {
              hasError = true;
              errorMessage = emailError;
            }
            if (name === "phone" && phoneError) {
              hasError = true;
              errorMessage = phoneError;
            }
            const handleChange = (...props) => {
              onChange(...props);
              if (name === "firstName" && emailError) {
                setDuplicateEmail(false);
                hasError = false;
              }

              if (name === "email" && emailError) {
                setDuplicateEmail(false);
                hasError = false;
              }

              if (name === "email" && emailError) {
                setDuplicateEmail(false);
                hasError = false;
              }

              if (name === "phone" && phoneError) {
                setDuplicatePhone(false);
                hasError = false;
              }
            };
            return (
              <div style={{ position: 'relative' }}>
                <Input
                  type={(type === 'password') ?
                    (name === 'password') ? (showPass ? 'text' : 'password') :
                      (name === 'repeat_password') && (showRepeatPass ? 'text' : 'password') : type}
                  name={name}
                  id={id}
                  invalid={hasError}
                  placeholder={placeholder}
                  onChange={handleChange}
                  onBlur={onBlur}
                  value={value}
                  maxLength={maxLength}
                />
                {type === 'password' ?
                  <p style={{
                    position: 'absolute',
                    top: '17px',
                    right: '11px',
                    fontSize: '11px',
                    fontWeight: '600',
                    opacity: '0.5',
                    cursor: 'pointer'
                  }}
                    onClick={() => togglePassword(
                      (name === 'password') ? 'pass' : 'repeat')}>
                    {value && <img width="20" height="20" src={require("../../../_assets/images/" +
                      ((name === 'password') ? (showPass ? "show.svg" : "hide.svg") :
                        (showRepeatPass ? "show.svg" : "hide.svg"))
                    )} alt="eyeIcon" />}</p>
                  : ''}

                {meta.touched && meta.error && (
                  <FormFeedback>{errorMessage}</FormFeedback>
                )}
              </div>
            );
          }}
        </Field>
      </FormGroup>
    );
  };
  const openTermsOfService = () => {
    setModalTermsAndService(true);
  }
  const openImportantUpdates = () => {
    setModalImpUpdates(true);
  }
  const acceptTermsAndService = () => {
    setModalTermsAndService(false);
    setTermsAndService(true)
  }

  const acceptImportantUpdate = () => {
    setModalImpUpdates(false);
    setImportantUpdate(true)
  }
  const onChangeTermsAndService = () => {
    setTermsAndService(!termsAndService)
  }
  const onChangeImportantUpdate = () => {
    setImportantUpdate(!importantUpdate)
  }
  const changeUser = () => {
    const user = componentLoad === 'tenant' ? 'landlord' : 'tenant'
    props.history.push(`/signup/${user}`)
    window.location.reload();
  }
  useEffect(() => {
    const slug = props.match.params.slug;
    setComponentLoad(slug)
    window.scrollTo(0, 0)
  }, [props.match.params.slug]);
  return (
    <AuthLayout title={`${componentLoad === 'tenant' ? "Tenant" : "Landlord"} sign up`} history={history}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          password: "",
          repeat_password: "",
          terms_and_services: false,
        }}
        validationSchema={schema}

        onSubmit={async (values, actions) => {
          if (termsAndService) {
            try {
              const data = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                phone_number: values.phone,
                password: values.password,
                repeat_password: values.repeat_password,
                user_type: componentLoad === 'tenant' ? 3 : 2,
                captcha_token: value
              };
              log.info("data", data);

              const res = await axios.post(
                API_URL + "/sign-up",
                data
              );

              dispatch(signUp(res.data.data.email, res.data.data.user_id));
              history.push({
                pathname: "/verification",
                state: {
                  data: values.email,
                  flow: 'signupflow'
                }
              });
            } catch (err) {
              window.grecaptcha.reset();
              if (err.response.data.errors) {
                if (err.response.data.errors.email) {
                  setDuplicateEmail(err.response.data.errors.email[0]);
                  actions.setFieldError("email", "error");
                }
                if (err.response.data.errors.phone_number) {
                  setDuplicatePhone(err.response.data.errors.phone_number[0]);
                  actions.setFieldError("phone", "error");
                }
                if (err.response.data.errors.password) {
                  setPasswordError(err.response.data.errors.password[0])
                  actions.setFieldError("password", err.response.data.errors.password[0]);
                }
                if (err.response.data.errors.captcha_token) {
                  setCaptchaError(err.response.data.errors.captcha_token[0]);
                  actions.setFieldError("captcha_token", err.response.data.errors.captcha_token[0]);
                }
              }
            }
          } else {
            setModalTermsAndService(true);
          }

        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} disabled={isSubmitting}>
            {renderInput({
              type: "text",
              id: "firstName",
              placeholder: "First Name",
              maxLength: 60
            })}
            {renderInput({
              type: "text",
              id: "lastName",
              placeholder: "Last Name",
              maxLength: 60
            })}
            {renderInput({
              type: "email",
              id: "email",
              placeholder: "Email Address",
              emailError: duplicateEmail,
            })}
            {renderInput({
              type: "text",
              id: "phone",
              placeholder: "Phone Number",
              phoneError: duplicatePhone,
              maxLength: 10
            })}
            {renderInput({
              type: "password",
              id: "password",
              placeholder: "Password",
              passwordError: passwordError,
            })}
            {renderInput({
              type: "password",
              id: "repeat_password",
              placeholder: "Repeat Password",
            })}
            <div className="condition-wrapper">
              <Field name={"terms_and_services"}>
                {({
                  field: { name, value, onChange, onBlur },
                  form: { touched, errors },
                  meta,
                }) => (
                  <div>
                    <CustomInput
                      className="d-flex"
                      type="checkbox"
                      id="terms_and_services"
                      label={<label>Agree to <span className="terms-condition cursorPointer" onClick={() => openTermsOfService()}>terms of service</span></label>}
                      checked={termsAndService}
                      onChange={onChangeTermsAndService}
                      onBlur={onBlur}
                      value={value}
                    />
                    {meta.touched && meta.error && (
                      <FormFeedback>{meta.error}</FormFeedback>
                    )}
                  </div>
                )}
              </Field>

              <Field name={"important_updates"}>
                {({
                  field: { name, value, onChange, onBlur },
                  form: { touched, errors },
                  meta,
                }) => (
                  <div>
                    <CustomInput
                      className="d-flex"
                      type="checkbox"
                      id="important_updates"
                      checked={importantUpdate}
                      onChange={onChangeImportantUpdate}
                      label={<label>Agree to receive important updates</label>} // PEN-3280 - <span className="terms-condition cursorPointer" onClick={() => openImportantUpdates()}>important updates</span>
                    />
                    {meta.touched && meta.error && (
                      <FormFeedback>{meta.error}</FormFeedback>
                    )}
                    {load && (
                      <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        className="recaptcha"
                        theme="light"
                        ref={recaptcha}
                        sitekey={Google_Captcha_API}
                        onChange={handleChangeCaptcha}
                        asyncScriptOnLoad={asyncScriptOnLoad}
                      />
                    )}
                    <p style={{ color: 'red' }}>{captchaError}</p>
                  </div>
                )}
              </Field>
            </div>
            <Button
              type="submit"
              className="btn btn-primary text-transform-none f-secondary-b mb-4"
              style={{ width: "100%" }}
              disabled={isSubmitting}
            >
              sign up now
            </Button>
            <p className="authentication-page-form-links" style={{ marginBottom: "35px" }}>
              Already have an account? &nbsp;
              <a
                style={{ color: "#000", textDecoration: "underline" }}
                href="/login">Log in here</a>
            </p>
            <p className="authentication-page-form-links m-0">
              Sign up as &nbsp;
              <span
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  changeUser();
                }}
              >{componentLoad === 'tenant' ? "landlord" : "tenant"}
              </span>
            </p>
          </Form>
        )}
      </Formik>

      <ModalBox
        show={modalTermsAndService}
        onHide={() => setModalTermsAndService(false)}
        size="lg"
        type="condition"
        actionbuttontitle={'Agree To Terms Of Service'}
        buttonaction={() => {
          acceptTermsAndService()
        }}
        backbuttontitle={'Cancel'}
        backaction={() => {
          setModalTermsAndService(false)
        }}
      >
        <h4>Terms Of Service</h4>
        <div style={{ height: '70vh', overflow: 'auto', textAlign: 'left' }}>
          <TermsAndConditionContent />
        </div>

      </ModalBox>
      <ModalBox
        show={modalImpUpdates}
        onHide={() => setModalImpUpdates(false)}
        size="lg"
        type="condition"
        actionbuttontitle={'Agree To Receive Important Updates'}
        buttonaction={() => {
          acceptImportantUpdate()
        }}
        backbuttontitle={'Cancel'}
        backaction={() => {
          setModalImpUpdates(false)
        }}
      >
        <h4>Important Updates</h4>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.

        Why do we use it?
        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).

</p>
      </ModalBox>
    </AuthLayout>
  );
};

export default withRouter(SignUp);
