import React from 'react';
import { withRouter } from "react-router-dom";
import { Container } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import Layout from '../components/Layout';

import './style.scss';
import TermsAndConditionContent from './TermsAndConditionContent';

const TermsAndConditions = (props) => {
    return (
        <div className="termsPage" id="termsPage">
            <Layout newHeader={true}>
                <section className="terms-page-header cu-container-wrap">
                    <Container>
                        <h1>Website Terms and Conditions of Use</h1>
                    </Container>
                </section>
                <TermsAndConditionContent />
            </Layout>
        </div>
    )
}

export default withRouter(TermsAndConditions);
