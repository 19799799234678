import React, { useState, useEffect } from "react";
import NumberFormat from 'react-number-format';
import { SidePanel, LargeButton } from "../../../components"
import { Row, Col, Form } from "react-bootstrap";
import { postAPI } from "../../../../Api";
import { logger,toaster } from "../../../../Utils";
import { useSelector } from "react-redux";

const log = logger('PaymentSummary')
const PaymentSummary = (props) => {
  const { isShow, handleClose, setAdvertisement_payment_id } = props
  const [discount_id, setDiscount_id] = useState(0);
  const [isPromoCode, setIsPromoCode] = useState(false);
  // const [promoCodeList, setPromoCodeList] = useState(null);
  const [discountResponse, setDiscountResponse] = useState([]);
  const [coupon_code, setCoupon_code] = useState('')
  // const [couponError, setCouponError] = useState(false)
  const [couponErrorMessage, setCouponErrorMessage] = useState('')
  
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  
  const paymentSummary = useSelector(state => state.propertyDetailsReducer.getPaymentSummary);

  const wrapperStyle = {
    overflowY : 'auto',
    height: 'calc(100vh - 220px)' 
  }

  const OnChangeInput = (event, setState) => {
    setCouponErrorMessage('')
    // if(event.target.name === 'discount') {
    //   let tmp_discount_id = Number(event.target.value);
    //   const url = '/landlord/apply-promo-code';
    //   const data = {
    //     'discount_id': Number(event.target.value),
    //     'promotion_id': paymentSummary.promotion_id
    //   }
      // postAPI(url, data).then(response => {
      //   if (response.response && response.response.status === 400) {
      //     const res = response.response.data;
      //     toaster('error', res.message);
      //     setDiscountResponse(res.data)
      //   } else if (response.response && response.response.status === 422) {
      //     toaster('error', response.response.data.errors.promotion_id[0]);
      //   } else if (response.status === 200) {
      //     const res = response.data;
      //     setDiscountResponse(res.data);
      //     toaster('success', res.message);
      //     setDiscount(tmp_discount_id);
      //   }
      //   else {
      //     toaster('error', 'Something went wrong!');
      //   }
      // })
    // }   
    setState(event.target.value)
  }

  const applyCoupon = () => {
    const url = '/landlord/apply-promo-code';
    const data = {
      'coupon_code': coupon_code,
      'promotion_id': paymentSummary.promotion_id
    }
    postAPI(url, data).then(response => {
      if (response.response && response.response.status === 400) {
        const res = response.response.data;
        setCouponErrorMessage(res.message)
        setDiscountResponse(res.data)
      } else if (response.response && response.response.status === 422) {
        const res = response.response.data;
        setCouponErrorMessage(res.message)
      } else if (response.status === 200) {
        const res = response.data;
        setDiscountResponse(res.data);
        setDiscount_id(res.data.discount_id)
        toaster('success', res.message);
      }
      else {
        toaster('error', 'Something went wrong!');
      }
    })
  }

  const onSubmit = () => {
    const url = '/landlord/create-listing/store-payment-summary';

    const data = {
      property_id: property_id,
      property_details_id: property_details_id,
      discount_id: discount_id,
      promotion_id: paymentSummary.promotion_id
    }
    postAPI(url, data).then(response => {
      if (response.status !== 200) {
        toaster('error', 'Something went wrong!');
      } else if (response.status === 200) {
        const res = response.data.data;
        handleClose();
        setIsPromoCode(false);
        log.info("res.advertisement_payment_id", res.advertisement_payment_id);
        setAdvertisement_payment_id(res.advertisement_payment_id)
      }
    })
  }

  useEffect(() =>{
    setCouponErrorMessage('')
    setDiscountResponse([])
    setCoupon_code('')
  },[handleClose])
  
  // useEffect(() => {
  //   // setCouponError(false)
  //   if (isPromoCode) {
  //     const promoCodeUrl = '/landlord/coupon/promo-code-list';
  //     const data = {
  //       "status": 1, //0: Inactive List, 1: Active List
  //     }
  //     getAPI(promoCodeUrl, data).then(response => {
  //       if (response.status !== 200) {
  //         toaster('error', 'Something went wrong!');
  //       } else if (response.status === 200) {
  //         const res = response.data.data;
  //         // setPromoCodeList(res);
  //       }
  //     })
  //   } else {
  //     setDiscountResponse([]);
  //     // setDiscount(0);
  //   }
  // }, [isPromoCode])

  // let listCouponOptions = null;
  // if(promoCodeList){
  //   if(promoCodeList.length > 0){
  //     listCouponOptions= promoCodeList.map((code, id) => {
  //       return (
  //         <option key={code.id} value={code.id}>
  //           {code.coupon_name}
  //         </option>
  //       )
  //     });
  //   }else{
  //     listCouponOptions = 'Loading...'
  //   }
  // }

  return (<>
    <SidePanel
      isShow={isShow}
      isHide={() => {handleClose(); setIsPromoCode(false);}}
      title="Payment Summary"
      subTitle="Review the detailed price">
      <Row style={wrapperStyle}>
        <Col md='12'>
          <ul className='list-display'>
            <li><span>Professional Photo and Videoshoot</span><span>${discountResponse.professional_photoshoot_amount || (paymentSummary && paymentSummary.professional_photoshoot_amount)}</span></li>
            <li>
              <span>Promoted Listing {paymentSummary && `(${paymentSummary.promoted_listing_duration})`}</span>
              <span>${discountResponse.promoted_listing_amount || (paymentSummary && paymentSummary.promoted_listing_amount)}</span>
            </li>
          </ul>
          <ul className='list-display'>
            <li><span>Sub Total</span><span>${discountResponse.sub_total || (paymentSummary && paymentSummary.sub_total)}</span></li>
            <li>
              <span>HST (+{discountResponse.hst_percentage || (paymentSummary && paymentSummary.hst_percentage)}%)</span>
              <span>${discountResponse.hst_fee || (paymentSummary && paymentSummary.hst_fee)}</span>
            </li>
            <li><span>Discount</span><span>${discountResponse.discount_amount || 0}</span></li>
          </ul>
          <h3 className='price-display'>
            <NumberFormat
              value={discountResponse?.grand_total ? discountResponse?.grand_total : paymentSummary?.grand_total}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'Total $'}
              decimalScale={2}
              decimalSeparator='.'
            />
          </h3>
          
          {
            !isPromoCode ?
              <span className='promo-code-display' onClick={()=>setIsPromoCode(true)}>+ Apply Promo Code</span>
              : 
              // (listCouponOptions ?
              //     <Form.Group>
              //       <Form.Label className="d-flex justify-content-between">
              //         <span>Select Promo Code</span>
              //         <span className='promo-code-display' onClick={()=>setIsPromoCode(false)}>Cancel</span>
              //       </Form.Label>
              //       <Form.Control
              //         as="select"
              //         className="my-1 mr-sm-2"
              //         id="professional-promo"
              //         custom
              //         name = "discount" 
              //         value = {discount}
              //         onChange = {(e) => OnChangeInput(e)}
              //       >
              //       <option disabled value="0">{listCouponOptions !== 'Loading...' ? 'Select one' : 'No coupons available'}</option>
              //         {promoCodeList.map(coupon => {
              //           return <option key={coupon.id} value={coupon.id}>{coupon.coupon_name}</option>
              //         })}
              //         <listCouponOptions />
              //         {listCouponOptions}
              //       </Form.Control> 
              //     </Form.Group>
              //     : 'Loading...')
              <>
                <Row>
                  <Form.Group as={Col} md="12">
                    <Row className='d-flex flex-row' style={{ justifyContent: 'space-between', padding: '0 13px' }}>
                      <Form.Label>Enter Coupon Code</Form.Label>
                      <span className='promo-code-display' onClick={()=>{setIsPromoCode(false); setDiscountResponse([])}}>Cancel</span>
                    </Row>
                    <Form.Control
                        className = {couponErrorMessage && 'is-invalid'}
                        type="text"
                        placeholder="RP20"
                        name="coupon_code"
                        id="coupon_code"
                        value={coupon_code}
                        onChange={(e) => OnChangeInput(e, setCoupon_code)}
                        maxLength={8}
                    />
                    <div style={{ display: 'block' }} class='invalid-feedback'>{couponErrorMessage}</div>
                  </Form.Group>
                </Row>
                <Row style={{ display: 'flex', flexDirection:'row-reverse', paddingRight: '25px' }}>
                  <LargeButton
                    style={{width: '50%'}}
                    title="Apply Coupon"
                    onClick={() => applyCoupon()}
                  />
                </Row>
              </>
          }

        </Col>
      </Row>

      <div className="fixed-bottom">
        <Col md='12'>
          <LargeButton
            title="continue to payment"
            onClick={() => onSubmit()} />
          <LargeButton
            variant="secondary"
            title="cancel"
            onClick={() => {handleClose(); setIsPromoCode(false); setCoupon_code(''); setDiscountResponse([])}} />
        </Col>
      </div>
    </SidePanel>
  </>);
}

export default PaymentSummary;