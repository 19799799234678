import React from "react";
import "./style.scss";
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Zoom } from '@react-pdf-viewer/full-screen';
import packageJson, { dependencies } from '../../../../package.json';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Loader from "../Loader";
import logger from '../../../Utils/logger';

const log = logger("PDFViewerComponent");

const PDFViewerComponent = (props) => {
  const { PDFUrl } = props;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      fullScreenPlugin: {
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
        },
      }
    }
  });
  // log.info("packageJson", dependencies['pdfjs-dist']);
  let url = `https://unpkg.com/pdfjs-dist@${dependencies['pdfjs-dist']}/build/pdf.worker.js`;
  return (
    <iframe
      src={PDFUrl}
      width={'100%'}
      height={'480'}
      className="pdfIframe"
    />

    // <Worker workerUrl={url}>
    //   <div className="pdfViewer">
    //     <Viewer fileUrl={PDFUrl}
    //       renderLoader={(percentages) => (
    //         <div>
    //           <Loader />
    //           <p>This may take a minute. please do not refresh the page.</p>
    //         </div>
    //       )}
    //       plugins={[
    //         defaultLayoutPluginInstance,
    //       ]}
    //     />

    //   </div>
    // </Worker>
  );
}
export default PDFViewerComponent;