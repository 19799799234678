import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { postAPI } from "../../../../Api";
import { logger } from "../../../../Utils";
import ListManagementTable from "../ListManagemantTable";
import { useDispatch, useSelector } from 'react-redux';
import { getBackPath, inactiveStatus } from "../../../../Store/actions/Action";
import { setInactiveTableData, setInactiveCurrentPage, setFilters } from '../../../../Store/actions/ListingAction';
import TableContentLoader from "../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/TableContentLoader";

const log = logger("Inactive");
const Inactive = (props) => {
    const url = '/admin/listing/inactive';
    const dispatch = useDispatch();
    const inactiveListStatus = useSelector(state => state.propertyDetailsReducer.inactiveListStatus);
    const [sort_by_status, setStatus] = useState(inactiveListStatus !== null ? inactiveListStatus : ['all']);
    const [isLoading, setIsLoading] = useState(true);
    const filters = useSelector(state => state.listingReducer.filters);
    const pagination = useSelector(state => state.listingReducer.inactiveCurrentPage);
    const currentComponent = useSelector(state => state.listingReducer.currentComponent);
    const [oldFilter, setOldFilter] = useState({...filters})

    const sortStatus = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let arraydata = [...sort_by_status]
        let returnVal = [];
        if (item === 'all') {
            let arr = []
            if (isChecked === true) {
                arr = ['all']
            } else {
                arr = []
            }
            returnVal = arr;
        } else if (item !== 'all') {
            isChecked === true ? arraydata.push(item) : arraydata.splice(arraydata.indexOf(item), 1);
            if (arraydata.includes('all')) {
                const indexElm = arraydata.indexOf('all');
                arraydata.splice(indexElm, 1);
            }
            returnVal = arraydata;
        }
        dispatch(setFilters({...filters, sort_by_status: returnVal}))
        setStatus(returnVal)
        const data = {
            'filter': {
                'city': filters.searchCity || '',
                'address': filters.searchAddress || '',
                'landlord': filters.searchLandlord || '',
                'tenant': filters.searchTenant || '',
                'rentpanda_id': filters.searchRentpandaId || '',
            },
            'sort_by_price': {
                'order': filters.sort_by_price_order === '' ? 'all' : filters.sort_by_price_order
            },
            'sort_by_status': returnVal,
            'limit': 10
        }
        postAPI(url, data).then(response => {
            if (response.status === 200) {
                setIsLoading(false)
                log.info("response.data", response.data)
                dispatch(setInactiveTableData(response.data.data));
            }
        })
    }
    const goToProfileDetail = (data) => {
        log.info(data)
        props.history.push({
            pathname: `/profile-detail/listings/${data.user_type}/${data.user_id}/${data.property_id}/${data.property_details_id}`,
            state: { path: currentComponent === 'inactive' && 'inactiveListing' }
        });
        dispatch(inactiveStatus(sort_by_status));
        dispatch(getBackPath('inactiveListing'));
    }
    const getData = (url) => {
        const data = {
            'filter': {
                'city': filters.searchCity,
                'address': filters.searchAddress,
                'landlord': filters.searchLandlord,
                'tenant': filters.searchTenant,
                'rentpanda_id': filters.searchRentpandaId,
            },
            'sort_by_price': {
                'order': filters.sort_by_price_order === '' ? 'all' : filters.sort_by_price_order
            },
            'sort_by_status': filters.sort_by_status.includes('all') ? [] : filters.sort_by_status,
            'limit': 10
        }
        postAPI(url, data).then(response => {
            if (response.status === 200) {
                setIsLoading(false);
                log.info("response.data 2", response.data.data)
                dispatch(setInactiveTableData(response.data.data));
            }
        })
    }

    const onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        dispatch(setInactiveCurrentPage(nextPage));
    }

    const onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        dispatch(setInactiveCurrentPage(prevPage));
    }

    // useEffect(() => {
    //     let setUrl = '';
    //     if (pagination !== null && pagination > 1 ) {
    //         setUrl = `/admin/listing/inactive?page=${pagination}`
    //     } else {
    //         setUrl = `/admin/listing/inactive`
    //     }
    //     getData(setUrl);
    //     if (inactiveListStatus !== null) {
    //         dispatch(inactiveStatus(null));
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dispatch, filters, pagination, inactiveListStatus]);

    useEffect(() => {
        let setUrl = ''
        if(JSON.stringify(oldFilter) !== JSON.stringify(filters)){
            setUrl = `/admin/listing/inactive`
            setOldFilter({...filters})
        }else{
            if (pagination !== null && pagination > 1 ) {
                setUrl = `/admin/listing/inactive?page=${pagination}`
            }else{
                setUrl = `/admin/listing/inactive`
            }
        }
        if (inactiveStatus !== null) {
            dispatch(inactiveStatus(null));
        }
        getData(setUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pagination, filters, inactiveStatus]);

    let userColumns = [];

    userColumns = [
        {
            header: 'City',
            name: 'city',
            width: '10%'
        },
        {
            header: 'Address',
            name: 'address',
            width: '20%'
        },
        {
            header: 'Landlord',
            name: 'landlord',
            width: '10%'
        },
        {
            header: 'Price',
            name: 'price',
            width: '10%'
        },
        {
            header: 'Rentpanda Id',
            name: 'rentpanda_id',
            width: '10%'
        },
    ];

    return (
        <React.Fragment>
            {isLoading === true ? <TableContentLoader columns={userColumns} component={'listingManagementInactive'} /> : <>
                <ListManagementTable
                    columns={userColumns}
                    sortStatus={(data) => sortStatus(data)}
                    sort_by_status={sort_by_status}
                    goToProfileDetail={(data) => goToProfileDetail(data)}
                    clickNext={(data) => onClickNext(data)}
                    clickPrev={(data) => onClickPrev(data)}
                />
            </>}
        </React.Fragment>
    )
};

export default withRouter(Inactive);