import React, { useEffect, useState } from 'react';
import { Alert, Image } from "react-bootstrap";
import { useDispatch } from 'react-redux'
import { AppLayout, PageTitleHeader, ModalBox } from "../../components";
import './style.scss';
import { BasicInfoDisplay, CurrentHomeInfoDisplay, PastHomeInfoDisplay, EmploymentInfoDisplay, PersonalReferencesInfoDisplay, PetsInfoDisplay, VehicleInfoDisplay, RoommateInfoDisplay } from '../../components/TenantProfile/Display';
import { BasicInfo, CurrentHomeInfo, PastHomeInfo, EmploymentInfo, PersonalReferencesInfo, PetsInfo, VehicleInfo, RoommateInfo } from '../../components/TenantProfile/ProfileInfo';
import { useHistory } from 'react-router-dom';
import { logger } from "../../../Utils";
import { getAPI } from '../../../Api';
import { setUserProfile } from '../../../Store/actions/UserProfileAction'

const log = logger("Profile");
const Profile = () => {
	const [profileDetails, setProfileDetails] = useState([])
	const [basicInfo, setBasicInfo] = useState(false)
	const [currentHomeInfo, setCurrentHomeInfo] = useState(false)
	const [newCurrentHomeInfo, setNewCurrentHomeInfo] = useState(false)
	const [pastHomeInfo, setPastHomeInfo] = useState(false)
	const [employmentInfo, setEmploymentInfo] = useState(false)
	const [personalReferencesInfo, setPersonalReferencesInfo] = useState(false)
	const [petsInfo, setPetsInfo] = useState(false)
	const [vehicleInfo, setVehicleInfo] = useState(false)
	const [roommateInfo, setRoommateInfo] = useState(false)
	const [modalShow, setModalShow] = useState(false);
	const [successModalShow, setSuccessModalShow] = useState(false);

	const dispatch = useDispatch()
	const history = useHistory();

	log.info('profileDetails', profileDetails)

	useEffect(() => {
		getProfileDetails();
	}, [basicInfo, currentHomeInfo, newCurrentHomeInfo, pastHomeInfo, employmentInfo, personalReferencesInfo, petsInfo, vehicleInfo, roommateInfo])

	const getProfileDetails = () => {
		const url = '/tenant/profile';
		getAPI(url).then(response => {
			if (response.status === 200) {
				const res = response.data.data;
				setProfileDetails(res)
				dispatch(setUserProfile(res))
			}
		})
			.catch(error => {
			});
	}

	const backToDashboard = () => {
		history.push('/dashboard/my-listing');
	}

	return (<>
		<AppLayout>
			<PageTitleHeader
				title='my profile'

				isArrow={true}
				onClick={() => backToDashboard()}
			/>
			<div className="profile-container">
				<div className="profile-container-box">

					{profileDetails && profileDetails.basic_info &&
						profileDetails.basic_info.is_profile_completed !== 1 &&
						<Alert variant='warning'>
							<Image className="icon" src={require("../../../_assets/images/alert-icon.svg")} alt="add" /><br />
					  Pro tip: Build your profile below so landlords can get to know you, you don’t have to
					  complete multiple applications and to increase the chances of getting that perfect home!
					</Alert>}

					<BasicInfoDisplay
						editaction={() => setBasicInfo(true)}
						profileDetails={profileDetails}
					/>

					<CurrentHomeInfoDisplay
						editaction={() => setCurrentHomeInfo(true)}
						addaction={() => setModalShow(true)}
						profileDetails={profileDetails}
					/>

					<PastHomeInfoDisplay
						editaction={() => setPastHomeInfo(true)}
						addaction={() => setPastHomeInfo(true)}
						profileDetails={profileDetails}
					/>

					<EmploymentInfoDisplay
						editaction={() => setEmploymentInfo(true)}
						addaction={() => setEmploymentInfo(true)}
						profileDetails={profileDetails}
					/>

					<PersonalReferencesInfoDisplay
						editaction={() => setPersonalReferencesInfo(true)}
						profileDetails={profileDetails}
					/>

					<PetsInfoDisplay
						editaction={() => setPetsInfo(true)}
						profileDetails={profileDetails}
					/>

					<VehicleInfoDisplay
						editaction={() => setVehicleInfo(true)}
						profileDetails={profileDetails}
					/>

					<RoommateInfoDisplay
						editaction={() => setRoommateInfo(true)}
						profileDetails={profileDetails}
					/>
				</div>
			</div>
		</AppLayout>

		<ModalBox
			show={modalShow}
			onHide={() => setModalShow(false)}
			size="sm"
			type="condition"
			actionbuttontitle={'Yes'}
			buttonaction={() => {
				setModalShow(false)
				setNewCurrentHomeInfo(true)
			}}
			backbuttontitle={'No'}
			backaction={() => {
				setModalShow(false)
			}}
		>
			<h4>are you sure you want to add new info</h4>
			<p>Your current home address will be converted to a past home address</p>
		</ModalBox>

		<ModalBox
			show={successModalShow}
			onHide={() => setSuccessModalShow(false)}
			size="sm"
			actionbuttontitle={'got it'}
			buttonaction={() => {
				setSuccessModalShow(false)
			}}
		>
			<h4>your current home address has been updated</h4>
		</ModalBox>

		<BasicInfo
			isShow={basicInfo}
			basic_info={profileDetails && profileDetails.basic_info}
			handleClose={() => setBasicInfo(false)}
			setCurrentHomeInfo={(flag) => setCurrentHomeInfo(flag)} />

		{ currentHomeInfo && 
		<CurrentHomeInfo
			isShow={currentHomeInfo}
			current_home={profileDetails && profileDetails.current_home}
			handleClose={() => setCurrentHomeInfo(false)}
			setPastHomeInfo={(flag) => setPastHomeInfo(flag)}
			type={'edit'} />
		}
		{newCurrentHomeInfo && 
		<CurrentHomeInfo
			isShow={newCurrentHomeInfo}
			current_home={''}
			handleClose={() => setNewCurrentHomeInfo(false)}
			setPastHomeInfo={(flag) => setPastHomeInfo(flag)}
			newCurrentHomeInfo={newCurrentHomeInfo}
			setSuccessModalShow={(flag) => setSuccessModalShow(flag)}
			type={'add'} />
		}
		<PastHomeInfo
			isShow={pastHomeInfo}
			id={profileDetails && profileDetails.id}
			past_home={profileDetails && profileDetails.past_home}
			handleClose={() => setPastHomeInfo(false)}
			setEmploymentInfo={(flag) => setEmploymentInfo(flag)} />

		<EmploymentInfo
			isShow={employmentInfo}
			id={profileDetails && profileDetails.id}
			employment_info={profileDetails && profileDetails.employment_info}
			handleClose={() => setEmploymentInfo(false)}
			setPersonalReferencesInfo={(flag) => setPersonalReferencesInfo(flag)} />

		<PersonalReferencesInfo
			isShow={personalReferencesInfo}
			id={profileDetails && profileDetails.id}
			personal_reference={profileDetails && profileDetails.personal_reference}
			handleClose={() => setPersonalReferencesInfo(false)}
			setPetsInfo={(flag) => setPetsInfo(flag)} />

		<PetsInfo
			isShow={petsInfo}
			id={profileDetails && profileDetails.id}
			pets_info={profileDetails && profileDetails.pets_info}
			handleClose={() => setPetsInfo(false)}
			setVehicleInfo={(flag) => setVehicleInfo(flag)} />

		<VehicleInfo
			isShow={vehicleInfo}
			id={profileDetails && profileDetails.id}
			vehicle_info={profileDetails && profileDetails.vehicle_info}
			handleClose={() => setVehicleInfo(false)}
			setRoommateInfo={(flag) => setRoommateInfo(flag)} />

		<RoommateInfo
			isShow={roommateInfo}
			id={profileDetails && profileDetails.id}
			roommate_info={profileDetails && profileDetails.roommate_info}
			handleClose={() => setRoommateInfo(false)} />

	</>);
}

export default Profile;
