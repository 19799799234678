import React, { useState, useRef, useEffect } from "react";
import { Col, Form, Row, Image, Button } from "react-bootstrap";
import { SidePanel, LargeButton, CancelButton, AutoCompleteAddress } from "../../../components";
import '../style.scss';
import { is_furnishedFilter, property_typeData, is_parkingFilter } from "../../../../Constants/tenantDetails";
import { logger } from "../../../../Utils";
import { bathroomsData, bedroomsData } from "../../../../Constants/propertyDetails";
import ReactSlider from 'react-slider';
import { postAPI } from "../../../../Api";
import { validate } from "../../../../Utils/Validation/validation";
import { FilterValidationSchema } from "./FilterValidationSchema";
import { setSearchLocation, setSearchFilter } from "../../../../Store/actions/LandingPageAction";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

const log = logger("FilterTenantProperty");
const FilterTenantProperty = (props) => {
    const { isShow, handleClose, apiCallBack, setSelectedId } = props;
    const searchLocation = useSelector(state => state.landingPageReducer.getSearchLocation) || '';
    const getSearchFilter = useSelector(state => state.landingPageReducer.getSearchFilter);
    const userProfile = useSelector(state => state.editProfile.editProfileList);
    const [is_verified, setIs_verified] = useState(false);
    const [emailMe, setEmailMe] = useState(false);
    const [email, setEmail] = useState((Cookies.get('access_token') && userProfile) ? (userProfile.data && userProfile.data.email) : '');
    const [property, setProperty] = useState([]);
    const [is_furnished, setIs_furnished] = useState(null);
    const [bedrooms, setBedrooms] = useState([]);
    const [bathrooms, setBathrooms] = useState([]);
    const [parking, setParking] = useState(null);
    const [price, setPrice] = useState([0, 0]);
    const [radius, setRadius] = useState([0, 0]);
    const [address, setAddress] = useState('');
    const [latitude, setLat] = useState(null);
    const [longitude, setLang] = useState(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const myRef = useRef();
    const [clickedOutside, setClickedOutside] = useState(false);
    const dispatch = useDispatch();

    const ClearFilter = (e) => {
        setIs_verified(false);
        setEmailMe(false);
        setEmail("");
        setProperty([]);
        setIs_furnished(null);
        setBedrooms([]);
        setBathrooms([]);
        setParking(null);
        setPrice([0, 0]);
        setRadius([0, 0]);
        dispatch(setSearchLocation(''));
        setAddress('');
        setLat(null);
        setLang(null);
        dispatch(setSearchFilter(null));
        onSubmit(e, 'clear');
    }

    const OnCheckInput = (event) => {
        if (event.target.name === 'is_verified') {
            setIs_verified(event.target.checked)
        } else if (event.target.name === 'emailMe') {
            setEmailMe(event.target.checked)
        } else if (event.target.name === 'email') {
            setEmail(event.target.value)
        }
    }
    const OnMultipleOptionCheck = (event) => {
        const Value = event.target.value;
        const name = event.target.getAttribute('name');
        if (name === 'property') {
            if (property.includes(Value)) {
                const index = property.indexOf(Value);
                property.splice(index, 1);
                setProperty(property => [...property])
            } else {
                setProperty(property => [...property, Value])
            }
        } else if (name === 'bedrooms') {
            if (bedrooms.includes(Value)) {
                const index = bedrooms.indexOf(Value);
                bedrooms.splice(index, 1);
                setBedrooms(bedrooms => [...bedrooms])
            } else {
                setBedrooms(bedrooms => [...bedrooms, Value])
            }
        } else if (name === 'bathrooms') {
            if (bathrooms.includes(Value)) {
                const index = bathrooms.indexOf(Value);
                bathrooms.splice(index, 1);
                setBathrooms(bathrooms => [...bathrooms])
            } else {
                setBathrooms(bathrooms => [...bathrooms, Value])
            }
        }
    }
    const OnSingleOptionCheck = (event) => {
        const Value = event.target.value;
        const name = event.target.getAttribute('name');
        if (name === 'is_furnished') {
            setIs_furnished(Value);
        } else if (name === 'parking') {
            setParking(Value);
        }
    }
    const onSubmit = (event, status) => {
        event.preventDefault();
        if (validate(FilterValidationSchema)) {
            log.info(price);
            const min_price_per_month = price !== null && price[0];
            const max_price_per_month = price !== null && price[1];
            const min_radius = radius !== null && radius[0];
            const max_radius = radius !== null && radius[1];

            let data = null;
            if (status !== 'clear') {
                data = {
                    "is_verified": (is_verified ? 1 : 0),
                    "city_address_or_location": address,
                    "property_type": property,
                    "min_price_per_month": min_price_per_month === 0 ? "" : min_price_per_month,
                    "max_price_per_month": max_price_per_month === 0 ? "" : max_price_per_month,
                    "min_radius": min_radius === 0 ? "" : min_radius,
                    "max_radius": max_radius === 0 ? "" : max_radius,
                    "is_furnished": is_furnished === null ? "" : is_furnished,
                    "bedrooms": bedrooms === null ? "" : bedrooms,
                    "bathrooms": bathrooms === null ? "" : bathrooms,
                    "is_parking_included": parking === null ? "" : parking,
                    "emailMe": emailMe,
                    "email": email,
                    "search_term": (searchLocation ? searchLocation : ""),
                    "latitude": latitude,
                    "longitude": longitude
                }
            }
            dispatch(setSearchFilter(data));
            const url = '/tenant/search-property';
            postAPI(url, data).then(res => {
                log.info("===>res", res)
                if (res.status === 200) {
                    apiCallBack(res);
                    setSelectedId(null)
                }
            })
                .catch(err => {
                    log.info("===>res", err)
                })
        }
    }
    const handlePlaceChanged = (place) => {
        const address = place.formatted_address;
        setAddress(address);
        dispatch(setSearchLocation(address))
        const latValue = place.geometry?.location.lat(),
            lngValue = place.geometry?.location.lng();
        log.info(latValue, lngValue);
        setLat(latValue);
        setLang(lngValue);
    }
    const handleChange = event => {
        event.target.setAttribute('autocomplete', 'off');
        dispatch(setSearchLocation(event.target.value))
        setAddress(event.target.value);
    }
    const handleClickInside = () => { setClickedOutside(false); setIsPopoverOpen(true); }
    useEffect(() => {
        const handleClickOutside = e => {
            if (!myRef.current.contains(e.target)) {
                setClickedOutside(true);
                setIsPopoverOpen(false);
            }
        };
        log.info("open", isPopoverOpen, "clickedOutside", clickedOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [clickedOutside, isPopoverOpen]);

    useEffect(() => {
        if (getSearchFilter) {
            setIs_verified(getSearchFilter?.is_verified || null);
            setAddress(getSearchFilter.city_address_or_location || null);
            setProperty(getSearchFilter.property_type || []);
            setPrice([getSearchFilter.min_price_per_month || 0, getSearchFilter.max_price_per_month || 0]);
            setIs_furnished(getSearchFilter.is_furnished === 0 ? 0 : getSearchFilter.is_furnished || null);
            setBedrooms(getSearchFilter.bedrooms || []);
            setBathrooms(getSearchFilter.bathrooms || []);
            setParking(getSearchFilter.is_parking_included === 0 ? 0 : getSearchFilter.is_parking_included || null);
            setEmailMe(getSearchFilter.emailMe || null);
            setEmail(getSearchFilter.email || '');
            setLat(getSearchFilter.latitude || null);
            setLang(getSearchFilter.longitude || null);
        }
    }, [isShow]);

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleClose}
            title="Set Filter">

            <Button className="clear-btn" onClick={(e) => ClearFilter(e)}>clear filter</Button>
            <form id="Form" onSubmit={onSubmit} autoComplete="off">
                <div className="sidepanel-body filter-tenant">

                    <Row className="rowBorderTop">
                        <Form.Group as={Col} md="12" className="mt-4" controlId="formBasicCheckbox">
                            <div className="display-flex-inline-elm">
                                <div className="form-group">
                                    <div className="custom-control custom-checkbox large">
                                        <input type="checkbox" className="custom-control-input" id={"is_verified"}
                                            name="is_verified"
                                            checked={is_verified} onChange={(e) => OnCheckInput(e)}
                                        />
                                        <label className="custom-control-label pl-3 pt-1" htmlFor={"is_verified"}>rent panda verified</label>
                                    </div>
                                </div>
                                <div onClick={handleClickInside} ref={myRef}>
                                    <Image width="20" height="20" id="button" src={require("../../../../_assets/images/que-icon.svg")} alt="close" />
                                </div>
                                {isPopoverOpen === true && <div className="custom-popover">
                                    <p>Verified properties means that our team has visited the place and made sure there are no scams.</p>
                                    <p>You can rent safely and confidently.</p>
                                </div>}
                            </div>
                        </Form.Group>

                    </Row>
                    <Row className="rowBorderTop autoAddress">
                        <Form.Group as={Col} md="12" className="mt-4">
                            <AutoCompleteAddress
                                id={'address'}
                                state={searchLocation === null ? "" : searchLocation}
                                updateField={(data) => handleChange(data)}
                                handlePlaceChanged={(data) => handlePlaceChanged(data)}
                                addressTitle="City, Address Or Location"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="12" className="mt-4">
                            <Form.Label>Property Type</Form.Label>
                            <ul className="select-options">
                                {property_typeData.map((data) =>
                                    <li onClick={(e) => OnMultipleOptionCheck(e, this)} key={"property_" + data.value} value={data.value} name="property" id="property"
                                        className={(property.includes(data.value)) ? "active" : ""}>{data.title}</li>)}
                            </ul>
                        </Form.Group>

                        {/* https://zillow.github.io/react-slider/#reactslider  */}

                        <Form.Group as={Col} md="12">
                            <Form.Label>Price Per Month</Form.Label>
                            <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="sliding-thumb"
                                trackClassName="sliding-track"
                                value={[parseFloat(price[0] / 100), parseFloat(price[1] / 100)]}
                                min={0}
                                max={100}
                                ariaLabel={['Lower thumb', 'Upper thumb']}
                                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                                renderThumb={(props, state) => {
                                    const priceValue = parseInt(state.valueNow) / 10
                                    return <div {...props}>{priceValue >= 1 ? priceValue : priceValue * 1000} {priceValue >= 1 && 'k'}</div>
                                }}
                                pearling
                                minDistance={0}
                                onChange={val => setPrice([val[0] * 100, val[1] * 100])}
                            />
                        </Form.Group>
                        {/* <Form.Group as={Col} md="12">
                            <Form.Label>Radius</Form.Label>
                            <ReactSlider
                                className="horizontal-slider"
                                thumbClassName="sliding-thumb"
                                trackClassName="sliding-track"
                                defaultValue={[0, 0]}
                                min={0}
                                max={50}
                                ariaLabel={['Lower thumb', 'Upper thumb']}
                                ariaValuetext={state => `Thumb value ${state.vNow}`}
                                renderThumb={(props, state) => <div {...props}>{state.valueNow}km</div>}
                                pearling
                                minDistance={0}
                                onChange={val => setRadius(val)}
                            />
                        </Form.Group> */}
                        <Form.Group as={Col} md="12">
                            <Form.Label>Furnished</Form.Label>
                            <ul className="select-options">
                                {is_furnishedFilter.map((data) =>
                                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"is_furnished_" + data.value} value={data.value} id="is_furnished" name="is_furnished"
                                    className={(is_furnished === data.value) ? "active" : ""}>{data.title}</li>)}
                            </ul>
                        </Form.Group>
                        <Form.Group as={Col} md="12">
                            <Form.Label>How many Bedroom(s)</Form.Label>
                            <ul className="select-options">
                                {bedroomsData.map((data) =>
                                    <li onClick={(e) => OnMultipleOptionCheck(e, this)} key={"bedrooms_" + data.value} value={data.value} name="bedrooms" id="bedrooms"
                                        className={(bedrooms.includes(data.value)) ? "active" : ""}>{data.title}</li>)}
                            </ul>
                        </Form.Group>
                        <Form.Group as={Col} md="12">
                            <Form.Label>How many Bathroom(s)</Form.Label>
                            <ul className="select-options">
                                {bathroomsData.map((data) =>
                                    <li onClick={(e) => OnMultipleOptionCheck(e, this)} key={"bathrooms_" + data.value} value={data.value} name="bathrooms" id="bathrooms"
                                        className={(bathrooms.includes(data.value)) ? "active" : ""}>{data.title}</li>)}
                            </ul>
                        </Form.Group>
                        <Form.Group as={Col} md="12">
                            <Form.Label>Parking Included</Form.Label>
                            <ul className="select-options">
                                {is_parkingFilter.map((data) =>
                                    <li onClick={(e) => OnSingleOptionCheck(e, this)} key={"parking_" + data.value} value={data.value} name="parking" id="parking"
                                        className={(parking === data.value) ? "active" : ""}>{data.title}</li>)}
                            </ul>
                        </Form.Group>
                    </Row>
                    <Row className="rowBorderTop">
                        {/* <Form.Group as={Col} md="12" className="mt-4" controlId="formBasicCheckbox">
                            <div className="form-group">
                                <div className="custom-control custom-checkbox large">
                                    <input type="checkbox" className="custom-control-input" id={"emailMe"}
                                        name="emailMe"
                                        checked={emailMe} onChange={(e) => OnCheckInput(e)}
                                    />
                                    <label className="custom-control-label pl-3 pt-1 form-check-label" htmlFor={"emailMe"}>email me similar new listings</label>
                                </div>
                            </div>
                        </Form.Group> */}
                        {emailMe === true &&
                            <Form.Group as={Col} className="email-elm" md="12">
                                <Form.Control
                                    type="text"
                                    name="email"
                                    id="email"
                                    value={email}
                                    placeholder="Enter Your Email"
                                    onChange={(e) => OnCheckInput(e)}
                                />

                            </Form.Group>
                        }
                    </Row>
                </div>

                <div className="fixed-bottom d-flex justify-content-between">
                    <CancelButton onClick={() => handleClose()} title='Cancel' />
                    <LargeButton title='apply filter' type="submit" style={{ width: '200px' }} />
                </div>
            </form>
        </SidePanel>
    </>);
}
export default FilterTenantProperty;