import React, { useEffect, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import { getAPI, postAPI } from '../../../../../Api';
import { logger } from '../../../../../Utils';
import { AppLayout, ModalBox, PageTitleHeader, PhoneNumberFormat } from '../../../../components';
import Cookies from 'js-cookie';
import './style.scss';

const log = logger('PreviewPartner');
const PreviewPartner = (props) => {
    const placeHolderImg = require("../../../../../_assets/images/placeholder.jpg");
    const [viewData, setViewData] = useState(null);
    const [image_url, setImageData] = useState(placeHolderImg);
    const [action, setAction] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(false);
    const isAdmin = Cookies.get('access_token') && parseInt(Cookies.get('role')) === 1;
    const back = () => {
        props.history.goBack();
    }
    useEffect(() => {
        if (props.match.params.id) {
            let prep = isAdmin ? '/admin' : '';
            getAPI(`${prep}/cms/partners/view/${props.match.params.id}`).then(resData => {
                if (resData.status === 200) {
                    log.info(resData)
                    const resDataView = resData.data.data;
                    setViewData(resDataView)
                    setImageData(resDataView?.image_url ? resDataView.image_url : placeHolderImg)
                    setTimeout(() => {
                        document.querySelector(".image-outer-preview").style.background = (`url(${resDataView?.image_url ? resDataView.image_url : placeHolderImg}) no-repeat center center`);
                    }, 100)
                }
            })
        }
    }, [props.match.params.id, isAdmin])
    let status = '';
    if (viewData) {
        if (viewData.status_info.status_name === 'Publish') {
            status = 'published'
        } else if (viewData.status_info.status_name === 'Unpublish') {
            status = 'unpublished'
        } else if (viewData.status_info.status_name === 'Draft') {
            status = 'draft'
        }
    }
    const openConfirmationModal = (val) => {
        if (val === 'edit') {
            props.history.push(`/cms/partners/edit`)
        } else {
            setAction(val)
            setDeleteModal(true);
        }

    }
    const onClickYes = () => {
        if (action === 'delete') {
            const data = {
                'id': viewData.id
            }
            postAPI(`/admin/cms/partners/delete`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true)
                }
            })
        }
        if (action === 'statusChange') {
            const data = {
                "id": viewData.id,
                "status": (viewData.status_info.status_id === 0 || viewData.status_info.status_id === 2) ? '1' : '2'
            }
            postAPI(`/admin/cms/partners/change-status`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true)
                    if (props.match.params.id) {
                        getAPI(`/admin/cms/partners/view/${props.match.params.id}`).then(resData => {
                            if (resData.status === 200) {
                                log.info(resData)
                                const resDataView = resData.data.data;
                                setViewData(resDataView)
                                setImageData(resDataView?.image_url ? resDataView.image_url : placeHolderImg)
                                log.info(resDataView.image_url)
                                setTimeout(() => {
                                    document.querySelector(".image-outer-preview").style.background = (`url(${resDataView?.image_url ? resDataView.image_url : placeHolderImg}) no-repeat center center`);
                                }, 100)
                            }
                        })
                    }
                }
            })
        }
    }
    const onClickNo = () => {
        setDeleteModal(false)
    }
    const toggleConfirm = () => {
        if (action === 'delete') {
            setConfirmAction(false)
            props.history.push(`/cms/content-management`);
        } else if (action === 'statusChange') {
            setConfirmAction(false)
        }
    }
    return (
        <div>
            <AppLayout noSidebar={true}>
                <div className='preview-partner'>
                    <PageTitleHeader
                        isArrow={true}
                        onClick={() => back()}
                        title={'Preview partner content'}
                    />
                    {viewData &&
                        <>
                            <div className='image-outer-preview'>
                                <div className="bannerImage" data-img-url={image_url}></div>
                            </div>

                            <div className='container mt-5'>
                                <div className='d-flex'>
                                    <h4>{viewData.company_name}</h4>
                                    {(isAdmin && status) && <div className={`partner-status ${status}`}>{status}</div>}
                                </div>
                                <Row className='mt-4'>
                                    <Col md={6} className='list-design'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <Image width={18} height={18} alt='phoneIcon' src={require(`../../../../../_assets/images/phone-icon.svg`)} />
                                            <PhoneNumberFormat phoneNumber={viewData.contact_number} />
                                        </div>
                                        <div className='d-flex align-items-center mb-2'>
                                            <Image width={18} height={18} alt='phoneIcon' src={require(`../../../../../_assets/images/mail-icon.svg`)} />
                                            <span>{viewData.contact_email}</span>
                                        </div>
                                        <div className='d-flex align-items-center mb-2'>
                                            <Image width={18} height={18} alt='phoneIcon' src={require(`../../../../../_assets/images/map-icon.svg`)} />
                                            <span>{viewData.company_address}</span>
                                        </div>
                                        {(viewData.service_requests && viewData.service_requests.length !== 0) &&
                                            <div className='d-flex mb-2'>
                                                <Image width={18} height={18} alt='phoneIcon' className='service-img' src={require(`../../../../../_assets/images/service-icon.svg`)} />
                                                <span className='services'>
                                                    {viewData.service_requests.map(function (obj) {
                                                        return obj.request_type;
                                                    }).join(", ")}
                                                </span>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col md={12}>
                                        <p className='bio'>{viewData.company_bio}</p>
                                    </Col>
                                </Row>
                                {isAdmin && <Row className='mt-4'>
                                    <Col md='12' className='d-flex justify-content-between'>
                                        <div></div>
                                        <div className='d-flex justify-content-between'>
                                            <Button onClick={() => openConfirmationModal('statusChange')} className='btn-save-publish-unpublish' type='submit'>{(status === 'unpublished' || status === 'draft') ? 'published' : 'unpublished'}</Button>
                                            <Button className='btn-save-delete' type='submit' onClick={() => openConfirmationModal('delete')}>delete</Button>
                                            <Button className='btn-publish' type='submit' onClick={() => openConfirmationModal('edit')}>edit</Button>
                                        </div>
                                    </Col>
                                </Row>}
                            </div>
                        </>}
                </div>
            </AppLayout>
            <ModalBox
                show={deleteModal}
                closeButtonHeader={false}
                size='sm'
                type='condition'
                actionbuttontitle={'Yes'}
                buttonaction={() => onClickYes()}
                backbuttontitle={action === 'statusChange' ? 'Not now' : 'Cancel'}
                backaction={() => onClickNo()}
            >
                <p className='order-one-modal'>
                    {action === 'delete' && 'are you sure you want to delete this partner?'}
                    {action === 'statusChange' &&
                        <>
                            {viewData.status_info.status_id === 0 ?
                                'Are you sure you want to publish content? ' :
                                'Are you sure you want to unpublish content?'}
                        </>
                    }
                </p>
            </ModalBox>
            <ModalBox
                show={confirmAction}
                onHide={() => toggleConfirm(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => toggleConfirm(false)}
            >
                <h4>
                    {action === 'delete' && 'Partner has been deleted.'}
                    {action === 'statusChange' &&
                        <>
                            {viewData.status_info.status_id === 0 ?
                                'Content published' :
                                'Content unpublish'}
                        </>
                    }
                </h4>
            </ModalBox>
        </div>

    )

}

export default withRouter(PreviewPartner);