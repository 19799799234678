import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'
import { Card, Image, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Cookies from 'js-cookie';
import { ModalBox } from '../../components';
import "./style.scss";
import { setMyFavourite } from "./helper";

const PropertyCard = (props) => {
  const {
    onFavouriteChange = () => { },
    selectedId,
    id,
    type,
    grid,
    inquiries,
    onClickInquiries,
    viewCount,
    cardType,
    approval,
    isDeactivated,
    hideVerifedStatus,
    isVerifed,
    image_url,
    title,
    // hide_exact_address,
    details,
    price,
    isLoveIcon,
    onClick,
    onClickRepairs,
    onClickNotice,
    isDeleteIcon,
    onDeleteAction,
    notification,
    new_message,
    new_tenancy_application,
    new_viewing_request,
    onVerifyAction,
    landlord_name,
    tenant_name,
  } = props;

  const role = useSelector(state => state.auth.role) || parseInt(Cookies.get('role'));
  const [isFavorite, setIsFavorite] = useState(isLoveIcon)
  const tokenCookie = Cookies.get('access_token');
  const isLoggedIn = tokenCookie;
  const history = useHistory();
  const [confirmAction, setConfirmAction] = useState(false);
  // let titiletoArr = title.split(',');
  // titiletoArr.splice(-2, 2)
  // let formatedTitled = hide_exact_address === 0 && role !== 1 ? titiletoArr.join(',').substr(0, 125) : title;


  const toggleFavourite = async (id) => {
    if (role === 2 || !isLoggedIn) {
      setConfirmAction(true);
      return
    }

    try {
      await setMyFavourite(id)
      if (!props.isFavListPage) {
        setIsFavorite(!isFavorite)
      }
      onFavouriteChange(!isFavorite, id)
    } catch (err) {

    }
  }

  const getSignedUp = () => {
    setConfirmAction(false);
    history.push('/signup/tenant');
  }

  return (
    <>
      <Col
        className="mb-4"
        id={"property_card_" + id}
        xl={grid ? grid : 3}
        lg={6}
        sm={6}
        xs={12}
      // style={{
      //   padding: type !== "list" ? "5px" : "5px 0"
      // }}
      >
        <Card
          className={
            type === "list"
              ? "display-card list"
              : selectedId === id
                ? "display-card selected-card"
                : "display-card"
          }
        >
          <div className="card-header-wrapper">
            <Card.Img
              variant="top"
              src={
                image_url
                  ? image_url
                  : require("../../../_assets/images/placeholder.jpg")
              }
              onClick={onClick}
              style={{ cursor: onClick && "pointer" }}
            />
            <div className="top-details-wrapper">
              {role === 2 && inquiries && inquiries > 0 ? (
                <div className="inquiries-wrapper" onClick={onClickInquiries}>
                  <span className="count">{inquiries}</span>new inquiries
                </div>
              ) : (
                ""
              )}
              
              {role === 2 && viewCount && viewCount > 0 ? (
                <div className="views-wrapper">
                  <Image className="icon" src={require('../../../_assets/images/white-eye-in-black-circle-icon.svg')} alt="view count" />
                  <NumberFormat
                    className="count"
                    value={viewCount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </div>
              ) : ""}

              {price && type !== "list" && (
                <div className="price-wrapper">
                  <span>
                    <NumberFormat
                      value={price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />{" "}
                  / month
                </span>
                </div>
              )}

              {isLoveIcon !== undefined && type !== "list" && (
                <Image
                  className="icon"
                  width="22px"
                  height="20px"
                  src={
                    isFavorite
                      ? require("../../../_assets/images/love-icon.svg")
                      : require("../../../_assets/images/love-icon-white-hollow.svg")
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleFavourite(id)}
                />
              )}
            </div>
            {approval ? approval !== "approved" && approval !== "rented" && (
              <div
                className={
                  approval === "in progress"
                    ? "approval-wrapper inprogress"
                    : `approval-wrapper ${approval}`
                }
              >
                <span>{approval}</span>
              </div>
            ) : (
              isDeactivated ? <div className={"approval-wrapper deactivated"}><span>deactivated</span></div> : ''
            )}
          </div>

          <Card.Body className={cardType === "rentedList" && "pb-0 d-flex flex-column align-content-between"}>
            <div className='top-section'>
              {cardType !== "rentedList" && (
                <>
                  {!hideVerifedStatus && type !== "list" ? (
                    <div className="status-wrapper">
                      {
                        isVerifed === 'unverified' ?
                          <span
                            className='cursor-pointer'
                            onClick={onVerifyAction}
                          >
                            <Image
                              className="icon"
                              width="20px"
                              height="20px"
                              src={require("../../../_assets/images/Unverified.png")}
                            />
                            {isVerifed}
                          </span>
                          : isVerifed === 'verification pending' ?
                            <span
                              style={{ cursor: 'default' }}
                            >
                              <Image
                                className="icon"
                                width="20px"
                                height="20px"
                                src={require("../../../_assets/images/Unverified.png")}
                              />
                              {isVerifed}
                            </span>
                            :
                            <span
                              style={{ cursor: 'default' }}
                              className="success"
                            >
                              <Image
                                className="icon"
                                width="20px"
                                height="20px"
                                src={require("../../../_assets/images/verified.png")}
                              />
                              {isVerifed}
                            </span>
                      }
                      {/* <span
                      onClick={onVerifyAction}
                      className={
                        !isVerifed || isVerifed === "unverified" ? (onVerifyAction ? "cursor-pointer" : "") : "success"
                      }
                    >
                      {isVerifed !== null && (
                        <Image
                          className="icon"
                          width="20px"
                          height="20px"
                          src={
                            !isVerifed || isVerifed === "unverified"
                              ? require("../../../_assets/images/Unverified.png")
                              : require("../../../_assets/images/verified.png")
                          }
                        />
                      )}

                      {isVerifed === "verified" && "verified"}
                      {isVerifed === "unverified" && "unverified"}
                    </span> */}

                      {notification &&
                        <div className="list-inline d-flex justify-content-end">
                          <div className="list-li">
                            <div className="icon-wrap">
                              <Image
                                width="20px"
                                height="14px"
                                alt="list-grid-icon"
                                src={require(`../../../_assets/images/messages/messages.svg`)}
                              />
                              {new_message && <div className="message-active"></div>}
                            </div>
                          </div>
                          <div className="list-li">
                            <div className="icon-wrap">
                              <Image
                                width="20px"
                                height="14px"
                                alt="list-grid-icon"
                                src={require(`../../../_assets/images/messages/calender.svg`)}
                              />
                              {new_tenancy_application && <div className="message-active"></div>}
                            </div>
                          </div>
                          <div className="list-li">
                            <Image
                              width="20px"
                              height="14px"
                              alt="list-grid-icon"
                              src={require(`../../../_assets/images/messages/application.svg`)}
                            />
                            {new_viewing_request && <div className="message-active"></div>}
                          </div>
                        </div>
                      }
                    </div>
                  ) : (
                      ""
                    )}
                </>
              )}

              {cardType === "rentedList" && (
                <span className="rented-title">{role === 2 ? 'Tenant' : 'Landlord'}: {role === 2 ? tenant_name : landlord_name}</span> //TBD
              )}
              {price && type === "list" && <label>{price}</label>}

              <h2 className="property-title" title={title}
                onClick={onClick}
                style={{ cursor: onClick && "pointer" }}
              >{title}</h2>

              <div className="details-wrapper">
                <div style={{ width: "100%" }}>
                  <span className="specification" title={details}>{details}</span>
                  {isVerifed && type === "list" && (
                    <span className="verified">{isVerifed}</span>
                  )}
                </div>
                {isLoveIcon && type === "list" && (
                  <div className="icon-wrapper">
                    <Image
                      className="icon"
                      width="20px"
                      height="20px"
                      src={require("../../../_assets/images/love-icon.svg")}
                    />
                  </div>
                )}

                {isDeleteIcon && type === "list" && (
                  <div className="icon-wrapper">
                    <Image
                      className="icon"
                      width="20px"
                      height="20px"
                      src={require("../../../_assets/images/delete-icon.svg")}
                    />
                  </div>
                )}

                {onDeleteAction && (
                  <div className="icon-wrapper">
                    <Image
                      className="icon"
                      width="20px"
                      height="20px"
                      src={require("../../../_assets/images/delete-icon.svg")}
                      onClick={onDeleteAction}
                    />
                  </div>
                )}
              </div>
            </div>

            {cardType === "rentedList" && (
              <div className="d-flex mt-auto justify-content-center rented-btn-container">
                <div className="rented-listing-btn btn-brdr-right cursorPointer" onClick={onClickRepairs}>
                  <span>
                    <img
                      src={require("../../../_assets/images/request-icon.svg")} alt=""
                    />
                  Repairs
                </span>
                  {props.request_count > 0 && <span className="notify-icon1">{props.request_count}</span>}
                </div>
                <div className="rented-listing-btn cursorPointer" onClick={onClickNotice}>
                  <span>
                    <img
                      src={require("../../../_assets/images/notice-icon.svg")} alt=""
                    />
                  Notice
                </span>
                  {props.notice_count > 0 && <span className="notify-icon2">{props.notice_count}</span>}
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      <ModalBox
        show={confirmAction}
        onHide={() => setConfirmAction(false)}
        size='sm'
        actionbuttontitle={'get started'}
        buttonaction={() => getSignedUp()}
      >
        <h4>Sign up for free</h4>
        <p>create an account and get all the tools<br />you need to find the perfect home </p>
      </ModalBox>
    </>
  );
};
export default PropertyCard;
