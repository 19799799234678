import React, { useEffect, useState } from "react";
import './style.scss';
import { logger, toaster } from "../../../Utils";
import { LargeButton, SidePanel, TenantProfileDisplay, ModalBox } from "../../components";
import { getAPI, postAPI } from "../../../Api";
import { useSelector, useDispatch } from "react-redux";
import { setIsProfileCompleted, setIsLeaseFromTenantApplication } from "../../../Store/actions/MessageDetailAction";

const log = logger("ViewTenantProfile");
const ViewTenantProfile = (props) => {
	const { isShow, handleClose, title, setInvite_apply, setLeaseOptionsScreen } = props;
	const [profile, setProfile] = useState(null);
	const [modalShow, setModalShow] = useState(false);
	const [disable, setDisable] = useState(false)

	const dispatch = useDispatch();
	const {conversationId, existingTenancyApplicationId, tenantId}  = useSelector(state => state.messageDetailsReducer);

	useEffect(() => {
		if (tenantId) {
			const url=`/landlord/tenant-profile/${tenantId}?conversation_id=${conversationId}`
			getAPI(url).then(response => {
				log.info("profile data", response.data.data);
				const res = response.data.data;
				setProfile(res);
				dispatch(setIsProfileCompleted(response.data.data.basic_info.is_profile_completed))
			})
				.catch(error => {
				});
		}
	}, [isShow]);

	const onSubmit = () => {
		handleClose();
		setInvite_apply(true);
	}

	const leaseAction = () => {
		handleClose();
		dispatch(setIsLeaseFromTenantApplication(true));
		setLeaseOptionsScreen(true);
	}

	const onCompleteProfile = () => {
		setDisable(true)
		const url = '/landlord/ask-tenant-complete-profile';
		const data = {
			"conversation_id": conversationId,
			"tenant_id": tenantId
		}

		postAPI(url, data).then(response => {
			if (response.status !== 200) {
				toaster('error', response.response.data.message)
			} else if (response.status === 200) {
				log.info('data', response.data);
				setDisable(false)
				setModalShow(false)
			}
		})
		.catch(error => {
			setDisable(false)
		});
	}

	return (<>
		<SidePanel extraClasses={"sidepanel-bg"} isShow={isShow} isHide={handleClose} title={title}>
			<div className="sidepanel-body" style={{top:'80px'}}>
				<TenantProfileDisplay profileData={profile} />
			</div>
			<div className="fixed-bottom d-flex justify-content-between dark-footer">
				{(profile && profile.basic_info && (
					(profile.basic_info.is_consent_payment_completed) ?
					<LargeButton onClick={() => leaseAction('create',0)} title={'create lease'} />
					:(profile.basic_info.is_profile_completed !== 0) ?
						(existingTenancyApplicationId ? 
							<LargeButton onClick={() => leaseAction('create',0)} title={'create lease'} />
							:<LargeButton onClick={() => onSubmit()} title='invite tenant to apply' />)

					: (profile.basic_info.request_for_profile_completion) ?
						(existingTenancyApplicationId ? 
							<LargeButton onClick={() => leaseAction('create',0)} title={'create lease'} />
							:<LargeButton onClick={() => onSubmit()} title='invite tenant to apply' />)
						// <div className='buttonLikeText grayButton' style={{width: '100%'}}>
						// 	<p>requested for profile completion</p>
						// </div>
					:<LargeButton onClick={() => {
						handleClose()
						setModalShow(true)
					}} title='ask tenant to complete profile' />
				))}
			</div>
		</SidePanel>
		<ModalBox
			show={modalShow}
			onHide={() => setModalShow(false)}
			size="md"
			type="condition"
			actionbuttontitle={'Yes'}
			buttonaction={() => {
				onCompleteProfile()
			}}
			disable={disable}
			backbuttontitle={'No'}
			backaction={() => {
				setModalShow(false)
			}}
		>
			<p className="modal-confirmation-text">you’ve submitted {profile && profile.basic_info && profile.basic_info.name} to complete their profile</p>
			<p>providing profile information might take about 2-4 days before they complete it</p>
		</ModalBox>
	</>);
}

export default ViewTenantProfile;