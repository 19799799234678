import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './index.scss';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import history from './history';

//Website & Authentication Imports
import LandlordHome from './Website/Landlord/Home';
// import TenantHome from './Website/Tenant/Home';
import PropertyManagementInquiry from './Website/PropertyManagement/Inquiry';
import HowItWorksTenant from './Website/HowItWorks/Tenant';
import HowItWorksLandlord from './Website/HowItWorks/Landlord';
import PortalsAndAuxillary from './Website/PortalsAndAuxillary';
import ForRealtors from './Website/ForRealtors';
import ForPandaPartners from './Website/ForPandaPartners';
import ForPropertyManagers from './Website/ForPropertyManagers';
import AboutUs from './Website/AboutUs';
import TermsAndConditions from './Website/TermsAndConditions';
import PrivacyPolicy from './Website/PrivacyPolicy';
import Resources from './Website/Resources';
import BlogResources from './Website/Resources/BlogResources';
import FormsResources from './Website/Resources/FormsResources';
import PartnerResources from './Website/Resources/PartnerResources';
import TenantHome from './Website/TenantHome';
import SignUp from './Website/Authentication/Signup';
import Login from './Website/Authentication/Login';
import Verification from './Website/Authentication/Verification';
import ForgotPasswordFlow from './Website/Authentication/ForgotPasswordFlow'
import ResetPassword from './Website/Authentication/ResetPassword'
import Profile from './App/components/Profile';
import EditProfile from './App/components/AuthFlow/EditProfile';
import ChangePassword from './App/components/AuthFlow/ChangePassword';
import ForgetPassword from './App/components/AuthFlow/ForgetPassword';
import Authenticate from './App/components/AuthFlow/Authenticate';
import ForgetChangePassword from './App/components/AuthFlow/ForgetChangePassword';
import { setLoggedIn } from './Store/actions/AuthAction';
import VerificationLoggedIn from './App/components/AuthFlow/VerificationLoggedIn/VerificationLoggedIn';
//Landlord Imports
import LandlordDashboard from './App/Landlord/Dashboard';
import CreateListing from './App/Landlord/CreateListing';
import Showings from './App/Landlord/Showings';
import CreateLease from './App/Landlord/CreateLease';
import ViewAppendixLandlord from './App/Landlord/CreateLease/ViewAppendixLandlord'
// import PropertyDetails from './App/Landlord/PropertyDetails';

//Tenant Imports
import TenantDashboard from './App/Tenant/Dashboard';
import TenantProfile from './App/Tenant/Profile';
import SearchListing from './App/Tenant/SearchListing';
import TenantPropertyDetails from './App/Tenant/TenantPropertyDetails';
import FavouritesList from './App/Tenant/FavouritesList';

//Admin Imports
import AdminDashboard from './App/Admin/Dashboard';
import ProfileDetail from './App/Admin/ProfileDetail/ProfileDetail';
import { MessageTenant } from './App/Tenant/TenantPropertyDetails/MessageTenant.js';
import RootPropertyDetails from './App/Landlord/PropertyDetails/RootPropertyDetails';
import ListingManagement from './App/Admin/ListingManagement';
import ContentManagement from './App/Admin/ContentManagement';
import ListManagementPropertyDetails from './App/Admin/ListingManagement/ListManagementPropertyDetails';
import RootTenantPropertyDetails from './App/Tenant/TenantPropertyDetails/RootTenantPropertyDetails';
import PhotographyTips from './Website/PhotographyTips';
import AdminLandlordProfile from './App/Admin/ProfileDetail/AdminLandlordProfile';
import AdminTenantProfile from './App/Admin/ProfileDetail/AdminTenantProfile';
import RootLeaseDetails from './App/Admin/ProfileDetail/LeaseDetails/RootLeaseDetails';
import AdminListingDetails from './App/Admin/ProfileDetail/AdminListingDetails/AdminListingDetails';
import RootCreateLease from './App/Admin/ProfileDetail/LeaseDetails/RootCreateLease';
import UserConfirmation from './App/components/UnsavedChangesModal';
import ReviewLeaseDetails from './App/Admin/ProfileDetail/LeaseDetails/ReviewLeaseDetails';
import MaintainanceRepair from './App/Landlord/MaintainanceRepair/MaintainanceRepair';
import RequestRepair from './App/Landlord/MaintainanceRepair/Repairs/RequestRepair';
import RequestManagementTable from './App/Admin/RequestManagement/RequestManagementTable';
import RentedListing from './App/Landlord/MaintainanceRepair/RentedListing/RentedListing';
import MaintainanceDetails from './App/Admin/ProfileDetail/MaintainanceDetails/MaintainanceDetails';
import InspectionDetails from './App/Admin/ProfileDetail/InspectionDetails/InspectionDetails';
import MoveInInspection from './App/Landlord/MaintainanceRepair/Inspection/MoveInInspection';
import NoticesDetails from './App/Admin/ProfileDetail/NoticesDetails/NoticesDetails';
import PandaPartners from './App/Landlord/MaintainanceRepair/Repairs/PandaPartners';
import DeactivatedListing from './App/Landlord/DeactivatedListing';
import { ViewAppendix } from './App/components';
import CouponManagement from './App/Admin/CouponManagement';
import CreateNotice from './App/Landlord/Notices/CreateNotice';
import ViewNotice from './App/Landlord/Notices/ViewNotice';
import NoticeInfo from './App/Landlord/Notices/NoticeInfo';
import CreateFaq from './App/Admin/ContentManagement/CMS/Faq/CreateFaq';
import Blog from './App/Admin/ContentManagement/CMS/Blog';
import PreviewBlog from './App/Admin/ContentManagement/CMS/Blog/PreviewBlog';
import PreviewFaq from './App/Admin/ContentManagement/CMS/Faq/PreviewFaq';
import CreatePartners from './App/Admin/ContentManagement/CMS/Partners/CreatePartners';
import PreviewPartner from './App/Admin/ContentManagement/CMS/Partners/PreviewPartner';
import Forms from './App/Admin/ContentManagement/CMS/Forms';
import PageNotFound from './App/components/NotFound/NotFound';


export const loading = () => (
  <div className='default-image-loader'>
    <Spinner animation='grow' variant='primary' />
  </div>
);

function PrivateRoute({ children, auth, component, dispatch, ...rest }) {
  let loggedIn = true;
  if (!auth.userId && !auth.loggedIn) {
    const tokenCookie = Cookies.get('access_token');
    if (tokenCookie) {
      loggedIn = true;
      dispatch(setLoggedIn(tokenCookie));
    } else {
      loggedIn = false;
    }
  }
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              history: { history },
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

class Routes extends Component {
  render() {
    const role = Number(Cookies.get('role'));

    return (
      <BrowserRouter history={history} getUserConfirmation={(message, callback) =>
        UserConfirmation(message, callback)}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path='/' component={TenantHome} />
            <Route exact path='/signup/:slug?' component={SignUp} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/verification' component={Verification} />
            <Route exact path='/forgotpassword' component={ForgotPasswordFlow} />
            <Route exact path='/forgetchangepassword' component={ForgetChangePassword} />
            <Route exact path='/reset-password' component={ResetPassword} />
            <Route exact path='/landlord' component={LandlordHome} />
            <Route exact path='/tenant' component={TenantHome} />
            <Route exact path='/property-management/inquiry' component={PropertyManagementInquiry} />
            <Route exact path='/how-it-works/tenant' component={HowItWorksTenant} />
            <Route exact path='/how-it-works/landlord' component={HowItWorksLandlord} />
            <Route exact path='/portals-and-auxillary/:slug?' component={PortalsAndAuxillary} />
            <Route exact path='/for-realtors' component={ForRealtors} />
            <Route exact path='/for-panda-partners' component={ForPandaPartners} />
            <Route exact path='/for-property-managers' component={ForPropertyManagers} />
            <Route exact path='/about-us' component={AboutUs} />
            <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            <Route exact path='/search-result' component={SearchListing} />
            <Route exact path={'/property-details/:property_id?/:property_details_id?/:slug?/tour'} component={RootTenantPropertyDetails} />
            <Route exact path='/resources' component={Resources} />
            <Route exact path='/resources/blog' component={BlogResources} />
            <Route exact path='/resources/forms' component={FormsResources} />
            <Route exact path='/resources/partners' component={PartnerResources} />
            <Route exact path='/resources/blog/preview/:id?' component={PreviewBlog} />
            <Route exact path='/resources/partners/preview/:id?' component={PreviewPartner} />

            {/* protected */}
            <PrivateRoute
              exact
              path={'/dashboard'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 2) && <LandlordDashboard />}
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/dashboard/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 1) && <AdminDashboard />}
              {(role === 3) && <TenantDashboard />}
              {(role === 2) && <PageNotFound />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/coupon-management/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 1) && <CouponManagement />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/profile-detail/:user_type?/:user_id?/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 1) && <ProfileDetail />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/profile-detail/landlord/:user_id?/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <AdminLandlordProfile />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/profile-detail/tenant/:user_id?/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <AdminTenantProfile />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/lease-detail/:user_type?/:lease_id?/:user_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <RootLeaseDetails />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/maintainance-details/:user_type?/:maintainance_id?/:user_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <MaintainanceDetails />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/notice-details/:user_type?/:notice_id?/:user_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <NoticesDetails />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/inspection-details/:user_type?/:inspection_id?/:user_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <InspectionDetails />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/review-lease-detail/:user_type?/:lease_id?/:user_id?/:property_id?/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <ReviewLeaseDetails />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/profile-detail/listings/:user_type?/:user_id?/:property_id?/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <AdminListingDetails />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/create-listing/:property_id?/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <CreateListing />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/edit-listing/:property_id?/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <CreateListing />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/create-lease/:property_id?/:property_details_id?/:lease_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <CreateLease />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/view-appendix/:property_id?/:property_details_id?/:lease_id?/:user_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 1) && <ViewAppendix />}
              {(role === 2) && <ViewAppendixLandlord />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/showings'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 2) && <Showings />}
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/rented-listing'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 2) && <RentedListing />}
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/maintainance-repair/:property_id?/:property_details_id?/:slug?/:lease_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <MaintainanceRepair role={role} />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/repair-request/:property_id?/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <RequestRepair role={role} />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/create-notice/:property_id?/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <CreateNotice />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/edit-notice/:property_id?/:property_details_id?/:notice_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <CreateNotice />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/view-notice/:property_id?/:property_details_id?/:notice_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <ViewNotice auth={this.props.auth} />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/notice-info/:notice_type?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <NoticeInfo />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/panda-partners'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <PandaPartners />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/move-in-inspection/:property_id?/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {((role === 2) || (role === 3)) && <MoveInInspection />}
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/move-out-inspection/:property_id?/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {((role === 2) || (role === 3)) && <MoveInInspection />}
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/view-move-in-inspection/:inspectionId?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {((role === 2) || (role === 3)) && <MoveInInspection />}
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/view-move-out-inspection/:inspectionId?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {((role === 2) || (role === 3)) && <MoveInInspection />}
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/view-repair-request/:requestId'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <RequestRepair role={role} preview={true} />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/deactivated-listing'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 2) && <DeactivatedListing />}
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/property-details/:property_id?/:property_details_id?/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 1) && <ListManagementPropertyDetails />}
              {(role === 2) && <RootPropertyDetails />}
              {(role === 3) && <RootTenantPropertyDetails />}
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/listing-details/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <TenantPropertyDetails />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/cms/forms/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <Forms />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/cms/blog/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <Blog />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/cms/partners/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <CreatePartners />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/cms/preview-blog/:id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <PreviewBlog />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/cms/blog'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <Blog />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/create-faq'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <CreateFaq />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/preview-faq'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <PreviewFaq />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/preview-partner/:id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <PreviewPartner />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/editprofile'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <EditProfile />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/authenticate'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <Authenticate />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/changepassword'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <ChangePassword />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/forgot-password'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <ForgetPassword />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/verify'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <VerificationLoggedIn />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/profile'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 2) && <Profile />}
              {(role === 3) && <TenantProfile />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/forgetpassword'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              <ForgetPassword />
            </PrivateRoute>

            <PrivateRoute
              exact
              path={'/search-listing'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 3) && <SearchListing />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/favourites'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 3) && <FavouritesList />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/messages/:property_id?/:property_details_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 3) && <MessageTenant />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/listing-management/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 1) && <ListingManagement />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/cms/:slug?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 1) && <ContentManagement />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/request-management'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 1) && <RequestManagementTable />}
            </PrivateRoute>
            <PrivateRoute
              exact
              path={'/create-lease/:user_id?/:property_id?/:property_details_id?/:component?/:lease_id?'}
              auth={this.props.auth}
              dispatch={this.props.dispatch}
            >
              {(role === 1) && <RootCreateLease />}
            </PrivateRoute>
            {/* end protected */}

            <Route
              exact
              path='/photography-tips'
              component={PhotographyTips}
            />
            <Route component={PageNotFound} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

const select = (state) => ({
  auth: state.auth,
});

export default connect(select)(Routes);
