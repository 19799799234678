import React, { Component } from "react";
import { Card } from "react-bootstrap";
import RpImage from "../../../../../components/Image";
import "./style.scss";

class Resources extends Component {
  render() {
    const { key, resource } = this.props;
    return (
      <Card className="resources-wrapper" key={key}>
        <div className="resources-img">
          <RpImage
            className=""
            width="100%"
            src={resource.image_url ? resource.image_url : require("../../../../../../_assets/images/placeholder.jpg")}
          />
        </div>

        <Card.Body className="">
          <blockquote className="mb-0">
            <p className="blockquote-text resources-title">{resource.headline}</p>
            <footer>
              <span className="resources-description">
                {resource.excerpt}
              </span>
            </footer>
          </blockquote>
        </Card.Body>
      </Card>
    );
  }
}

export default Resources;
