import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Accordion, Card } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import { getAPI } from '../../../../../Api';
import { logger } from '../../../../../Utils';
import { AppLayout, PageTitleHeader } from '../../../../components';
import './style.scss';

const log = logger('PreviewFaq');
const PreviewFaq = (props) => {
    const [faqList, setFaqList] = useState([]);
    const [accordianId, setAccordionId] = useState(null);
    const back = () => {
        props.history.goBack();
    }
    const clickExterior = (id) => {
        const check = id === accordianId ? null : id
        setAccordionId(check);
    }
    useEffect(() => {
        getAPI('/admin/cms/faq/list').then(res => {
            if (res.status === 200) {
                setFaqList(res.data.data);
            }
        })
    }, [])
    const tagsList = ['Rent', 'property Management']
    return (
        <>
            <AppLayout noSidebar={true}>
                <div className='preview-faq'>
                    <PageTitleHeader
                        isArrow={true}
                        onClick={() => back()}
                        title={'Preview faq'}
                    />
                    <h2 className='text-center'>Frequently Asked Question</h2>
                    <div className='container'>
                        <Row>
                            <Col md={12}>
                                <Accordion defaultActiveKey={faqList.length !== 0 && faqList[0].title}>
                                    {faqList.length !== 0 ?
                                        <>
                                            {faqList.map((faq, i) => {
                                                return <Card className='mb-3' key={'faq_' + i}>
                                                    <Card.Header className='d-flex justify-content-between'>
                                                        <span className='accordian-heading'>{faq.title}</span>
                                                        <Accordion.Toggle as={Button} className='inspection-btn' variant="link" eventKey={faq.title} onClick={() => clickExterior(faq.id)}>
                                                            <img className="next-arrow"
                                                                src={accordianId === faq.id ? require('../../../../../_assets/images/faq-arrow2.svg') : require('../../../../../_assets/images/faq-arrow1.svg')} alt="next" />
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={faq.title}>
                                                        <Card.Body className='section-card'>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <div className='d-flex justify-content-between'>
                                                                        <span>Category:  {faq.category.name}</span>
                                                                        {faq.cms_tags.length !== 0 &&
                                                                            <span className='tag-list'>tag:
                                                                            <ul className='select-options'>
                                                                                    {faq.cms_tags.map((data, i) =>
                                                                                        <li key={'tag_' + i} name='tag' id='tag'
                                                                                            className={'active'}>{data.name}</li>)}
                                                                                </ul></span>}
                                                                    </div>
                                                                </Col>
                                                                <Col md={12} className='faq-desc'>{faq.description}</Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            })}
                                        </> : ""}
                                </Accordion>
                            </Col>
                        </Row>
                    </div>
                </div>
            </AppLayout>
        </>
    )

}

export default withRouter(PreviewFaq);