import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { FILE_API_URL } from '../../../../Constants/endPoints';
import { logger, toaster, validate, dateFormat, numberFormatSanitizerInt } from '../../../../Utils';
import { postAPI } from '../../../../Api';
import { CostNumberFormat, SingleOptionCheck, FileBrowse, DatePickerComponent, ModalBox } from '../../';
import { CreateLeaseDetailsSchema } from "./CreateLeaseSchema/CreateLeaseDetailsSchema";
import { subDays, addMonths, addYears, lastDayOfMonth, differenceInDays, getDaysInMonth } from 'date-fns';
import moment from "moment";
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { rentDeposite, leaseType, rentPaid, utilitiesPaidBy, parkingType, getUtilitiesHydro, getUtilitiesHeat, getUtilitiesWater, rentPaidType, tenancyAgreement } from '../../../../Constants/LeaseConstants';
import { getUtilitiesAndAmenities } from '../../../../Constants/propertyDetails';
import PDFViewerComponent from '../../PDFViewerComponent/PDFViewerComponent';
import Loader from '../../Loader';


const log = logger('CreateLeaseDetails');
const CreateLeaseDetails = (props) => {
    const { setCurrentStep, property_id, property_details_id, leaseData, lease_id } = props;
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const [rent, setRent] = useState('');
    const [taking_rent_deposit, setDeposit] = useState(0);
    const [lease_type, setLeaseType] = useState(propertyDetails.details[0].lease_duration);
    const [utility_electricity_pay_by, setUtilityElectric] = useState(1);
    const [utility_heat_pay_by, setUtilityHeat] = useState(1);
    const [utility_water_pay_by, setUtilityWater] = useState(1);
    const [is_parking_available, setParkingAvailable] = useState(0);
    const [parking_type, setParkingType] = useState(1);
    const [parking_spot, setParkingSpot] = useState('');
    const [parking_price, setParkingPrice] = useState('');
    const [aminities, setAminities] = useState([1]);
    const [other_animities_type, setOtherAmenitiesType] = useState('');
    const [start_date, setStartDate] = useState(new Date());
    const [end_date, setEndDate] = useState(new Date());
    const [tenancy_agreement, setTenancy_agreement] = useState(0);
    // const [full_name_contact, setFullName] = useState('');
    // const [other_service_utilities1, setOther_service_utilities1] = useState('');
    // const [other_service_utilities2, setOther_service_utilities2] = useState('');
    // const [other_service_utilities3, setOther_service_utilities3] = useState('');
    const [rent_pay_method, setRent_pay_method] = useState('');
    const [rent_paid_on, setRent_paid_on] = useState('1st');
    const [rent_paid_on_day_of_each, setRent_paid_on_day_of_each] = useState(1);
    const [rent_payable_to, setRent_payable_to] = useState(null);
    const [administrative_charge, setAdministrative_charge] = useState(0);
    const [amenitiesData, setAmenitiesData] = useState([]);

    const [is_rent_deposit, setRentDeposit] = useState(1);
    const [is_rent_deposit_amount, setRent_deposit_amount] = useState(0);
    const [is_rent_discounted, setRentDiscounted] = useState(1);
    const [rent_discount_amount, setDiscountedAmount] = useState('');
    const [rent_discount_description, setRentDiscountDesc] = useState('');
    const [is_key_deposite, setKeyDeposite] = useState(1);
    const [key_deposite_amount, setKeyDepositeAmount] = useState('');
    const [is_smoking_allowed, setSmoking_allowed] = useState(0);
    const [lease_pdf, setLease_pdf] = useState({ file: '', percent: 0 });
    const [errorClass, setErrorClass] = useState(false);
    const [errorRentMethodClass, setErrorRentMethodClass] = useState(false);
    const [uploadData, setUploadData] = useState(null);
    const [gas, setGas] = useState(0);
    const [AC, setAc] = useState(0);
    const [storageSpace, setStorageSpace] = useState(0);
    const [laundry, setLaundry] = useState(0);
    const [guestParking, setGuestParking] = useState(0);
    const [saveLoading, setBtnDisable] = useState(false);
    const [first_rental_partial_period, setFirstRentalPartialPeriod] = useState(0);
    const [partial_cover_start_date, setCoverStartDate] = useState(new Date());
    const [partial_cover_end_date, setCoverEndDate] = useState(new Date());
    const [partial_payment_date, setPartialPaymentDate] = useState(new Date());
    const [partial_cover_amount, setPartialCoverAmount] = useState('');
    const [pdfModal, setPdfModal] = useState(false);
    // const [amenities, setAmenities] = useState([]);

    const [other_service_utilities, setOther_service_utilities] = useState([]);
    const [other_service_utilities_extra, setOther_service_utilities_extra] = useState('');


    const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const profileDetails = useSelector(state => state.editProfile.editProfileList);

    const [saveState, setSaveState] = useState('');
    const [is_lease_updated, setLease_updated] = useState(false);
    const [isSaveEnable, setSaveEnable] = useState(false);
    const [rent_paid_on_day_of_each_other, setRent_paid_on_day_of_each_other] = useState("")
    const [showImageLoading, setShowImageLoading] = useState(false)

    log.info('lease_pdf.file', lease_pdf.file);

    const backToPrevPage = () => {
        setCurrentStep(0);
        props.history.push({
            pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
            state: {
                lease_id: lease_id,
                step: 0
            }
        });
    }

    // function mergeArrayObjects(arr1, arr2) {
    //     return arr1.map((item, i) => {
    //         if (item.id === arr2[i].id) {
    //             //merging two objects
    //             return Object.assign({}, item, arr2[i])
    //         }
    //     })
    // }

    const onAmenitiesToggle = (id) => {
        const amenitiesDataCopy = [...amenitiesData]
        setAmenitiesData(amenitiesDataCopy.map((i) => {
            if (i.id === id) {
                i.allow = i.allow ? 0 : 1
            }
            return i
        }))
    }

    const OnSingleOptionCheck = (event) => {
        const Value = event.target.value;
        const name = event.target.getAttribute('name');
        log.info(name, " ===> ", Value);

        if (name === 'tenancy_agreement') {
            setTenancy_agreement(Value);
        } else if (name === 'rent_pay_method') {
            setRent_pay_method(Value);
            setErrorRentMethodClass(false)
        } else if (name === 'rent_paid_on_day_of_each') {
            setRent_paid_on_day_of_each(Value);
        } else if (name === 'is_smoking_allowed') {
            setSmoking_allowed(Value);
        } else if (name === 'is_key_deposite') {
            setKeyDeposite(Value);
        } else if (name === 'is_rent_deposit') {
            if (Value === 1) {
                setRent_deposit_amount(rent)
            }
            setRentDeposit(Value);
        } else if (name === 'is_rent_discounted') {
            setRentDiscounted(Value);
        } else if (name === 'lease_type') {
            log.info('hello', Value)
            setLeaseType(Value);
        } else if (name === 'utility_electricity_pay_by') {
            setUtilityElectric(Value);
        } else if (name === 'utility_heat_pay_by') {
            setUtilityHeat(Value);
        } else if (name === 'utility_water_pay_by') {
            setUtilityWater(Value);
        } else if (name === 'is_parking_available') {
            setParkingAvailable(Value);
        } else if (name === 'parking_type') {
            setParkingType(Value);
        } else if (name === 'gas') {
            setGas(Value);
        } else if (name === 'AC') {
            setAc(Value);
        } else if (name === 'storageSpace') {
            setStorageSpace(Value);
        } else if (name === 'laundry') {
            setLaundry(Value);
        } else if (name === 'guestParking') {
            setGuestParking(Value);
        } else if (name === 'first_rental_partial_period') {
            setFirstRentalPartialPeriod(Value)
        }
        setSaveEnable(true);
    }

    const handleDate = (eventDate, setState, setNextDate) => {
        setState(eventDate);
        if (lease_type === 1) {
            setNextDate(subDays(addYears(eventDate, 1), 1))
        } else if (lease_type === 2) {
            setNextDate(subDays(addMonths(eventDate, 1), 1))
        }
        setSaveEnable(true);
    }

    const handleDate2 = (eventDate, setState) => {
        setState(eventDate);
        var result = lastDayOfMonth(new Date(eventDate))
        // log.info(result)
        setCoverEndDate(result)
        setPartialPaymentDate(eventDate)
        partialCalculator(eventDate, result)
        setSaveEnable(true);
    }
    const partialCalculator = (eventDate, result) => {
        var noOfDaysRemain = differenceInDays(
            new Date(result), new Date(eventDate)
        )
        var dayInMonth = getDaysInMonth(new Date(eventDate));
        // (rent/daysin month) * No of days remaining
        let rent = unitDetails && unitDetails?.price_per_month
        let partialAmount = ((Number(rent) / Number(dayInMonth)) * (Number(noOfDaysRemain) + 1))
        log.info('partialAmount', partialAmount)
        setPartialCoverAmount(partialAmount)
    }

    const handleDrop = (file, setImage) => {
        const token = `Bearer ${Cookies.get('access_token')}`
        const headers = {
            'Authorization': token,
            'Content-Type': 'multipart/form-data'
        }
        setShowImageLoading(true)

        file.map((dataFile) => {
            setUploadData(dataFile);
            let formdata = new FormData();
            formdata.append('file', dataFile);
            let url = FILE_API_URL;
            Axios.post(
                url, formdata, {
                headers: headers,
                onUploadProgress: progressEvent => {
                    var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
                    if (percent >= 100) {
                        setImage({ percent: percent });
                        return file;
                    } else {
                        setImage({ percent: percent });
                        return null;
                    }
                }
            })
                .then(response => {
                    setImage({ file: response.data.data.url })
                    setErrorClass(false)
                    setShowImageLoading(false)

                })
                .catch(error => {
                    setShowImageLoading(false)

                });
            return dataFile;
        })
        setSaveEnable(true);
    }
    const removeFile = (index, setFile) => {
        setFile('')
    }

    const onSubmit = (event) => {
        if (tenancy_agreement === 1 && lease_pdf.file === "") {
            setErrorClass(true);
        }
        if (rent_pay_method === '') {
            setErrorRentMethodClass(true);
            setTimeout(() => {
                document.getElementById('errorRentMethodClass').scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, 300)
        }

        event.preventDefault();
        if (validate(CreateLeaseDetailsSchema) && !errorClass && rent_pay_method !== '') {
            setBtnDisable(true)
            const updatedAmenities = amenitiesData.map((data) => {
                return { id: data.id, allow: data.allow }
            })
            const data = {
                'lease_id': lease_id,
                'property_id': property_id,
                'property_detail_id': property_details_id,
                'lease_type': lease_type,
                'lease_start_date': start_date,
                'lease_end_date': end_date,
                'rent': rent,
                'utility_electricity_pay_by': utility_electricity_pay_by,
                'utility_heat_pay_by': utility_heat_pay_by,
                'utility_water_pay_by': utility_water_pay_by,
                'is_parking_available': is_parking_available,
                'parking_type': parking_type,
                'parking_spot': parking_spot,
                'parking_price': Number(parking_price),
                // 'aminities': aminities,
                'other_animities_type': other_animities_type,
                'is_smoking_allowed': is_smoking_allowed,
                'tenancy_agreement': tenancy_agreement,
                'lease_appendix': lease_pdf && lease_pdf.file,
                'rent_paid_on': rent_paid_on,
                'rent_paid_on_day_of_each': rent_paid_on_day_of_each,
                'rent_paid_on_day_of_each_other': rent_paid_on_day_of_each_other,

                'rent_payable_to': rent_payable_to,
                'rent_pay_method': rent_pay_method,
                'first_rental_partial_period': first_rental_partial_period,
                'partial_cover_start_date': dateFormat(partial_cover_start_date),
                'partial_cover_end_date': dateFormat(partial_cover_end_date),
                'partial_cover_amount': partial_cover_amount,
                'partial_payment_date': dateFormat(partial_payment_date),
                'administrative_charge': administrative_charge,
                'is_rent_deposit': is_rent_deposit,
                'is_rent_deposit_amount': is_rent_deposit ? is_rent_deposit_amount : 0,
                'is_key_deposite': is_key_deposite,
                'key_deposite_amount': key_deposite_amount,
                'is_rent_discounted': is_rent_discounted,
                'rent_discount_amount': rent_discount_amount,
                'rent_discount_description': rent_discount_description,

                'other_service_utilities': [
                    // other_service_utilities1,
                    // other_service_utilities2,
                    // other_service_utilities3,
                    other_service_utilities_extra
                ],
                'amenities': updatedAmenities,
                'action': saveState
            }
            log.info("data", data);
            const url = '/landlord/listing/lease/details';
            postAPI(url, data).then(res => {
                setBtnDisable(false)
                if (res.status === 200) {
                    saveState !== 'update' && isSaveEnable && toaster('success', 'Data Saved Successfully!');
                    if (saveState === 'next') {
                        setCurrentStep(2);
                        props.history.push({
                            pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
                            state: {
                                lease_id: lease_id,
                                step: 2
                            }
                        });
                    } else if (saveState === 'update') {
                        setLease_updated(true);
                    }
                } else if (res.response.status === 400) {
                    log.info(res.response)
                    toaster('error', res.response.data.message);
                } else if (res.response.status === 422) {
                    toaster('error', res.response.data.message);
                }
            })
        }
    }

    const handleViewPdf = () => {
        setPdfModal(!pdfModal);
    }

    const handleCostChange = (e) => {
        log.info("setPartialCoverAmount", e);
        setPartialCoverAmount(numberFormatSanitizerInt(e.target.value));
    }


    log.info('partial_cover_amount', partial_cover_amount);

    useEffect(() => {
        const fetchedData = leaseData;
        if ((fetchedData && fetchedData.lease_deatils) && profileDetails) {
            setRent(fetchedData.lease_deatils.rent === '0.0' ? fetchedData.lease_deatils.rent :
                unitDetails !== null ? unitDetails?.price_per_month || fetchedData.lease_deatils.rent : fetchedData.lease_deatils.rent
            );
            setDeposit(fetchedData.lease_deatils.is_rent_deposit !== null ? fetchedData.lease_deatils.is_rent_deposit : 0);
            setLeaseType(fetchedData.lease_deatils.lease_type !== null ? fetchedData.lease_deatils.lease_type : propertyDetails.details[0].lease_duration);
            setUtilityElectric(fetchedData.lease_deatils.utility_electricity_pay_by !== null ? fetchedData.lease_deatils.utility_electricity_pay_by :
                unitDetails !== null ? getUtilitiesHydro(unitDetails?.utilities) : 1
            );
            setUtilityHeat(fetchedData.lease_deatils.utility_heat_pay_by !== null ? fetchedData.lease_deatils.utility_heat_pay_by :
                unitDetails !== null ? getUtilitiesHeat(unitDetails?.utilities) : 1
            );
            setUtilityWater(fetchedData.lease_deatils.utility_water_pay_by !== null ? fetchedData.lease_deatils.utility_water_pay_by :
                unitDetails !== null ? getUtilitiesWater(unitDetails?.utilities) : 1
            );
            setParkingAvailable((fetchedData?.lease_deatils?.is_parking_available) || (unitDetails?.is_parking_included)?1:0);

            setParkingType(fetchedData.lease_deatils.parking_type !== null ? fetchedData.lease_deatils.parking_type : 1);
            setParkingSpot((fetchedData?.lease_deatils?.parking_spot) || (unitDetails?.parking_slot));
            setParkingPrice(fetchedData.lease_deatils.parking_price !== null ? fetchedData.lease_deatils.parking_price : '');
            setAminities(fetchedData.lease_deatils.amenities.length !== 0 ? fetchedData.lease_deatils.amenities : [1]);
            setRent_payable_to(fetchedData.lease_deatils.rent_payable_to !== null ? fetchedData.lease_deatils.rent_payable_to : profileDetails !== null ? profileDetails.data.name : '');
            setPartialCoverAmount(fetchedData.lease_deatils.partial_cover_amount !== null ? fetchedData.lease_deatils.partial_cover_amount : "");
            setAdministrative_charge(fetchedData.lease_deatils.administrative_charge !== null ? fetchedData.lease_deatils.administrative_charge : "20")
            setEndDate(fetchedData.lease_deatils.lease_end_date !== null ? moment(fetchedData.lease_deatils.lease_end_date).toDate() : end_date)
            setTenancy_agreement(fetchedData.lease_deatils.tenancy_agreement)
            setRent_paid_on(fetchedData.lease_deatils.rent_paid_on !== null ? fetchedData.lease_deatils.rent_paid_on : "1st")
            setRent_paid_on_day_of_each(fetchedData.lease_deatils.rent_paid_on_day_of_each)
            setRent_paid_on_day_of_each_other(fetchedData.lease_deatils.rent_paid_on_day_of_each_other)

            setRent_pay_method(fetchedData.lease_deatils.rent_pay_method !== null ? fetchedData.lease_deatils.rent_pay_method : '')
            setFirstRentalPartialPeriod(fetchedData.lease_deatils.first_rental_partial_period)
            setCoverStartDate(fetchedData.lease_deatils.partial_cover_start_date !== null ? moment(fetchedData.lease_deatils.partial_cover_start_date).toDate() : "")
            setCoverEndDate(fetchedData.lease_deatils.partial_cover_end_date !== null ? moment(fetchedData.lease_deatils.partial_cover_end_date).toDate() : "")
            setPartialPaymentDate(fetchedData.lease_deatils.partial_payment_date !== null ? moment(fetchedData.lease_deatils.partial_payment_date).toDate() : "")
            setRentDeposit(fetchedData.lease_deatils.is_rent_deposit !== null && fetchedData.step_completed>1
                ? fetchedData.lease_deatils.is_rent_deposit
                : 1)
            setRent_deposit_amount(fetchedData.lease_deatils.is_rent_deposit_amount !== null ? fetchedData.lease_deatils.is_rent_deposit_amount : unitDetails !== null ? unitDetails?.price_per_month : '')
            fetchedData.lease_deatils.amenities && fetchedData.lease_deatils.amenities.map((data) => {
                if (data.id === 8) {
                    setGas(data.allow)
                }
                if (data.id === 9) {
                    if (unitDetails && unitDetails?.amenities?.includes(1)) {
                        setAc(1)
                    } else {
                        setAc(data.allow)
                    }
                }
                if (data.id === 10) {
                    setStorageSpace(data.allow)
                }
                if (data.id === 11) {
                    if (unitDetails && unitDetails?.laundry !== 0) {
                        setLaundry(1)
                    } else {
                        setLaundry(data.allow)
                    }
                }
                if (data.id === 12) {
                    setGuestParking(data.allow)
                }
            })
            setLease_pdf({
                file: fetchedData.lease_deatils.lease_appendix !== null ? fetchedData.lease_deatils.lease_appendix : ""
            })
            // setOther_service_utilities(fetchedData.lease_deatils.other_service_utilities && fetchedData.lease_deatils.other_service_utilities[0])
            setOther_service_utilities_extra(fetchedData.lease_deatils.other_service_utilities && fetchedData.lease_deatils.other_service_utilities)
            // setOther_service_utilities3(fetchedData.lease_deatils.other_service_utilities && fetchedData.lease_deatils.other_service_utilities[2])
        }

        if (fetchedData && fetchedData.other_details) {
            setRentDiscounted(fetchedData.other_details.is_rent_discounted !== null ? fetchedData.other_details.is_rent_discounted : 1);
            setDiscountedAmount(fetchedData.other_details.rent_discount_amount);
            setRentDiscountDesc(fetchedData.other_details.rent_discount_description);
            setKeyDeposite(fetchedData.other_details.is_key_deposite !== null ? fetchedData.other_details.is_key_deposite : 1);
            setKeyDepositeAmount(fetchedData.other_details.key_deposite_amount);
            setSmoking_allowed(fetchedData.other_details.is_smoking_allowed)
        }
    }, [leaseData]);

    useEffect(() => {
        const leaseAmenities = leaseData && leaseData.lease_deatils && leaseData.lease_deatils.amenities
        const unitAmenities = unitDetails?.amenities
        let leaseAmenitiesId = []
        log.info('unit amenities', unitDetails?.amenities)
        let allowedAmenities = unitAmenities ? [...unitAmenities] : []
        if (leaseAmenities && leaseAmenities.length > 0) {
            for (let el in leaseAmenities) {
                if (leaseAmenities[el].allow === 1) {
                    leaseAmenitiesId.push(leaseAmenities[el].id)
                }
            }
            allowedAmenities = [...leaseAmenitiesId]
            // const allowedAmenities = [...leaseAmenitiesId, ...unitAmenities]
            // uniqueAllowedAmenities = [...new Set(allowedAmenities)]
        }

        (async () => {
            const { amenitiesData = [] } = await getUtilitiesAndAmenities()
            const newAmenitiesData = amenitiesData.map((data) => {
                if (allowedAmenities.includes(data.id)) {
                    return ({ ...data, allow: 1 })
                }
                return ({ ...data, allow: 0 })
            })
            setAmenitiesData(newAmenitiesData)
        })()

    }, [leaseData])

    useEffect(() => {
        log.info("amenitiesData", amenitiesData);
        unitDetails && amenitiesData && amenitiesData.filter(data => data.value !== 1).map(filter => {
            if (unitDetails?.amenities.includes(filter.value)) {
                setOther_service_utilities(amenities => [...amenities, filter.title])
            }
            return filter
        })
    }, [unitDetails])

    useEffect(() => {
        if (lease_type === 1) {
            setEndDate(subDays(addYears(start_date, 1), 1))
        } else if (lease_type === 2) {
            setEndDate(subDays(addMonths(start_date, 1), 1))
        }

    }, [start_date, lease_type])

    const otherUtilities = (data) => {
        setOther_service_utilities_extra(data)
    }

    // const parkingPriceHandler = (e) => {
    //     log.info("value", e.target.value)
    //     const re = /\.?\d{0,2}?$/;
    //     let value = e.target.value
    //     if (e.target.value.includes('$')) {
    //         value = e.target.value.substring(1)
    //     }
    //     if (value === '' || re.test(value)) {
    //         setParkingPrice(value)
    //     }
    // }

    let maxLength = 25;
    return (<>
        {!leaseData ? <Loader /> :
            <Form id='Form' onSubmit={onSubmit}>
                <div className='inner-wrapper'>
                    <div className='body-container'>
                        <div className='body-header'>
                            <h3 className='text-left lease-heading'>lease details</h3>
                            {leaseData && leaseData.is_sent_to_tenant ?
                                <button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>
                                : <button disabled={!isSaveEnable} className='save-link' style={{ alignSelf: 'flex-start', background: 'none', border: '0', padding: '0' }} type='submit' onClick={() => setSaveState('save')}>Save</button>}
                        </div>

                        <Row>
                            <Form.Group as={Col} md='5'>
                                <Form.Label>How long is the lease</Form.Label>
                                <SingleOptionCheck
                                    mapData={leaseType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'lease_type'} setParam={lease_type}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md='6'>
                                <Form.Label>Please indicate the start date {(lease_type === 0 || lease_type === 1) ? ' and end date' : ''}</Form.Label>
                                <Row>
                                    <Col md='6'>
                                        <DatePickerComponent
                                            name="start_date"
                                            id="start_date"
                                            value={start_date}
                                            handleDateChange={(date) => handleDate(date, setStartDate, setEndDate)}
                                            displayText={"Select Date"}
                                            minDate={new Date()}
                                        />
                                    </Col>
                                    {(lease_type === 0 || lease_type === 1) &&
                                        <Col md='6'>
                                            <DatePickerComponent
                                                name="end_date"
                                                id="end_date"
                                                value={end_date}
                                                handleDateChange={(end_date) => handleDate(end_date, setEndDate)}
                                                displayText={"Select Date"}
                                                minDate={start_date}
                                                disableValue={lease_type === 0 ? false : true}
                                            />
                                        </Col>}

                                </Row>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='10'>
                                <Form.Label>Are there any additional terms about the tenancy agreement?</Form.Label>
                                <Row>
                                    <Col md='12' className={'mb-3'}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <SingleOptionCheck
                                                mapData={tenancyAgreement}
                                                OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)}
                                                name={'tenancy_agreement'}
                                                setParam={tenancy_agreement}
                                                extraClass={'d-inline-flex mb-0'}
                                            />
                                        </div>
                                    </Col>
                                    {tenancy_agreement === 0 &&
                                        <Col className='mb-2' xl={"12"} md={'12'}>
                                            <div className="ln-pdf-file-box" style={{ cursor: 'pointer' }} onClick={handleViewPdf}>
                                                <div className="ln-pdf-file-box-left">
                                                    <Image className="icon" src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
                                                </div>
                                                <div className='ln-pdf-file-box-right'>
                                                    <div className="ln-pdfname">
                                                        {leaseData && leaseData.lease_deatils.rent_panda_lease_appendix.split('/').pop().length > maxLength ? leaseData && leaseData.lease_deatils.rent_panda_lease_appendix.split('/').pop().substring(0, maxLength) + '...' : leaseData && leaseData.lease_deatils.rent_panda_lease_appendix.split('/').pop()}
                                                    </div>
                                                    <div className="ln-pdfname-static-word">
                                                        <b>View PDF</b>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className='d-flex justify-content-start' >

                                            </span>
                                            <ModalBox
                                                show={pdfModal}
                                                onHide={() => handleViewPdf()}
                                                size="xl"
			                                    className="cu-full-modal"
                                            >
                                                <PDFViewerComponent PDFUrl={leaseData && leaseData.lease_deatils.rent_panda_lease_appendix} />
                                            </ModalBox>
                                        </Col>
                                    }
                                    {tenancy_agreement === 1 ?
                                        <Col md='5'>
                                            {(lease_pdf && lease_pdf.file) ?
                                                <Col className='confirm-section mb-2 d-flex justify-content-between details'>
                                                    <span className='d-flex justify-content-start'>
                                                        <Image className="icon" src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
                                                        <div className='file-details d-flex'>
                                                            {lease_pdf.file.split('/').pop().length > maxLength ? lease_pdf.file.split('/').pop().substring(0, maxLength) + '...' : lease_pdf.file.split('/').pop()}
                                                        </div>
                                                    </span>
                                                    <Image className="icon cursor-pointer" title='Delete' onClick={(e) => removeFile(e, setLease_pdf)} src={require("../../../../_assets/images/red-delete-icon.svg")} alt="Delete" />
                                                </Col>
                                                : <FileBrowse
                                                    handleDrop={(e) => handleDrop(e, setLease_pdf)}
                                                    size={'sm'}
                                                    title={'Upload Documents'}
                                                    percent={lease_pdf.percent}
                                                    multiple={false}
                                                    fileType={'.pdf'}
                                                    disable={false}
                                                    imageData={lease_pdf.file && lease_pdf.file}
                                                    removeFile={(e) => removeFile(e, setLease_pdf)}
                                                    lableClass={'custom-lable'}
                                                    section={'upload-lease'}
                                                    uploadData={uploadData && uploadData}
                                                    errorClass={errorClass === true ? "error-upload-box" : ""}
                                                    showImageLoading={showImageLoading}
                                                />}
                                        </Col> : ""}
                                </Row>
                            </Form.Group>
                        </Row>

                        <hr />
                        <h3 className='text-left lease-heading'>more details about the rent</h3>
                        <Row>
                            <Form.Group as={Col} md='5'>
                                <Form.Label>Rent is to be paid on the:</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='rent_paid_on'
                                    id='rent_paid_on'
                                    value={rent_paid_on}
                                    placeholder='Type here'
                                    onChange={(e) => setRent_paid_on(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group as={Col} md='3'>
                                <Form.Label>Day of each:</Form.Label>
                                <SingleOptionCheck
                                    mapData={rentPaidType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'rent_paid_on_day_of_each'} setParam={rent_paid_on_day_of_each}
                                />
                            </Form.Group>

                            {rent_paid_on_day_of_each === 2 &&

                                <Form.Group as={Col} md='3'>
                                    <Form.Label>{"Other:"}</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='rent_paid_on_day_of_each_other'
                                        id='rent_paid_on_day_of_each_other'
                                        value={rent_paid_on_day_of_each_other}
                                        placeholder='Example: Weekly'
                                        onChange={(e) => setRent_paid_on_day_of_each_other(e.target.value)}
                                    />
                                </Form.Group>

                            }
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='5'>
                                <Form.Label>Base rent for the rental property</Form.Label>
                                <CostNumberFormat
                                    name={'rent'}
                                    id={'rent'}
                                    classname='form-control'
                                    value={rent ? rent : ''}
                                    updateField={(e) => {
                                        const value=e.target.value
                                        setRent(numberFormatSanitizerInt(value))
                                        if (is_rent_deposit) {
                                            setRent_deposit_amount(numberFormatSanitizerInt(value))
                                        }

                                    }}
                                    pre='$'
                                    placeholder={'Please indicate $ amount'}
                                // disabled={true}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='2'>
                                <Form.Label>Is parking available</Form.Label>
                                <SingleOptionCheck
                                    mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_parking_available'} setParam={is_parking_available}
                                />
                            </Form.Group>
                            {is_parking_available === 1 &&
                                <>
                                    <Form.Group as={Col} md='3'>
                                        <Form.Label>Parking Type</Form.Label>
                                        <SingleOptionCheck
                                            mapData={parkingType} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'parking_type'} setParam={parking_type}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md='2'>
                                        <Form.Label># of spots</Form.Label>
                                        <Form.Control
                                            type='text'
                                            name='parking_spot'
                                            id='parking_spot'
                                            value={parking_spot}
                                            placeholder='Type here'
                                            onChange={(e) => setParkingSpot(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md='3'>
                                        <Form.Label>Price for Parking (per month)</Form.Label>
                                        <CostNumberFormat
                                            name={'parking_price'}
                                            id={'parking_price'}
                                            classname='form-control'
                                            value={parking_price}
                                            updateField={(e) => setParkingPrice(numberFormatSanitizerInt(e.target.value))}
                                            pre='$'
                                            placeholder={'$$$'}
                                            minValue={-1}
                                            decimalScale={2}
                                            decimalSeparator='.'
                                            disabled={parking_spot === 0}
                                        />
                                    </Form.Group>

                                </>
                            }
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='5'>
                                <Form.Label>Rent is payable to:</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='rent_payable_to'
                                    id='rent_payable_to'
                                    value={rent_payable_to}
                                    placeholder='Type here'
                                    onChange={(e) => setRent_payable_to(e.target.value)}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='8'>
                                <Form.Label>Rent will be paid using the following methods:</Form.Label>
                                <div id='errorRentMethodClass'>
                                    <SingleOptionCheck
                                        mapData={rentPaid} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'rent_pay_method'} setParam={rent_pay_method} errMessage={errorRentMethodClass ? 'Rent payment method is required' : ''} isInvalid={errorRentMethodClass}
                                    />
                                </div>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='3'>
                                <Form.Label>Is the first rental period a partial period?</Form.Label>
                                <SingleOptionCheck
                                    mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'first_rental_partial_period'} setParam={first_rental_partial_period}
                                />
                            </Form.Group>

                            {first_rental_partial_period === 1 &&
                                <Form.Group as={Col} md='9'>
                                    <Form.Label>This partial covers the rental of the unit from:</Form.Label>
                                    <Row>
                                        <Col md='5'>
                                            <DatePickerComponent
                                                name="partial_cover_start_date"
                                                id="partial_cover_start_date"
                                                value={partial_cover_start_date}
                                                handleDateChange={(date) => handleDate2(date, setCoverStartDate)}
                                                displayText={"Select Date"}
                                                minDate={new Date()}
                                            />
                                        </Col>
                                        <span className='mt-2'>to</span>
                                        <Col md='5'>
                                            <DatePickerComponent
                                                name="partial_cover_end_date"
                                                id="partial_cover_end_date"
                                                value={partial_cover_end_date}
                                                // handleDateChange={(end_date) => handleDate2(end_date, setCoverEndDate)}
                                                displayText={"Select Date"}
                                                disableValue={true}
                                                minDate={partial_cover_start_date}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md='5'>
                                            <Form.Label className='mt-4'>The tenant will have to pay an amount of:</Form.Label>
                                            <CostNumberFormat
                                                name={'partial_cover_amount'}
                                                id={'partial_cover_amount'}
                                                classname='form-control'
                                                value={partial_cover_amount ? partial_cover_amount : ''}
                                                updateField={(e) => handleCostChange(e)}
                                                pre='$'
                                                placeholder='Type here'
                                                disabled={false}
                                                decimalScale={2}
                                                decimalSeparator='.'
                                            />
                                        </Col>
                                        <span className='mt-6'>on</span>
                                        <Col md='5'>
                                            <Form.Label className='mt-4'>Date of partial payment</Form.Label>
                                            <DatePickerComponent
                                                name="partial_payment_date"
                                                id="partial_payment_date"
                                                value={partial_payment_date}
                                                displayText={"Select Date"}
                                                disableValue={true}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>}
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='6'>
                                <Form.Label>Administrative charge for non-sufficient funds (maximum $20)</Form.Label>
                                <NumberFormat
                                    thousandSeparator={true}
                                    prefix='$'
                                    allowNegative={false}
                                    allowEmptyFormatting={false}
                                    allowLeadingZeros={false}
                                    value={administrative_charge ? administrative_charge : ''}
                                    onChange={(e) => setAdministrative_charge(numberFormatSanitizerInt(e.target.value))}
                                    className='form-control'
                                    placeholder={'$20'}
                                    name={'administrative_charge'}
                                    id={'administrative_charge'}
                                    isAllowed={(value) => {
                                        const { formattedValue, floatValue } = value;
                                        return formattedValue === '' || (floatValue > 0 && floatValue <= 20);
                                    }}
                                />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='7'>
                                <Form.Label>Is smoking allowed? (If allowed, please ensure to include it to your lease appendix)</Form.Label>
                                <SingleOptionCheck
                                    mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_smoking_allowed'} setParam={is_smoking_allowed}
                                />
                            </Form.Group>
                        </Row>

                        <hr />
                        <h3 className='text-left lease-heading'>deposits and discounts</h3>
                        <Row>
                            <Form.Group as={Col} md='3'>
                                <Form.Label>Is there a rent deposit?</Form.Label>
                                <SingleOptionCheck
                                    mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_rent_deposit'} setParam={is_rent_deposit}
                                />
                            </Form.Group>

                            {is_rent_deposit === 1 &&
                                <Form.Group as={Col} md='5'>
                                    <Form.Label>Rent Deposit Amount</Form.Label>
                                    <CostNumberFormat
                                        name={'is_rent_deposit_amount'}
                                        id={'is_rent_deposit_amount'}
                                        classname='form-control'
                                        value={is_rent_deposit_amount ? is_rent_deposit_amount : ''}
                                        decimalSeparator={'.'}
                                        decimalScale={2}
                                        updateField={(e) => setRent_deposit_amount(numberFormatSanitizerInt(e.target.value))}
                                        pre='$'
                                        placeholder={'$200'}
                                    />
                                </Form.Group>}
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='3'>
                                <Form.Label>Is there a key deposit?</Form.Label>
                                <SingleOptionCheck
                                    mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_key_deposite'} setParam={is_key_deposite}
                                />
                            </Form.Group>

                            {is_key_deposite === 1 &&
                                <Form.Group as={Col} md='5'>
                                    <Form.Label>Key Deposit Amount <em>(can only be amount to replace key)</em></Form.Label>
                                    <CostNumberFormat
                                        name={'key_deposite_amount'}
                                        id={'key_deposite_amount'}
                                        classname='form-control'
                                        value={key_deposite_amount ? key_deposite_amount : ''}
                                        updateField={(e) => setKeyDepositeAmount(numberFormatSanitizerInt(e.target.value))}
                                        pre='$'
                                        placeholder={'$200'}
                                        decimalSeparator={'.'}
                                        decimalScale={2}
                                    />
                                </Form.Group>}
                        </Row>

                        <Row>
                            <Form.Group as={Col} md='3'>
                                <Form.Label>Is there a rent discount</Form.Label>
                                <SingleOptionCheck
                                    mapData={rentDeposite} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'is_rent_discounted'} setParam={is_rent_discounted}
                                />
                            </Form.Group>
                            {is_rent_discounted === 1 &&
                                <>
                                    <Form.Group as={Col} md='5'>
                                        <Form.Label>Amount</Form.Label>
                                        <CostNumberFormat
                                            name={'rent_discount_amount'}
                                            id={'rent_discount_amount'}
                                            classname='form-control'
                                            value={rent_discount_amount ? rent_discount_amount : ''}
                                            updateField={(e) => setDiscountedAmount(numberFormatSanitizerInt(e.target.value))}
                                            pre='$'
                                            placeholder={'$200'}
                                            decimalSeparator={'.'}
                                            decimalScale={2}
                                        />

                                        <Form.Label className="mt-3">Rent Discount Description</Form.Label>
                                        <Form.Control
                                            type='text'
                                            as='textarea'
                                            rows={5}
                                            name='rent_discount_description'
                                            id='rent_discount_description'
                                            value={rent_discount_description}
                                            placeholder='Write description here...'
                                            onChange={(e) => setRentDiscountDesc(e.target.value)}
                                        />
                                    </Form.Group>
                                </>
                            }
                        </Row>


                        <hr />
                        <h3 className='text-left lease-heading'>who pays for utilities?</h3>
                        <Row>
                            <Form.Group as={Col} md='3'>
                                <Form.Label>Electricity</Form.Label>
                                <SingleOptionCheck
                                    mapData={utilitiesPaidBy} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'utility_electricity_pay_by'} setParam={utility_electricity_pay_by}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md='3'>
                                <Form.Label>Heat</Form.Label>
                                <SingleOptionCheck
                                    mapData={utilitiesPaidBy} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'utility_heat_pay_by'} setParam={utility_heat_pay_by}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md='3'>
                                <Form.Label>Water</Form.Label>
                                <SingleOptionCheck
                                    mapData={utilitiesPaidBy} OnSingleOptionCheck={(data) => OnSingleOptionCheck(data)} name={'utility_water_pay_by'} setParam={utility_water_pay_by}
                                />
                            </Form.Group>
                        </Row>
                        <hr />

                        <h3 className='text-left lease-heading'>amenities</h3>
                        <Row>
                            {
                                // leaseData?.lease_deatils?.amenities.length === 0 ? 
                                amenitiesData && amenitiesData.map((data, i) => {
                                    return (
                                        <Form.Group as={Col} md='2'>
                                            <Form.Label>{data.name}</Form.Label>
                                            <SingleOptionCheck
                                                mapData={rentDeposite} OnSingleOptionCheck={() => onAmenitiesToggle(data.id)} name={data.name} setParam={data.allow}
                                            />
                                        </Form.Group>
                                    )
                                })
                                // :
                                // leaseData?.lease_deatils?.amenities.map((data, i) => {
                                //     return (
                                //         <Form.Group as={Col} md='2'>
                                //             <Form.Label>{amenitiesData?.map(el => {
                                //                 if(el.id === data.id){
                                //                     return el.name
                                //                 }
                                //             })}</Form.Label>
                                //             <SingleOptionCheck
                                //                 mapData={rentDeposite} OnSingleOptionCheck={() => onAmenitiesToggle(data.id)} name={data.id} setParam={data.allow}
                                //             />
                                //         </Form.Group>
                                //     )
                                // })
                            }
                        </Row>

                        {/* <Row>
                        <Form.Control
                            type='text'
                            name='other_service_utilities'
                            id='other_service_utilities'
                            value={
                                other_service_utilities && other_service_utilities.map((data, i) => {
                                    return data
                                }).join(', ')
                            }
                            disabled
                        > 
                        </Form.Control>
                    </Row> */}

                        {/* {other_service_utilities.map((data, i) => {
                        return <Row key={i}><Form.Group as={Col} md='4'>
                            <Form.Control
                                type='text'
                                name='other_service_utilities'
                                id='other_service_utilities'
                                value={data}
                                placeholder='Type here'
                                disabled={true}
                            // onChange={(e) => setOther_service_utilities(e.target.value)}
                            />
                        </Form.Group></Row>
                    })} */}
                        <Row>
                            <Form.Label className="w-100 m-2">Others</Form.Label>
                            <Form.Group as={Col} md='4'>
                                <Form.Control
                                    type='text'
                                    name='other_service_utilities_extra'
                                    id='other_service_utilities_extra'
                                    value={other_service_utilities_extra}
                                    placeholder='Fan, TV, Sofa'
                                    onChange={(e) => otherUtilities(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                    </div>
                </div>

                <div className='container-footer'>
                    <Button variant='secondary' onClick={() => backToPrevPage()}>Back</Button>
                    {leaseData && leaseData.is_sent_to_tenant ?
                        <Button disabled={saveLoading === false ? false : true} variant='success' type='submit' onClick={() => setSaveState('update')}>Update {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                        : <Button disabled={saveLoading === false ? false : true} variant='success' type='submit' onClick={() => setSaveState('next')}>Next {saveLoading === true && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                    }
                </div>
            </Form>
        }
        <ModalBox
            show={is_lease_updated}
            onHide={() => setLease_updated(false)}
            size="sm"
            actionbuttontitle={"got it"}
            buttonaction={() => {
                setLease_updated(false);
                setCurrentStep(3);
            }}
        >
            <h4>your lease has been updated</h4>
            <p>the tenant has been <br /> notified of the changes. </p>
        </ModalBox>
    </>);
}

export default withRouter(CreateLeaseDetails);
