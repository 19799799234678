export const ReferenceValidationSchema = {
    name: [
        { rule: "required", message: "Name required" },
    ],
    relationship: [
        { rule: "required", message: "Relationship required" }
    ],
    phone_number: [
        { rule: "required", message: "Phone number required" },
        { rule: "phone", length:10, message: "Phone number must be of 10 digits" },
    ]
}