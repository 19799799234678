import { logger } from "../Utils"

const log = logger('Constant')
export const rentPaid = [
    { value: 0, title: 'Direct Deposit' },
    { value: 1, title: 'Cheque' },
    { value: 2, title: 'Cash' },
    { value: 3, title: 'Credit Card' },
    { value: 4, title: 'E-Transfer' },
    { value: 5, title: 'Other' }
]
export const rentDeposite = [
    { title: "Yes", value: 1 }, { title: "No", value: 0 }
]

export const tenancyAgreement = [
    { title: "Use Rent Panda Lease Appendix", value: 0 }, { title: "Upload My Own", value: 1 }
]

export const leaseType = [
    { title: "1 Year lease", value: 1 }, { title: "Monthly Lease", value: 2 }, { title: "Other", value: 0 }
]

export const utilitiesPaidBy = [
    { title: "Landlord", value: 1 }, { title: "Tenant", value: 2 }
]

export const parkingType = [
    { title: "Indoor", value: 1 }, { title: "Outdoor", value: 2 }
]
export const leaseAnimities = [
    { title: "A/C", value: 1 },
    { title: "TV", value: 2 },
    { title: "Balcony", value: 3 },
    { title: "Laundry", value: 4 },
    { title: "Internet", value: 5 },
    { title: "Guest Parking", value: 6 },
    { title: "Phone", value: 7 },
    { title: "Additional Storage Space", value: 8 },
    { title: "Other", value: 9 },
]

export const leaseAppliances = [
    { title: "Stove", value: 1 },
    { title: "Dishwasher", value: 2 },
    { title: "Microwave", value: 3 },
    { title: "In Suite Washer", value: 4 },
    { title: "In Sute Dryer", value: 5 },
    { title: "Oven", value: 6 },
]
export const promotionPlansList = [
    { title: "1 day", value: 1 },
    { title: "1 week", value: 2 },
    { title: "1 month", value: 3 },
]

export const getPromotionPlan = (value) => {
    var title = "";
    promotionPlansList.map((data) => {
        if (value === data.value) {
            title = (data.title)
        }
        return data;
    })
    return title;
}
export const leaseTypeDisplay = (value) => {
    var title = "";
    leaseType.map((data) => {
        if (value === data.value) {
            title = (data.title)
        }
        return data;
    })
    return title;
}

export const amenitiesDisplay = (value) => {
    var title = "";
    leaseAnimities.map((data) => {
        if (value === data.value) {
            title = (data.title)
        }
        return data;
    })
    return title;
}

export const appliancesDisplay = (value) => {
    var title = "";
    leaseAppliances.map((data) => {
        if (value === data.value) {
            title = (data.title)
        }
        return data;
    })
    return title;
}

export const rentPaidDisplay = (value) => {
    var title = "";
    rentPaid.map((data) => {
        if (value === data.value) {
            title = (data.title)
        }
        return data;
    })
    return title;
}
export const parkingTypeDisplay = (value) => {
    var title = "";
    parkingType.map((data) => {
        if (value === data.value) {
            title = (data.title)
        }
        return data;
    })
    return title;
}

export const aminitiesDisplay = (value) => {
    var title = "";
    if (value === 8) {
        title = 'Gas'
    } else if (value === 9) {
        title = 'A/C'
    } else if (value === 10) {
        title = 'Additional Storage Space'
    } else if (value === 11) {
        title = 'Laundry'
    } else if (value === 12) {
        title = 'Guest Parking'
    }
    return title;
}

export const aminitiesGrid = (value) => {
    var title = "";
    if (value === 10) {
        title = '3'
    } else if (value === 12) {
        title = '2'
    } else if (value === 9 || value === 8 || value === 11) {
        title = '1'
    }
    return title;
}

export const getUtilitiesHydro = (value) => {
    var title = null;
    if (value?.includes(2)) {
        title = 1
        log.info('getUtilitiesHydro', title)
    } else {
        title = 2
        log.info('getUtilitiesHydro', title)
    }
    return title;
}
export const getUtilitiesHeat = (value) => {
    var title = null;
    if (value?.includes(1)) {
        title = 1
        log.info('getUtilitiesHydro', title)
    } else {
        title = 2
        log.info('getUtilitiesHydro', title)
    }
    return title;
}
export const getUtilitiesWater = (value) => {
    var title = null;
    if (value?.includes(3)) {
        title = 1
        log.info('getUtilitiesHydro', title)
    } else {
        title = 2
        log.info('getUtilitiesHydro', title)
    }
    return title;
}


export const rentPaidType = [
    { title: "Month", value: 1 }, { title: "Other", value: 2 }
]