import React, { useEffect, useState } from 'react';
import { Col, Row, Dropdown, DropdownButton, InputGroup, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from "react-router-dom";
import { getAPI, postAPI } from '../../../../../Api';
import { cmsClickedData } from '../../../../../Store/actions/Action';
import { logger } from '../../../../../Utils';
import { validate } from '../../../../../Utils/Validation/validation';
import { AppLayout, DropDown, ModalBox, PageTitleHeader } from '../../../../components';
import Footer from "../../../../../Website/components/Footer";
import ImageComponent from '../../../ProfileDetail/ListUsableComponent/ImageComponent';
import { FaqValidationSchema } from './FaqValidationSchema';
import PublishDND from './PublishDND';
import './style.scss';

const log = logger('CreateFaq');
const CreateFaq = (props) => {
    const [displayType, setDisplayType] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const [manageModal, setManageModal] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [titleFaq, setTitle] = useState('');
    const [descriptionFaq, setDescription] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [catIdErr, setErrCatId] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);
    const [sendManageData, setSendManageData] = useState(null)
    const [viewData, setViewData] = useState(null);
    const [draftModal, setDraftModal] = useState(false);
    const [publishPanel, setPublishPanel] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [action, setAction] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null)
    const clickedData = useSelector(state => state.CMSReducer.clickedData);
    const [publishLoading, setPublishLoading] = useState(false);
    const dispatch = useDispatch();

    const onClickCategory = (value, display) => {
        setDisplayType(display);
        setCategoryId(value)
        setErrCatId('')
    }
    const back = () => {
        props.history.push(`/cms/content-management`);
        dispatch(cmsClickedData(null));
    }
    const getData = () => {
        getAPI(`/admin/cms/faq/view/${viewData.id}`).then(resData => {
            log.info(resData)
            const resDataView = resData.data.data;
            if (resData.status === 200) {
                setViewData(resDataView)
            }
        })
    }

    useEffect(() => {
        const data = {
            'name': ''
        }
        const urlCategory = `/admin/cms/get-categories`;
        postAPI(urlCategory, data).then(res => {
            if (res.status === 200) {
                setCategoryList(res.data.data)
            }
        })
        const urlTag = `/admin/cms/get-tags`;
        postAPI(urlTag, data).then(res => {
            if (res.status === 200) {
                log.info(res.data.data)
                setTagList(res.data.data)
            }
        })
    }, [])

    const [tagDropdown, setTagDropdown] = useState(false);
    const showTagDropdown = () => {
        setTagDropdown(!tagDropdown);
    }
    const onValueChange = (e, data) => {
        const isChecked = e.target.checked;
        log.info('isChecked', isChecked, data)
        isChecked === true ?
            setSelectedData((state) => ([...state, data]))
            :
            setSelectedData((state) => {
                return state.filter(filterData => filterData.id !== data.id)
            })
        log.info('selectedData===>', selectedData)
    }
    const onSubmit = (e, val) => {
        e.preventDefault();
        setSubmitStatus(val)
        if (validate(FaqValidationSchema) && categoryId !== '') {
            let tag = [];
            selectedData.length !== 0 && selectedData.map(data => tag.push(data.id))
            
            const data = {
                "cms_category_id": categoryId,
                "tags": tag,
                "title": titleFaq,
                "description": descriptionFaq,
                "status": val
            }
            let url = '';
            if (clickedData !== null) {
                data.id = clickedData.id;
                url = '/admin/cms/faq/update';
            } else {
                url = '/admin/cms/faq/create';
            }
            setSaveLoading(true);
            postAPI(url, data).then(response => {
                setSaveLoading(false);
                if (response.status === 200) {
                    const res = response.data.data;
                    if (val === '0') {
                        setDraftModal(true)
                        setModalContent('draft')
                    } else if (val === '1') {
                        dispatch(cmsClickedData(null));
                        setPublishPanel(true)
                        setModalContent('published')
                    }
                    getAPI(`/admin/cms/faq/view/${res.id}`).then(resData => {
                        log.info(resData)
                        const resDataView = resData.data.data;
                        if (resData.status === 200) {
                            setViewData(resDataView)
                        }
                    })
                }
            })
        } else if (categoryId === '') {
            setErrCatId('category is required')
        }
    }
    const openConfirmationModal = (val) => {
        setAction(val)
        setDeleteModal(true);
    }
    const onClickYes = () => {
        if (action === 'delete') {
            const data = {
                'id': viewData.id
            }
            postAPI(`/admin/cms/faq/delete`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true)
                }
            })
        }
        if (action === 'statusChange') {
            setPublishLoading(true);
            const data = {
                "id": viewData.id,
                "status": (viewData.status_info.status_id === 2) ? '1' : '2'
            }
            postAPI(`/admin/cms/faq/change-status`, data).then(res => {
                if (res.status === 200) {
                    setDeleteModal(false);
                    setConfirmAction(true);
                    setPublishLoading(false);
                    getData();
                }
            })
        }
    }
    const onClickNo = () => {
        setDeleteModal(false)
    }
    const togggleDraftModal = () => {
        setDraftModal(false);
        if (clickedData !== null) {
            getData();
        } else {
            dispatch(cmsClickedData(null));
            props.history.push(`/cms/content-management`);
        }
    }
    const toggleConfirm = () => {
        if (action === 'delete') {
            setConfirmAction(false)
            dispatch(cmsClickedData(null));
            props.history.push(`/cms/content-management`);
        } else if (action === 'statusChange') {
            setConfirmAction(false)
        }
    }
    const openPublishModal = (val, data) => {
        if (val === true) {
            setManageModal(true)
            setSendManageData(data)
        }
    }
    const onManageOrderYes = () => {
        postAPI(`/admin/cms/faq/change-order`, sendManageData).then(res => {
            setSaveLoading(false);
            if (res.status === 200) {
                setModalContent('sort');
                setDraftModal(true);
                setManageModal(false)
            }
        })
    }
    const onManageOrderNo = () => {
        setManageModal(false)
    }
    useEffect(() => {
        if (viewData !== null) {
            setCategoryId(viewData.cms_category_id);
            setSelectedData(viewData.cms_tags);
            setTitle(viewData.title);
            setDescription(viewData.description);
            categoryList.map((data) => {
                return (data.id === viewData.cms_category_id) && setDisplayType(data.name);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewData])
    useEffect(() => {
        if (clickedData !== null) {
            getAPI(`/admin/cms/faq/view/${clickedData.id}`).then(resData => {
                log.info(resData)
                const resDataView = resData.data.data;
                if (resData.status === 200) {
                    setViewData(resDataView)
                }
            })
        }
    }, [clickedData])
    let status = '';
    if (viewData) {
        if (viewData.status_info.status_name === 'Publish') {
            status = 'published'
        } else if (viewData.status_info.status_name === 'Unpublish') {
            status = 'unpublished'
        } else if (viewData.status_info.status_name === 'Draft') {
            status = 'draft'
        }
    }
    const clickPreview = () => {
        props.history.push('/preview-faq')
    }
    return (
        <>
            <AppLayout noSidebar={true}>
                <div className='create-faq'>
                    <PageTitleHeader
                        isArrow={true}
                        onClick={() => back()}
                        title={clickedData !== null ? 'faq' : 'creating new faq'}
                        status={status}
                        extraElm={
                            clickedData !== null ? <div className='header-right'>
                                <div className='ml-3 cursorPointer' onClick={() => clickPreview()}><img src={require('../../../../../_assets/images/green-eye-view-icon.svg')} alt="" /> Preview</div>
                                <div className='ml-3 cursorPointer' onClick={() => setPublishPanel(true)}><img src={require('../../../../../_assets/images/switch-icon.svg')} alt="" /> Manage FAQ Order</div>
                                <Button onClick={() => openConfirmationModal('delete')} className='btn-cancel delete ml-3' type='button' >delete</Button>
                                <Button className={`ml-3 ${(viewData && (Number(viewData.status_info.status_id) === 1 || Number(viewData.status_info.status_id) === 0)) ? 'btn-cancel' : 'btn-publish'}`} type='button' onClick={() => openConfirmationModal('statusChange')}>{viewData && (Number(viewData.status_info.status_id) === 2) ? 'publish' : 'unpublish'}</Button>
                            </div> : ''
                        }
                    />
                    <Form id='Form'>
                        <Row className='container-detail admin'>
                            <Col md={4} className="mb-3">
                                <DropdownButton
                                    title={`Category: ${displayType}`}
                                    id='dropdown-menu-align-left'
                                    style={{ width: '100%' }}
                                    className={catIdErr !== '' ? 'is-invalid2' : ''}
                                >
                                    {categoryList.length !== 0 && categoryList.map((options, i) => {
                                        return <Dropdown.Item eventKey={options.id} onClick={() => onClickCategory(options.id, options.name)} key={i}>{options.name}</Dropdown.Item>
                                    })}
                                </DropdownButton>
                                {catIdErr !== '' && <div class="invalid-feedback2">{catIdErr}</div>}
                            </Col>
                            <Col md={8} className="mb-3">
                                <div className='d-flex justify-content-between extra-class-dropdown'>
                                    <span>Tags:
                                        <strong>
                                            {selectedData.map(function (obj) {
                                                return obj.name;
                                            }).join(", ")}
                                        </strong>
                                    </span>
                                    <DropDown close={() => setTagDropdown(false)} open={tagDropdown} position={"right"} enableTopArrow={false} style={{ width: "208px" }}
                                        content={
                                            <div className="row checkbox-left check-status">
                                                {tagList.map((data, i) => {
                                                    return <div className="col-12" key={i}>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-checkbox large">
                                                                <input type="checkbox" className="custom-control-input" id={"customCheck" + data.id}
                                                                    name={data.id}
                                                                    checked={selectedData.find(elm => elm.id === data.id)} onChange={(e) => onValueChange(e, data)}
                                                                />
                                                                <label className="custom-control-label capitalize pl-3 pt-1" htmlFor={"customCheck" + data.id}>{data.name}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        }
                                    >
                                        <div onClick={showTagDropdown}><ImageComponent /></div>
                                    </DropDown>
                                </div>
                            </Col>
                            <Form.Group as={Col} md='12' className="mb-3">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text className='title-inside'><span>Title of Form:</span></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type='text'
                                        name={'titleFaq'}
                                        id={'titleFaq'}
                                        value={titleFaq}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md='12'>
                                <Form.Label className='ml-2'>Description:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows="8"
                                    placeholder="type description here"
                                    name="descriptionFaq"
                                    id="descriptionFaq"
                                    value={descriptionFaq}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Form.Group>
                        </Row>
                        <Row className='container-detail admin'>
                            <Col md='12' className='d-flex justify-content-between'>
                                {clickedData !== null ? <div></div> :
                                    <Button className='btn-cancel' type='button' onClick={() => togggleDraftModal()} disabled={saveLoading === true || publishLoading === true ? true : false}>cancel</Button>
                                }
                                <div className='d-flex justify-content-between'>
                                    <Button className='btn-save-draft' type='submit' onClick={(e) => onSubmit(e, '0')} disabled={saveLoading === true ? true : false}>save as draft {(submitStatus === '0' && saveLoading === true) && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                                    <Button className='btn-publish' type='submit' onClick={(e) => onSubmit(e, '1')} disabled={saveLoading === true ? true : false}>publish {(submitStatus === '1' && saveLoading === true) && <i className='fa fa-spinner fa-spin ml-2'></i>}</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Footer />
            </AppLayout>
            <ModalBox
                show={draftModal}
                onHide={() => togggleDraftModal(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => togggleDraftModal(false)}
            >
                <h4>{modalContent === 'draft' ? 'content saved' : 'Content is published'}</h4>
            </ModalBox>
            {publishPanel === true &&
                <PublishDND
                    isShow={publishPanel} handleClose={(data) => setPublishPanel(data)}
                    openPublishModal={(data, sendData) => openPublishModal(data, sendData)}
                    viewData={viewData}
                />
            }
            <ModalBox
                show={deleteModal}
                closeButtonHeader={false}
                size='sm'
                type='condition'
                actionbuttontitle={'Yes'}
                buttonaction={() => onClickYes()}
                backbuttontitle={'Not now'} //{action === 'statusChange' ? 'Not now' : 'Cancel'}
                backaction={() => onClickNo()}
            >
                <p className='order-one-modal'>
                    {action === 'delete' && 'are you sure you want to delete content? This cannot be undo.'}
                    {action === 'statusChange' &&
                        <>
                            {viewData.status_info.status_id === 0 ?
                                'Are you sure you want to publish content? ' :
                                'Are you sure you want to unpublish content?'}
                        </>
                    }
                </p>
            </ModalBox>
            <ModalBox
                show={confirmAction}
                onHide={() => toggleConfirm(false)}
                size='sm'
                actionbuttontitle={'got it'}
                buttonaction={() => toggleConfirm(false)}
            >
                <h4>
                    {action === 'delete' && 'Faq has been deleted.'}
                    {action === 'statusChange' && `Content ${status}`}
                </h4>
            </ModalBox>

            <ModalBox
                show={manageModal}
                closeButtonHeader={false}
                size='sm'
                type='condition'
                actionbuttontitle={'Yes'}
                buttonaction={() => onManageOrderYes()}
                backbuttontitle={'Not now'}
                backaction={() => onManageOrderNo()}
            >
                <p className='order-one-modal'>
                    Are you sure you want to update content? the original content will be replaced
                </p>
            </ModalBox>
        </>
    )

}

export default withRouter(CreateFaq);