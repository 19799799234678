import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setRequestCurrentPage } from '../../../Store/actions/Action';
import { HighlightContent } from '..';
import { colorRequest } from '../../../Constants/colorStatus';
import { logger } from '../../../Utils';
import { phoneNumberDisplay } from '../../../Utils/NumberSanitizer/NumberSanitizer';
import RequestManagementHeader from './RequestManagementHeader';
import './table.scss';
import UserFilter from '../../Admin/Dashboard/UserFilter';

const log = logger('TableTenant');
const TableTenant = (props) => {
    const { component, columns, goToProfileDetail, onClickSwitch } = props;
    const dispatch = useDispatch();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [compare, compareId] = useState(null);

    const tableData = useSelector(state => { return component === 'userManagement' ? state.userReducer.tableData.data : state.requestReducer.tableData.data });
    const from = useSelector(state => { return component === 'userManagement' ? state.userReducer.tableData.from : state.requestReducer.tableData.from });
    const to = useSelector(state => { return component === 'userManagement' ? state.userReducer.tableData.to : state.requestReducer.tableData.to });
    const last_page = useSelector(state => { return component === 'userManagement' ? state.userReducer.tableData.last_page : state.requestReducer.tableData.last_page });
    const total = useSelector(state => { return component === 'userManagement' ? state.userReducer.tableData.total : state.requestReducer.tableData.total });
    const currentPage = useSelector(state => { return component === 'userManagement' ? state.userReducer.currentPage : state.requestReducer.currentPage });
    const filters = useSelector(state => { return component === 'userManagement' ? state.userReducer.filters : state.requestReducer.filters });

    const handleClickInside = (data, option) => {
        setIsPopoverOpen(option === 'yes' ? true : false);
        compareId(data)
    }
    return (
        <div className='table-component-wrapper' style={{ overflow: 'visible', minHeight: '600px' }}>
            <div className='table-responsive fixing-height'>
                <table className='table-component'>
                    <thead>
                        <tr>
                            {columns.map((data, i) => {
                                return (
                                    <th key={'table_head_' + i} width={data.width} className={component === 'requestManagement' ? 'pr-0 requestManagement' : ''}>{data.header}
                                        <UserFilter component={component} data={data} />
                                        <RequestManagementHeader
                                            component={component}
                                            data={data}
                                            searchCity={props.searchCity}
                                            searchName={props.searchName}
                                            setSearchCity={props.setSearchCity}
                                            setSearchName={props.setSearchName}
                                            setSeachRole={props.setSeachRole}
                                            sort_by_type={props.sort_by_type}
                                            sortType={props.sortType}
                                            dateTime={props.dateTime}
                                            searchdateTime={props.searchdateTime}
                                            searchRequestStatus={props.searchRequestStatus}
                                            requestStatus={props.requestStatus}
                                        />
                                    </th>)
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {(tableData && tableData.length > 0) && tableData.map((data, i) => {
                            return (<React.Fragment key={'table_body_' + i}>
                                {component === 'userManagement' && 
                                <tr className='cursorPointer'>
                                    <td onClick={() => goToProfileDetail(data)}>
                                        <HighlightContent searchWord={filters.name} highlightWord={data.name} />
                                    </td>
                                    <td onClick={() => goToProfileDetail(data)}>
                                        <HighlightContent searchWord={filters.email} highlightWord={data.email} />
                                    </td>
                                    <td onClick={() => goToProfileDetail(data)}>
                                        <HighlightContent searchWord={filters.phone_number} highlightWord={data.phone_number && phoneNumberDisplay(data.phone_number.toString())} />
                                    </td>
                                    <td>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div onClick={() => onClickSwitch(data)}><Image
                                                width='20px'
                                                height='20px'
                                                alt='switch'
                                                className='mb-1 ml-2'
                                                src={require(`../../../_assets/images/switch-icon.svg`)} /></div>
                                            <span onClick={() => goToProfileDetail(data)} className='type-user'>{data.user_type}</span>
                                            <span onClick={() => goToProfileDetail(data)} className={data.is_active === 'Active' ? 'status active' : 'status'}>{data.is_active}</span>
                                        </div>
                                    </td>
                                </tr>}
                                {component === 'requestManagement' && <tr className='cursorPointer'>
                                    <td onClick={() => goToProfileDetail(data)}>
                                        <HighlightContent searchWord={props.searchName} highlightWord={data.name_of_user} />
                                    </td>
                                    <td onClick={() => goToProfileDetail(data)}>{data.role}</td>
                                    <td onClick={() => goToProfileDetail(data)}>{data.request_type}</td>
                                    <td onClick={() => goToProfileDetail(data)}>
                                        {log.info(props.searchCity)}
                                        {(data.city === "" || data.city === null) ? '--' :
                                            <HighlightContent searchWord={props.searchCity} highlightWord={data.city} />}
                                    </td>
                                    <td onClick={() => goToProfileDetail(data)}>
                                        {data.date === null && data.time === null ? '--' :
                                            <>
                                                {data.date} <span style={{ textTransform: 'capitalize' }}>{`(${data.time})`}</span>
                                            </>}
                                    </td>
                                    <td>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <span onClick={() => goToProfileDetail(data)} className={colorRequest(data.request_status)} style={{ paddingRight: '15px' }}>{data.request_status}</span>
                                            {(props.loginId === data.assigned_to && data.assigned_comment !== null) &&
                                                <span className='warning-popover'>
                                                    <img
                                                        onMouseEnter={() => handleClickInside(data.id, 'yes')}
                                                        onMouseLeave={() => handleClickInside(data.id, 'no')}
                                                        src={require('../../../_assets/images/warning-sign.svg')} width={14} height={13} alt='warning' />
                                                    {(isPopoverOpen === true && compare === data.id) && <div className="custom-popover">
                                                        <p className='popover-content'>{data.assigned_comment}</p>
                                                    </div>}
                                                </span>
                                            }
                                            <span onClick={() => goToProfileDetail(data)} className='status'><img className='next-arrow' src={require('../../../_assets/images/next.svg')} alt='next' /></span>
                                        </div>
                                    </td>
                                </tr>}
                            </React.Fragment>
                            )
                        })}
                    </tbody>
                </table>
                {tableData.length === 0 &&
                    <div className='empty-table-data'>
                        There is no data found
            </div>
                }
            </div>

            {tableData && tableData.length !== 0 &&
                <div className='table-footer'>
                    <div>
                        <span className='left'>
                            {tableData.length === 0 ? <span>Data not found</span> : <span className='left'>
                                {tableData.length} of {total}
                            </span>}
                            {/* {per_page > total ? total : per_page} of {total} */}
                        </span>
                    </div>
                    <div>
                        <span className='right'>
                            {from} to {to}
                            {currentPage !== from ? <img className='previous-arrow' onClick={() => component === 'userManagement' ? dispatch(setCurrentPage(parseInt(currentPage) - 1)) : dispatch(setRequestCurrentPage(parseInt(currentPage) - 1))} src={require('../../../_assets/images/next.svg')} alt='previous' /> : ''}
                            {currentPage !== last_page ? <img className='next-arrow' onClick={() => component === 'userManagement' ? dispatch(setCurrentPage(parseInt(currentPage) + 1)) : dispatch(setRequestCurrentPage(parseInt(currentPage) + 1))} src={require('../../../_assets/images/next.svg')} alt='next' /> : ''}
                        </span>
                    </div>
                </div>
            }

        </div>
    )
}

export default TableTenant;