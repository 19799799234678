import React from "react";
import "./style.scss";
import { Card, Image } from "react-bootstrap";
import EditButton from "../Buttons/EditButton";

const ProfileCard = (props) => {
  const { title, children, editaction, addaction } = props
  return (
    <Card className='profile-card'>
      <Card.Header className='profile-card-title d-flex justify-content-between align-items-center'>
        {title}
        <span className='d-flex justify-content-flex-end align-items-center'>
          {addaction && <span className='add-link' onClick={addaction}>
            <Image className="icon" src={require("../../../_assets/images/plus-black-icon.svg")} alt="add" /> Add New Info
            </span>}
          <EditButton onClick={editaction} />
        </span>
      </Card.Header>
      <Card.Body>
        {children}
      </Card.Body>
    </Card>
  );
}
export default ProfileCard;