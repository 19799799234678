import React, { useState, useEffect } from "react";
import "./style.scss";
import { Row, Col, Navbar, NavDropdown } from "react-bootstrap";
import { withRouter } from 'react-router-dom';
import { logger } from "../../../Utils";
import { getAPI } from "../../../Api";
import Cookies from "js-cookie";
import Moment from 'react-moment';
import { useDispatch, useSelector } from "react-redux";
import { updateRawData } from "../../../Store/actions/RawDataAction";
import { setIsLeaseFromTenantApplication } from "../../../Store/actions/MessageDetailAction";
import { useGlobalState } from "../../../StateContext";
import { getTenantsList } from "../../../Store/actions/AddedTenantAction";

const log = logger("Leases");
const Leases = (props) => {
  const { setUploadLease, setLeaseId } = props;
  const [propertyLists, setPropertyLists] = useState(null);
  const [activeKey, setActiveKey] = useState('new_to_old');
  const accessToken = Cookies.get("access_token") ? Cookies.get("access_token") : null;

  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const [globalState, setGlobalState] = useGlobalState()

  const dispatch = useDispatch();

  useEffect(() => {
    if (property_details_id) {
      const url = `/landlord/listing/lease/lease-list/${property_id}/${property_details_id}`
      getAPI(url).then(res => {
        if (res.status === 200) {
          const fetchedData = res.data.data;
          setPropertyLists(fetchedData);
          log.info('fetchedData', fetchedData);
        }
      })
    }
  }, [property_details_id, accessToken])

  const getData = (url) => {
    getAPI(url).then(response => {
      const res = response.data.data;
      log.info(res);
      setPropertyLists(res)
    })
      .catch(error => {
      });
  }

  const handleDropdownSelect = (key) => {
    setActiveKey(key);
    let url = `/landlord/listing/lease/lease-list/${property_id}/${property_details_id}?sort_by=post_date&sort_order=${key}`;
    getData(url)
  }

  const leaseAction = (data, lease_id, is_manual_uploaded, isView = false) => {
    dispatch(setIsLeaseFromTenantApplication(false));
    dispatch(getTenantsList([]))
    if (is_manual_uploaded !== 1) {
      if (data === 'create') {
        setGlobalState({ showReviewMessage: !!lease_id })
        props.history.push({
          pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
          state: {
            lease_id: lease_id,
            step: (lease_id === 0 ? 0 : 3),
            view: isView
          }
        });
      } else if (data === 'upload') {
        setUploadLease(true);
      }
    } else {
      console.log('Manual upload');
      setLeaseId(lease_id);
      setUploadLease(true)
    }
    updateRawData({ leaseCurrentStep: 0 })
  }

  return (
    <div className="profile-container">
      <div className="profile-container-box">
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            zIndex: "20",
            padding: "0 5px",
            marginBottom: '16px'
          }}
        >
          <Navbar className="filter-nav">
            <Navbar.Collapse className="d-flex justify-content-between">
              <Navbar.Text>posted date:</Navbar.Text>
              <NavDropdown title={activeKey.split('_').join(' ')} id="nav-dropdown">
                <NavDropdown.Item onClick={(e) => handleDropdownSelect('new_to_old')}>posted date: new to old</NavDropdown.Item>
                <NavDropdown.Item onClick={(e) => handleDropdownSelect('old_to_new')}>posted date: old to new</NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          </Navbar>

          <Navbar className="create-lease">
            <Navbar.Collapse className="d-flex justify-content-center">
              <NavDropdown title={'Create New'} id="nav-dropdown">
                <NavDropdown.Item onClick={(e) => leaseAction('create', 0)}>Create Lease</NavDropdown.Item>
                <NavDropdown.Item onClick={(e) => leaseAction('upload')}> Upload Your Own</NavDropdown.Item>
              </NavDropdown>
            </Navbar.Collapse>
          </Navbar>
        </div>

        <Row>
          <Col>
            <div className="table-component-wrapper list-view-calender lease-list">
              <table className="table-component">
                <thead>
                  <tr className="list-view-header">
                    <th>Tenant(s)</th>
                    <th>Created Date</th>
                    <th>Lease Period</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {propertyLists && propertyLists.map((data, i) => {
                    return (
                      <tr onClick={(e) => leaseAction('create', data.lease_id, data.is_manual_uploaded, true)} key={'table_body_' + i} className={'row-wrapper ' + data.formatted_status}>
                        <td>{data.tenants && data.tenants.map((data) => {
                          return data
                        }).join(', ')}</td>
                        <td><Moment format="MMM D, YYYY">{data.created_date}</Moment></td>
                        <td>{data.lease_start_date ?
                          <>
                            <Moment format="MMM D, YYYY">{data.lease_start_date}</Moment>
                            {' - '}
                            <Moment format="MMM D, YYYY">{data.lease_end_date}</Moment>
                          </> : '-'}</td>
                        <td><span className={'lease-status'}>{data.formatted_status}</span>
                          {data.is_sign_offline ? <span className={'sign-type'}>To be signed offline</span> : ''}</td>
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        {(propertyLists && propertyLists.length === 0) &&
          <Row className="m-0">
            <Col className="empty-showing-request" md={12}>There is no lease available</Col>
          </Row>
        }
      </div>
    </div>
  );
}

export default withRouter(Leases);
