import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Nav, Image, Button } from 'react-bootstrap';
import { logger } from "../../Utils";
import { postAPI } from "../../Api";
import { Loader, ModalBox, PDFViewerComponent } from '../../App/components';
import Layout from '../components/Layout';
import Carousel from "react-multi-carousel";
// import FaqSection from './FaqSection';

import './style.scss';

const log = logger("Resources");
const Resources = (props) => {
    const [showLoader, setShowLoader] = useState(false);
    const [pdfModal, setPdfModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const handleFormClick = (form_url) => {
        log.info("PDF form url", form_url);
        setPdfUrl(form_url);
        setPdfModal(true);
    }

    const [articlesList, setArticlesList] = useState([]);
    const [formsList, setFormsList] = useState([]);
    const [partnersList, setPartners] = useState([]);
    const placeHolderImg = require("../../_assets/images/placeholder.jpg");
    useEffect(() => {
        const data = {"limit": 4};
        setShowLoader(true);
        postAPI(`/cms/articles/list`, data).then(response => {
            setShowLoader(true);
            if (response.status === 200) {
                setArticlesList(response.data.data)
                setShowLoader(false);
            } else {
                setShowLoader(false);
            }
        });
        postAPI(`/cms/forms/list`, data).then(response => {
            setShowLoader(true);
            if (response.status === 200) {
                setFormsList(response.data.data)
                setShowLoader(false);
            } else {
                setShowLoader(false);
            }
        });
        postAPI(`/cms/partners/list`, data).then(response => {
            setShowLoader(true);
            if (response.status === 200) {
                setPartners(response.data.data)
                setShowLoader(false);
            } else {
                setShowLoader(false);
            }
        });
    }, []);

    let [numberOfCards, setNumberOfCards] = useState(3);
    let [isDesktop, setIsDesktop] = useState(false);
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1500 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 1500, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1200, min: 991 },
            items: 3,
            partialVisibilityGutter: 26,
        },
        tablet2: {
            breakpoint: { max: 991, min: 579 },
            items: 2,
            partialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: { max: 579, min: 0 },
            items: 1,
            partialVisibilityGutter: 80,
        },
    };
    const resize = () => {
        let width = window.screen.width;
        let cardNum = 3;
        let isDesktop = '';
        if (width <= 579) {
            cardNum = 1;
            isDesktop = false;
        } else if (width > 579 && width <= 991) {
            cardNum = 2;
            isDesktop = false;
        } else if (width > 991 && width <= 1200) {
            cardNum = 3;
            isDesktop = false;
        } else if (width > 1200 && width <= 1500) {
            cardNum = 3;
            isDesktop = true;
        } else if (width > 1500 && width <= 4000) {
            cardNum = 3;
            isDesktop = true;
        }
        setNumberOfCards(cardNum);
        setIsDesktop(isDesktop);
    };
    useEffect(() => {
        window.addEventListener("resize", resize);
        resize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const articlesBox = (articlesList, colCount) => {
        return (articlesList.length > 0 ? articlesList.slice(0, 3).map((article, i) => {
            return <Col key={'article' + article.id} md={colCount} className="grid mb-4">
                    <div className="resources-box">
                        <div className="resources-box-image">
                            <Image className="grid-img" src={article.image_url ? article.image_url : placeHolderImg} alt="grid-img" />
                        </div>
                        <h4 className="grid-name">{article.headline}</h4>
                        <p className="grid-content">{article.excerpt}</p>
                        <div className="mt-auto">
                            <Nav.Link className="date-text" href={`/resources/blog/preview/${article.id}`}>Read more
                                <Image
                                    className="pl-2 next"
                                    src={require("../../_assets/images/arrow-forward.svg")}
                                    alt='View More'
                                />
                            </Nav.Link>
                        </div>
                    </div>
                </Col>
            }) : showLoader ? <Loader /> : <h2>No Data Found</h2>
        );
    }
    const formsBox = (formsList, colCount) => {
        return (formsList.length > 0 ? formsList.slice(0, 3).map((form, i) => {
            return <Col key={'article' + i} md={colCount} className="grid mb-4">
                    <div className="resources-box">
                        <div className="resources-box-image">
                            <Image className="grid-img" src={form.thumbnail_url ? form.thumbnail_url : placeHolderImg} alt="grid-img" />
                        </div>                                            
                        <h4 className="grid-name">{form.title}</h4>
                        <p className="grid-content">{form.description}</p>

                        <div className="mt-auto">
                            {form.form_url &&
                                <Button variant="link" className="date-text" onClick={() => handleFormClick(form.form_url)}>Read more 
                                    <Image
                                        className="pl-2 next"
                                        src={require("../../_assets/images/arrow-forward.svg")}
                                        alt='View More'
                                    />
                                    {showLoader && <i className='fa fa-spinner fa-spin ml-2'></i>}
                                </Button>
                            }
                        </div>
                    </div>                                        
                </Col>
            }) : showLoader ? <Loader /> : <h2>No Data Found</h2>
        );
    }
    const partnersBox = (partnersList, colCount) => {
        return (partnersList.length > 0 ? partnersList.slice(0, 3).map((partner, i) => {
            return <Col key={'article' + i} md={colCount} className="grid mb-4">
                    <div className="resources-box">
                        <div className="resources-box-image">
                            <Image className="grid-img" src={partner.image_url ? partner.image_url : placeHolderImg} alt="grid-img" />
                        </div>
                        <h4 className="grid-name">{partner.company_name}</h4>
                        <p className="grid-content">{partner.company_bio}</p>
                        <div className="mt-auto">
                            <Nav.Link className="date-text" href={`/resources/partners/preview/${partner.id}`}>Read more 
                                <Image
                                    className="pl-2 next"
                                    src={require("../../_assets/images/arrow-forward.svg")}
                                    alt='View More'
                                />
                            </Nav.Link>
                        </div>                                            
                    </div>
                </Col>
            }) : showLoader ? <Loader /> : <h2>No Data Found</h2>
        );
    }

    return (
        <div className="ResourcesPage">
            <Layout newHeader={true}>
                <div className="ResourcesPage-header">
                    <Container>
                        <div className="main-heading text-left text-md-center text-transform-lowercase">resources</div>
                        <div className="sub-heading text-left text-md-center">Tips, expert advice, key forms, preferred partners and answers to all your questions.</div>
                    </Container>
                </div>

                <div className="ResourcesPage-contentBox">
                    <section>
                        <Container>
                            <Row className="section-head">
                                <Col><h2 className="subHead">Recent Blogs</h2></Col>
                                {(articlesList && articlesList.length >= numberOfCards) &&
                                <Col className="text-right viewMore col-auto">
                                    <Nav.Link className="date-text" href={`/resources/blog`}>View More 
                                        <Image
                                            className="pl-2 next"
                                            src={require("../../_assets/images/arrow-forward.svg")}
                                            alt='View More'
                                        />
                                    </Nav.Link>
                                </Col>}
                            </Row>

                            <Carousel
                                responsive={responsive}
                                infinite={isDesktop ? false : true}
                                partialVisible={isDesktop ? false : true}
                                removeArrowOnDeviceType={["superLargeDesktop", "desktop"]}
                                swipeable={numberOfCards === 1}
                                showDots={true}
                                draggable={true}
                                autoPlay={isDesktop ? false : true}
                                autoPlaySpeed={5000}
                                keyBoardControl={true}
                                dotListClass="landlord-resources-dot-list"
                                arrows={numberOfCards !== 1}>
                                    {articlesList && articlesBox(articlesList, 12)}
                            </Carousel>
                        </Container>
                    </section>

                    <section>
                        <Container>
                            <Row className="section-head">
                                <Col><h2 className="subHead">Common Forms &amp; Info</h2></Col>
                                {(formsList && formsList.length >= numberOfCards) &&
                                <Col className="text-right viewMore col-auto">
                                    <Nav.Link className="date-text" href={`/resources/forms`}>View More 
                                        <Image
                                            className="pl-2 next"
                                            src={require("../../_assets/images/arrow-forward.svg")}
                                            alt='View More'
                                        />
                                    </Nav.Link>
                                </Col>}
                            </Row>
                            
                            <Carousel
                                responsive={responsive}
                                infinite={isDesktop ? false : true}
                                partialVisible={isDesktop ? false : true}
                                removeArrowOnDeviceType={["superLargeDesktop", "desktop"]}
                                swipeable={numberOfCards === 1}
                                showDots={true}
                                draggable={true}
                                autoPlay={isDesktop ? false : true}
                                autoPlaySpeed={5000}
                                keyBoardControl={true}
                                dotListClass="landlord-resources-dot-list"
                                arrows={numberOfCards !== 1}>
                                    {formsList && formsBox(formsList, 12)}
                            </Carousel>
                        </Container>
                    </section>

                    <section>
                        <Container>
                            <Row className="section-head">
                                <Col><h2 className="subHead">Panda Partners</h2></Col>
                                {(partnersList && partnersList.length >= numberOfCards) &&
                                <Col className="text-right viewMore col-auto">
                                    <Nav.Link className="date-text" href={`/resources/partners`}>View More 
                                        <Image
                                            className="pl-2 next"
                                            src={require("../../_assets/images/arrow-forward.svg")}
                                            alt='View More'
                                        />
                                    </Nav.Link>
                                </Col>}
                            </Row>
                            
                            <Carousel
                                responsive={responsive}
                                infinite={isDesktop ? false : true}
                                partialVisible={isDesktop ? false : true}
                                removeArrowOnDeviceType={["superLargeDesktop", "desktop"]}
                                swipeable={numberOfCards === 1}
                                showDots={true}
                                draggable={true}
                                autoPlay={isDesktop ? false : true}
                                autoPlaySpeed={5000}
                                keyBoardControl={true}
                                dotListClass="landlord-resources-dot-list"
                                arrows={numberOfCards !== 1}>
                                    {partnersList && partnersBox(partnersList, 12)}
                            </Carousel>
                        </Container>
                    </section>

                    {/* <FaqSection /> */}
                </div>
            </Layout>

            <ModalBox
                show={pdfModal}
                onHide={() => setPdfModal(false)}
                size="xl"
                className="cu-full-modal"
            >
                <PDFViewerComponent PDFUrl={pdfUrl && pdfUrl} />
            </ModalBox>
        </div>
    )
}

export default withRouter(Resources)
