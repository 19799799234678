import React from 'react'
// import Rollbar from 'rollbar'

const ROLL_BAR_TOKEN = 'de9b83045bdc45e097c81d0bbc442168'

class ErrorBoundry extends React.Component {
    // state = {
    //     rollBar: new Rollbar({
    //         accessToken: ROLL_BAR_TOKEN,
    //         captureUncaught: true,
    //         captureUnhandledRejections: true,
    //     })
    // }
    componentDidCatch(e) {
        try {
            console.error('error is ', e)
            // this.state.rollBar.info(e)
        } catch (err) {
        }
    }
    render() {
        return <>{this.props.children}</>
    }
}
export default ErrorBoundry