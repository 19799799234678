import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./style.scss";
import {
  JombotronSection,
  ToolsSection,
  InformationSection,
  ResourcesSection,
  ReviewsSection
} from "./components";
import { HeaderLandingPage, FooterLandingPage } from "../../components";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="landing-page-main">
          <HeaderLandingPage />
          <JombotronSection />
          <ToolsSection />
          <InformationSection />
          <ResourcesSection />
          <ReviewsSection />
          <FooterLandingPage />
        </div>          
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
