import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'
import { Card, Image, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Cookies from 'js-cookie';
import { ModalBox } from '../../components';
import "./style.scss";
import { setMyFavourite } from "./helper";

const PropertyCard = (props) => {
  const {
    onFavouriteChange = () => { },
    onVerifyAction,
    id,
    type,
    isVerifed,
    image_url,
    title,
    // hide_exact_address,
    details,
    price,
    isLoveIcon,
    onClick,
  } = props;
  
  const role = useSelector(state => state.auth.role) || parseInt(Cookies.get('role'));
  const [isFavorite, setIsFavorite] = useState(isLoveIcon)
  const tokenCookie = Cookies.get('access_token');
  const isLoggedIn = tokenCookie;
  const history = useHistory();
  const [confirmAction, setConfirmAction] = useState(false);
  // let titiletoArr = title.split(',');
  // titiletoArr.splice(-2, 2)
  // let formatedTitled = hide_exact_address === 0 && role !== 1 ? titiletoArr.join(',').substr(0, 125) : title;


  const toggleFavourite = async (id) => {
    if (role === 2 || !isLoggedIn) {
      setConfirmAction(true);
      return
    }

    try {
      await setMyFavourite(id)
      setIsFavorite(!isFavorite)
      onFavouriteChange(!isFavorite, id)
    } catch (err) {

    }
  }

  const getSignedUp = () => {
    setConfirmAction(false);
    history.push('/signup/tenant');
  }

  return (
    <Col xs={12}  >

      <Card
        className={'horizontal-card-item-container'}
      >
        <div className={'horizontal-card-header'}>
          <Card.Img
            variant="top"
            src={
              image_url
                ? image_url
                : require("../../../_assets/images/placeholder.jpg")
            }
            onClick={onClick}
            style={{ borderTopLeftRadius: 10,borderBottomLeftRadius:10, cursor: onClick && "pointer", height: 170, width: 124 }}
          />
        </div>
        <Card.Body>
          <div className='top-section'>
            {price && type !== "list" && (
              <div className="price-wrapper">
                <span>
                  <NumberFormat
                    value={price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />{" "}
                  / month
                </span>
              </div>
            )}

            <h2 className="property-title"
              onClick={onClick}
              style={{ cursor: onClick && "pointer" }}
            >{title}</h2>

            <div className="details-wrapper">
              {details && details?.length ? 
              <div style={{ width: "100%" }}>
                <span className="specification">{details}</span>
                <br />
              </div>:null}
              <div className={'horizontal-card-footer  space_bt'}>
                <div className="status-wrapper" >
                  {
                    isVerifed === 'unverified' ?
                      <span
                        className='cursor-pointer'
                        style={{ display: 'flex' }}
                        onClick={onVerifyAction}
                      >
                        <Image
                          className="icon"
                          width="20px"
                          height="20px"
                          src={require("../../../_assets/images/Unverified.png")}
                        />
                        {isVerifed}
                      </span>
                      : isVerifed === 'verification pending' ?
                        <span
                          style={{ cursor: 'default' }}
                        >
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/Unverified.png")}
                          />
                          {isVerifed}
                        </span>
                        :
                        <span
                          style={{ cursor: 'default', display: 'flex' }}
                          className="success"
                        >
                          <Image
                            className="icon"
                            width="20px"
                            height="20px"
                            src={require("../../../_assets/images/verified.png")}
                          />
                          {isVerifed}
                        </span>
                  }



                </div>    
              </div>
              <div className={'love_icon'}>
                <Image
                  className="icon"
                  width="22px"
                  height="20px"
                  src={
                    isFavorite
                      ? require("../../../_assets/images/love-icon.svg")
                      : require("../../../_assets/images/love-white-border.svg")
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleFavourite(id)}
                />
                </div>
            </div>
          </div>


        </Card.Body>
      </Card>

      <ModalBox
        show={confirmAction}
        onHide={() => setConfirmAction(false)}
        size='sm'
        actionbuttontitle={'get started'}
        buttonaction={() => getSignedUp()}
      >
        <h4>Sign up for free</h4>
        <p>create an account and get all the tools<br />you need to find the perfect home </p>
      </ModalBox>
    </Col>
  );
};
export default PropertyCard;
