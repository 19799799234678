import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { ModalBox, SingleOptionCheckInspection } from "../../../components";
import "../style.scss";

const sectionRating = [
  { title: "Good", value: 1 },
  { title: "Issue", value: 2 },
  { title: "Repair", value: 3 },
];

const ReviewMoveInInspection = (props) => {
  const { sections } = props;
  const [accordianId, setAccordionId] = useState("Exterior");
  const [openImageModal, setOpenImageModal]=useState(false);
  const[modalImage, setModalImage]=useState('');
  const clickExterior = (name) => {
    const check = name === accordianId ? null : name;
    setAccordionId(check);
  };
  const path = props.history.location.pathname.split("/")[1];
const toggleModal =(image)=>{
  setOpenImageModal(!openImageModal)
  setModalImage(image);
}

  return (
    <div className="inspection">
      {sections.map((section, index) => {
        return (
          <>
            <Accordion defaultActiveKey={section.section_name} className="accordian">
              <div className="accordian-main" key={index}>
                <div className="accordian-head d-flex justify-content-between">
                  <Accordion.Toggle
                    // as={Button}
                    style={{ background: 'transparent', border: 'none' }}
                    // variant="link"
                    eventKey={section.section_name}
                    onClick={() => clickExterior(section.section_name)}
                  >
                    <span>{section.section_name}</span>
                  </Accordion.Toggle>
                  <div>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={section.section_name}
                      onClick={() => clickExterior(section.section_name)}
                    >
                      <img
                        className="next-arrow"
                        src={
                          accordianId === section.section_name
                            ? require("../../../../_assets/images/nextUp.svg")
                            : require("../../../../_assets/images/nextDown.svg")
                        }
                        alt="next"
                      />
                    </Accordion.Toggle>
                  </div>
                </div>
                
                <Accordion.Collapse eventKey={section.section_name}>
                  <div className="accordian-body">
                    <Row>
                      {section.items.map((item, index) => {
                        return (
                          <Col md="4" key={index}>
                            <div className="item-card">
                              <div className="item-card-title d-flex justify-content-between">
                                <span>{item.item_name}</span>
                              </div>
                              {(path === "move-out-inspection" || path === "view-move-out-inspection") && (
                                      <div className="d-flex justify-content-between move-in-status-block">
                                        <div className="d-flex align-items-start flex-column move-in-status-text">
                                          <span>Move In Status: Good</span>
                                          <span>Note: NA</span>
                                        </div>
                                        <img
                                          className="move-in-status-image"
                                          src={require("../../../../_assets/images/image-icon.svg")}
                                        />
                                      </div>
                                    )}
                              <div
                                className={
                                  "status-card-preview d-flex flex-column justify-content-center" +
                                  (item.item_status === 1
                                    ? " good-active"
                                    : item.item_status === 2
                                    ? " issue-active"
                                    : item.item_status === 3 ?
                                      " repair-active" : ' background-grey')
                                }
                              >
                                {item.item_status ? <img
                                  src={require("../../../../_assets/images/" +
                                    (item.item_status === 1
                                      ? "good-active.svg"
                                      : item.item_status === 2
                                      ? "issue-active.svg"
                                      : item.item_status === 3 ? "repair-active.svg"
                                      :'issue-active.svg'
                                      ))}
                                /> : 
                                <SingleOptionCheckInspection
                                  mapData={sectionRating}
                                  // OnSingleOptionCheck={(data) =>
                                  //   OnSingleOptionCheck(data, i, index)
                                  // }
                                  type={'reviewInspection'}
                                  name={"promotionPlan"}
                                  setParam={item.item_status}
                                />
                                }
                                <span className="d-flex  justify-content-center">
                                  {item.item_status === 1
                                    ? "Good"
                                    : item.item_status === 2
                                    ? "Issue"
                                    : item.item_status === 3 && "Repair"}
                                </span>
                              </div>
                              <div className="preview-notes">
                                <span>Note: {item.notes!== null ? item.notes : "N/A"}</span>
                              </div>
                              <div className="d-flex flex-row flex-wrap">
                                {item.images && item.images.map((image, index) => {
                                  return <img onClick={()=>toggleModal(image)} className="preview-image cursor-pointer" src={image} key={index} />;
                                })}
                                {item.images.length===0 && <div className="no-image-box d-flex justify-content-center">
                                  <span>No Images</span>
                                  </div>}
                              </div>

                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </Accordion.Collapse>
              </div>
            </Accordion>
            </>
          );
        })}
      <ModalBox
                show={openImageModal}
                onHide={toggleModal}
                size='xl'
                dialogClassName="modal-90w"
                extraClassUpper="modal-border-none"       
            >
                <img className="modal-image-preview" src={modalImage}/>
            </ModalBox>
    </div>
  );
};

export default withRouter(ReviewMoveInInspection);
