import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, Image } from "react-bootstrap";
import axios from 'axios';
import { IMAGE_API_URL } from "../../../../Constants/endPoints"
import Cookies from "js-cookie";
import { FileBrowse, LargeButton } from "../../../components"
import { toaster } from "../../../../Utils"
import { postAPI } from "../../../../Api";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentPage } from "../../../../Store/actions/PropertyDetailAction";
import { useLocation } from 'react-router-dom';
import { reorder } from "./helper";
import ProfessionalPhotoShoot from "./ProfessionalPhotoShoot";

const ImageUpload = (props) => {
  const { setPhotoType, backToPrevPage, setSuccessModalShow, useAsPureComponent = false } = props
  const [imageUrlData, setImageUrlData] = useState([]);
  const [videoUrlData, setVideoUrlData] = useState([]);
  const [errorClass, setErrorClass] = useState(false);
  const [showImageLoading, setShowImageLoading] = useState(false)
  const dispatch = useDispatch();
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];

  useEffect(() => {
    if (unitDetails && !useAsPureComponent) {
      // setImageUrlData([...(unitDetails.images || []), ...imageUrlData])
      setImageUrlData([...(unitDetails.images || [])])
      setVideoUrlData([...(unitDetails.videos || []), ...(videoUrlData||[])])
    }
  }, [unitDetails?.images?.length])

  const removeImage = (index) => {
    imageUrlData.splice(index, 1);
    setImageUrlData(imageUrlData => [...imageUrlData])
  }

  const handleDrop = (file) => {
    const url = IMAGE_API_URL;
    const token = `Bearer ${Cookies.get("access_token")}`
    const headers = {
      'Authorization': token,
      "Content-Type": "multipart/form-data"
    }
    setShowImageLoading(true)
    file.map((dataFile) => {
      let formdata = new FormData()
      formdata.append('image', dataFile)

      axios.post(url, formdata, {
        headers: headers
      })
        .then(function (response) {
          setImageUrlData(imageUrlData => [...imageUrlData, response.data.data.url])
          setErrorClass(false)
          setShowImageLoading(false)
        })
        .catch(function (error) {
          setShowImageLoading(false)
        });
      return dataFile;
    })

  }

  const OnChangeInput = (event, index) => {
    if (event.target.name === 'videoUrlData') {
      const videoUrl = [...videoUrlData]
      videoUrl[index] = event.target.value
      setVideoUrlData(videoUrl)
    }
  }

  const VideoUrlFild = (action, index) => {
    if (action === 'add') {
      setVideoUrlData(videoUrlData => [...videoUrlData, ''])
    } else if (action === 'remove') {
      videoUrlData.splice(index, 1);
      setVideoUrlData(videoUrlData => [...videoUrlData])
    }
  }

  const onSubmit = (type) => {
    if (imageUrlData.length === 0) {
      setErrorClass(true)
    } else {
      const url = '/landlord/create-listing/step-5/upload-photos';
      //To remove empty values
      const videoUrlDataFiltered = videoUrlData.filter(videoUrl => {
        return videoUrl !== "";
      });

      const data = {
        property_id: property_id,
        property_details_id: property_details_id,
        images: imageUrlData,
        videos: videoUrlDataFiltered
      }
      postAPI(url, data).then(response => {
        if (response.status !== 200) {
          toaster('error', 'Something went wrong!');
        } else if (response.status === 200) {
          if (currentPath === 'edit-listing') {
            type === 'save' && setSuccessModalShow(true)
          } else {
            type === 'save' && toaster('success', 'Data Saved Successfully!')
          }
          if (type === 'next') {
            toaster('success', 'Data Saved Successfully!');
            dispatch(getCurrentPage(6))
          }
        }
      })
    }

  }

  const videoShootReq = () => {
    onSubmit()
    setPhotoType('video')
  }

  const onDragEnd = (result) =>
    setImageUrlData(
      reorder(
        imageUrlData, result.source.index,
        result.destination.index))


  return (<>
    <div className="inner-wrapper cu-step-form-body" style={{ minHeight: "750px" }}>
      <div className="body-container">
        <div className="body-header">
          <h3>upload my own photos</h3>
          {!(currentPath === 'edit-listing') && <span className="save-link" onClick={() => onSubmit('save')}>Save</span>}
        </div>
        <p>Your phone is a great tool, but knowing how to use it is key. <Link target="_blank" to="/photography-tips" className="click-here">Click here for photo tips</Link></p>
        <Row>
          <Col md="9">
            <FileBrowse
              handleDrop={(e) => handleDrop(e)}
              percent={0}
              onDragEnd={onDragEnd}
              multiple={true}
              fileType={".jpg,.png,.jpeg"}
              disable={false}
              imageData={imageUrlData}
              showImageLoading={showImageLoading}
              removeImage={(e) => removeImage(e)}
              errorClass={errorClass === true ? "error-upload-box" : ""}
            />
          </Col>
        </Row>

        <Row className="d-flex justify-content-between align-items-center" style={{ marginTop: '40px', paddingTop: '25px', borderTop: '3px solid #EEEEEF' }}>
          <Col xl="3" md="4">
            {videoUrlData.length > 0 ?
              videoUrlData.map((videoUrl, index) => {
                return (
                  <Form.Group key={'video_' + index}>
                    <Form.Label className="d-flex justify-content-between">
                      <span>Paste Video Link</span>
                      <span>
                        <Image
                          style={{ cursor: 'pointer' }}
                          width="20px"
                          height="20px"
                          src={require("../../../../_assets/images/delete-icon.svg")}
                          onClick={() => VideoUrlFild('remove', index)}
                        />
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="url"
                      name="videoUrlData"
                      value={videoUrl}
                      placeholder="Paste video link here..."
                      onChange={(e) => OnChangeInput(e, index)}
                    />
                  </Form.Group>
                )
              })
              : ''}

            <LargeButton
              title="add video link"
              isIcon={true}
              variant="dark"
              onClick={() => VideoUrlFild('add')} />
          </Col>
          <div className="text-center col-md-auto">
            <span style={{ textDecoration: 'underline', fontWeight: '600', cursor: 'pointer' }}>
              {/* <p onClick={()=> setPhotoType('professional')}>Don't have a video? Get a 360 video easily!</p> */}
              <p onClick={() => videoShootReq()}>Don't have a video? Get a 360 video easily!</p>
            </span>
          </div>
        </Row>
      </div>
    </div>

    <div className="container-footer cu-step-form-footer">
      {(currentPath === 'edit-listing') ?
        <>
          <Button variant="secondary" onClick={() => backToPrevPage()}>Back</Button>
          <Button variant="success" onClick={() => onSubmit('save')}>Save</Button>
        </> :
        <>
          <Button variant="secondary" onClick={() => dispatch(getCurrentPage(4))}>Back</Button>
          <Button variant="success" onClick={() => onSubmit('next')}>Next</Button>
        </>
      }
    </div>

  </>);
}

export default ImageUpload;
