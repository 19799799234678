import React, { Component } from "react";
import { Card } from "react-bootstrap";
import RpImage from "../../../../../components/Image";
import "./style.scss";

class Reviews extends Component {
  render() {
    const { key, review } = this.props;
    return (
      <Card className="review-wrapper" key={key}>
        <RpImage
          className="review-image"
          width="100%"          
          src={require("../../../../../../_assets/images/landing-pages/user-pic.svg")}
        />        
        <div className="review-description">{review.description}</div>
        <div className="blockquote-text review-title">{review.name}</div>        
      </Card>
    );
  }
}

export default Reviews;
