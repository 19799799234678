import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { ModalBox } from "../../../components";
import Cookies from 'js-cookie';
import '../style.scss';
const InspectionTable = (props) => {
    const role = Number(Cookies.get('role'));
    const [modalShow, setModalShow]=useState(false);
    const [deleteItemId, setDeleteItemId]=useState(null);
    const { maintenanceList, deleteItem } = props;  
    

    const viewRequest=(id, type)=>{
        if(type==="Move In"){
            props.history.push(`/view-move-in-inspection/${id}`);
        }else if(type==="Move Out"){
            props.history.push(`/view-move-out-inspection/${id}`);
        }
       
        }
    return (
        <div>
           
            <div className={"table-component-wrapper repair-list "+ (role===3 && maintenanceList.length===0 && "d-none")} >
                <table className="table-component">
                    <thead>
                        <tr className="list-view-header repair-list-head">
                            <th>{role===2 ? "Viewing Request" : role===3 && "Showing Request"}</th>
                            <th>{role===2 ? "Date Created" : role===3 && "Date"}</th>
                            {role===3 && <th>Time</th>}
                            {role===3 && <th>Landlord</th>}
                            {role===2 &&<th>Tenant(s)</th> }                          
                            {role===2 && <th>Status</th>}
                            {role===2 && <th></th>}
                            
                        </tr>
                    </thead>
                    <tbody>
                       {maintenanceList && maintenanceList.map((list, index)=>{
                           return <tr className={list.status_string==='In Progress' ? 'item-inProgress' : list.status_string==='Completed' && 'item-completed-inspection'} style={{lineHeight:'45px'}} key={index}>
                           {role===2 && <td className="cursor-pointer" onClick={()=>viewRequest(list.id,list.viewing_request)} width={'30%'}>{list.viewing_request}{" Inspection"}</td>}
                           {role===3 && <td className="cursor-pointer" onClick={()=>viewRequest(list.id,list.showing_request)} width={'30%'}>{list.showing_request}{" Inspection"}</td>}
                           <td className="cursor-pointer" onClick={()=>{
                               if(role===2){
                                viewRequest(list.id,list.viewing_request)
                               }else if(role===3){
                                viewRequest(list.id,list.showing_request)
                               }
                               }}>{list.date_created}</td>
                           {role===3 &&<td className="cursor-pointer" onClick={()=>viewRequest(list.id,list.viewing_request)}>{list.time}</td>}
                           {role===3 &&<td className="cursor-pointer" onClick={()=>viewRequest(list.id,list.viewing_request)}>{list.landlord}</td>}
                           {role===2 && <td className="cursor-pointer overflow-ellipsis" onClick={()=>viewRequest(list.id,list.viewing_request)}>{list.tenants}</td>  }                         
                           {role===2 && <td className={list.status_string==='In Progress' ? 'item-inProgress-text' : list.status_string==='Completed' && 'item-completed-text'} onClick={()=>viewRequest(list.id,list.viewing_request)}><span className="status">{list.status_string}</span></td> }
                           {role===2 &&<td> {list.status_string==='In Progress' && <img className="cursor-pointer" onClick={()=>{
                               setDeleteItemId(list.id);
                               setModalShow(true);
                        }} alt="Delete" src={require('../../../../_assets/images/delete.svg')}/>}</td>}
                       </tr>
                       })}              
                    </tbody>                   
                </table>
                {maintenanceList.length===0 && <div className="d-flex justify-content-center pt-4"><span>No inspection history</span></div>}      
            </div>
            {role===3 && maintenanceList.length===0 && 
            <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '60px 0' }}>
                        <Image src={require("../../../../_assets/images/no-data.svg")} />
                        <p style={{ fontSize: '25px', color: '#979797' }}>You Have No Inspection Yet </p>                       
                      </Col>      
}
            
        <ModalBox
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="sm"
          type="condition"
          actionbuttontitle={"Yes"}
          buttonaction={() => {
            setModalShow(false);
              deleteItem(deleteItemId);}}
          backbuttontitle={"No"}
          backaction={() => {
            setModalShow(false);
          }}
        >         
              <p className="modal-confirmation-text">
                are you sure you want to <br /> cancel this request?
              </p>
              <p className="modal-text">it will be deleted permanently</p>
           
          
        </ModalBox>
        </div>
    );
}

export default withRouter(InspectionTable);