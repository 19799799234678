import React from "react";
import EditButton from "../../Buttons/EditButton";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const UnitVerified = (props) => {
  const { setGetVerified } = props;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const shootScheduled = unitDetails.professionalPhotoshoot

  const role = Number(Cookies.get("role"));
  return (
    shootScheduled && shootScheduled.status ?
      <div className={'inner-wrapper verify-lisitng-box'}>
        <span style={{ cursor: 'default' }} >your shoot has been completed</span>
      </div> 
      : shootScheduled && shootScheduled.shoot_scheduled ?
      <div className={'inner-wrapper verify-lisitng-box'}>
        <span style={{ cursor: 'default' }} >you've sent a request for a photoshoot.</span>
      </div>
      : <div className={'inner-wrapper verify-lisitng-box'}>
          <h3>want your listing verified?</h3>
          <p>our team can come by to take<br/> photos and verify the property in<br/> no time!</p>
          <span onClick={()=>setGetVerified(true)}>Schedule a shoot</span>
        </div>
  );
}
export default UnitVerified;