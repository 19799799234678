
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const SERVICE_AREA_LATLNG = {
  'Guelph': [43.5448048, -80.24816659999999],
  'Thunder Bay': [48.3808951, -89.2476823],
  'Waterloo': [43.4642578, -80.5204096],
  'Cambridge': [43.3616211, -80.3144276]
}


export const isWithin20Km = (lat, lng) => {
  return Object.values(SERVICE_AREA_LATLNG).find((latLng) => {
    const distance = calcDistance(latLng[0], latLng[1], lat, lng).toFixed(1)
    return distance <= 20
  })
}



export const calcDistance = (lat1, lon1, lat2, lon2) => {

  const kmConstant = 6371;
  const distanceLat = toRad(lat2 - lat1);
  const distanceLon = toRad(lon2 - lon1);
  const lat1Radian = toRad(lat1);
  const lat2Radian = toRad(lat2);

  const a = Math.sin(distanceLat / 2) * Math.sin(distanceLat / 2) + Math.sin(distanceLon / 2) * Math.sin(distanceLon / 2) * Math.cos(lat1Radian) * Math.cos(lat2Radian);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = kmConstant * c;
  return parseFloat(distance);
}


export const toRad = (Value) => {
  return Value * Math.PI / 180;
}