import React, { useState, useEffect } from 'react';
import { withRouter  } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { setSearchLocation } from '../../../Store/actions/LandingPageAction';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import { Loader } from '../../../App/components';
import { logger } from '../../../Utils';

const log = logger("LovesMovingAround");
const LovesMovingAround = (props) => {
    const {isLoading} = props;
    const locations = useSelector(state => state.landingPageReducer.getLocationList) || [];
    const dispatch = useDispatch();
    let [numberOfCards, setNumberOfCards] = useState(4);

    const  renderLocations = (locations, colCount) => {
        return locations.map((location, i) => {
            return <Col md={colCount} key={`location_${i}`} onClick={() => handleClick(location)}>
                <Card>
                    <Card.Body>
                        <div className="rentals-text">rentals in</div>
                        <div className="rentals-location">{location}</div>
                    </Card.Body>
                </Card>
            </Col>
        })
    };
    const responsive = {
        superLargeDesktop: {
        breakpoint: { max: 4000, min: 1500 },
        items: 5,
        },
        desktop: {
        breakpoint: { max: 1500, min: 1024 },
        items: 4,
        },
        tablet: {
        breakpoint: { max: 1200, min: 991 },
        items: 3,
        },
        tablet2: {
        breakpoint: { max: 991, min: 579 },
        items: 2,
        },
        mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        },
    };
    const resize = () => {
        let width = window.screen.width;
        let cardNum = 4;
        if (width <= 464) {
          cardNum = 1;
        } else if (width > 464 && width <= 1024) {
          cardNum = 2;
        } else if (width > 1024 && width <= 1500) {
          cardNum = 4;
        } else if (width > 1500 && width <= 4000) {
          cardNum = 4;
        }
        setNumberOfCards(cardNum);
    };

    useEffect(() => {
        window.addEventListener("resize", resize);
        resize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleClick = (location) => {
        log.info("location", location);
        dispatch(setSearchLocation(location));
        props.history.push('/search-result');
    }
    return (
        <div className="loves-moving-around">
            <Container className="loves-moving-around-inner1">
                <h2 className="landing-title">rent panda loves moving around.</h2>
                <Row>
                    {locations ? renderLocations(locations, 4) : isLoading && <Loader/>}
                </Row>
            </Container>

            <Container className="loves-moving-around-inner2">
                <h2 className="landing-title">rent panda loves moving around.</h2>
                <Carousel
                    responsive={responsive}
                    infinite={false}
                    swipeable={numberOfCards === 1}
                    showDots={true}
                    draggable={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    keyBoardControl={true}
                    dotListClass="landlord-resources-dot-list"
                    arrows={numberOfCards !== 1}>
                        {locations && renderLocations(locations, 12)}
                </Carousel>
            </Container>
        </div>
    )
}

export default withRouter(LovesMovingAround);
