export const SET_PROPERTY_DETAIL = 'SET_PROPERTY_DETAIL';
export const SET_UNIT_DETAIL = 'SET_UNIT_DETAIL';
export const GET_PREVIOUS_PAGE = 'GET_PREVIOUS_PAGE';
export const GET_CURRENT_PAGE = 'GET_CURRENT_PAGE';
export const GET_LEASE_DATA = 'GET_LEASE_DATA';
export const GET_ROLE_DATA = 'GET_ROLE_DATA';
export const GET_INACTIVE_FILTER_DATA = 'GET_INACTIVE_FILTER_DATA';
export const GET_PAGINATION_LIST_MAN = 'GET_PAGINATION_LIST_MAN';
export const GET_RENTED_PAGINATION = 'GET_RENTED_PAGINATION';
export const GET_PROMOTED_PAGINATION = 'GET_PROMOTED_PAGINATION';
export const GET_INACTIVE_PAGINATION = 'GET_INACTIVE_PAGINATION';
export const GET_NOTIFICATION_PAGINATION = 'GET_NOTIFICATION_PAGINATION';
export const GET_ACTIVELIST_STATUS = 'GET_ACTIVELIST_STATUS';
export const GET_INACTIVELIST_STATUS = 'GET_INACTIVELIST_STATUS';
export const GET_UPLOAD_MANUAL = 'GET_UPLOAD_MANUAL';
export const GET_BACK_PATH = 'GET_BACK_PATH';
export const GET_LEASE_HEADING = 'GET_LEASE_HEADING';
export const GET_LEASE_DETAIL_PATH = 'GET_LEASE_DETAIL_PATH';
export const GET_REQUEST_PAGE = 'GET_REQUEST_PAGE';
export const GET_NOTICE_TYPE = 'GET_NOTICE_TYPE';
export const SET_PAYMENT_SUMMARY = 'SET_PAYMENT_SUMMARY';

export function setPropertyDetail(data) {
    return function (dispatch) {
        dispatch({ type: SET_PROPERTY_DETAIL, propertyDetails: data });
    };
}

export function getUnitDetail(data) {
    return function (dispatch) {
        dispatch({ type: SET_UNIT_DETAIL, unitDetails: data });
    };
}

export function getCurrentPage(data) {
    return function (dispatch) {
        dispatch({ type: GET_CURRENT_PAGE, currentPage: data });
    };
}

export function getPreviousPage(data) {
    return function (dispatch) {
        dispatch({ type: GET_PREVIOUS_PAGE, previousPage: data });
    };
}

export function getLeaseData(data) {
    return function (dispatch) {
        dispatch({ type: GET_LEASE_DATA, leaseFetchedData: data });
    };
}

export function getRole(data) {
    return function (dispatch) {
        dispatch({ type: GET_ROLE_DATA, roleFetchedData: data });
    };
}

export function inActiveUserFilter(data) {
    return function (dispatch) {
        dispatch({ type: GET_INACTIVE_FILTER_DATA, inactiveFilterFetch: data });
    };
}

export function listManagemantPagination(data) {
    return function (dispatch) {
        dispatch({ type: GET_PAGINATION_LIST_MAN, previousPageListMan: data });
    };
}

export function rentedPagination(data) {
    return function (dispatch) {
        dispatch({ type: GET_RENTED_PAGINATION, previousPageRented: data });
    };
}

export function promotedPagination(data) {
    return function (dispatch) {
        dispatch({ type: GET_PROMOTED_PAGINATION, previousPagePromoted: data });
    };
}

export function inactivePagination(data) {
    return function (dispatch) {
        dispatch({ type: GET_INACTIVE_PAGINATION, previousPageInactive: data });
    };
}

export function notificationPagination(data) {
    return function (dispatch) {
        dispatch({ type: GET_NOTIFICATION_PAGINATION, previousPageNotification: data });
    };
}

export function activeStatus(data) {
    return function (dispatch) {
        dispatch({ type: GET_ACTIVELIST_STATUS, activeListStatus: data });
    };
}
export function inactiveStatus(data) {
    return function (dispatch) {
        dispatch({ type: GET_INACTIVELIST_STATUS, inactiveListStatus: data });
    };
}

export function uploadManual(data) {
    return function (dispatch) {
        dispatch({ type: GET_UPLOAD_MANUAL, manualData: data });
    };
}

export function getBackPath(data) {
    return function (dispatch) {
        dispatch({ type: GET_BACK_PATH, backPath: data });
    };
}

export function getLeaseHeading(data) {
    return function (dispatch) {
        dispatch({ type: GET_LEASE_HEADING, leaseHeading: data });
    };
}

export function getLeaseDetailPath(data) {
    return function (dispatch) {
        dispatch({ type: GET_LEASE_DETAIL_PATH, leasePath: data });
    };
}

export function requestManagementPagination(data) {
    return function (dispatch) {
        dispatch({ type: GET_REQUEST_PAGE, requestPage: data });
    };
}

export function getNoticeType(data) {
    return function (dispatch) {
        dispatch({ type: GET_NOTICE_TYPE, noticeType: data });
    };
}

export function setPaymentSummary(data) {
    return function (dispatch) {
        dispatch({ type: SET_PAYMENT_SUMMARY, getPaymentSummary: data });
    };
}