import React, { useEffect, useState} from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Nav, Image } from 'react-bootstrap';
import { logger } from "../../../Utils";
import { postAPI } from "../../../Api";
import { Loader } from '../../../App/components';
import Layout from '../../components/Layout';

import '../style.scss';

const log = logger("PartnerResources");
const PartnerResources = (props) => {
    const [partnersList, setPartners] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    
    useEffect(() => {
        setShowLoader(true);
        postAPI(`/cms/partners/list`).then(response => {
            if (response.status === 200) {
                setPartners(response.data.data)
                setShowLoader(false);
            }
            log.info("/cms/partners/list > response ", response.data.message);
        });
    }, []);
    
    return (
        <div className="PartnerResourcesPage">
            <Layout newHeader={true}>
                <div className="PartnerResourcesPage-header">
                    <Container>
                        <div className="main-heading text-center">panda partners</div>
                        <div className="sub-heading text-center">Trusted partners who can help make your rental experience</div>
                    </Container>
                </div>

                <div className="PartnerResourcesPage-contentBox">
                    <Container>
                        <Row>

                            {(partnersList && partnersList.length > 0) ? partnersList.map((partner, i) => {
                                return <Col key={'data' + i} md={4} className="grid mb-4">
                                    <div className="resources-box">
                                        <div className="resources-box-image">
                                            <Image className="grid-img" src={partner.image_url ? partner.image_url : require("../../../_assets/images/placeholder.jpg")} alt="grid-img" />
                                        </div>
                                    <h4 className="grid-name">{partner.company_name}</h4>
                                    <p className="grid-content">{partner.company_bio}</p>
                                    <div className="mt-auto">

                                    <Nav.Link className="date-text" href={`/resources/partners/preview/${partner.id}`}>Read more 
                                        <Image
                                            className="pl-2 next"
                                            src={require("../../../_assets/images/arrow-forward.svg")}
                                            alt='View More'
                                        />
                                    </Nav.Link>
                                    </div>
                                    </div>
                                </Col>
                            }) : showLoader ? <Loader /> : <h2>No Data Found</h2>}

                        </Row>
                    </Container>
                </div>
            </Layout>            
        </div>
    )
}

export default withRouter(PartnerResources)
