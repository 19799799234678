import React, { useState, useEffect } from "react";
import { Col, Row, Form, Image } from "react-bootstrap";
import { LargeButton, CancelButton, SidePanel } from "../../../components";
import { getAPI, postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import { toaster, validate } from "../../../../Utils";
import { LeasePdfViewSchema } from "./CreateLeaseSchema/LeasePdfViewSchema";


const SignLease = (props) => {
    const { isShow, handleClose, openFinalCreateLease, lease_id, setLeasePdfViewScreen, setSignOffline } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [full_name_sign_Lease, setFullName] = useState('');
    const [full_name_sign_LeaseUrl, setFullNameUrl] = useState(null);
    const [submitLoader, setSubmitLoader] = useState(false);

    const clearValidationError = () => {
        let ele = document.getElementById('full_name_sign_Lease');
        let classNames = ele.className.split(" ");
        let errorIndex = classNames.indexOf('is-invalid');
        if (errorIndex !== -1) {
            classNames.splice(errorIndex, 1);
            ele.className = classNames.join(" ");
        }
        let errorEle = document.getElementById('error_full_name_sign_Lease');
        if (errorEle) {
            errorEle.remove();
        }
    }

    const handleCloseConfirm = () => {
        clearValidationError();
        handleClose();
    }

    const createSignImage = (value) => {
        const textToImage = require('text-to-image');
        textToImage.generate('\n\t' + value, {
            fontFamily: "Broetown-Signature",
            customHeight: 70,
            textAlign: 'center',
            fontSize: 35,
            fontWeight: 600,
            lineHeight: 15,
            margin: 20,
            textColor: "black"
        }).then(function (dataUri) {
            setFullNameUrl(dataUri);
        });
    }

    const onChangeInput = (e) => {
        validate(LeasePdfViewSchema)
        setFullName(e.target.value)
        createSignImage(e.target.value)
    }

    const skipAction = () => {
        let url = `/landlord/listing/lease/sign-lease-offline/${lease_id}`
        getAPI(url).then(res => {
            if (res.status === 200) {
                handleClose();
                setSignOffline(true);
                setLeasePdfViewScreen(true);
            } else if (res.response.status === 400) {
                toaster('error', res.response.data.message);
            } else if (res.response.status === 422) {
                toaster('error', res.response.data.message);
            }
        })

    }

    const createSignLease = () => {
        if (validate(LeasePdfViewSchema)) {
            setSubmitLoader(true);
            const url = "/landlord/listing/lease/sign";
            const data = {
                "lease_id": lease_id,
                "property_id": property_id,
                "property_detail_id": property_details_id,
                "landlord_lease_signature": full_name_sign_LeaseUrl
            }
            postAPI(url, data).then(res => {
                setSubmitLoader(false);
                if (res.status === 200) {
                    handleClose();
                    setSignOffline(false);
                    setLeasePdfViewScreen(true);
                } else if (res.response.status === 400) {
                    toaster('error', res.response.data.message);
                } else if (res.response.status === 422) {
                    toaster('error', res.response.data.message);
                }
            })
        }
    }

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id]);

    useEffect(() => {
        setFullName('');
        setFullNameUrl(null)
    }, [isShow])

    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleCloseConfirm}
            title="CREATE A LEASE">
            <h4 className="edit-sub">Sign your lease</h4>
            <div className="sidepanel-body">
                <Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label style={{ color: "black" }} className="text-capitalize">Type your full name for digital signature</Form.Label>
                        <Form.Control
                            type="text"
                            name="full_name_sign_Lease"
                            id="full_name_sign_Lease"
                            value={full_name_sign_Lease}
                            placeholder="e.g. John Doe"
                            maxLength="30"
                            onChange={(e) => onChangeInput(e)}
                        />
                        <p className="mt-3 disclaimer" style={{ color: "#646464" }}>Disclaimer: this is a simple electronic signature and may not constitute a legally binding agreement between tenant and landlord.
                            Rent Panda recommends that all leases be signed in person, with each party to the agreement obtaining a copy of the fully executed and legally binding lease agreement as per section 17 of the Residential Tenancy Agreement.</p>
                        <p className='skip-link' onClick={() => skipAction()}>Skip (Sign Offline)</p>

                        {full_name_sign_LeaseUrl &&
                            <Form.Group className="mt-3 signature">
                                <Form.Label style={{ color: "black" }}>Esignature</Form.Label>
                                <Image src={full_name_sign_LeaseUrl} alt="Sign Image" />
                            </Form.Group>}
                    </Form.Group>
                </Row>
            </div>
            <div className="fixed-bottom d-flex justify-content-between">
                <CancelButton onClick={() => handleCloseConfirm()} title='Back' />
                <LargeButton saveLoading={submitLoader} title='create lease' onClick={() => createSignLease()} style={{ width: '200px' }} />
            </div>
        </SidePanel>
    </>);
}
export default withRouter(SignLease);