import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import { Loader, ModalBox } from "../../../components";
import { Google_Map_API } from "../../../../Constants/endPoints";
import Geocode from "react-geocode";
import { logger, toaster } from "../../../../Utils";
import { GoogleApiWrapper } from "google-maps-react";
import { AutoCompleteWithMap } from "./AutoCompleteWithMap";
import { validate } from "../../../../Utils/Validation/validation";
import { StepOneValidationSchema } from "./ValidationSchema/StepOneValidationSchema";
import { postAPI } from "../../../../Api";
import { Prompt } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentPage } from "../../../../Store/actions/PropertyDetailAction";
import { isWithin20Km } from "./helper";


const log = logger("StepOne");
const StepOne = (props) => {
  const {
    setProperty_id
  } = props;

  const dispatch = useDispatch();
  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const property_details_id = propertyDetails?.details[0]?.id;
  const [showAreaError, setShowAreaError] = useState(false)
  const [address, setAddress] = useState("");
  const [place, setPlace] = useState("");
  const [address_line_1, setAddress_line_1] = useState("");
  const [address_line_2, setAddress_line_2] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [latitude, setLatitude] = useState(34.059849);
  const [longitude, setLongitude] = useState(-118.455551);
  const [hide_exact_address, setHide_exact_address] = useState(false);
  const [property_type, setProperty_type] = useState(false);
  const [is_condo, setIs_condo] = useState(false);
  const [validAddress, setValidAddress] = useState('');
  const [mapPosition, setMapPosition] = useState({
    lat: "",
    lng: "",
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: "",
    lng: "",
  });
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [addressIsValid, setAddressIsValid] = useState(false);

  log.info("propertyDetails", propertyDetails);

  const getAddress = (latitude, longitude) => {
    Geocode.setApiKey(Google_Map_API);
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        const address = response.results[0];
        setPlace(address);
        // setShouldBlockNavigation(false);
      },
      (error) => {
        console.error(error);
      }
    );
    setShouldBlockNavigation(true);
  };

  const OnChangeInput = (event) => {
    if (event.target.name === "address") {
      setAddress(event.target.value);
    } else if (event.target.name === "property_type") {
      setProperty_type(event.target.value);
    }
    setShouldBlockNavigation(true);
  };

  const OnCheckInput = (event) => {
    if (event.target.name === "hide_exact_address") {
      setHide_exact_address(event.target.checked);
    } else if (event.target.name === "is_condo") {
      setIs_condo(event.target.checked);
    }
    setShouldBlockNavigation(true);
  };

  const onSubmit = (type) => {
    if (!addressIsValid) {
      document.getElementById("address").classList.add("is-invalid");
      toaster("error", "Please select a valid address.");
      return false;
    }
    if (validate(StepOneValidationSchema)) {
      const url = "/landlord/create-listing/step-1";
      // const defaultAddress = `${address_line_1},${address_line_2}, ${province.slice(0,2).toUpperCase()}, ${postal_code}, ${country}`
      // console.log(address)
      // console.log(place)
      const data = {
        address: address ? address : place.formatted_address, 
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        city: city,
        province: province,
        country: country,
        postal_code: postal_code,
        latitude: latitude,
        longitude: longitude,
        //unit_no: unit_no,
        hide_exact_address: hide_exact_address,
        property_type: property_type,
        is_condo: is_condo,
      };
      if (property_id) {
        data.property_id = property_id;
      }
      if (property_details_id) {
        data.property_details_id = property_details_id;
      }
      postAPI(url, data).then((response) => {
        log.info("data", response.status);
        if (response.status !== 200) {
          toaster('error', response.response.data.message);
        } else if (response.status === 200) {
          setProperty_id(response.data.data.property_id);
          toaster("success", "Data Saved Successfully!");
          setShouldBlockNavigation(false);
          if (type === "next") {
            dispatch(getCurrentPage(1));
          } else {
            dispatch(getCurrentPage(1));
            dispatch(getCurrentPage(0));
          }
        }
      });
    }
  };

  const getPlaceDetails = (addressArray) => {
    let postalCode=''
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] &&
        "locality" === addressArray[i].types[0] &&
        addressArray[i].long_name) {
        setCity(addressArray[i].long_name);
      } else if (addressArray[i].types[0] &&
        "administrative_area_level_1" === addressArray[i].types[0] &&
        addressArray[i].long_name
      ) {
        setProvince(addressArray[i].long_name);
      } else if (
        addressArray[i].types[0] &&
        "country" === addressArray[i].types[0] &&
        addressArray[i].long_name
      ) {
        setCountry(addressArray[i].long_name);
      } else if (
        addressArray[i].types[0] &&
        "postal_code" === addressArray[i].types[0] &&
        addressArray[i].long_name
      ) {
        postalCode=addressArray[i].long_name
        setPostal_code(postalCode);
      }
    }
    if(!postalCode)
    {
      setShowAreaError('POSTAL_CODE')
    }
  };

  const updateField = (e) => {
    e.preventDefault();
    //setAddress(e.target.value);
    setPlace(e.target.value);
    if (e.target.value !== validAddress) {
      setAddressIsValid(false);
    } else {
      setAddressIsValid(true);
    }
  };

  const handlePlaceChanged = (place) => {
    const latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    if (!isWithin20Km(latValue, lngValue)) {
      setShowAreaError(true)
      return
    }
    const address = place.formatted_address;
    //setAddress(address);
    setValidAddress(address);
    setAddressIsValid(true);

    setPlace(place);
    setLatitude(latValue);
    setLongitude(lngValue);
    setMapPosition({
      ...mapPosition,
      lat: latValue,
      lng: lngValue,
    });
    setMarkerPosition({
      ...markerPosition,
      lat: latValue,
      lng: lngValue,
    });
  };

  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker);
    setSelectedPlace(props);
    setShowingInfoWindow(true);
  };
  const onInfoWindowClose = () => {
    setActiveMarker(null);
    setShowingInfoWindow(false);
  };

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const latValue = latLng.lat();
    const lngValue = latLng.lng();
    Geocode.setApiKey(Google_Map_API);
    Geocode.enableDebug();
    Geocode.fromLatLng(latValue, lngValue).then(
      (response) => {
        const place = response.results[0];
        const address = response.results[0].formatted_address;
        //setAddress(address);
        setPlace(place);
        setLatitude(latValue);
        setLongitude(lngValue);

        setMapPosition({
          ...mapPosition,
          lat: latValue,
          lng: lngValue,
        });
        setMarkerPosition({
          ...markerPosition,
          lat: latValue,
          lng: lngValue,
        });
      },
      (error) => { }
    );
  };

  useEffect(() => {
    console.log(place)
    if (place.formatted_address) {
      document.getElementById("address").classList.remove("is-invalid");
      //Set address_line_1, address_line_2
      const address_array = place.formatted_address.split(",");
      setAddress_line_1(address_array[0]);
      setAddress_line_2(address_array[1]);

      //Set city, province, country, postal_code
      getPlaceDetails(place.address_components);
    }
  }, [place]);

  useEffect(() => {
    if (propertyDetails && propertyDetails.id) {
      getAddress(propertyDetails.latitude, propertyDetails.longitude);
      setMapPosition({
        lat: propertyDetails.latitude,
        lng: propertyDetails.longitude,
      });
      setMarkerPosition({
        lat: propertyDetails.latitude,
        lng: propertyDetails.longitude,
      });
      setAddress_line_1(propertyDetails.address_line_1);
      setAddress_line_2(propertyDetails.address_line_2);
      setCity(propertyDetails.city);
      setProvince(propertyDetails.province);
      setCountry(propertyDetails.country);
      setPostal_code(propertyDetails.postal_code);
      setLatitude(propertyDetails.latitude);
      setLongitude(propertyDetails.longitude);
      //setUnit_no(propertyDetails.unit_no);
      setHide_exact_address(propertyDetails.hide_exact_address);
      setAddress(propertyDetails.address);
      setProperty_type(propertyDetails.property_type);
      setIs_condo(propertyDetails.is_condo);
    }
    if (propertyDetails?.address) {
      setAddressIsValid(true)
    }
  }, [propertyDetails]);

  useEffect(() => {
    !hide_exact_address && setAddress(address)
  },[hide_exact_address, address])


  const onGotIt = () => {
    if(document.getElementById('address'))
      {
        const addressElem=  document.getElementById('address')
        addressElem.value=""
        setAddress("")
        setPlace("")
      }
    setShowAreaError(false)
  }

  return (
    <>
      <Prompt
        when={shouldBlockNavigation}
        message="your information will be lost do you want to leave this page?"
      />

      <div className="inner-wrapper cu-step-form-body">
        <div className="body-container">
          <div className="body-header">
            <h3>enter property location</h3>
            <span className="save-link" onClick={() => onSubmit()}>
              Save
            </span>
          </div>
          <Form id="Form">
            <Form.Group>
              <AutoCompleteWithMap
                google={props.google}
                height="200px"
                zoom={15}
                displayTitle={"Address (please select the suggested addresses)"}
                address={address}
                place={place}
                mapPosition={mapPosition}
                showingInfoWindow={showingInfoWindow}
                selectedPlace={selectedPlace}
                activeMarker={activeMarker}
                updateField={(e) => updateField(e)}
                handlePlaceChanged={(data) => handlePlaceChanged(data)}
                onMarkerClick={(props, marker) => onMarkerClick(props, marker)}
                onInfoWindowClose={(data) => onInfoWindowClose(data)}
                onMarkerDrag={(t, map, coord) => onMarkerDragEnd(coord)}
              />
            </Form.Group>

            <Form.Group controlId="formBasicCheckbox2">
              <Form.Check
                type="checkbox"
                name="hide_exact_address"
                label="Hide exact address"
                checked={hide_exact_address}
                onChange={(e) => OnCheckInput(e)}
              />
            </Form.Group>

            {hide_exact_address ? (
              <Row>
                <Form.Group as={Col} md="6">
                  <Form.Label className='d-flex justify-content-between'><span>Publicly Displayed Address</span> <span>{address.length + '/125'}</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={address}
                    id='address'
                    maxLength='125'
                    placeholder="Enter a title you want potential tenants to see"
                    onChange={(e) => OnChangeInput(e)}
                  />
                </Form.Group>
              </Row>
            ) : (
              ""
            )}

            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Please indicate if this is a:</Form.Label>
                <Form.Control
                  as="select"
                  className="my-1 mr-sm-2"
                  id="property_type"
                  custom
                  name="property_type"
                  value={property_type}
                  onChange={(e) => OnChangeInput(e)}
                >
                  <option value="">Select One</option>
                  <option value="1">Full property</option>
                  <option value="2">Unit in building/house</option>
                  <option value="3">Room for rent</option>
                </Form.Control>
              </Form.Group>
            </Row>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                name="is_condo"
                label="This property is a condo"
                checked={is_condo}
                onChange={(e) => OnCheckInput(e)}
              />
            </Form.Group>
          </Form>
        </div>
      </div>

      <div className="container-footer cu-step-form-footer">
        <Button
          variant="success"
          type="submit"
          onClick={() => onSubmit("next")}
        >
          Next
        </Button>
      </div>
      <ModalBox
        show={showAreaError}
        onHide={onGotIt}
        size='sm'
        actionbuttontitle={'got it'}
        buttonaction={onGotIt}
      >
        {
          showAreaError === 'POSTAL_CODE' ?
            <b style={{ fontSize: 20 }}>
              Please select valid address.
           </b>
            :
            <>
              <b style={{ fontSize: 20 }}>
                oh no! your place is out of our range...for now.
        </b>

              <p style={{ marginTop: 10 }}>
                rent panda has a team on the ground in all of our communities, but unfortunately we aren’t servicing your area just yet. We are expanding quickly and hope to be there soon. we will reach
                out when we come to your neighbourhood and can’t wait to connect!
        </p>
            </>
        }
      </ModalBox>

    </>
  );
};

export default GoogleApiWrapper({
  apiKey: Google_Map_API,
  LoadingContainer: Loader,
})(StepOne);
