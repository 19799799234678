import React from 'react';
import './style.scss';
import FileUpload from '../FileUpload';
import { Image, ProgressBar } from 'react-bootstrap';
import { logger } from '../../../Utils';
import DNDContainer from '../DNDContainer/'
import Loader from '../Loader';


const FileBrowse = (props) => {
	const { showImageLoading, errorClass, percent, loaderStyle = {}, lableClass, handleDrop, multiple, disable, imageData, removeImage, fileType, size, title, fixFor, onDragEnd = () => { } } = props;

	return (
		<div className={imageData && imageData.length > 0 ? 'uploadOuter less-padding' : 'uploadOuter'} >
			{showImageLoading && <div className={'imgloader'} style={{ ...loaderStyle }}>
				<div className={'loadingtext'}>
					<Loader />
				</div>
			</div>}
			<div className={'browse-outer-wrapper ' + errorClass}>
				<div className='image-outer-wrapper'>
					{/* {section === 'upload-lease' ? '' : */}
					<>
						{fixFor === '1843' &&
							(<div className='image-inner-wrapper' id="fake-loader-wrapper">
								{
									(multiple && !imageData[0] && percent > 0) &&
									<div className={'display-image'} style={{ padding: '39px 0' }}>
										<ProgressBar striped variant='success' now={percent} />
									</div>
								}
							</div>)
						}

						{size === 'sm' ?
							imageData &&
							<div className='image-inner-wrapper' style={{ width: '100%' }}>
								<Image className={'display-image ' + (size === 'sm' && 'full')} src={imageData} alt={'image_'} />
								<Image className='remove-icon'
									src={require('../../../_assets/images/remove-icon.svg')}
									alt='remove-icon'
									onClick={() => removeImage(1)} />
							</div>
							:
							<DNDContainer
								onDragEnd={onDragEnd}
								items={imageData}
								renderItem={(img, i) => {
									return <div className='image-inner-wrapper' key={'image-wrap-' + i}>
										{percent ?
											<div className={'display-image'} style={{ padding: '39px 0' }}>
												<ProgressBar striped variant='success' now={percent} />
											</div> :
											<div className={'display-image'} style={{ backgroundImage: `url(${img})` }}>
												<Image className='remove-icon'
													src={require('../../../_assets/images/remove-icon.svg')}
													alt='remove-icon'
													onClick={() => removeImage(i)} />
											</div>
										}
									</div>
								}}
							/>
						}
					</>
					{/* } */}
				</div>

				<FileUpload
					handleDrop={handleDrop}
					fileType={fileType}
					multipleFiles={multiple}
					disable={disable ? true : false}
					percent={percent}
					html_view={
						<>
							{
								!multiple && !imageData ?
									<div className={'browse-wrapper ' + (size === 'sm' ? 'small-wrapper' : '')}>
										{!showImageLoading && <>
											<img src={require('../../../_assets/images/upload-icon.svg')} width='30' height='30' className='img-responsive dragimgicon' alt='upload' />
											<span className={`browse ${lableClass}`}>{title ? title : 'Browse to upload'}</span>
										</>
										}
									</div> :
									multiple &&
									<div className={'browse-wrapper ' + (size === 'sm' ? 'small-wrapper' : '')}>
										{!showImageLoading && <>
											<img src={require('../../../_assets/images/upload-icon.svg')} width='30' height='30' className='img-responsive dragimgicon' alt='upload' />
											<span className={`browse ${lableClass}`}>{title ? title : 'Browse to upload'}</span>
										</>}
									</div>
							}

						</>
					}
				/>
			</div>
		</div >
	);
}

export default FileBrowse;