import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Image } from 'react-bootstrap';
import { Nav } from "react-bootstrap";
import "./style.scss";
class JombotronSection extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="sec-jombotron">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h2 className="jombotron-title text-left">rent panda likes<br />
                  <span>finding great<br />tenants.</span>
                </h2>
                <div className="text-center mb-4 d-lg-none">
                  <Image src={require("../../../../../_assets/images/landing-pages/landing-banner.png")} alt="Banner" />                  
                </div>
                <h5 className="jombotron-sub-title f-secondary-b">do it yourself or leave it to us.</h5>
                <p className="jombotron-disc-title">Renting can really suck. But it doesn’t have to. With the right tools<br />and team, renting can feel as good as a warm panda hug.</p>
                <div className="">
                  <Nav.Link href="/signup/landlord" className="btn btn-primary d-block d-md-inline-block mr-md-3 text-transform-none border-0">create a free listing</Nav.Link>
                  {/* <Nav.Link href="/property-management/inquiry" className="btn outline-btn-primary d-block d-md-inline-block text-center">let us do the work</Nav.Link> */}
                  <a href="#letUsDoWork" className="btn outline-btn-primary d-block d-md-inline-block text-center nav-link">let us do the work</a>
                </div>
                
              </div>
              <div className="col-lg-5">
                <div className="text-center d-none d-lg-block">
                  <Image src={require("../../../../../_assets/images/landing-pages/landing-banner.png")} alt="Banner" />                  
                </div>
              </div>
            </div>
            <div className="text-center searching-home-text">
              <a href="/tenant"><u>searching for a home? click here</u></a>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withRouter(JombotronSection);
