import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { DatePickerComponent, DropDown, HighlightContent, SearchInput } from '../../components';
import './style.scss';
import { Dropdown, DropdownButton, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { setFilters } from '../../../Store/actions/ListingAction';
import { logger } from '../../../Utils';

const log = logger("ListManagementTable")
const ListManagementTable = (props) => {
	const { clickNext, clickPrev, columns, goToProfileDetail, sort_by_status, sortStatus } = props;
	const sort_price = [
		{ value: 'low_to_high', display: 'Lowest to highest' },
		{ value: 'high_to_low', display: 'Highest to lowest' },
		{ value: 'all', display: 'All' }
	];
	const sort_status = [
		{ value: 'for_rent', display: 'For Rent', isChecked: false },
		{ value: 'need_approval', display: 'Needs approval', isChecked: false },
		{ value: 'in_progress', display: 'In Progress', isChecked: false },
		{ value: 'deactivated', display: 'Deactivated', isChecked: false },
		{ value: 'rented', display: 'Rented', isChecked: false },
		{ value: 'all', display: 'View All', isChecked: false }
	];
	const dispatch = useDispatch();
	const filters = useSelector(state => state.listingReducer.filters);
	const currentComponent = useSelector(state => state.listingReducer.currentComponent);
	const listingTableData = useSelector(state => {
		switch (currentComponent) {
			case 'rented': { return state.listingReducer.rentedTableData; }
			case 'promoted': { return state.listingReducer.promotedTableData; }
			case 'inactive': { return state.listingReducer.inactiveTableData; }
			default: { return state.listingReducer.listingTableData; }
		}
	});
	const tableData = listingTableData.data && listingTableData.data;
	const to = listingTableData && listingTableData.to;
	const from = listingTableData && listingTableData.from;
	const current_page = listingTableData && listingTableData.current_page;
	const last_page = listingTableData && listingTableData.last_page;
	const total = listingTableData && listingTableData.total;

	const [status_filter, setStatusFilter] = useState(false);
	const [openInfo, setOpenInfo] = useState()
	const showStatusFilter = () => {
		setStatusFilter(!status_filter);
	}
	const changeStatus = (data) => {
		if (data === 1) {
			return "one";
		} else if (data === 2) {
			return "two";
		} else if (data === 3) {
			return "three";
		} else if (data === 5) {
			return "five";
		}
	}

	const [displayType, setDisplayType] = useState('Sort');
	useEffect(() => {
		sort_price.filter(data => data.value === filters.sort_by_price_order).map(filter => {
			setDisplayType(filter.display)
			return filter;
		})
	}, [filters.sort_by_price_order, sort_price]);

	// useEffect(()=>{
	//     dispatch(setFilters({...filters, searchAddress: ''}));
	// }, []);

	const onClickRole = (value, display) => {
		setDisplayType(display);
		dispatch(setFilters({ ...filters, sort_by_price_order: value }))
	}

	const propertyType = data => {
		const propType = data.property_type === 1 ? '' : (data.property_type === 2 ?
							(data.unit_name ? (isNaN(data.unit_name) ? `${data.unit_name}, ` : `Unit #${data.unit_name}, `) : 'Unit, ') :
							(data.unit_name ? `${data.unit_name}, ` : 'Room, '));
		return propType;
	}
	const handleAddress = (data, i) => {
		if (data) {
			const generatedAddress = (data.hide_exact_address === 1) ? `${propertyType(data)}${data.address_line_1}, ${data.address_line_2}, ${data.province?.slice(0,2).toUpperCase()} ${data.postal_code}, ${data.country}` : data.address;
			return generatedAddress;
		}
	}

	return (
		<div className="table-component-wrapper listing-management" style={{ overflow: 'visible', minHeight: '600px' }}>
			<div className='table-responsive fixing-height'>
				<table className="table-component">
					<thead>
						<tr className='thead-height'>
							{columns.map((data, i) => {
								return (
									<th key={'table_head_' + i} width={data.width} className={data.header !== 'Status' ? 'pr-0' : ''}>
										{data.header !== 'IsVerify' ? data.header :
											<Image src={require("../../../_assets/images/verified.png")} width={20} height={20} alt={'verified'} />
										}
										{(() => {
											switch (data.header) {
												case 'City':
													return (
														<SearchInput searchValue={filters.searchCity} setSearchValue={(value) => dispatch(setFilters({ ...filters, searchCity: value }))} placeHolder={'Search'} extraCalss="table-search-filter" />
													)
												case 'IsVerify':
													return (
														<div className="row checkbox-left check-status all-listing">
															<div className="col-12">
																<div className="form-group">
																	<div className="custom-control custom-checkbox large">
																		<input type="checkbox" className="custom-control-input" id={"customCheck" + data.header}
																			name={filters.is_verified ? filters.is_verified : 'is_verified'}
																			checked={filters.is_verified} onChange={() => dispatch(setFilters({ ...filters, is_verified: !filters.is_verified }))}
																		/>
																		<label className="custom-control-label pl-3 pt-1" style={{ textTransform: 'capitalize' }} htmlFor={"customCheck" + data.header}></label>
																	</div>
																</div>
															</div>
														</div>
													)
												case 'Address':
													return (
														<SearchInput searchValue={filters.searchAddress} setSearchValue={(value) => dispatch(setFilters({ ...filters, searchAddress: value }))} placeHolder={'Search'} extraCalss="table-search-filter" />
													)
												case 'Landlord':
													return (
														<SearchInput searchValue={filters.searchLandlord} setSearchValue={(value) => dispatch(setFilters({ ...filters, searchLandlord: value }))} placeHolder={'Search'} extraCalss="table-search-filter" />
													)
												case 'Tenant':
													return (
														<SearchInput searchValue={filters.searchTenant} setSearchValue={(value) => dispatch(setFilters({ ...filters, searchTenant: value }))} placeHolder={'Search'} extraCalss="table-search-filter" />
													)
												case 'Rentpanda Id':
													return (
														<SearchInput min_length={1} searchValue={filters.searchRentpandaId} setSearchValue={(value) => dispatch(setFilters({ ...filters, searchRentpandaId: value }))} placeHolder={'Search'} extraCalss="table-search-filter" />
													)
												case 'Price':
													return (
														<DropdownButton
															// alignLeft
															title={displayType}
															id='dropdown-menu-align-left'
															className='all-listing-module'
														>
															{sort_price.map((options, i) => {
																return <Dropdown.Item style={{ textTransform: 'capitalize' }} eventKey={options.value} onClick={() => onClickRole(options.value, options.display)} key={i}>{options.display}</Dropdown.Item>
															})}
														</DropdownButton>
													)
												case 'Expiry Date':
													return (
														<span className='custom-close-btn'>
															<DatePickerComponent
																name="filterByExpiryDate"
																id="filterByExpiryDate"
																value={filters.filterByExpiryDate}
																handleDateChange={(date) => dispatch(setFilters({ ...filters, filterByExpiryDate: date }))}
																displayText={"Select or type"}
																popperPlacement='bottom-start'
																clear={filters.filterByExpiryDate ? true : false}
															/>
														</span>
													)
												case 'Status':
													return (
														currentComponent === 'rented' || currentComponent === 'promoted' ?
															<DropdownButton
																// alignLeft
																title='View all'
																id='dropdown-menu-align-left'
																className='all-listing-module'
																disabled={true}
															>
																<Dropdown.Item></Dropdown.Item>
															</DropdownButton>
															:
															<span className="request-type">
																<DropDown close={() => setStatusFilter(false)} open={status_filter} position={"right"} enableTopArrow={false} style={{ width: "241px", top: "47px" }}
																	content={
																		<div className="row checkbox-left check-status">
																			{sort_status.map((data, i) => {
																				return <div className="col-12" key={i}>
																					<div className="form-group">
																						<div className="custom-control custom-checkbox large">
																							<input type="checkbox" className="custom-control-input" id={"customCheck" + data.value}
																								name={data.value}
																								checked={sort_by_status.includes(data.value)} onChange={sortStatus}
																							/>
																							<label className="custom-control-label pl-3 pt-1" style={{ textTransform: 'capitalize' }} htmlFor={"customCheck" + data.value}>{data.display}</label>
																						</div>
																					</div>
																				</div>
																			})}
																		</div>
																	}
																>
																	<Dropdown onClick={showStatusFilter} className="multiselect-dropdown">
																		<Dropdown.Toggle id="dropdown-basic">
																			{sort_status.filter((data) => {
																				let status = '';
																				if (sort_by_status.includes(data.value)) {
																					status = data;
																				}
																				return status;
																			}).map(function (obj) {
																				return obj.display;
																			}).join(", ")}
																		</Dropdown.Toggle>
																	</Dropdown>
																</DropDown>
															</span>
													)
												default:
													return null;
											}
										})()}
									</th>)
							})}
						</tr>
					</thead>
					<tbody>
						{tableData && tableData.map((data, i) => {
							return (
								<tr key={'table_body_' + i} onClick={() => goToProfileDetail(data)} className="cursorPointer">
									{currentComponent === 'all-listing' &&
										<td style={{ minWidth: '85px' }}>
											<Image src={data.is_verified === 0 ? require("../../../_assets/images/Unverified.png") : require("../../../_assets/images/verified.png")} width={20} height={20} alt={'verified'} />
										</td>
									}
									{currentComponent !== 'all-listing' && <td>
										<HighlightContent searchWord={filters.searchCity} highlightWord={data.city} />
									</td>}


									<td>{log.info(handleAddress(data, i))}
										<HighlightContent searchWord={filters.searchAddress} highlightWord={handleAddress(data, i)} /></td>
									<td><HighlightContent searchWord={filters.searchLandlord} highlightWord={data.landlord_name} /></td>
									{currentComponent !== "inactive" &&
										<td>{currentComponent === "promoted" ?
											<span>{`${data.expiry_date} (${data.days_left} days)`}</span>
											:
											<HighlightContent searchWord={filters.searchTenant} highlightWord={data.tenant ? data.tenant : 'None'} />}
										</td>
									}
									<td><NumberFormat value={data.price_per_month} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
									<td>RP <HighlightContent searchWord={filters.searchRentpandaId} highlightWord={data.rentpanda_id.toString()} /></td>
									{(currentComponent !== "promoted" && currentComponent !== "rented" && currentComponent !== "inactive") &&
										<td>
											<span className={`formatted-status ${data.formatted_status === "Deactivated" ? "statusDeactivated" : changeStatus(data.status)}`}>{data.formatted_status} </span>

											{data.delivered_lease_exists === true &&
												<span onMouseEnter={() => setOpenInfo(true)} onMouseLeave={() => setOpenInfo(false)}>
													<Image src={require("../../../_assets/images/alert-icon-dark.svg")} width={16} height={16} style={{ marginLeft: '10px' }} alt={'Alert Icon'} />
												</span>}
										</td>}
								</tr>)
						})}
					</tbody>
				</table>
				{tableData && tableData.length === 0 &&
					<div className="empty-table-data">
						There is no data found

                    </div>
				}
			</div>
			<div className="table-footer">
				<div>
					{tableData && (tableData.length === 0 ? <span>Data not found</span> : <span className="left">
						{tableData.length} of {total}
					</span>)}
				</div>
				<div>
					{from && to &&
						<span className="right">
							{from} to {to}
							{current_page !== from ? <img className="previous-arrow" onClick={() => clickPrev(current_page)} src={require('../../../_assets/images/next.svg')} alt="previous" /> : <img className="previous-arrow disabled" src={require('../../../_assets/images/next.svg')} alt="previous" />}
							{current_page !== last_page ? <img className="next-arrow" onClick={() => clickNext(current_page)} src={require('../../../_assets/images/next.svg')} alt="next" /> : <img className="next-arrow disabled" src={require('../../../_assets/images/next.svg')} alt="next" />}
						</span>}
				</div>
			</div>

		</div>
	)
}

export default withRouter(ListManagementTable);