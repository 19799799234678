import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { PropertyCard, Loader } from "../../components";
import { withRouter } from 'react-router-dom';
import { logger } from "../../../Utils";
import { getAPI } from "../../../Api";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const log = logger("MyListing");
const MyListing = (props) => {
    const [propertyLists, setPropertyLists] = useState(null);
    const accessToken = Cookies.get("access_token") ? Cookies.get("access_token") : null;
    const profileDetails = useSelector(state => state.editProfile.editProfileList);

    log.info("profileDetails",profileDetails);

    useEffect(() => {
      if (accessToken) {
        getData();
      }
    }, [props, accessToken])

    
    const getData = () => {
      const url = '/tenant/my-listing';
      getAPI(url).then(response => {
        const res = response.data.data;
        log.info(res);
        setPropertyLists(res)
      })
        .catch(error => {
        });
    }
  
    const propertyDetails = (details) => {
      props.history.push({
        pathname: '/property-details/' + details.property_id + '/' + details.property_detail_id + '/details',
      });
    } 
  
    const formattedDetails = (data) => {
      const {bedroom_count, property_type} = data;
      const bedRoom = bedroom_count && (bedroom_count > 1) ? (bedroom_count + ' Bedrooms') : (bedroom_count + ' Bedroom');
      // property_type {
      //   1 : 'Full Property',
      //   2 : 'Unit',
      //   3 : 'Room for Rent'
      // }
      let details = '';
      if (property_type === 3) {
        details = 'Room for Rent';
      } else if (property_type === 2) {
        details = `Unit • ${bedRoom}`;
      } else if (property_type === 1) {
        details = `Full Property • ${bedRoom}`;
      }
      return details;
    }
    
    return (
        <div className="profile-container">
        <div className="profile-container-box">
          <Container fluid>
            <Row>
              {propertyLists ?
                propertyLists.length > 0 ?
                  propertyLists.map((list, i) => {
                    return (
                      <PropertyCard
                        key={"propery_" + i}
                        id={list.property_detail_id}
                        price={list.price_per_month}
                        image_url={list.image_url}
                        isVerifed={list.is_verified === 1 ? "verified" : "unverified"}
                        title={list.address}
                        details={formattedDetails(list)}
                        onClick={() => propertyDetails(list)}
                        isLoveIcon={list.is_favorite === 1 ? true : false}
                        notification={true}
                        new_message={list.new_message}
                        new_tenancy_application={list.new_tenancy_application}
                        new_viewing_request={list.new_viewing_request}
                      />
                    )
                  }) :
                  <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '60px 0' }}>
                    <Image src={require("../../../_assets/images/no-data.svg")} />
                    <p style={{ fontSize: '25px', color: '#979797' }}>you haven't messaged anyone yet</p>
                  </Col>
                : <Loader />}

            </Row>
          </Container>
        </div>
      </div>
    );
}

export default withRouter(MyListing);