import { postAPI } from "../../../Api";

export const removeFromContact = async (conversation_id, listData) => {
    try {
        const url = '/landlord/remove-from-contact-list';
        const data = {
            "conversation_id": conversation_id
        }
        const res = await postAPI(url, data)
        if(res.status === 200){
            const newListData = listData.filter((data) => {
                return data.conversation_id !== conversation_id
            })
            return newListData
        }
        return listData
    } catch (error) {
        return listData
    }
}