import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { logger, toaster } from "../../../../Utils";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
import Moment from "react-moment";
import SignatureView from "../Signature/SignatureView";
import SignatureForm from "../Signature/SignatureForm";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";

const log = logger("N11DetailsView");
const N11DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, property_id, property_detail_id, cancelNotice } = props;

  const role = Number(Cookies.get('role'));
  const [phoneError, setPhoneError] = useState(false);
  //Signature Data
  const [signData, setSignData] = useState(null);

  log.info("signData", signData);

  const redirectToNoticeInfo = () => {
    props.history.push(`/notice-info/11`);
  };

  const disableError = (data) => {
    setPhoneError(data);
  };

  const onSubmit = () => {
    const url = "/tenant/notice/sign-notice";
    const data = {
      id: noticeData && noticeData.id,
      tenant_first_name: signData && signData?.tenant_first_name,
      tenant_last_name: signData && signData?.tenant_last_name,
      tenant_phone_number: signData && signData?.tenant_phone_number,
      tenant_sign_date: signData && signData?.tenant_sign_date,
      tenant_signature: signData && signData?.tenant_signature,
      agree_terms_and_conditions: 1,
    };

    postAPI(url, data).then((response) => {
      if (response.status === 200) {
        const notice_id = response.data.data.id;
        toaster("success", "Data Saved Successfully!");
        viewNotice(notice_id);
      }
    });
  };

  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  log.info('noticeData', noticeData);

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Agreement To End Tenancy (N11 Form)</span>
          {noticeData && noticeData.is_notice_received
            ?role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <div className="d-flex flex-column">
          <span className="field-title-text">
            When do you want your tenant to move out? (Date of Termination)
          </span>
          <span className="field-value-text">
            {<Moment format="MMMM D, YYYY">{noticeData.date}</Moment>}
          </span>
        </div>
        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />

        {role === 2 ?
          <div className="agree-info d-flex justify-content-start mt-4">
            <input
              type="checkbox"
              className="checkit"
              id={"one"}
              name={"one"}
              checked={'checked'}
              hidden
            />
            <label htmlFor={"one"} className="checkmark"></label>
            <span className="checkbox-title">
              I have read and agree with the
            </span>
            <span className="checkbox-subtitle" onClick={redirectToInfo}>
              Important information from the Landlord and Tenant Board
            </span>
          </div>
          : <p className="cursor-pointer">Landlord had read and agreed with the <u onClick={() => redirectToNoticeInfo()}>Important information from the Landlord and Tenant Board</u></p>}

        {(noticeData && noticeData.tenant_signature) &&
          <>
            <div className="border-separator"></div>
            <SignatureView noticeData={noticeData} type={'tenant'} />
            <div className="agree-info d-flex justify-content-start mt-4">
              <input
                type="checkbox"
                className="checkit"
                id={"one"}
                name={"one"}
                checked={'checked'}
                hidden
              />
              <label htmlFor={"one"} className="checkmark"></label>
              <span className="checkbox-title">
                I have read and agree with the
            </span>
              <span className="checkbox-subtitle" onClick={redirectToInfo}>
                Important information from the Landlord and Tenant Board
            </span>
            </div>
          </>}

        {((role === 3) && (noticeData && !noticeData.tenant_signature)) &&
          <>
            <div className="border-separator"></div>
            <div className="mt-4 mb-4">
              <span className="form-title">Tenant Signature</span>
            </div>
            <SignatureForm
              phoneError={phoneError}
              disableError={(data) => disableError(data)}
              type={"lastName"}
              signUserType={"tenant"}
              setSignData={(data) => setSignData(data)}
              noticeData={noticeData}
              notice_type={11}
            />
          </>}
      </div>

      <div className="container-footer button-extra-margin">
        {(noticeData.status !== 3 && role === 3) &&
          ((noticeData && noticeData.tenant_signature) ?
            <Button variant="success" onClick={() => onServeNotice()}>
              Serve Notice
            </Button>
            : <Button variant="success" onClick={() => onSubmit()}>
              Review
            </Button>
          )}

        {(noticeData.status === 0 && role === 2) &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
          </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
          </Button>}
      </div>
    </>
  );
};

export default withRouter(N11DetailsView);
