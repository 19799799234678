import React from 'react';
import AppLayout from '../AppLayout/AppLayout';
import { Image, Col } from 'react-bootstrap';

const PageNotFound = (props) => {

    return (
        <AppLayout>
            <Col className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '60px 0' }}>
                <Image src={require("../../../_assets/images/no-data.svg")} />
                <p style={{ fontSize: '46px', color: '#979797' }}>Page Not Found</p>
            </Col>
        </AppLayout>
    )
}

export default PageNotFound;