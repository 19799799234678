import React, { useState,useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { logger } from "../../../../Utils";
import LargeButton from "../../Buttons/LargeButton";
import "../style.scss";
// import { frequencyTypeDisplay } from "../../../../Constants/NoticeConstants";
import Moment from "react-moment";
import SignatureView from "../Signature/SignatureView";
import ModalBox from "../../ModalBox";
import CheckBox from "../../Checkbox/Checkbox";
import Cookies from 'js-cookie';
import { useGlobalState } from "../../../../StateContext";

const log = logger("N3DetailsView");
const N4DetailsView = (props) => {
  const {
    noticeData,
    downloadPdf,
    // redirectToInfo,
    // onServeNotice,
    getParalegal,
    cancelNotice,
    onSubmit=()=>{}
  } = props;
  const [isCheckListOpen, setIsCheckListOpen] = useState(false);
  const [non_payment_of_rent, setNon_payment_of_rent] = useState([]);
  const [globalState,setGlobalState]=useGlobalState()
  log.info("noticeData", noticeData);

  useEffect(()=>{
    setIsCheckListOpen(globalState?.showCheckList)
  },[globalState?.showCheckList])

  const toggleModal = () => {
    setIsCheckListOpen(!isCheckListOpen);
    setGlobalState({showCheckList:!isCheckListOpen})

  };
  const role = Number(Cookies.get('role'));

  const checkboxHandler = (id) => {
    let tempArray;
    tempArray = [...non_payment_of_rent];
    if (tempArray.includes(id)) {
      const index = tempArray.indexOf(id);
      if (index > -1) {
        tempArray.splice(index, 1);
      }
    } else {
      tempArray.push(id);
    }
    setNon_payment_of_rent(tempArray);
  };

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Non Payment Of Rent (N4 form)</span>
          {noticeData && noticeData.is_notice_received
            ? role !== 3 && (
              <LargeButton
                title={"get help from our paralegal"}
                style={{ width: "310px", margin: "0" }}
                onClick={getParalegal}
              />
            ) : (
              <LargeButton
                title={<div>
                  <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
                onClick={() => downloadPdf()}
                style={{
                  width: "auto",
                  height: "48px",
                  margin: "0",
                  borderRadius: "5px",
                }}
              />
            )}
        </div>
        <Row>
          <Col md={4} className="d-flex flex-column">
            <span className="field-title-text">
              How much is the total amount ($) does your tenant owe you?
            </span>
            <span className="field-value-text">
              ${Number(noticeData.total_amount_tenant_owes).toLocaleString()}
            </span>
          </Col>

          <Col md={4} className="d-flex flex-column">
            <span className="field-title-text">
              Deadline (Date of termination if tenant fails)
            </span>
            <span className="field-value-text">
              {
                <Moment format="MMMM D, YYYY">
                  {noticeData.tenant_move_out}
                </Moment>
              }
            </span>
          </Col>
        </Row>

        <p style={{ marginTop: '35px' }} className="form-title">Missed Payment Information</p>

        {noticeData.missed_date_and_owing.map((missedPayment, index) => {
          return (<>
            {(parseInt(missedPayment.start_date) !== 0) ?
              <div className="missed-information">
                <div className="missed-info-title">
                  <span>Missed Payment Information {index + 1}</span>
                </div>
                <Row>
                  <Col md={3} className="d-flex flex-column">
                    <span className="field-title-text mt-0">Rent Period</span>
                    <span className="field-value-text mt-0">
                      {
                        <Moment format="MMMM D, YYYY">
                          {missedPayment.start_date}
                        </Moment>
                      }
                      -
                      {
                        <Moment format="MMMM D, YYYY">
                          {missedPayment.end_date}
                        </Moment>
                      }
                    </span>
                  </Col>

                  <Col md={3} className="d-flex flex-column">
                    <span className="field-title-text mt-0">
                      How much was charged?
                    </span>
                    <span className="field-value-text mt-0">
                      ${Number(missedPayment.charges).toLocaleString()}
                    </span>
                  </Col>
                  <Col md={3} className="d-flex flex-column">
                    <span className="field-title-text mt-0">
                      How much was paid for this period?
                    </span>
                    <span className="field-value-text mt-0">
                      ${Number(missedPayment.paid_for_period).toLocaleString()}
                    </span>
                  </Col>
                  <Col md={3} className="d-flex flex-column">
                    <span className="field-title-text mt-0">Rent Owing</span>
                    <span className="field-value-text red-text mt-0">
                      $
                      {(
                        Number(missedPayment.charges) -
                        Number(missedPayment.paid_for_period)
                      ).toLocaleString()}
                    </span>
                  </Col>
                </Row>
              </div>
            : '' }
          </>);
        })}
        <div className="rent-owing-total">
          <span>
            Your tenant(s) owe you a total of: $
            {(
              Number(noticeData.missed_date_and_owing[0].charges) +
              Number(noticeData.missed_date_and_owing[1].charges) +
              Number(noticeData.missed_date_and_owing[2].charges) -
              (Number(noticeData.missed_date_and_owing[0].paid_for_period) +
                Number(noticeData.missed_date_and_owing[1].paid_for_period) +
                Number(noticeData.missed_date_and_owing[2].paid_for_period))
            ).toLocaleString()}
          </span>
        </div>
        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />

        {/* <div className="agree-info d-flex justify-content-start mt-4">
          <input
            type="checkbox"
            className="checkit"
            id={"one"}
            name={"one"}
            checked={"checked"}
            hidden
          />
          
        </div> */}
      </div>

      <div className="container-footer button-extra-margin">


        {noticeData.status === 0 &&
          <Button variant="success" onClick={() => setIsCheckListOpen(true)}        >
            Serve Notice
        </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
      <ModalBox
        dialogClassName="modal-90w"
        show={isCheckListOpen}
        onHide={toggleModal}
        size="xl"
      >
        <>
          <div className="checkList-agreement d-flex align-items-start flex-column">
            <div className="checkList-title">
              <span>Non Payment of Rent (n4 form) - Check List</span>
            </div>
            <div className="checkList-subtitle d-flex align-items-start flex-column">
              <span>
                Before you serve the notice to your tenant(s), <strong>make sure you can
                answer YES to each of the following questions.</strong>
              </span>
              <span>
                If not, your notice may be invalid. If you file an application
                to the Landlord and Tenant Board based on an invalid notice,
                your application may be dismissed and you will have to start
                over.
              </span>
            </div>
            <div className="checkList-checkbox ">
              <div className="d-flex flex-column align-items-start mt-3">
                <CheckBox
                  extraClass="extra-width"
                  label={
                    <strong>
                      Have you waited until the day after the rent was due to
                      give this notice to the tenant?
                    </strong>
                  }
                  name={"1"}
                  checked={non_payment_of_rent.includes("1")}
                  onChange={() => checkboxHandler("1")}
                />
                <span className="checkList-checkbox-subtitle">
                  Your tenant has until midnight on the day that rent is due to
                  pay you the rent. Make sure you wait until the day after the
                  rent was due before you give the tenant this notice.
                </span>
              </div>
              <div className="d-flex flex-column align-items-start mt-3">
                <CheckBox
                  extraClass="extra-width"
                  label={
                    <strong>
                      Did you fill in the correct termination date?
                    </strong>
                  }
                  name={"2"}
                  checked={non_payment_of_rent.includes("2")}
                  onChange={() => checkboxHandler("2")}
                />
                <span className="checkList-checkbox-subtitle">
                  If your tenant pays rent by the <b>month or year</b>, you must give
                  at least <b>14 days</b> notice. If your tenant pays rent by the <b>day
                  or week</b>, you must give at least 7 days notice. When counting
                  the days, do not include the date you are giving the notice to
                  the tenant. For example, if you give the notice to the tenant
                  by hand on March 3rd, the first day of the 14-day notice
                  period is March 4th; in this example, the earliest termination
                  date would be March 17th. <b>If you are giving the notice to the
                  tenant by mail or courier, you have to add extra days in
                  calculating the termination date</b>. Read the Instructions to
                  this form to see how many days you have to add.
                </span>
              </div>
              <div className="d-flex flex-column align-items-start mt-3">
                <CheckBox
                  extraClass="extra-width"
                  label={
                    <strong>
                      Did you name each tenant who is in possession of the
                      rental unit?
                    </strong>
                  }
                  name={"3"}
                  checked={non_payment_of_rent.includes("3")}
                  onChange={() => checkboxHandler("3")}
                />
                <span className="checkList-checkbox-subtitle">
                  If there is more than one tenant in possession of the rental
                  unit, fill in the names of all the tenants on the notice. Make
                  sure you spell each tenant's name correctly. You must give
                  each tenant a copy of this notice.
                </span>
              </div>
              <div className="d-flex flex-column align-items-start mt-3">
                <CheckBox
                  extraClass="extra-width"
                  label={
                    <strong>
                      Did you fill in the complete address of the rental unit?
                    </strong>
                  }
                  name={"4"}
                  checked={non_payment_of_rent.includes("4")}
                  onChange={() => checkboxHandler("4")}
                />
                <span className="checkList-checkbox-subtitle">
                  Be sure that you have provided the full address - be sure to
                  also identify the correct rental unit (for example Unit 202 or
                  Basement) and provide the postal code.
                </span>
              </div>
              <div className="d-flex flex-column align-items-start mt-3">
                <CheckBox
                  extraClass="extra-width"
                  label={<strong>Did you check your math?</strong>}
                  name={"5"}
                  checked={non_payment_of_rent.includes("5")}
                  onChange={() => checkboxHandler("5")}
                />
                <span className="checkList-checkbox-subtitle">
                  Make sure you have correctly calculated the amount you believe
                  the tenant owes.
                </span>
              </div>
              <div className="d-flex flex-column align-items-start mt-3">
                <CheckBox
                  extraClass="extra-width"
                  label={<strong>Did you include only rent amounts?</strong>}
                  name={"6"}
                  checked={non_payment_of_rent.includes("6")}
                  onChange={() => checkboxHandler("6")}
                />
                <span className="checkList-checkbox-subtitle">
                  This form is only for non-payment of rent. Rent includes the
                  basic rent for the rental unit, plus any amount the tenant
                  pays you separately for services (such as parking). If the
                  tenant is paying all or a portion of a utility bill directly
                  to the utility company or indirectly through the landlord,
                  this is not considered rent. See the Instructions for more
                  information. You should not use this form to ask the tenant to
                  pay amounts other than rent (such as the last month's rent
                  deposit or an NSF cheque charge).
                </span>
              </div>
              <div className="d-flex flex-column align-items-start mt-3">
                <CheckBox
                  extraClass="extra-width"
                  label={<strong>Did you sign and date the notice?</strong>}
                  name={"7"}
                  checked={non_payment_of_rent.includes("7")}
                  onChange={() => checkboxHandler("7")}
                />
                <span className="checkList-checkbox-subtitle">
                  If you don't, the notice may be invalid.
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button className="back-to-review-btn" onClick={toggleModal}>Back To Review</button>
            <button disabled={non_payment_of_rent.length === 0} className={`cnf-btn ${non_payment_of_rent.length === 0 ? 'cnf-btn-disabled' : ''}`} variant="success"
              onClick={() => onSubmit(non_payment_of_rent)}>
              Confirm
            </button>
          </div>
        </>
      </ModalBox>
    </>
  );
};

export default N4DetailsView;
