import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { LargeButton, CancelButton, SidePanel, ModalBox, PDFViewerComponent } from "../..";
import { Col, Image, Form } from "react-bootstrap";
import { getAPI, postAPI } from "../../../../Api";
import { withRouter } from "react-router-dom";
import { logger, toaster, validate } from "../../../../Utils";
import Loader from "../../Loader";
import { LeasePdfViewSchema } from "./CreateLeaseSchema/LeasePdfViewSchema";

const log = logger("LeasePdfView");
const LeasePdfView = (props) => {
    const { isShow, handleClose, lease_id, role, is_signOffline, handleBack } = props;
    const [property_id, setProperty_id] = useState('');
    const [property_details_id, setProperty_details_id] = useState('');
    const [pdfData, setPdfData] = useState(null);
    const [openSucessModal, setOpenSucessModal] = useState(false);
    const [full_name_sign_Lease, setFullName] = useState('');
    const [full_name_sign_LeaseUrl, setFullNameUrl] = useState(null);
    const [openTenantSucessModal, setOpenTenantSucessModal] = useState(false);
    const [openPDFView, setOpenPDFView] = useState(false);
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false);
    const createSignImage = (value) => {
        const textToImage = require('text-to-image');
        textToImage.generate('\n\t' + value, {
            fontFamily: "Broetown-Signature",
            customHeight: 70,
            textAlign: 'center',
            fontSize: 35,
            fontWeight: 600,
            lineHeight: 15,
            margin: 20,
            textColor: "black"
        }).then(function (dataUri) {
            setFullNameUrl(dataUri);
        });
    }

    const onChangeInput = (e) => {
        validate(LeasePdfViewSchema)
        setFullName(e.target.value)
        setErrors({})
        createSignImage(e.target.value)
    }

    const handleCloseConfirm = (action = 'close') => {
        if (action === 'back') {
            handleClose();
            handleBack && handleBack();
        } else {
            createSignImage('');
            setFullName('');
            handleClose();
        }

    }

    const onButtonAction = () => {
        setOpenSucessModal(false);
        backToLease();
    }

    const onDownload = () => {
        setOpenPDFView(true);
        // handleClose();
    }

    const handleOpenTenantSucessModal = (data) => {
        setOpenTenantSucessModal(data);
        props.history.push({
            pathname: `/maintainance-repair/${property_id}/${property_details_id}/overview`
        })
    }

    const submit = () => {
        // if (!full_name_sign_Lease?.length && role === 3) {
        //     setErrors({ full_name_sign_Lease: 'please enter your full name' })
        //     return
        // }
        setLoading(true);
        if (role === 2) {
            if (!props.is_sent_to_tenant || props.isUpdated === true) {
                const url = "/landlord/listing/lease/create-lease";
                const data = {
                    "lease_id": lease_id,
                    "property_id": property_id,
                    "property_detail_id": property_details_id
                }
                postAPI(url, data).then(res => {
                    if (res.status === 200) {
                        handleClose();
                        setOpenSucessModal(true);
                    } else if (res.response.status === 400) {
                        log.info(res.response)
                        toaster('error', res.response.data.message);
                    } else if (res.response.status === 422) {
                        toaster('error', res.response.data.message);
                    }
                    setLoading(false);
                })
            }
        } else if (validate(LeasePdfViewSchema)) {
            const url = "/tenant/listing/lease/signed-tenant-lease";
            const data = {
                "lease_id": lease_id,
                "property_id": property_id,
                "property_detail_id": property_details_id,
                "tenant_lease_signature": full_name_sign_LeaseUrl
            }
            postAPI(url, data).then(res => {
                if (res.status === 200) {
                    handleClose();
                    setOpenTenantSucessModal(true);
                }
                if (res.response?.status === 400) {
                    toaster('error', res.response.data.message);
                }
                setLoading(false);
            })
        }
    }

    const backToLease = () => {
        props.history.push({
            pathname: `/property-details/${property_id}/${property_details_id}/leases`
        })
    }

    useEffect(() => {
        if (property_details_id && (lease_id && lease_id !== 0 && isShow)) {
            let url = '';
            if (role === 2) {
                url = `/landlord/listing/lease/view-appendix/${property_details_id}/${lease_id}`;
            } else {
                url = `/tenant/listing/lease/view-appendix/${property_details_id}/${lease_id}`;
            }

            getAPI(url).then(res => {
                if (res.status === 200) {
                    const fetchedData = res.data.data;
                    setPdfData(fetchedData);
                    log.info('fetchedData', fetchedData);
                }
            })
        }
    }, [lease_id, property_details_id, role, isShow]);

    useEffect(() => {
        setProperty_id(Number(props.match.params.property_id));
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props.match.params.property_id, props.match.params.property_details_id, props.match.params.lease_id]);
    log.info('propertyDetails', propertyDetails);
    log.info('is_signOffline', is_signOffline);
    return (<>
        <SidePanel
            isShow={isShow}
            isHide={handleCloseConfirm}
            title={propertyDetails && role !== 2 ? "Lease for " + propertyDetails.address_line_1 : "CREATE A LEASE"}
            subTitle={role === 2 ? "Download The Lease" : ""}>
            <div className="sidepanel-body lease-pdf-view">
                {pdfData ?
                    <Col className='confirm-section mt-3 d-flex justify-content-start'>
                        <Image className="icon" src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
                        <div className='file-details'>
                            {pdfData.lease_pdf_url.split('/').pop()}<br />
                            <Image className="icon" src={require("../../../../_assets/images/download.svg")} alt="Download" />
                            <span className='file-details-link' onClick={() => onDownload()}>Download</span>
                        </div>
                    </Col> : <Loader />}
                {role === 2 &&
                    <p className="mt-3 disclaimer">Disclaimer: Please ensure that you and your tenant have signed the lease (digitally or in person) and both of you keep a digital and hard copy of the lease for your records.
                    If you have signed the lease offline, ensure you provide your tenant with a copy of the signed lease within 21 days of signing.</p>}

                {role === 3 &&
                    (is_signOffline ?
                        <p className="mt-2">The landlord has indicated that this lease will be signed in person (offline). This is just your copy for review.</p>
                        : <>
                            <Form.Group className="mt-3">
                                <Form.Label style={{ color: "black" }} className="text-capitalize">Type your full name for digital signature</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="full_name_sign_Lease"
                                    id="full_name_sign_Lease"
                                    value={full_name_sign_Lease}
                                    placeholder="e.g. John Doe"
                                    maxLength="30"
                                    onChange={(e) => onChangeInput(e)}
                                />
                            </Form.Group>
                            <p style={{ color: "#646464" }}>If you have any problems, please contact the landlord before signing</p>

                            {full_name_sign_LeaseUrl &&
                                <Form.Group className="mt-3 signature">
                                    <Form.Label style={{ color: "black" }}>Esignature</Form.Label>
                                    <Image src={full_name_sign_LeaseUrl} alt="Sign Image" />
                                </Form.Group>}
                        </>)}
            </div>
            {role === 2 &&
                <div className="fixed-bottom">
                    {props.is_sent_to_tenant && props.isUpdated === false && <div className="d-block disclaimer font-italic pt-2 text-center">You have already sent the lease to the tenant(s)</div>}
                    <div className="d-flex justify-content-between">
                        <CancelButton onClick={() => handleCloseConfirm('back')} title='Back' />
                        <LargeButton saveLoading={loading} isDisable={props.is_sent_to_tenant && props.isUpdated === false ? true : false} title={'send to tenant'} onClick={(e) => submit(e)} style={{ width: '200px' }} extraClass={props.is_sent_to_tenant && props.isUpdated === false ? 'disabled' : ''} />
                    </div>
                </div>
            }

            {(role === 3 && !is_signOffline) &&
                <div className="fixed-bottom d-flex justify-content-between">
                    <CancelButton onClick={() => handleCloseConfirm('back')} title='Back' />
                    <LargeButton title={'submit'} onClick={() => submit()} style={{ width: '200px' }} />
                </div>}
        </SidePanel>

        <ModalBox
            show={openSucessModal}
            onHide={() => setOpenSucessModal(false)}
            size="sm"
            actionbuttontitle={"got it"}
            buttonaction={() => onButtonAction()}
        >
            {is_signOffline ?
                <>
                    <h4>you have sent the lease to the tenant(s) for offline signing</h4>
                    <p>once signed, the tenant can send it to<br />your on Rent panda and you will be<br />notified to sign it</p>
                </>
                : <>
                    <h4>Congrats on renting your place!</h4>
                    <p>You have uploaded a lease and sent it to the tenant for their records. make sure that you and the tenant have copies of the signed lease for your records.<br /> <br />
                </p>
                </>}
        </ModalBox>

        <ModalBox
            show={openTenantSucessModal}
            onHide={() => setOpenTenantSucessModal(false)}
            size="sm"
            actionbuttontitle={"got it"}
            buttonaction={() => handleOpenTenantSucessModal(false)}
        >
            <h4>Congrats on signing the lease and finding the perfect place to call home.</h4>
            <p>the listing has now been moved to the “my home” section of your dashboard.<br /><br />
            from here you can view the details of your lease, message the landlord, notify them of repairs and give your notice when you decide to leave the home.</p>
        </ModalBox>

        <ModalBox
            show={openPDFView}
            onHide={() => setOpenPDFView(false)}
            size="xl"
            className="cu-full-modal"
        >
            <PDFViewerComponent PDFUrl={pdfData && pdfData.lease_pdf_url} />
        </ModalBox>
    </>);
}
export default withRouter(LeasePdfView);