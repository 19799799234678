import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Nav, Image } from 'react-bootstrap';
import { logger } from "../../../Utils";
import { postAPI } from "../../../Api";
import { Loader } from '../../../App/components';
import Layout from '../../components/Layout';

import '../style.scss';

const log = logger("BlogResources");
const BlogResources = (props) => {
    const [articlesList, setArticlesList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    
    useEffect(() => {
        setShowLoader(true);
        postAPI(`/cms/articles/list`).then(response => {
            if (response.status === 200) {
                setArticlesList(response.data.data)
                setShowLoader(false);
            }
            log.info("/cms/articles/list > response ", response.data.message);
        })
    }, [])

    return (
        <div className="BlogResourcesPage">
            <Layout newHeader={true}>
                <div className="BlogResourcesPage-header">
                    <Container>
                        <div className="main-heading text-center">the blog</div>
                        <div className="sub-heading text-center">Tips, tricks, how-tos, rental industry news and general thoughts from Rent Panda.</div>
                    </Container>
                </div>

                <div className="BlogResourcesPage-contentBox">
                    <Container>
                        <Row>
                            {(articlesList && articlesList.length > 0) ? articlesList.map((article, i) => {
                                return <Col key={'article' + i} md={4} className="grid mb-4">
                                    <div className="resources-box">
                                        <div className="resources-box-image">
                                            <Image className="grid-img" src={article.image_url ? article.image_url : require("../../../_assets/images/placeholder.jpg")} alt="grid-img" />
                                        </div>                                        
                                        <h4 className="grid-name">{article.headline}</h4>
                                        <p className="grid-content">{article.excerpt}</p>
                                        <div className="mt-auto">
                                            <Nav.Link className="date-text" href={`/resources/blog/preview/${article.id}`}>Read more
                                                <Image
                                                    className="pl-2 next"
                                                    src={require("../../../_assets/images/arrow-forward.svg")}
                                                    alt='View More'
                                                />
                                            </Nav.Link>
                                        </div>
                                        
                                    </div>

                                </Col>
                            }) : showLoader ? <Loader /> : <h2>No Data Found</h2>}

                        </Row>
                    </Container>
                </div>
            </Layout>            
        </div>
    )
}

export default withRouter(BlogResources)
