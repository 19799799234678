import React, { useState } from "react";
import { Col, Row, Navbar, NavDropdown } from "react-bootstrap";
import '../style.scss';
import Moment from 'react-moment';
import { useDispatch, useSelector } from "react-redux";
import { setViewingRequestId, getCalenderData, setReceiverData, setConversationId } from "../../../../Store/actions/MessageDetailAction";


const CalenderListView = (props) => {
    const { user_type, setConfirmShowing, activeKey, setActiveKey } = props;
    const dispatch = useDispatch();
    const calenderListViewData = useSelector(state => state.showingDetailReducer.showingListData);

    const handleDropdownSelect = (key) => {
        setActiveKey(key);
    }

    const onClickAction = (data) => {
        dispatch(getCalenderData(data));
        //dispatch(setReceiverData(null));
        dispatch(setViewingRequestId(data.viewingRequestId));
        dispatch(setConversationId(data.conversation_id));
        setConfirmShowing(true);
    }
    return (
        <div>
            <Row className="ml-1">
                <Col md='5' lg='3' className="pl-0">
                    <Navbar className="filter-nav">
                        <Navbar.Collapse className="d-flex justify-content-between">
                            <Navbar.Text>posted date:</Navbar.Text>
                            <NavDropdown title={activeKey.split('_').join(' ')} id="nav-dropdown">
                                <NavDropdown.Item onClick={(e) => handleDropdownSelect('new_to_old')}>posted date: new to old</NavDropdown.Item>
                                <NavDropdown.Item onClick={(e) => handleDropdownSelect('old_to_new')}>posted date: old to new</NavDropdown.Item>
                            </NavDropdown>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>
            <div className="table-component-wrapper list-view-calender">
                <div className='table-responsive'>
                    <table className="table-component">
                        <thead>
                            <tr className="list-view-header">
                                <th>Showing Request</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>{user_type === 'tenant' ? 'Landlord' : 'Tenant'}</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {calenderListViewData.map((data, i) => {
                                return (
                                    <tr key={'table_body_' + i} className={data.status + ' row-wrapper cursor-pointer'} onClick={() => onClickAction(data)}>
                                        <td width='30%'>{(data.unit ? data.unit + ', ' : '') + data.address}</td>
                                        <td>{data.date}</td>
                                        <td>{data.date && <Moment format="hh:mm A">{data.date + ' ' + data.time}</Moment>}</td>
                                        <td>{user_type === 'tenant' ? data.landlord : data.tenant}</td>
                                        <td><span>{data.status}</span></td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {(calenderListViewData && calenderListViewData.length === 0) &&
                <Row className="m-0">
                    <Col className="empty-showing-request" md={12}>There are no showing requests or scheduled showings to display.</Col>
                </Row>
            }
        </div>
    );
}

export default CalenderListView;