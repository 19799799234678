import React, { useState } from "react";
import NumberFormat from 'react-number-format';
import { Form, Row, Col, Image } from "react-bootstrap";
import { SidePanel, LargeButton } from "../../../components"
import { postAPI, getAPI } from "../../../../Api";
import { logger, toaster } from "../../../../Utils";
import { validate } from '../../../../Utils/Validation/validation';
import { PaymentValidationSchema } from './PaymentValidationSchema';
import { useSelector } from "react-redux";

const log = logger("PaymentMethod");
const PaymentMethod = (props) => {
  const { isShow, handleClose, setCertnPurchaseModel } = props
  const [card_number, setCard_number] = useState('');
  const [card_name, setCard_name] = useState('');
  const [month, setMonth] = useState('select');
  const [year, setYear] = useState('select');
  const [cvv, setCvv] = useState('');
  const [billing_address, setBilling_address] = useState('');
  const [billing_country, setBilling_country] = useState('');
  const [billing_province, setBilling_province] = useState('');
  const [billing_postal_code, setBilling_postal_code] = useState('');

  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;
  const tenant_id = useSelector(state => state.messageDetailsReducer.tenantId);
  const creditCheckPaymentId = useSelector(state => state.messageDetailsReducer.creditCheckPaymentId);
  const conversationId = useSelector(state => state.messageDetailsReducer.conversationId);

  const getYeas = () => {
    const currentYear = new Date().getFullYear();
    let yearArray = []
    for (var i=currentYear; i < (currentYear+15); i++){
      yearArray.push(<option value={i}>{i}</option>)
    }
    return yearArray;
  }

  const hasNumbers = (v) => {
    var regex = /\d/g;
    return regex.test(v);
  }

  const OnChangeInput = (event) => {
    if (event.target.value === '' || event.target.value === 'select') {
      event.target.classList.add("is-invalid");
      const errMsg = PaymentValidationSchema[event.target.id][0].message;
      event.target.nextSibling === null && createErrorDiv(event.target, errMsg);
    } else {
      event.target.classList.remove("is-invalid");
    }

    if (event.target.name === 'card_number') {
      const fromat = event.target.value.replace(" ", "")
      setCard_number(fromat)
    } else if (event.target.name === 'card_name') {
      log.info("hasNumbers(event.target.value)", hasNumbers(event.target.value))
      if (hasNumbers(event.target.value)) {
        log.info("if hasNumbers(event.target.value)", hasNumbers(event.target.value))
        event.target.classList.add("is-invalid");
        event.target.nextSibling === null
          ? createErrorDiv(event.target, "Numbers are not allowed in name")
          : event.target.nextSibling.innerText = "Numbers are not allowed in name";
      } else {
        event.target.classList.remove("is-invalid");
        setCard_name(event.target.value);
      }
    } else if (event.target.name === 'month') {
      setMonth(event.target.value)
    } else if (event.target.name === 'year') {
      setYear(event.target.value)
    } else if (event.target.name === 'cvv') {
      setCvv(event.target.value)
    } else if (event.target.name === 'billing_address') {
      setBilling_address(event.target.value)
    } else if (event.target.name === 'billing_country') {
      setBilling_country(event.target.value)
    } else if (event.target.name === 'billing_province') {
      setBilling_province(event.target.value)
    } else if (event.target.name === 'billing_postal_code') {
      setBilling_postal_code(event.target.value)
    }
  }

  const createErrorDiv = (target, msg) => {
    const errorDiv = document.createElement("div");
    errorDiv.id = `error_${target.id}`;
    errorDiv.className = "invalid-feedback";
    errorDiv.innerText = msg;
    target.parentNode.appendChild(errorDiv);
  }

  const matchingId = (id) => {
    switch (id) {
      case "expiry_month" : return "month";
      case "expiry_year" : return "year";
      case "cvc" : return "cvv";
      default: return id;
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (validate(PaymentValidationSchema)) {
      const url = '/landlord/process-certn-payment';
      const data = {    
        property_id: property_id,
        property_details_id: property_details_id,
        credit_check_payment_id: creditCheckPaymentId,
        tenant_id: tenant_id,
        card_number: card_number,
        card_holder_name: card_name,
        expiry_month: month,
        expiry_year: year,
        cvc: cvv, 
        billing_address: billing_address,
        billing_country: billing_country,
        billing_province: billing_province,
        billing_postal_code: billing_postal_code,
        discount_id: props.discount_id || 0
      }
      
      postAPI(url, data).then(response => {
        if (response.status === 422) {
          const errorMsg = response.data.message;
          log.info("error message: ", errorMsg);
          toaster('error', errorMsg);
          const errorEntries = Object.entries(response.response.data.errors);
          for (const [errorId, message] of errorEntries) {
            toaster('error', message);

            let errorElem = document.getElementById(matchingId(errorId));
            const errMsg = (PaymentValidationSchema[matchingId(errorId)]) && (PaymentValidationSchema[matchingId(errorId)])[0].message;
            errorElem && errorElem.classList.add("is-invalid");

            if (errorElem) {
              if (errorElem.nextSibling === null) {
                createErrorDiv(errorElem, errMsg);
              } else {
                errorElem.nextSibling.innerText = errMsg;
              }
              log.info("if errorId", errorId)
            }
          }
        } else if (response.status === 200) {
          getAPI(`/landlord/get-certn-report/${tenant_id}/${conversationId}`)
          .then((res) => {
            if(res.status === 200){
              // toaster('success', res.data.message)
            }
          })
          .catch((err) => {
            // console.log(err)
          })
          handleClose();
          setCertnPurchaseModel(true);
        } else {
          toaster('error', 'Something went wrong!');
        }
      })
    }
  }

  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title="Payment Method">
      <Form>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Card Number</Form.Label>
            <NumberFormat
              placeholder="xxxx-xxxx-xxxx-xxxx"
              value={card_number}
              className="form-control"
              id="card_number"
              name="card_number"
              onChange={(e) => OnChangeInput(e)}
              format="#### #### #### ####" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Card Holder Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Name"
              id="card_name"
              name="card_name"
              value={card_name}
              onChange={(e) => OnChangeInput(e)}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Expiry Date</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  as="select"
                  className="my-1 mr-sm-2"
                  custom
                  id="month"
                  name="month"
                  value={month}
                  onChange={(e) => OnChangeInput(e)}
                >
                  <option value="">Month</option>
                  <option value="1">01</option>
                  <option value="2">02</option>
                  <option value="3">03</option>
                  <option value="4">04</option>
                  <option value="5">05</option>
                  <option value="6">06</option>
                  <option value="7">07</option>
                  <option value="8">08</option>
                  <option value="9">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </Form.Control>
              </Col>

              <Col>
                <Form.Control
                  as="select"
                  className="my-1 mr-sm-2"
                  custom
                  id="year"
                  name="year"
                  value={year}
                  onChange={(e) => OnChangeInput(e)}
                >
                  <option value="">Year</option>
                  {getYeas()}
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>CVV</Form.Label>
            <Form.Control
              type="text"
              placeholder="xxx"
              id="cvv"
              name="cvv"
              value={cvv}
              onChange={(e) => OnChangeInput(e)}
            />
          </Form.Group>
        </Row>

        <hr />

        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Please put address"
              id="billing_address"
              name="billing_address"
              value={billing_address}
              onChange={(e) => OnChangeInput(e)}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Country"
              id="billing_country"
              name="billing_country"
              value={billing_country}
              onChange={(e) => OnChangeInput(e)}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Province</Form.Label>
            <Form.Control
              type="text"
              placeholder="Province"
              id="billing_province"
              name="billing_province"
              value={billing_province}
              onChange={(e) => OnChangeInput(e)}
            />
          </Form.Group>

          <Form.Group as={Col} md="6">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Postal Code"
              id="billing_postal_code"
              name="billing_postal_code"
              value={billing_postal_code}
              onChange={(e) => OnChangeInput(e)}
            />
          </Form.Group>
        </Row>

      </Form>

      <div className="sticky-bottom" style={{ flexDirection: 'column' }}>
        <Col md='12'>
          <LargeButton
            title="submit payment"
            onClick={(e) => onSubmit(e)} />

          <LargeButton
            variant="secondary"
            title="cancel"
            onClick={handleClose} />
        </Col>
        {/* <span>Powered By</span>
        <Image src={require("../../../../_assets/images/RentMoola.png")} alt='Square Logo' /> */}
      </div>
    </SidePanel>
  </>);
}

export default PaymentMethod;