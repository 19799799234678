import React, { useState } from "react";
import { SidePanel, ModalBox, DatePickerComponent } from "../../../components"
import { Form, Row, Col, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { FreeRentAssessmentValidationScheme } from './FreeRentAssessmentValidationScheme'
import { validate } from '../../../../Utils/Validation/validation';
import moment from 'moment';
import { postAPI } from "../../../../Api";
import { toaster } from "../../../../Utils";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";

const FreeRentAssessment = (props) => {
  const { isShow, handleClose, setFree_rent_assessment_id } = props
  const [date, setDate] = useState(new Date());
  const [assessment_time, setAssessment_time] = useState('select');
  const [phone_number, setPhone_number] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [fullName, setFullName] = useState('');
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');

  const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
  const property_id = propertyDetails && propertyDetails.id;
  const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
  const property_details_id = unitDetails && unitDetails.id;

  const OnChangeInput = (event) => {
    if (event.target.name === 'assessment_time') {
      setAssessment_time(event.target.value)
    } else if (event.target.name === 'phone_number_free') {
      setPhone_number(event.target.value)
    }
  }
  const clearForm = () => {
    setFullName('');
    setDate(new Date());
    setAssessment_time('select');
    setPhone_number('');
  }

  const onSubmit = (e) => {
    e.preventDefault()
    console.log(validate(FreeRentAssessmentValidationScheme))
    if(validate(FreeRentAssessmentValidationScheme)){
      const url = '/landlord/create-free-rent-assessment';
      const data = {
        property_id: property_id,
        assessment_date: moment(date).format('YYYY-MM-DD'),
        assessment_time: assessment_time,
        phone_number: phone_number.replaceAll("-",'')
      }

      if (property_details_id) {
        data.property_details_id = property_details_id;
      }
      if (props.landlordLanding) {
        data.full_name = fullName;
      }
      postAPI(url, data).then(response => {
        if (response === undefined) {
          toaster('error', 'Something went wrong!');
        } else if (response.status === 200) {
          setFree_rent_assessment_id(response.data.data.free_rent_assessment_id)
          handleClose()
          setModalShow(true)
          clearForm()
        } else if (response.response) {
          if (response.response.status === 422) {
            if (props.landlordLanding) {
              if (response.response.data.errors.property_id) {
                setErrorModalText('You are already a member');
              } else {
                setErrorModalText(response.response.data.message);
              }
              setErrorModalShow(true);
            } else {
              toaster('error', response.response.data.message);
            }
          }
        }
      })
    }
  }
  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={`${props.landlordLanding ? "Let's chat about your rental needs." : "Get A Free Rent Assessment"}`}
      subTitle={`${props.landlordLanding ? "Let us know what time is best to reach you and we will give you a shout!" : "Let us know what time is best to reach you and we will call to book you in."}`}>
      <Form id="Form">
        {props.landlordLanding && <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Your full name</Form.Label>
            <Form.Control
              type='text'
              placeholder='John Doe'
              name="fullName"
              id="fullName"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
            />
          </Form.Group>
        </Row>}
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>What date can our city manager call you?</Form.Label>
            <DatePickerComponent
              name="date"
              id="datefreeass"
              value={date}
              handleDateChange={date => setDate(date)}
              displayText={"Select Date"}
              minDate={new Date()}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>When would be best to contact you?</Form.Label>
            <Form.Control
              as="select"
              className="my-1 mr-sm-2"
              id="free-inlineForm"
              custom
              name="assessment_time"
              value={assessment_time}
              onChange={(e) => OnChangeInput(e)}
            >
              <option value="select">Select one</option>
              <option value="morning">Morning</option>
              <option value="afternoon">Afternoon</option>
              <option value="evening">Evening</option>
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Phone Number</Form.Label>
            <NumberFormat
              format="###-###-####"
              mask="_"
              placeholder="123-123-1234"
              name="phone_number_free"
              id="phone_number_free"
              className="form-control"
              value={phone_number ? phone_number : ""}
              onChange={(e) => OnChangeInput(e)}
            />
          </Form.Group>
        </Row>
      </Form>

      <div className="container-footer">
        <Button variant="success" onClick={(e) => onSubmit(e)}>Submit</Button>
      </div>
    </SidePanel>

    <ModalBox
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="sm"
      actionbuttontitle={'got it'}
      buttonaction={() => setModalShow(false)}
    >
      <p>You have requested a call for a rent assessment. Our team will contact you during the time requested.</p>
    </ModalBox>

    <ModalBox
      show={errorModalShow}
      onHide={() => setErrorModalShow(false)}
      size="sm"
      actionbuttontitle={'got it'}
      buttonaction={() => setErrorModalShow(false)}
    >
      <p>{errorModalText}</p>
    </ModalBox>
  </>);
}

export default FreeRentAssessment;