import React, { useState, useEffect } from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAPI, postAPI } from "../../../Api";
import {
  AppLayout,
  PageTitleHeader,
  ModalBox,
  PDFViewerComponent,
  Loader,
} from "../../components";
import { logger, toaster } from "../../../Utils";
import "./style.scss";
import Cookies from 'js-cookie';
import Moment from "react-moment";
import { ParalegalForm } from "../../components/Notices/NoticeForms";
import { Prompt } from "react-router";


import {
  N1Details,
  N2Details,
  N3Details,
  N4Details,
  N5Details,
  N6Details,
  N7Details,
  N8Details,
  N10Details,
  N11Details,
  N12Details,
  N13Details,
  WarningDetails,
  EntryDetails,
  N9Details
} from "../../components/Notices/DetailsView";
import { updateRawData } from "../../../Store/actions/RawDataAction";
import { useGlobalState } from "../../../StateContext";

const log = logger("ViewNotice");
const ViewNotice = (props) => {
  const [noticeData, setNoticeData] = useState(null);
  const [property_id, setProperty_id] = useState(null);
  const [property_detail_id, setProperty_detail_id] = useState(null);
  const [notice_id, setNotice_id] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const address = useSelector(
    (state) => state.maintenanceRepairReducer.address
  );
  const noticeDataRaw = useSelector((state) => state.rawData)
  const { showLoader = false, allCleared } = noticeDataRaw || {}
  const [modalShow, setModalShow] = useState(false);
  const [is_pdfModal, setPdfModal] = useState(false);
  const [is_Paralegal, setParalegal] = useState(false);
  const [non_payment_of_rent, setNon_payment_of_rent] = useState([]);
  const role = Number(Cookies.get('role'));

  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [globalState, setGlobalState] = useGlobalState()

  const back = () => {
    props.history.push(
      `/maintainance-repair/${property_id}/${property_detail_id}/notices`
    );
  };

  const downloadPdf = () => {
    setPdfModal(true);
  };

  const getParalegal = () => {
    setParalegal(true);
  };

  const redirectToInfo = () => {
    props.history.push(`/notice-info/${noticeData.notice_type}`);
  };

  const onEditNotice = () => {
    props.history.push({
      pathname: `/edit-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  const onServeNotice = (data) => {
    //Serve Notice action here
    if (noticeData.notice_type === 4) {
      setGlobalState({ showCheckList: true })
      return
    }
    setNon_payment_of_rent(data);
    setModalShow(true);
  };

  const onServeAction = () => {

    let url = '';
    if (role === 2) {
      url = "/landlord/notice/serve-notice";
    } else {
      url = "/tenant/notice/serve-notice";
    }
    let data

    if (noticeData && noticeData.notice_type === 4) {
      data = {
        notice_id: notice_id,
        non_payment_of_rent: non_payment_of_rent
      };
    } else {
      data = {
        notice_id: notice_id,
      };
    }

    postAPI(url, data).then((response) => {
      if (response.status === 200) {
        toaster("success", "Data Saved Successfully!");
        back();
      }

    });
  };

  const redirectToMessage = () => {
    if (noticeData.status === 0) {
      updateRawData({ allCleared: false })
      setTimeout(() =>
        props.history.push({
          pathname: `/property-details/${property_id}/${property_detail_id}/messages`,
        }), 300);
      return
    }
    props.history.push({
      pathname: `/property-details/${property_id}/${property_detail_id}/messages`,
    })
  };

  const getData = () => {
    let url = '';
    updateRawData({ showLoader: true })

    if (notice_id) {
      if (role === 2) {
        url = `/landlord/notice/details/${notice_id}`;
      } else if (role === 3) {
        url = `/tenant/notice/details/${notice_id}`;
      }

      getAPI(url)
        .then((response) => {
          const res = response.data.data;
          console.log('response is', res)
          setNoticeData(res);
          updateRawData({ showLoader: false })

        })
        .catch((error) => {
          updateRawData({ showLoader: false })
        });
    }
  };

  const getReasonData = () => {
    const url = `/notice/types-and-further-options`;
    getAPI(url)
      .then((response) => {
        const res = response.data.data;
        log.info(res);
        setRequestData(res);
      })
      .catch((error) => { });
  };

  const cancelNoticeAction = () => {
    let url = '';
    if (role === 2) {
      url = `/landlord/notice/delete/${notice_id}`;
    } else if (role === 3) {
      url = `/tenant/notice/delete/${notice_id}`;
    }

    getAPI(url)
      .then((response) => {
        const res = response.data.data;
        log.info(res);
        getData();
        toaster("success", "Notice Deleted Successfully!");
        setCancelModalShow(false);
        back();
      })
  }

  useEffect(() => {
    getData();
    getReasonData();
    setProperty_id(props.match.params.property_id);
    setProperty_detail_id(props.match.params.property_details_id);
    setNotice_id(props.match.params.notice_id);
  }, [props]);

  const createdAtDate = noticeData?.created_at
  console.log('noticeData.status', noticeData?.status)
  return (
    <>
      <Prompt
        when={noticeData && !noticeData.status && !allCleared}
        message="This will lose your changes. Are you sure want leave the page?"
      />
      <AppLayout>
        {/* {showLoader &&
          <div id={'notice_loader'} style={{ top: '50%', position: 'absolute', left: '44%', width: '12%' }}>
            <Loader />
          </div>
        } */}
        <PageTitleHeader
          title={noticeData && (noticeData.formatted_notice_type || 'review your notice').toLowerCase()}
          // mobileTitle={"create new notices"}
          isArrow={true}
          onClick={() => back()}
          rightBtn={(noticeData && noticeData.is_notice_received) || noticeData?.status === 0 ? 'true' : ''}
          rightBtnText={noticeData && (noticeData.is_notice_received ? <>
            <i className='fa fa-print mr-2' />
          Download PDF</> : "Serve Notice")}
          rightBtnHandler={noticeData && (noticeData.is_notice_received ? downloadPdf : onServeNotice)}
          leftBtn={noticeData && noticeData.status !== 4 && !(noticeData.status === 2 || noticeData.status === 3) && !noticeData.is_notice_received && "true"}
          leftBtnText={noticeData && !(noticeData.status === 2 || noticeData.status === 3) && !noticeData.is_notice_received && noticeData.status !== 4 && "Edit"}
          leftBtnHandler={noticeData && !(noticeData.status === 2 || noticeData.status === 3) && !noticeData.is_notice_received && onEditNotice}
          extraClass='text-lowercase'
        />

        {noticeData && (noticeData.is_notice_received && (noticeData.status !== 3) && (noticeData.notice_type === 10 || noticeData.notice_type === 11)) &&
          <div className="sign-required-layer">
            <div className="status">Signature is required for this notice to be granted</div>
          </div>
        }

        <div className="notice-form-card">
          <div className="d-flex justify-content-between">
            <span className="address-text">{address}</span>
            {!!createdAtDate && <span> {role === 3 || (role === 2 && noticeData.notice_type === 9) ? 'Date received' : 'Date Created'}: <Moment format="MMMM D, YYYY">{createdAtDate}</Moment></span>
            }
          </div>
          {noticeData &&
            <>
              <Form id="Form">
                <Row>
                  <Form.Group as={Col} md="3" className="request-select">
                    <Form.Label>
                      <span style={{ fontWeight: 600 }}>
                        {role === 2 ? 'Present Tenant(s)' : 'Landlord'}
                      </span>
                       &nbsp;&nbsp;
                    <span style={{ cursor: "pointer" }} onClick={() => redirectToMessage()}>
                        <Image className="icon mr-2"
                          width="20px"
                          height="20px"
                          src={require("../../../_assets/images/chat-icon.svg")}
                        />
                      </span>
                    </Form.Label>
                    <br />
                    {role === 2 ?
                      <b>{noticeData.tenant_details && noticeData.tenant_details.map((data) => {
                        return data.name
                      }).join(', ')}</b>
                      : <b>{noticeData.landlord && noticeData.landlord['name']}</b>}
                  </Form.Group>
                  {role === 2 && (noticeData.notice_type === 9 ?
                    <Form.Group as={Col} md="8">
                      <Form.Label><span style={{ fontWeight: 600 }}>Notice Type</span></Form.Label>
                      <p style={{ fontWeight: 700 }}>Move Out Notice (N9 Form)</p>
                    </Form.Group> :
                    <>
                      <Form.Group as={Col} md="3">
                        <Form.Label>
                          <span style={{ fontWeight: 600 }}> I want to:</span>
                        </Form.Label><br />
                        <b>{noticeData.formatted_i_want_to}</b>
                      </Form.Group>
                      {!(noticeData.notice_type === 16 || noticeData.notice_type === 17) &&
                        (<Form.Group as={Col} md="6">
                          <Form.Label>
                            Please select further information on why you're sending this
                            notice
                    </Form.Label>
                          <br />
                          <b>{noticeData.formatted_notice_type}</b>
                        </Form.Group>)}
                    </>)}

                  {role === 3 &&
                    <>
                      <Form.Group as={Col} md="8">
                        <Form.Label><span style={{ fontWeight: 600 }}> Notice Type</span></Form.Label>
                        <p style={{ fontWeight: 700 }}>
                          {noticeData.notice_type === 9 ? 'Tenant’s notice to end the tenancy' : <>
                            {noticeData.formatted_i_want_to}
                            {(noticeData.formatted_notice_type) && `- ${noticeData.formatted_notice_type}`}</>
                          }

                        </p>
                      </Form.Group>
                    </>}
                </Row>
              </Form>
            </>}
        </div>

        {noticeData && noticeData.notice_type === 1 && (
          <N1Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 2 && (
          <N2Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 3 && (
          <N3Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 4 && (
          <N4Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={(data, isConfirmed) => onServeNotice(data, isConfirmed)}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
            onSubmit={(data) => {
              setNon_payment_of_rent(data);
              setModalShow(true);
            }}
          />
        )}

        {noticeData && noticeData.notice_type === 5 && (
          <N5Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 6 && (
          <N6Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 7 && (
          <N7Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 8 && (
          <N8Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 10 && (
          <N10Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
            property_id={property_id}
            property_detail_id={property_detail_id}
            auth={props.auth}
          />
        )}

        {noticeData && noticeData.notice_type === 11 && (
          <N11Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
            property_id={property_id}
            property_detail_id={property_detail_id}
          />
        )}

        {noticeData && noticeData.notice_type === 12 && (
          <N12Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 13 && (
          <N13Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 16 && (
          <EntryDetails
            noticeData={noticeData}
            requestData={requestData && requestData[2].further_options}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 17 && (
          <WarningDetails
            noticeData={noticeData}
            requestData={requestData && requestData[3].further_options}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

        {noticeData && noticeData.notice_type === 9 && (
          <N9Details
            noticeData={noticeData}
            downloadPdf={() => downloadPdf()}
            redirectToInfo={() => redirectToInfo()}
            onServeNotice={() => onServeNotice()}
            getParalegal={() => getParalegal()}
            cancelNotice={() => setCancelModalShow(true)}
          />
        )}

      </AppLayout>

      <ParalegalForm
        isShow={is_Paralegal}
        handleClose={() => setParalegal(false)}
      />

      <ModalBox
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        type="condition"
        actionbuttontitle={"No"}
        backbuttontitle={"Yes"}
        buttonaction={() => {
          setModalShow(false);
        }}
        backaction={() => {
          setModalShow(false);
          onServeAction();
        }}
      >
        {role === 2 ?
          <h4>
            are you sure you want to submit this notice to <br />
            {noticeData &&
              noticeData.tenant_details &&
              noticeData.tenant_details
                .map((data) => {
                  return data.name;
                })
                .join(", ")}{" "}
          ?
        </h4>
          : <>
            {(noticeData && noticeData.notice_type === 11) ?
              <h4>Your tenancy will be ended<br /> on <Moment format="MMMM D, YYYY">{noticeData.date}</Moment></h4>
              : (noticeData && noticeData.notice_type === 10) ?
                <>
                  <h4>
                    are you sure you want to<br />submit this notice to&nbsp;
                    {noticeData && noticeData.landlord && noticeData.landlord['name']}{" ?"}
                  </h4>
                </>
                :
                <>
                  <h4>
                    would you like to send the move out notice to <br />
                    {noticeData && noticeData.landlord && noticeData.landlord['name']}{" ?"}
                  </h4>
                  {/* {(noticeData && noticeData.notice_type !== 10) && <p>It cannot be undone</p>} */}
                </>}
          </>}
      </ModalBox>

      <ModalBox
        show={is_pdfModal}
        onHide={() => setPdfModal(false)}
        size="xl"
        className="cu-full-modal"
      >
        <PDFViewerComponent PDFUrl={noticeData && noticeData.pdf_url} />
      </ModalBox>

      <ModalBox
        show={cancelModalShow}
        onHide={() => setCancelModalShow(false)}
        size="sm"
        type="condition"
        actionbuttontitle={"Yes"}
        buttonaction={() => {
          cancelNoticeAction();
        }}
        backbuttontitle={"No"}
        backaction={() => {
          setCancelModalShow(false);
        }}
      >
        <h4>are you sure you want to cancel this notice?</h4>
        {/* <p>It cannot be undone</p> */}
      </ModalBox>
    </>
  );
};

export default withRouter(ViewNotice);
