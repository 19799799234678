import React, { Component } from "react";
import Routes from "./routes";
import { Provider } from "react-redux";
import Offline from './App/components/Offline'
import store from "./configureStore";
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import StateProvider from "./StateProvider";
import ErrorBoundry from "./ErrorBoundry";

class App extends Component {

  render() {
    let persistor = persistStore(store)
    return (
      <Provider store={store}>
        <Offline>
          <div className="no-internet-connection">No internet connection!</div>
        </Offline>
        <ErrorBoundry>
        <PersistGate loading={null} persistor={persistor}>
          <StateProvider>
            <Routes />
          </StateProvider>
        </PersistGate>
        </ErrorBoundry>
      </Provider>
    );
  }
}

export default App;
