import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Image, Button } from 'react-bootstrap';
import { logger } from '../../../../Utils';
import { LargeButton, CancelButton, SidePanel, ModalBox } from '../..';
import { AddingTenantSchema } from '../Schema/AddingTenantSchema';
import { validate } from '../../../../Utils/Validation/validation';
import { postAPI } from '../../../../Api';
import { useDispatch, useSelector } from 'react-redux';
import { getTenantsList } from '../../../../Store/actions/AddedTenantAction';
import toaster from '../../../../Utils/toaster/toaster';

const log = logger('AddingTenants');
const AddingTenants = (props) => {
	const { isShow, handleClose, sendBackRes, from, setSkipOpen, singleReasonData, setTenantList } = props;
	const listTenants = useSelector(state => state.addedTenantReducer.addedTenants);
	const dispatch = useDispatch();
	const [tenantAdded, setTenantAdded] = useState([
		{
			tenantFullName: '', tenantEmailId: ''
		}
	]);
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);

	const OnChangeInput = (event, index) => {
		log.info(event.target.value);
		let items = [...tenantAdded];
		let item = { ...items[index] };
		const name = event.target.name ? event.target.name : event.target.getAttribute('name')
		item[name] = event.target.value;
		items[index] = item;
		setTenantAdded(items);
		isSubmitClicked && setTimeout(() => {
			validate(AddingTenantSchema)
		}, 300)
	}
	const addMore = () => {
		const data = { tenantFullName: '', tenantEmailId: '' }
		setTenantAdded(tenantAdded => [...tenantAdded, data])
	}
	const OnRemove = (index) => {
		let items = [...tenantAdded];
		items.splice(index, 1);
		setTenantAdded(items);
	}
	const addAndInvite = () => {
		setIsSubmitClicked(true);
		if (validate(AddingTenantSchema)) {
			const url = '/landlord/listing/lease/add-tenant';
			const data = [];
			tenantAdded.map((tenant) => {
				const newData = {
					name: tenant.tenantFullName,
					email: tenant.tenantEmailId
				}
				data.push(newData)
			})
			const tenant = {
				'tenants': data
			}
			postAPI(url, tenant).then(res => {
				if (res.status === 200) {
					let resTenant = [];
					if (listTenants !== null && listTenants !== undefined) {
						// let concated = res.data.data.concat(listTenants[0]);
						resTenant.push(res.data.data);
					} else {
						resTenant.push(res.data.data);
					}
					dispatch(getTenantsList(resTenant))
					setTenantList(resTenant);
					handleClose();
					if (props.isDeactivation) {
						props.setLeaseOptionsScreen(true);
					} else {
						sendBackRes(true);
					}
				} else {
					let obj = Object.keys(res.response.data.errors);
					let msg = [];
					obj.map(o => {
						let arr = o.split('.');
						msg.push(data[arr[1]].email + ' has already been taken');
					})
					toaster('error', msg.join(', '))

				}
			})
		}
	}
	const handleCloseConfirm = () => {
		handleClose();
		setTenantAdded([{ tenantFullName: '', tenantEmailId: '' }]);
		if (props.isDeactivation) {
			props.setOpenDeactivate(true);

		} else {
			sendBackRes(true);
		}
	}
	const openSkipModal = () => {
		setSkipOpen(true)
		handleClose();
	}

	useEffect(() => {
		dispatch(getTenantsList([]))
	}, [])
	useEffect(() => {
		setTenantAdded([
			{
				tenantFullName: '', tenantEmailId: ''
			}
		])
	}, [isShow])
	return (<>
		<SidePanel
			isShow={isShow}
			isHide={() => { props.isDeactivation ? handleCloseConfirm() : setTenantAdded([{ tenantFullName: '', tenantEmailId: '' }]); handleClose('callBackApi') }}
			title={
				props.isDeactivation ? "register tenants" :
					(from === 'listing-details' ? 'Add And Send Invite' : 'ADD AND SEND INVITATION')
			}
			subTitle={
				props.isDeactivation ? "Your tenants will be sent an invitation for you to manage your property better" :
					(from === 'listing-details' ? 'Invite a tenant outside of rent panda' : 'invite a tenant outside of rent panda')
			}>
			<div className='deactive-reasons sidepanel-body'>
				<Form id='Form'>
					{tenantAdded ?
						tenantAdded.map((data, i) => {
							return (
								<div key={'tenantAdded' + i} className={'tenantAdded' + i}>
									<Row>
										<Form.Group as={Col} md='12'>
											<div className='display-flex-inline-elm'>
												<Form.Label>Tenant’s Full Name</Form.Label>
												{i !== 0 &&
													<div className='sub-title d-flex justify-content-between align-items-center pt-0'>
														<Image
															className='icon cursorPointer'
															width='20px'
															height='20px'
															title='Remove'
															onClick={() => OnRemove(i)}
															src={require('../../../../_assets/images/delete-icon.svg')}
														/>
													</div>}
											</div>
											<Form.Control
												type='text'
												placeholder='John Doe'
												name='tenantFullName'
												id={'tenantFullName' + i}
												value={data.tenantFullName}
												onChange={(e) => OnChangeInput(e, i)} />
										</Form.Group>
										<Form.Group as={Col} md='12'>
											<Form.Label>Tenant’s Email</Form.Label>
											<Form.Control
												type='text'
												placeholder='name@mail.com'
												name='tenantEmailId'
												id={'tenantEmailId' + i}
												value={data.tenantEmailId}
												onChange={(e) => OnChangeInput(e, i)} />
										</Form.Group>
									</Row>
									<hr />
								</div>)
						}) : ''}
				</Form>

				<Row>
					<Col md='12'>
						<Button variant="link" onClick={() => addMore()} className='pull-right add-more'>+ Add More</Button>
					</Col>
				</Row>
				{(props.isDeactivation || (singleReasonData && singleReasonData.order === 2)) && <Row className="fixed-bottom skip-link">
					<Col md='12'>
						<Button variant="link" onClick={() => openSkipModal()} className="skip-btn mb-4">{(props.isDeactivation && props.singleReasonData === 4) ? "Skip and Deactivate" : "Skip"}</Button>
					</Col>
				</Row>}
			</div>

			<div className='fixed-bottom d-flex justify-content-between'>
				<CancelButton onClick={() => handleCloseConfirm()} title='back' />
				<LargeButton title={props.isDeactivation ? 'next' : 'add and invite'} onClick={() => addAndInvite()} style={{ width: '160px' }} />
			</div>
		</SidePanel>
	</>);
}
export default AddingTenants;