export const NAVIGATIONLINKS = [
  {
    pathName: "/login",
    displayName: "log in",
  },
  {
    pathName: "/signup/tenant",
    displayName: "sign up",
    className: "drawer-bordered-bottom",
  },
  {
    pathName: "/resources",
    displayName: "resources",
  },
  {
    displayName: "how it works",
    className: "hasSubMenu",
  },
  {
    pathName: "/how-it-works/landlord",
    displayName: "for landlords",
    className: "subMenu",
  },
  {
    pathName: "/how-it-works/tenant",
    displayName: "for tenants",
    className: "subMenu drawer-bordered-bottom",
  },
  {
    pathName: "/for-partners",
    displayName: "for panda partners",
  },
  {
    pathName: "/for-realtors",
    displayName: "for realtors",
  },
  {
    pathName: "/for-property-managers",
    displayName: "for property managers",
    className: "drawer-bordered-bottom",
  },
  // {
  //   pathName: "/portals-and-auxillary/advertisers",
  //   displayName: "Advertise On Rentpanda.Ca",
  // },
  {
    pathName: "/about-us",
    displayName: "about us",
  },
  {
    pathName: "/terms-and-conditions",
    displayName: "terms and conditions",
  },
  {
    pathName: "/privacy-policy",
    displayName: "privacy policy",
  },
];
