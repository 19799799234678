import React from 'react';
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Button } from 'react-bootstrap';
import { setSearchLocation } from '../../../Store/actions/LandingPageAction';
import { PropertyCard, Loader } from '../../../App/components';
import { logger } from '../../../Utils';
import Cookies from 'js-cookie';

const log = logger("FoundFewPlaces");
const FoundFewPlaces = (props) => {
    const { isLoading, listData } = props;
    // const {getListData}  = useSelector(state => state.landingPageReducer);
    const dispatch = useDispatch();

    const handleViewMore = () => {
        dispatch(setSearchLocation(''));
        props.history.push('/search-result');
    }

    const propertyDetails = (property_id, property_details_id) => {
        if (Cookies.get('access_token') && parseInt(Cookies.get('role')) === 3) {
            props.history.push({
                pathname: '/property-details/' + property_id + '/' + property_details_id + '/details'
            });
        } else {
            props.history.push({
                pathname: '/property-details/' + property_id + '/' + property_details_id + '/details/tour',
                state: { backpath: props.location.pathname }
            });
        }
    }

    const formattedDetails = (data) => {
        const { bedrooms, property_type } = data;
        const bedRoom = bedrooms && (bedrooms > 1) ? (bedrooms + ' Bedrooms') : (bedrooms + ' Bedroom');
        // property_type {
        //   1 : 'Full Property',
        //   2 : 'Unit',
        //   3 : 'Room for Rent'
        // }
        let details = '';
        if (property_type === 3) {
            details = 'Room for Rent';
        } else if (property_type === 2) {
            details = `Unit • ${bedRoom}`;
        } else if (property_type === 1) {
            details = `Full Property • ${bedRoom}`;
        }
        return details;
    }

    return (
        <div className="found-few-places cu-container-wrap">
            { !!listData?.data?.length ?
                <Container>
                    <h2 className="landing-title">rent panda found a few places you might like.</h2>
                    <Row>
                        {listData && listData.data.map((data, i) => {
                            return <React.Fragment key={"property" + i}>
                                <PropertyCard
                                    price={data.price}
                                    id={data.property_details_id}
                                    isVerifed={data.is_verified}
                                    image_url={data.image_url}
                                    title={data.address}
                                    details={formattedDetails(data)}
                                    isDeleteIcon={data.isDeleteIcon}
                                    grid={4}
                                    onClick={() => propertyDetails(data.id, data.property_details_id)}
                                    isLoveIcon={data.is_favorite}
                                />
                            </React.Fragment>
                        })}
                    </Row>
                    <Button variant="Link" className="btn-view-more" onClick={handleViewMore}>View More
                        <img className="pl-2 next" src={require("../../../_assets/images/next.svg")} alt='view more' />
                    </Button>
                </Container> :
                (isLoading ? <Loader /> :

                    listData?.data?.length === 0 ?
                        <Container><h2 className="sub-head">Data not found.</h2></Container> : '')
            }
        </div>
    )
}

export default withRouter(FoundFewPlaces);
