import React, { useEffect, useState } from 'react';
import { SidePanel, LargeButton, CancelButton, DatePickerComponent, AutoCompleteAddress, CostNumberFormat } from '../../../components'
import { Form, Row, Col } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { is_ownedData } from '../../../../Constants/tenantDetails'
import { logger, toaster } from '../../../../Utils'
import { numberFormatSanitizerInt, phoneNumberSanitizer } from '../../../../Utils/NumberSanitizer/NumberSanitizer';
import NumberFormat from 'react-number-format';
import { validate } from '../../../../Utils/Validation/validation';
import { CurrentHomeInfoValidationSchema } from './ValidationSchema/CurrentHomeInfoValidationSchema';
import { RentedHomeValidationSchema } from './ValidationSchema/RentedHomeValidationSchema';
import { postAPI } from '../../../../Api';
import { dateSanitizer } from '../../../../Utils/DateSanitizer/DateSanitizer';
import Cookies from 'js-cookie';
import './style.scss';

const log = logger('CurrentHomeInfo');
const CurrentHomeInfo = (props) => {
  const { current_home, isShow, handleClose, setPastHomeInfo, newCurrentHomeInfo, setSuccessModalShow, user_id } = props;
  const role = Number(Cookies.get('role'));
  const [id, setId] = useState(0);
  const [full_address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('');
  const [postal_code, setPostal_code] = useState('');
  const [latitude, setLatitude] = useState(34.059849);
  const [longitude, setLongitude] = useState(-118.455551);
  const [unit_no, setUnit_no] = useState('');
  const [start_date, setStart_date] = useState(moment(new Date()).format('YYYY-MM-DD'));
  // const [end_date, setEnd_date] = useState(new Date());
  const [is_owned, setIs_owned] = useState(0);
  const [monthly_rent, setMonthly_rent] = useState('');
  const [landlord_name, setLandlord_name] = useState('');
  const [landlord_email, setLandlord_email] = useState('');
  const [landlord_phone, setLandlord_phone] = useState('');
  const [isSubmitClicked,setIsSubmitClicked]=useState(false)
  let allowSubmit = false

  useEffect(() => {
    if (current_home && current_home.id) {
      setId(current_home.id)
      setAddress(current_home.full_address)
      setCity(current_home.city)
      setProvince(current_home.province)
      setCountry(current_home.country)
      setPostal_code(current_home.postal_code)
      setLatitude(current_home.latitude)
      setLongitude(current_home.longitude)
      setUnit_no(current_home.unit_no)
      setStart_date(moment(new Date(current_home.start_date)).format('YYYY-MM-DD'))
      // setEnd_date(new Date(current_home.end_date))
      setIs_owned(current_home.is_owned)
      setMonthly_rent(current_home.monthly_rent)
      setLandlord_name(current_home.landlord_name)
      setLandlord_email(current_home.landlord_email)
      setLandlord_phone(current_home.landlord_phone)
    }
    setIsSubmitClicked(false)
  }, [current_home])
  


  useEffect(() => {
    if (isSubmitClicked) {
      validate(is_owned?CurrentHomeInfoValidationSchema:RentedHomeValidationSchema)
    }
  }, [id,
    unit_no,
    full_address,
    city,
    province,
    country,
    postal_code,
    latitude,
    longitude,
    start_date,
    is_owned,
    monthly_rent,
    landlord_name,
    landlord_email,
    landlord_phone])


  const current_home_2 = current_home && [{...current_home}]

  const OnChangeInput = (event, setInputState) => {
    // validate(CurrentHomeInfoValidationSchema)
    if (event.target.name === 'monthly_rent_current') {
      const change = numberFormatSanitizerInt(event.target.value)
      setInputState(change)
    } else if (event.target.name === 'landlord_phone_current') {
      const change2 = phoneNumberSanitizer(event.target.value)
      setInputState(change2)
    } else {
      setInputState(event.target.value)
    }
  }
  const handleDateChange = (date, setStart_date) => {
    const dateValid = dateSanitizer(date);
    log.info(dateValid);
    setStart_date(dateValid);
  };
  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute('name');
    if (name === 'is_owned') {
      setIs_owned(Value);
    }
  }

  const onSubmit = () => {
    setIsSubmitClicked(true)
    let url = '';
    if (user_id) {
      url = '/admin/users/tenant/current-home/' + user_id;
    } else {
      url = '/tenant/profile/current-home';
    }

    let data = {
      id: id ? id : 0,
      full_address: full_address,
      city: city,
      province: province,
      country: country,
      postal_code: postal_code,
      latitude: latitude,
      longitude: longitude,
      unit_no: unit_no,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      is_owned: is_owned,
    }
    if (is_owned && validate(CurrentHomeInfoValidationSchema) && full_address !== '' && is_owned) {
      // !isSubmitClicked && postData(url, data)
      postData(url, data)
    }
    else if(!is_owned && validate(RentedHomeValidationSchema)){
      data = {
        ...data,
        'monthly_rent': monthly_rent,
        'landlord_name': landlord_name,
        'landlord_email': landlord_email,
        'landlord_phone': landlord_phone
      }
      // !isSubmitClicked && postData(url, data)
      postData(url, data)
    }
  }

  const postData = (url, data) => {
    postAPI(url, data).then(response => {
      if (response === undefined) {
        toaster('error', 'Something went wrong!');
      } else if (response.status === 200) {
        if (role !== 1) {
          if (newCurrentHomeInfo) {
            handleClose()
            setSuccessModalShow(true)
          } else {
            handleClose()
            setPastHomeInfo(true)
            toaster('success', 'Data Saved Successfully!')
          }
        } else {
          handleClose();
          toaster('success', 'Data Saved Successfully!');
        }
      }
    })
  }

  const handlePlaceChanged = (place) => {
    const address = place.formatted_address;
    setAddress(address);
    getPlaceDetails(place.address_components);
    setLatitude(place.geometry.location.lat())
    setLongitude(place.geometry.location.lng())
  }

  const handleChange = event => {
    setAddress(event.target.value);
  }

  const getPlaceDetails = (addressArray) => {
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'locality' === addressArray[i].types[0]) {
        setCity(addressArray[i].long_name)
      } else if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
        setProvince(addressArray[i].long_name)
      } else if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
        setCountry(addressArray[i].long_name)
      } else if (addressArray[i].types[0] && 'postal_code' === addressArray[i].types[0]) {
        setPostal_code(addressArray[i].long_name)
      }
    }
  };


  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={role !== 1 ? 'My Profile' : 'edit tenant profile'}
      subTitle={role !== 1 ? 'Where Do You Currently Live?' : 'Current Address'}>

      <div className='sidepanel-body mt-2'>
        <Form id='Form'>
          {/* {current_home_2 && current_home_2.map((data, i) =>   { */}
          {/* return( */}
          <> 
          <Row>
            <Form.Group as={Col} md='12'>
              <AutoCompleteAddress
                md='12'
                as={Col}
                id={'current_form_address'}
                name='current_form_address_2'
                state={full_address}
                updateField={(e) => handleChange(e)}
                handlePlaceChanged={(data) => handlePlaceChanged(data)}
                addressTitle='Street Number & Name'
              />
            </Form.Group>
            {/* <span>Error</span> */}
          </Row>

          <Row>
            <Form.Group as={Col} md='12'>
              <Form.Label className="custom-lable">Unit Number (If applicable)</Form.Label>
              <Form.Control
                type='text'
                placeholder='Unit 111'
                name='unit_no_current_form'
                id={'unit_no_current_form_2'}
                value={unit_no}
                onChange={(e) => setUnit_no(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md='12'>
              <Form.Label className="custom-lable">{role !== 1 ? 'Living here since?' : 'Lived here since'}</Form.Label>
              <Row>
                <Col>
                  <DatePickerComponent
                    id='current_form_start_date_2'
                    name='current_form_start_date'
                    format={'dd/MM/yyyy'}
                    extraClass={'calender-icon-none'}
                    value={start_date === '' ? start_date : moment(start_date).toDate()}
                    handleDateChange={(date) => handleDateChange(date, setStart_date)}
                    maxDate={new Date()}
                    displayText={'Select Date'}
                    popperPlacement='bottom-start'
                  />
                </Col>

                {/* <Col>
                  <DatePickerComponent
                    name='current_end_date'
                    id='current_end_date'
                    format={'dd/MM/yyyy'}
                    value={end_date && end_date}
                    extraClass={'calender-icon-none'}
                    handleDateChange={(date) => handleDateChange(date, setEnd_date)}
                    displayText={'Select Date'}
                    popperPlacement='bottom-end'
                  />
                </Col> */}
              </Row>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md='12' className='mb-0'>
              <Form.Label className="custom-lable">Do you own or rent this place?</Form.Label>
              <ul className='select-options'>
                {is_ownedData.map((data) =>
                  <li onClick={(e) => OnSingleOptionCheck(e, this)} key={'is_owned_' + data.value} value={data.value} name='is_owned'
                    className={(is_owned === data.value) ? 'active' : ''}>{data.title}</li>)}
              </ul>
            </Form.Group>
          </Row>
          { !is_owned && 
          <>
            <Row>
              <Form.Group as={Col} md='12'>
                <Form.Label className="custom-lable">Monthly Rent</Form.Label>
                <CostNumberFormat
                  name={'monthly_rent_current'}
                  id={'monthly_rent_current_form_2'}
                  classname='form-control'
                  // value={monthly_rent ? monthly_rent : ''}
                  value={monthly_rent}
                  updateField={(e) => OnChangeInput(e, setMonthly_rent)}
                  pre='$'
                  placeholder={'$1,200'}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md='12'>
                <Form.Label className="custom-lable">Landlord Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  name='landlord_name_current'
                  id={'landlord_name_current_form_2'}
                  // value={landlord_name ? landlord_name : ''}
                  value={landlord_name}
                  onChange={(e) => OnChangeInput(e, setLandlord_name)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md='12'>
                <Form.Label className="custom-lable">Landlord Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  name='landlord_email_current'
                  id={'landlord_email_current_form_2'}
                  // value={landlord_email ? landlord_email : ''}
                  value={landlord_email}
                  onChange={(e) => OnChangeInput(e, setLandlord_email)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} md='12'>
                <Form.Label className="custom-lable">Landlord Phone</Form.Label>
                <NumberFormat
                  format='###-###-####'
                  mask='_'
                  placeholder='Enter Phone Number'
                  name='landlord_phone_current'
                  id={'landlord_phone_current_form_2'}
                  className='form-control'
                  // value={landlord_phone ? landlord_phone : ''}
                  value={landlord_phone}
                  onChange={(e) => OnChangeInput(e, setLandlord_phone)}
                />
              </Form.Group>
            </Row>
          </>
          } </>
        </Form>
      </div>

      <div className={role !== 1 ? 'fixed-bottom d-flex justify-content-between' : 'fixed-bottom'}>
        {role !== 1 ?
          <>
            <CancelButton onClick={() => handleClose()} title='Back' />
            <LargeButton onClick={() => onSubmit()} title={'continue'} style={{ width: '200px', float: 'right' }} />
          </> :
          <Row>
            <Col md={12}>
              <LargeButton onClick={() => onSubmit()} title={'save changes'} style={{ width: '200px', float: 'right' }} />
            </Col>
          </Row>}
      </div>
    </SidePanel>
  </>);



}

export default CurrentHomeInfo;