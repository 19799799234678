import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Image } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { logger, toaster } from '../../../../Utils';
import { DollorNumberDisplay, EditButton, PhoneNumberFormat, LargeButton } from '../../../components';
import { leaseTypeDisplay, aminitiesDisplay, aminitiesGrid, rentPaidDisplay, parkingTypeDisplay } from '../../../../Constants/LeaseConstants';
import { dateFormat3 } from '../../../../Utils/DateSanitizer/DateSanitizer';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { FILE_API_URL } from '../../../../Constants/endPoints';
import { uploadSignedLeaseAPI } from '../../../../Store/api/leaseApi';
import { getUtilitiesAndAmenities } from '../../../../Constants/propertyDetails';
import FileBrowse from '../../FileBrowse/FileBrowse';
import { useGlobalState } from '../../../../StateContext';
import Loader from '../../Loader';

const log = logger('CompleteLeaseDetails');
const CompleteLeaseDetails = (props) => {
	const { component, openSignLease, setCurrentStep, property_id, property_details_id, leaseData, lease_id, setOpenSignLease, setLeasePdfViewScreen, inProgress, leaseCreationHandler } = props;
	log.info("component", component)
	const [leaseDetailsData, setLeaseDetails] = useState(null);
	const [propertyAddress, setPropertyAddress] = useState(null);
	const [contactDetails, setContactDetails] = useState(null);
	const [notices_and_documents, setNotices_and_documents] = useState(null);
	const [landlordMailingDetails, setLandlordMailingDetails] = useState(null);
	const [otherDetails, setOtherDetails] = useState(null);
	const [tenantDetails, setTenantDetails] = useState(null);
	const [other_service_utilities, setOther_service_utilities] = useState([]);
	const [errorClass, setErrorClass] = useState(false);
	const [showImageLoading, setShowImageLoading] = useState(false);
	const [uploadData, setUploadData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [globalState, setGlobalState] = useGlobalState()
	const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
	const backToPrevPage = () => {
		setCurrentStep(0);
	}
	const [amenitiesData, setAmenitiesData] = useState([]);

	const viewAppendix = () => {
		console.log('viewAppendix action here...')
		props.history.push({
			pathname: `/view-appendix/${property_id}/${property_details_id}/${lease_id}`,
			state: {
				pdfUrl: leaseDetailsData && leaseDetailsData.tenancy_agreement === 1 ? leaseDetailsData?.lease_appendix : leaseDetailsData?.rent_panda_lease_appendix
			}
		})
	}

	const onSubmit = () => {
		if (leaseData && leaseData.tenant_details &&
			leaseData.tenant_details.tenants && leaseData.tenant_details.tenants.length > 0) {
			if (leaseData && leaseData.is_signed) {
				setLeasePdfViewScreen(true);
			} else {
				setOpenSignLease(true);
			}
		} else {
			toaster('error', 'Please add tenant!');
		}
	}

	const handleSubmitSignedLease = () => {
		setLoading(true);
		uploadSignedLeaseAPI({ lease_id: lease_id, lease_pdf: uploadData.file }).then(res => {
			log.info("handleSubmitSignedLease", res);
			if (res.status === 200) {
				setLoading(false);
				toaster('success', res.data.message)
				document.getElementById('profileTop').scrollIntoView();
				props.history.push({
					// pathname: '/create-lease/' + property_id + '/' + property_details_id + '/' + lease_id,
					pathname: '/maintainance-repair/' + property_id + '/' + property_details_id + '/overview',
					state: {
						lease_id: lease_id,
						step: 3
					}
				});
			} else {
				toaster('error', res.response.data.message)
			}
		})
	}

	const handleDrop = (file, setImage) => {
		const token = `Bearer ${Cookies.get('access_token')}`
		const headers = {
			'Authorization': token,
			'Content-Type': 'multipart/form-data'
		}
		setShowImageLoading(true)

		file.map((dataFile) => {
			setUploadData(dataFile);
			let formdata = new FormData();
			formdata.append('file', dataFile);
			let url = FILE_API_URL;
			Axios.post(
				url, formdata, {
				headers: headers,
				onUploadProgress: progressEvent => {
					var percent = Math.round(progressEvent.loaded * 100 / progressEvent.total);
					if (percent >= 100) {
						setImage({ percent: percent });
						return file;
					} else {
						setImage({ percent: percent });
						return null;
					}
				}
			})
				.then(response => {
					setImage({ file: response.data.data.url })
					setErrorClass(false)
					setShowImageLoading(false)

				})
				.catch(error => {
					setShowImageLoading(false)

				});
			return dataFile;
		})
	}

	const removeFile = (index, setFile) => {
		setFile('')
	}

	useEffect(() => {
		const fetchedData = leaseData;
		if (fetchedData && fetchedData.contact_details) {
			setContactDetails(fetchedData.contact_details)
			setNotices_and_documents(fetchedData.notices_and_documents)
			setPropertyAddress(fetchedData.property_address)
			setLeaseDetails(fetchedData.lease_deatils)
			setLandlordMailingDetails(fetchedData.landlord_mailing_address)
			setOtherDetails(fetchedData.other_details)
			setTenantDetails(fetchedData.tenant_details)
		}

	}, [leaseData]);

	useEffect(() => {
		(async () => {
			const { amenitiesData = [] } = await getUtilitiesAndAmenities()
			setAmenitiesData(amenitiesData)
		})()
	}, [])

	// console.log(amenitiesData && amenitiesData[0].name)	

	useEffect(() => {
		leaseData && leaseData.lease_deatils && setOther_service_utilities(leaseData.lease_deatils.other_service_utilities)
		unitDetails && amenitiesData.filter(data => data.value !== 1).map(filter => {
			if (unitDetails?.amenities?.includes(filter.value)) {
				setOther_service_utilities(amenities => [filter.title, ...amenities])
			}
			return filter
		})
		log.info(unitDetails, leaseData)
	}, [unitDetails, leaseData])
	const maxLength = 25;
	log.info("other_service_utilities ==>", other_service_utilities);

	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const handleClickInside = (option) => {
		setIsPopoverOpen(option === 'yes' ? true : false);
	}

	return (<>
		{!leaseData ? <Loader /> : <>
			{leaseData && leaseData.is_signed === false && leaseData.is_sent_to_tenant === true && leaseData.is_signed_offline === true &&
				<div className='inner-wrapper mb-4 complete-lease-details'>
					<div className='body-container'>
						<div className='d-flex justify-content-between'>
							<h4>lease to be signed offline</h4>

						</div>
						{/* <hr /> */}
						<Row>

							<>
								<Form.Group as={Col} md='6'>
									<span>You can upload your signed lease to start managing your rented property using Rent Panda’s features</span>
								</Form.Group>

								<Form.Group as={Col} md='6'>
									{(uploadData && uploadData.file) ?
										<Col className='confirm-section mb-2 d-flex justify-content-between details'>
											<span className='d-flex justify-content-start'>
												<Image className="icon" src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
												<div className='file-details d-flex font-16'>
													{uploadData.file.split('/').pop().length > maxLength ? uploadData.file.split('/').pop().substring(0, maxLength) + '...' : uploadData.file.split('/').pop()}
												</div>
											</span>
											<Image className="icon cursor-pointer" title='Delete' onClick={(e) => removeFile(e, setUploadData)} src={require("../../../../_assets/images/red-delete-icon.svg")} alt="Delete" />
										</Col>
										: <FileBrowse
											handleDrop={(e) => handleDrop(e, setUploadData)}
											size={'sm'}
											title={'Upload Signed Lease'}
											percent={uploadData && uploadData.percent}
											multiple={false}
											fileType={'.pdf'}
											disable={false}
											imageData={uploadData && uploadData.file}
											removeFile={(e) => removeFile(e, setUploadData)}
											lableClass={'custom-lable'}
											section={'upload-lease'}
											uploadData={uploadData && uploadData}
											errorClass={errorClass === true ? "error-upload-box" : ""}
											showImageLoading={showImageLoading}
										/>}
								</Form.Group>


							</>
						</Row>

					</div>
				</div>
			}
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>landlord contact information</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(0)} />
						</div>
					</div>
					<hr />
					<Row>
						{(contactDetails) &&
							<>
								<Form.Group as={Col} md='3'>
									<Form.Label>Full Name</Form.Label>
									<span>{contactDetails.full_name}</span>
								</Form.Group>

								<Form.Group as={Col} md='3'>
									<Form.Label>Email Address</Form.Label>
									<span>{contactDetails.email}</span>
								</Form.Group>

								<Form.Group as={Col} md='3'>
									<Form.Label>Phone number</Form.Label>
									<span><PhoneNumberFormat phoneNumber={contactDetails.phone_number} /></span>
								</Form.Group>
							</>}
					</Row>
					<Row>
						{(notices_and_documents) &&
							<>
								<Form.Group as={Col} md='12'>
									<Form.Label>Both the landlord and tenant agree to receive notices and documents by email, where allowed by the Landlord and Tenant Board’s Rules of Procedure</Form.Label>
									<span>{notices_and_documents.agree_to_received_notice ?
										<>
											Yes - email: {notices_and_documents.agree_to_received_notices_email}
										</>
										: 'No'}</span>
								</Form.Group>

								<Form.Group as={Col} md='12'>
									<Form.Label>The landlord is providing phone and/or email contact information for emergencies or day-to-day communications</Form.Label>
									<span>{notices_and_documents.day_to_day_communication ?
										<>
											Yes  - email: {notices_and_documents.day_to_day_communication_email}, phone number: &nbsp;
                                        <PhoneNumberFormat phoneNumber={notices_and_documents.day_to_day_communication_phone_number} />
										</>
										: 'No'}</span>
								</Form.Group>
							</>}
					</Row>
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>landlord mailing address</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(0)} />
						</div>
					</div>
					<hr />
					<Row>
						{(landlordMailingDetails) &&
							<>
								<Form.Group as={Col} md='4'>
									<Form.Label>Address</Form.Label>
									<span>{landlordMailingDetails.mailing_address}</span>
								</Form.Group>

								<Form.Group as={Col} md='1'>
									<Form.Label>Unit</Form.Label>
									<span>{landlordMailingDetails.mailing_unit_no || ' -- '}</span>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>City</Form.Label>
									<span>{landlordMailingDetails.mailing_city}</span>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>Province</Form.Label>
									<span>{landlordMailingDetails.mailing_province}</span>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>Postal Code</Form.Label>
									<span>{landlordMailingDetails.mailing_postal_code}</span>
								</Form.Group>
							</>}
					</Row>
				</div>
			</div>


			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>rental property address</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(0)} />
						</div>
					</div>
					<hr />
					<Row>
						{(propertyAddress) &&
							<>
								<Form.Group as={Col} md='4'>
									<Form.Label>Address</Form.Label>
									<span>{propertyAddress.address}</span>
								</Form.Group>

								<Form.Group as={Col} md='1'>
									<Form.Label>Unit</Form.Label>
									<span>{propertyAddress.unit_no || ' -- '}</span>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>City</Form.Label>
									<span>{propertyAddress.city}</span>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>Province</Form.Label>
									<span>{propertyAddress.province}</span>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>Postal Code</Form.Label>
									<span>{propertyAddress.postal_code}</span>
								</Form.Group>

								<Form.Group as={Col} md='3'>
									<Form.Label>Is this rental unit in a condo?</Form.Label>
									<span>{(propertyAddress.is_condo === 0 ? "No" : "Yes")}</span>
								</Form.Group>
							</>}
					</Row>
				</div>
			</div>
			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>lease details</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					<Row>
						{(leaseDetailsData) &&
							<>
								<Form.Group as={Col} md='2'>
									<Form.Label>Lease Type</Form.Label>
									<span>{(leaseDetailsData.lease_type !== null && leaseDetailsData.lease_type !== "") ? leaseTypeDisplay(leaseDetailsData.lease_type) : '--'}</span>
								</Form.Group>

								<Form.Group as={Col} md='2'>
									<Form.Label>Start  Date</Form.Label>
									<span>{leaseDetailsData.lease_start_date && <Moment format="MMM D, YYYY">{leaseDetailsData.lease_start_date}</Moment>}</span>
								</Form.Group>
								{leaseDetailsData.lease_type !== 2 &&
									<Form.Group as={Col} md='2'>
										<Form.Label>End Date</Form.Label>
										<span>{leaseDetailsData.lease_end_date && <Moment format="MMM D, YYYY">{leaseDetailsData.lease_end_date}</Moment>}</span>
									</Form.Group>
								}

								<Form.Group as={Col} md={(leaseDetailsData.tenancy_agreement === 1) ? 2 : 4}>
									<Form.Label>{(leaseDetailsData.tenancy_agreement === 1) ? "Additional Terms" : "Attached Terms"}</Form.Label>
									{(leaseDetailsData.tenancy_agreement === 1) ?
										<span>Yes</span> : (
											!!leaseDetailsData.rent_panda_lease_appendix ?
												<span onClick={() => viewAppendix()} className="view-appendix cursorPointer pdf-title">
													<Image className="icon" width='25' src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
													{leaseDetailsData.rent_panda_lease_appendix && <>
														{leaseDetailsData.rent_panda_lease_appendix.split('/').pop().length > maxLength ? leaseDetailsData.rent_panda_lease_appendix.split('/').pop().substring(0, maxLength) + '...' : leaseDetailsData.rent_panda_lease_appendix.split('/').pop()}
													</>}
												</span> :
												<span>No</span>)
									}
								</Form.Group>

								{(leaseDetailsData.tenancy_agreement === 1) &&
									<Form.Group as={Col} md='4'>
										<Form.Label>Attached Terms</Form.Label>
										<span onClick={() => viewAppendix()} className="view-appendix cursorPointer pdf-title">
											<Image className="icon" width='25' src={require("../../../../_assets/images/pdf-icon.svg")} alt="PDF ICON" />
											{leaseDetailsData.lease_appendix && <>
												{leaseDetailsData.lease_appendix.split('/').pop().length > maxLength ? leaseDetailsData.lease_appendix.split('/').pop().substring(0, maxLength) + '...' : leaseDetailsData.lease_appendix.split('/').pop()}
											</>}
										</span>
									</Form.Group>}
							</>}
					</Row>
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>more details about the rent</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					{(leaseDetailsData !== null) &&
						<>
							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label>Rent is to be paid on:</Form.Label>
									<span>{(leaseDetailsData.rent_paid_on !== null || leaseDetailsData.rent_paid_on !== '') ? leaseDetailsData.rent_paid_on : '--'}</span>
								</Form.Group>
								<Form.Group as={Col} md='3'>
									<Form.Label>Base rent for the rental property</Form.Label>
									<span>{(leaseDetailsData.rent !== null || leaseDetailsData.rent !== '') ?
										<DollorNumberDisplay price={leaseDetailsData.rent} />
										: '--'}</span>
								</Form.Group>
								<Form.Group as={Col} md='2'>
									<Form.Label>Is parking available?</Form.Label>
									<span>{leaseDetailsData.is_parking_available === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>
								{leaseDetailsData.is_parking_available === 1 &&
									<>
										<Form.Group as={Col} md='2' className='pr-0 pl-0'>
											<Form.Label>Parking Type</Form.Label>
											<span>{parkingTypeDisplay(leaseDetailsData.parking_type)}</span>
										</Form.Group>
										<Form.Group as={Col} md='1'>
											<Form.Label># of spots</Form.Label>
											<span>{(leaseDetailsData.parking_spot !== null) ? leaseDetailsData.parking_spot : '--'}</span>
										</Form.Group>
										<Form.Group as={Col} md='2'>
											<Form.Label>Price for parkings</Form.Label>
											<span>{(leaseDetailsData.parking_price !== null) ?
												<DollorNumberDisplay price={leaseDetailsData.parking_price} />
												: '--'}</span>
										</Form.Group>
									</>
								}
								<Form.Group as={Col} md='2'>
									<Form.Label>Rent is payable to:</Form.Label>
									<span>{(leaseDetailsData.rent_payable_to !== null || leaseDetailsData.rent_payable_to !== '') ? leaseDetailsData.rent_payable_to : '--'}</span>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label>Payment Method</Form.Label>
									<span>{rentPaidDisplay(leaseDetailsData.rent_pay_method)}</span>
								</Form.Group>
								<Form.Group as={Col} md='4'>
									<Form.Label>Is the first rental period a partial period?</Form.Label>
									<span>{leaseDetailsData.first_rental_partial_period === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>

								{leaseDetailsData.first_rental_partial_period === 1 &&
									<>
										<Form.Group as={Col} md='3'>
											<Form.Label>Partial Period</Form.Label>
											<span>{dateFormat3(leaseDetailsData.partial_cover_start_date)} to {dateFormat3(leaseDetailsData.partial_cover_end_date)}</span>
										</Form.Group>
										<Form.Group as={Col} md='3'>
											<Form.Label>Partial Period Payment Amount</Form.Label>
											<span><DollorNumberDisplay price={leaseDetailsData.partial_cover_amount} />&nbsp;{'on'} {dateFormat3(leaseDetailsData.partial_payment_date)}</span>
										</Form.Group>
									</>}
							</Row>
							<Row>
								<Form.Group as={Col} md='6'>
									<Form.Label>Administrative charge for non-sufficient funds (maximum $20)</Form.Label>
									<span>{leaseDetailsData.administrative_charge !== null ? <DollorNumberDisplay price={leaseDetailsData.administrative_charge} /> : '--'}</span>
								</Form.Group>
								{otherDetails &&
									<Form.Group as={Col} md='2'>
										<Form.Label>Is smoking allowed?</Form.Label>
										<span>{otherDetails.is_smoking_allowed === 1 ? 'Yes' : 'No'}</span>
									</Form.Group>}
							</Row>
						</>
					}
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>deposits and discounts</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					{leaseDetailsData &&
						<Row>
							<Form.Group as={Col} md='2'>
								<Form.Label>Is there a rent deposit?</Form.Label>
								<span>{leaseDetailsData.is_rent_deposit === 1 ? 'Yes' : 'No'}</span>
							</Form.Group>
							{leaseDetailsData.is_rent_deposit === 1 &&
								<Form.Group as={Col} md='4'>
									<Form.Label>Rent Deposit Amount</Form.Label>
									<DollorNumberDisplay price={leaseDetailsData.is_rent_deposit_amount} />
								</Form.Group>
							}
						</Row>}
					{otherDetails &&
						<>
							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label>Is there a key deposit?</Form.Label>
									<span>{otherDetails.is_key_deposite === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>
								{otherDetails.is_key_deposite === 1 &&
									<Form.Group as={Col} md='4'>
										<Form.Label>key Deposit amount (can only be amount to replace key)</Form.Label>
										<DollorNumberDisplay price={otherDetails.key_deposite_amount} />
									</Form.Group>
								}
							</Row>
							<Row>
								<Form.Group as={Col} md='2'>
									<Form.Label>Is there a rent discount</Form.Label>
									<span>{otherDetails.is_rent_discounted === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>
								{otherDetails.is_rent_discounted === 1 &&
									<>
										<Form.Group as={Col} md='2'>
											<Form.Label>Amount</Form.Label>
											<DollorNumberDisplay price={otherDetails.rent_discount_amount} />
										</Form.Group>
										<Form.Group as={Col} md='8'>
											<Form.Label>Rent Discount Description</Form.Label>
											<span>{otherDetails.rent_discount_description}</span>
										</Form.Group>
									</>
								}
							</Row>
						</>
					}
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>who pays for the utilities</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					<Row>
						<Form.Group as={Col} md='2'>
							<Form.Label>Electricity</Form.Label>
							<span>{leaseDetailsData !== null && (leaseDetailsData.utility_electricity_pay_by === 1 ? "Landlord" : "Tenant")}</span>
						</Form.Group>

						<Form.Group as={Col} md='2'>
							<Form.Label>Heat</Form.Label>
							<span>{leaseDetailsData !== null && (leaseDetailsData.utility_heat_pay_by === 1 ? "Landlord" : "Tenant")}</span>
						</Form.Group>

						<Form.Group as={Col} md='2'>
							<Form.Label>Water</Form.Label>
							<span>{leaseDetailsData !== null && (leaseDetailsData.utility_water_pay_by === 1 ? "Landlord" : "Tenant")}</span>
						</Form.Group>
					</Row>
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>amenities</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(1)} />
						</div>
					</div>
					<hr />
					{leaseDetailsData !== null &&
						<Row>
							{leaseDetailsData.amenities && leaseDetailsData.amenities.map((data, i) => {
								// return <Form.Group as={Col} md={aminitiesGrid(data.id)} key={i}>
								return <Form.Group as={Col} md={2} key={i}>
									<Form.Label>{amenitiesData && amenitiesData.length > 0 && amenitiesData[i].name}</Form.Label>
									<span>{data.allow === 1 ? 'Yes' : 'No'}</span>
								</Form.Group>
							})}
							<Form.Group as={Col} md={'6'}>
								<Form.Label>Others</Form.Label>
								{leaseDetailsData.other_service_utilities &&
									<ul className='amenities'>
										{other_service_utilities.map((data, i) => {
											return data !== '' && <li key={'amenities' + i}>{data}</li>
										})}

									</ul>}
							</Form.Group>
						</Row>
					}
				</div>
			</div>

			<div className='inner-wrapper mb-4 complete-lease-details'>
				<div className='body-container'>
					<div className='d-flex justify-content-between'>
						<h4>tenant details</h4>
						<div className='button-wrapper pt-0'>
							<EditButton onClick={() => setCurrentStep(2)} />
						</div>
					</div>
					<hr />
					<Row>
						{(tenantDetails !== null && tenantDetails.tenants.length !== 0) &&
							<Form.Group as={Col} md='5'>
								<Row>
									<Form.Group as={Col} md='5' className="utilities-list">
										<Form.Label>Tenant Name</Form.Label>
									</Form.Group>
									<Form.Group as={Col} md='7' className="utilities-list">
										<Form.Label>Email</Form.Label>
									</Form.Group>
								</Row>
								{tenantDetails.tenants.map((tenant, i) => {
									return (<Row key={"tenant_list_" + i}>
										<Form.Group as={Col} md='5' className="utilities-list">
											<span>{tenant.name}</span>
										</Form.Group>
										<Form.Group as={Col} md='7' className="utilities-list">
											<span className='utilities'>{tenant.email}</span>
										</Form.Group>
									</Row>)
								})}
							</Form.Group>
						}
						<Form.Group as={Col} md='7'>
							<div className="utilities-list">
								<Form.Label>Would you like to require your tenant to have tenant’s insurance?</Form.Label>
								<span className='warning-popover d-inline'>
									<img className='cursorPointer ml-2 mb-1'
										onMouseEnter={() => handleClickInside('yes')}
										onMouseLeave={() => handleClickInside('no')}
										src={require('../../../../_assets/images/exclaim.svg')} width={16} height={16} alt='warning' />
									{(isPopoverOpen === true) && <div className="custom-popover extra">
										<p className='popover-content' style={{ height: 'auto', minHeight: 'auto' }}>You can make tenant insurance mandatory as part of your lease, protecting you from a tenant assuming your homeowners insurance covers them for relocation costs, content loss, property damage or an unnecessary lawsuit in the event of a dispute.</p>
									</div>}
								</span>
							</div>
							<span className='utilities'>{tenantDetails && (tenantDetails.required_tenant_insurance === 1 ? "Yes" : "No")}</span>
						</Form.Group>
					</Row>
				</div>
			</div>

			<div className="d-flex justify-content-between align-items-center">
				{(leaseDetailsData && leaseDetailsData.lease_appendix !== '') ?
					<LargeButton title='view lease appendix' variant='dark' onClick={() => viewAppendix()} style={{ display: 'block', width: "300px", height: "40px", padding: "0px" }} /> : <span></span>}
				<div className='container-footer'>
					{inProgress && (leaseData && leaseData.step_completed < 3 && leaseData.is_sent_to_tenant !== true && leaseData.is_signed !== true) ? <Button variant='success' type='submit' style={{ width: '250px' }} onClick={leaseCreationHandler}>Continue Lease Creation</Button> :
						<>
							{/* <Button variant='secondary' onClick={() => backToPrevPage()}>Back To Edit</Button> */}
							{uploadData && uploadData.file ? <Button variant='success' disabled={(uploadData && uploadData.file) && loading === false ? false : true} type='submit' onClick={() => handleSubmitSignedLease()}>Submit</Button> : <Button variant='success' type='submit' onClick={() => onSubmit()}>Next</Button>}
						</>
					}
				</div>
			</div>
		</>}
	</>);
}

export default withRouter(CompleteLeaseDetails);
