import { getAPI } from "../../../Api";
import Cookies from "js-cookie";
import { setConversationId, setViewingRequestId } from "../../../Store/actions/MessageDetailAction";
import store from '../../../configureStore'
export const markAllAsRead = async (id = '') => {
    try {
        const res = await getAPI(`/notifications/update-seen-at/${id}`)
        return res
    } catch (err) {
        return false
    }
}

export const markNotificationAsReadAndReplacePath = async (data, replace) => {
    try {
        await markAllAsRead(data.state.id)
        setTimeout(() =>
            replace({ ...data }), 300)
    } catch (err) {

    }

}

export const markAsReadAndReplace = async (data, replace) => {
    try {
        await markAllAsRead(data.state.id)
        setTimeout(() =>
            replace(data.pathname))
    } catch (err) {
    }
}




export const getStateForShowings = (data, isDispatch = true) => {
    if (isDispatch)
        store.dispatch(setViewingRequestId(data.viewing_request_id))
    return ({ viewingRequestId: data.viewing_request_id, ...data, })
}

/*
=>1: Showing Request Sent, 
2: Tenancy Application Sent, 
3: Lease Sent, 
4: Lease Signed, 
5: Maintenance Request Created, 
6: Maintenance Request Completed
=>7: showing invitation rescheduling request
=>8: showing invitation cancellation (by tenant)
=>9: showing invitation confirmation (by tenant)
=>10: showing invitation rejection (by tenant)
11: Received New Message
12: Notice
13: Lease Signed by Tenant
=>14: Showing Invitation Sent
=>15: Showing request reschedule
=>16: Showing request cancellation
=>17: Showing request confirmation
=>18: Showing request rejection
19: Tenancy Application Invitation
20: Profile Incomplete
21: Screening consent request
22: Move In Inspection
23: Move Out Inspection
24: Expiry of purchased promotion"
*/

const role = Number(Cookies.get('role'));
export const redirectTo = (replace, push, data) => ({
    // Showings 1, 7, 9, 14, 15, 17
    1: () => markNotificationAsReadAndReplacePath({ pathname: (role === 2 ? `/property-details/${data.property_id}/${data.property_detail_id}/showings` : `/dashboard/showings`), state: getStateForShowings(data) }, replace),
    7: () => markNotificationAsReadAndReplacePath({ pathname: (role === 2 ? `/property-details/${data.property_id}/${data.property_detail_id}/showings` : `/dashboard/showings`), state: getStateForShowings(data) }, replace),
    9: () => markNotificationAsReadAndReplacePath({ pathname: (role === 2 ? `/property-details/${data.property_id}/${data.property_detail_id}/showings` : `/dashboard/showings`), state: getStateForShowings(data) }, replace),
    14: () => markNotificationAsReadAndReplacePath({ pathname: (role === 2 ? `/property-details/${data.property_id}/${data.property_detail_id}/showings` : `/dashboard/showings`), state: getStateForShowings(data) }, replace),
    15: () => markNotificationAsReadAndReplacePath({ pathname: (role === 2 ? `/property-details/${data.property_id}/${data.property_detail_id}/showings` : `/dashboard/showings`), state: getStateForShowings(data) }, replace),
    17: () => markNotificationAsReadAndReplacePath({ pathname: (role === 2 ? `/property-details/${data.property_id}/${data.property_detail_id}/showings` : `/dashboard/showings`), state: getStateForShowings(data) }, replace),

    // Showings (Reject/Cancel) 8, 10, 16, 18
    8: () => markNotificationAsReadAndReplacePath({ pathname: `/property-details/${data.property_id}/${data.property_detail_id}/messages`, state: getStateForShowings(data) }, replace),
    10: () => markNotificationAsReadAndReplacePath({ pathname: `/property-details/${data.property_id}/${data.property_detail_id}/messages`, state: getStateForShowings(data) }, replace),
    16: () => markNotificationAsReadAndReplacePath({ pathname: `/property-details/${data.property_id}/${data.property_detail_id}/messages`, state: getStateForShowings(data) }, replace),
    18: () => markNotificationAsReadAndReplacePath({ pathname: `/property-details/${data.property_id}/${data.property_detail_id}/messages`, state: getStateForShowings(data) }, replace),

    //tenancy  check
    2: () => markNotificationAsReadAndReplacePath({ pathname: `/property-details/${data.property_id}/${data.property_detail_id}/messages`, state: getStateForShowings(data, false) }, replace),

    19: () => markNotificationAsReadAndReplacePath({ pathname: `/property-details/${data.property_id}/${data.property_detail_id}/messages`, state: getStateForShowings(data, false) }, replace),

    //concent check
    21: () => markNotificationAsReadAndReplacePath({ pathname: `/property-details/${data.property_id}/${data.property_detail_id}/messages`, state: getStateForShowings(data, false) }, replace),


    // Lease
    3: () => {
        const path=role===3 ? `/property-details/${data.property_id}/${data.property_detail_id}/messages`:  '/property-details/' + data.property_id + '/' + data.property_detail_id + '/leases';
        markNotificationAsReadAndReplacePath({ pathname: path, state: getStateForShowings(data) }, replace)
    },

    
    //repair request
    5: () => {
        if (!!data.maintenance_repair_request_id)
            markNotificationAsReadAndReplacePath({ pathname: `/view-repair-request/${data.maintenance_repair_request_id}`, state: data }, replace)
    },


    //promotion
    24: () => markAsReadAndReplace({ pathname: `/property-details/${data.property_id}/${data.property_detail_id}/unit-details`, state: newMessageRecive(data) }, replace),

    // new messages
    11: () => markAsReadAndReplace({ pathname: `/property-details/${data.property_id}/${data.property_detail_id}/messages`, state: newMessageRecive(data) }, replace),

    12: () => markNotificationAsReadAndReplacePath({ pathname: `/view-notice/${data.property_id}/${data.property_detail_id}/${data.notice_id}`, state: getStateForShowings(data) }, replace),

    //tenant sign lease
    13: () => markNotificationAsReadAndReplacePath({ pathname: `/maintainance-repair/${data.property_id}/${data.property_detail_id}/overview`, state: getStateForShowings(data) }, replace),
    4: () => markNotificationAsReadAndReplacePath({ pathname: `/maintainance-repair/${data.property_id}/${data.property_detail_id}/overview`, state: getStateForShowings(data) }, replace),

    20: () => { markAsReadAndReplace({ pathname: `/profile`,state:data }, replace)},


    //move in
    22: () => {
        markAsReadAndReplace({ pathname: `/maintainance-repair/${data.property_id}/${data.property_detail_id}/inspections`,state:data }, replace)
    },
    
    //move out
    23: () => {
        markAsReadAndReplace({ pathname: `/maintainance-repair/${data.property_id}/${data.property_detail_id}/inspections`,state:data }, replace)
    }


})



export const newMessageRecive = (data) => {
    store.dispatch(setConversationId(null))
    return data
}