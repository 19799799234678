
export const convertTime24to12 = (timeStr) => {
     if (timeStr) {
          if (timeStr.toLowerCase().includes('am') || timeStr.toLowerCase().includes('pm')) {
               const arr = timeStr.split(" ")
               const timeArr = arr[0].split(":")
               return ({ hr: timeArr[0], min: timeArr[1], ampm: arr[1] })
          }


          let time = timeStr.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [timeStr];

          if (time.length > 1) {
               time = time.slice(1);
               time[5] = +time[0] < 12 ? ':AM' : ':PM';
               time[0] = +time[0] % 12 || 12;
          }
          const timeString = time.join('')
          const arr = timeString.split(":")
          return { hr: arr[0], min: arr[1], ampm: arr[3] }
     }
     return { hr: '', min: '', ampm: '' }

}

export const convertTime12to24 = (time12h) => {
     const [time, modifier] = time12h.split(' ');
     let [hours, minutes] = time.split(':');

     if (hours === '12') {
          hours = '00';
     }

     if (modifier === 'PM') {
          hours = parseInt(hours, 10) + 12;
     }

     return { hours, minutes };
}

export const onTimeChange = (e, timeValue, setTimeValue) => {
     const regex = /^[0-9]+$/;
     const timeParam = e.target.name
     let value = e.target.value
     if (value.trim().length === 0) {
          setTimeValue({ ...timeValue, [timeParam]: value })
     }
     if (value.match(regex)) {
          const timeParamValue = parseInt(value)
          if (timeParam === 'hr' && timeParamValue > 12) {
               setTimeValue({ ...timeValue, [timeParam]: '00' })
               return
          }
          if (timeParam === 'min' && timeParamValue > 59) {
               setTimeValue({ ...timeValue, [timeParam]: '00' })
               return
          }
          if (timeParamValue < 10 && timeParamValue > 0) {
               setTimeValue({ ...timeValue, [timeParam]: '0' + timeParamValue })
               return
          }
          if (timeParamValue > 9 && timeParamValue.toString().indexOf('0') === 0) {
               setTimeValue({ ...timeValue, [timeParam]: timeParamValue.toString().substr(1) })
               return

          }
          if (timeParamValue === 0) {
               setTimeValue({ ...timeValue, [timeParam]: '00' })
               return
          }

          setTimeValue({ ...timeValue, [e.target.name]: value?.toString().length === 3 ? value.substring(1) : value })
     }
}




export const onIncrementDecrement = (timeParam, timeValue, setTimeValue, offset) => {
     const timeParamValue = parseInt(timeValue[timeParam] || 0)

     if (timeParamValue + offset >= 0) {
          if (timeParam === 'hr' && timeParamValue + offset > 12) {
               setTimeValue({ ...timeValue, [timeParam]: '00' })
               return
          }
          if (timeParam === 'hr' && timeParamValue + offset > 11 && timeValue.ampm === 'AM') {
               setTimeValue({ ...timeValue, [timeParam]: '00' })
               return
          }

          if (timeParam === 'min' && timeParamValue + offset > 59) {
               const hrValue = (parseInt(timeValue.hr) + 1 > 12 ? 0 : parseInt(timeValue.hr) + 1)
               setTimeValue({ ...timeValue, [timeParam]: '00', hr: hrValue < 10 ? '0' + hrValue : hrValue })
               return
          }
          const value = timeParamValue + offset;

          setTimeValue({ ...timeValue, [timeParam]: value < 10 ? '0' + value : value })
     }
}


export const isValidTime = ({ hr, min, ampm }) => {
     const hour = parseInt(hr);
     const minute = parseInt(min)

     if (hour === 0 && minute === 0)
          return true

     if (hour === 12 && ampm === 'PM')
          return true

     if ((hour > 10 && ampm === 'PM') || (hour < 8 && ampm === 'AM'))
          return false
     return true

}

export const makeTimeValid = ({ hr, min, ampm }) => {
     console.log({ hr, min, ampm })
     if (hr === "12" && ampm === "AM")
          return ({ hr: '00', min, ampm })


     return ({ hr, min, ampm })

}