import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from 'react-bootstrap';
import Resources from "./components/Resources";
import { postAPI } from '../../../../../Api';
// import { Container, Row, Col, Button } from "reactstrap";
import "./style.scss";
class ResourcesSection extends Component {
  responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1500 },
      items: 5,
      partialVisibilityGutter: 17,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 4,
      partialVisibilityGutter: 20,
    },
    tablet: {
      breakpoint: { max: 1200, min: 991 },
      items: 3,
      partialVisibilityGutter: 26,
    },
    tablet2: {
      breakpoint: { max: 991, min: 579 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 579, min: 0 },
      items: 1,
      partialVisibilityGutter: 80,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      numberOfCards: 3,
      resourcesData: [],
    };
  }

  resize = () => {
    let numberOfCards = 4;
    let width = window.screen.width;
    if (width <= 579) {
      numberOfCards = 1;
    } else if (width > 579 && width <= 1024) {
      numberOfCards = 2;
    } else if (width > 1024 && width <= 1500) {
      numberOfCards = 4;
    } else if (width > 1500 && width <= 4000) {
      numberOfCards = 4;
    }
    this.setState({ numberOfCards: numberOfCards });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();

    const url = '/cms/articles/list';
    postAPI(url).then(response => {
        if (response.status === 200) {
            const resData = response.data.data;
            this.setState({resourcesData: resData});
        }
    })
    // if (!this.props.resources || this.props.resources.length === 0) {
    //     this.props.fetchResourcesContent();
    // }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  renderResources = () => {
    return this.state.resourcesData && this.state.resourcesData.map((resource, index) => {
      return (
        <div key={index}>
          <Resources
            resource={resource}
            numberOfCards={this.state.numberOfCards}
          />
        </div>
      );
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="landlord-resources">
          <div className="text-center">              
            <h3 className="landing-title f-secondary-b">rent panda answers the hard questions. and the easy ones.</h3>
          </div>
          <Carousel
            responsive={this.responsive}
            infinite={true}
            partialVisible={true}
            swipeable={this.state.numberOfCards === 1}
            showDots={true}
            draggable={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            dotListClass="landlord-resources-dot-list"
            arrows={this.state.numberOfCards !== 1}
          >
            {this.renderResources()}
          </Carousel>
          <div className="text-center learn-more-action">
            <Button href={"/resources/blog"} className="btn btn-primary btn-block border-0">learn more</Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ResourcesSection);
