import { postAPI } from "../../../Api";

export const setMyFavourite = async (property_details_id) => {
    const url = '/tenant/add-to-favorite-listing';
    const data = {
        'property_details_id': property_details_id
    }
    const res = await postAPI(url, data)

    return res;
}