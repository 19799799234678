import React, { Component } from "react";
import { Image, Button } from "react-bootstrap";
import { Loader } from '../../../../../App/components';
import { getAPI } from '../../../../../Api';
import { FreeRentAssessment } from "../../../../../App/Landlord/CreateListing/Steps";
import "./style.scss";
import { getAds } from "../../../../../Utils/Ads";
class InformationSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerImage: null,
      freeRentAssessment: false,
      free_rent_assessment_id: ''
    }
  }


  componentDidMount = async () => {
    const adImages = await getAds()
    if(adImages?.length)
    this.setState({ bannerImage: adImages[0] });
  }

  handleClose = () => {
    this.setState({ freeRentAssessment: false })
  }


  render() {

    return (
      <React.Fragment>
        <section id="letUsDoWork" className="sec-information-section">
          <div className="container">
            <div className="text-center">
              <div className="landing-sub-title">LET US DO THE WORK</div>
              <h3 className="landing-title f-secondary-b">rent panda likes getting its hands dirty.</h3>
            </div>

            <div className="work-box-wrap">
              <div className="row">
                <div className="col-lg-6 work-box-right mb-4">
                  <div className="work-box-inner">
                    <h2 className="work-box-title text-left">tenant acquisition package</h2>
                    <ul>
                      <li>rent assessment &amp; property walk through</li>
                      <li>pro photos &amp; videos</li>
                      <li>writing, posting and marketing the listing</li>
                      <li>managing messages &amp; pre-screening </li>
                      <li>conducting showings</li>
                      <li>screening tenants &amp; processing applications</li>
                      <li>drafting the lease &amp; lease appendix</li>
                    </ul>
                    <div className="cost">Cost:</div>
                    <div className="month-rent">1 month rent</div>
                    <Button onClick={() => this.setState({ freeRentAssessment: true })} className="btn-block mt-auto">let’s chat</Button>
                  </div>
                </div>
                <div className="col-lg-6 work-box-left mb-4">
                  <div className="work-box-inner">
                    <h2 className="work-box-title text-left">rental management package</h2>
                    <ul>
                      <li>rent collection</li>
                      <li>on call 24/7 for tenants</li>
                      <li>management of repairs &amp; maintenance</li>
                      <li>bi-annual inspections</li>
                      <li>snow and lawn care management</li>
                      <li>issuing tenant notices</li>
                      <li>50% discount on package 1 service</li>
                    </ul>
                    <div className="cost">Cost:</div>
                    <div className="month-rent">9% monthly rent</div>
                    <Button onClick={() => this.setState({ freeRentAssessment: true })} className="btn-block mt-auto">let’s chat</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-ad-area">
              {this.state.bannerImage ?
                <a href={this.state.bannerImage?.image_link}>
                  <Image src={this.state.bannerImage?.image_url} alt="" />
                </a>

                : <Loader />}
            </div>
          </div>
        </section>

        {/* Free Rent Assessment Form */}
        <FreeRentAssessment
          isShow={this.state.freeRentAssessment}
          handleClose={this.handleClose}
          landlordLanding={true}
          setFree_rent_assessment_id={(data) => this.setState({ free_rent_assessment_id: data })} />
      </React.Fragment>
    );
  }
}

export default InformationSection;
