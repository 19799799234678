import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setSearchLocation } from '../../../Store/actions/LandingPageAction';
import { Loader, AutoCompleteAddress } from '../../../App/components';
import { logger } from '../../../Utils';

const log = logger("HeroSection");
const HeroSection = (props) => {
    const searchLocation = useSelector(state => state.landingPageReducer.getSearchLocation) || '';
    const locations = useSelector(state => state.landingPageReducer.getLocationList) || [];
    // const [address, setAddress] = useState('');
    const dispatch = useDispatch();
    
    // const onSubmit = (e) => {
    //     e.preventDefault();
    //     // if (searchLocation !== '') { }
    //     log.info("searchLocation", searchLocation);
    //     props.history.push('/search-result');
    // }
    const handleCapsuleClick = (location) => {
        dispatch(setSearchLocation(location));
        props.history.push('/search-result');
    }
    
    const handlePlaceChanged = (place) => {
        const address = place.formatted_address;
        log.info("address", address);
        // setAddress(address);
        dispatch(setSearchLocation(address))
        props.history.push('/search-result');
    }
    const handleChange = event => {
        event.target.setAttribute('autocomplete', 'off');
        dispatch(setSearchLocation(event.target.value))
        // setAddress(event.target.value);
    }

    return (
        <div className="hero-section">
            <Container>
                <h1 className="main-head">
                    <div className="main-head-line-one">rent panda found the </div>
                    <div className="main-head-line-two">perfect place for you.</div>
                </h1>
                <h2 className="sub-head">search verified listings. create a profile. book showings. sign a lease.</h2>
                {/* <Form onSubmit={e => onSubmit(e)} className="searchbox-container col-md-5">
                    <SearchInput searchValue={searchLocation} setSearchValue={(e) => dispatch(setSearchLocation(e))} placeHolder="where do you want to live?" extraCalss="search-filter mb-4" />
                </Form> */}
                <Col md={5} className="searchbox-container">
                    <AutoCompleteAddress
                        className="searchBar"
                        placeholder="Type to search location"
                        id="searchLocation"
                        state={searchLocation === null ? "" : searchLocation}
                        updateField={(data) => handleChange(data)}
                        handlePlaceChanged={(data) => handlePlaceChanged(data)}
                    />
                </Col>
                <Row className="location-capsules">
                    {locations ? locations.map((location, i) => <Button className="mb-2" onClick={() => handleCapsuleClick(location)} variant="dark" key={`location_${i}`}>{location}</Button>) : <Loader/>}
                </Row>
                <Link className="link-to-landlord" to='/landlord'><u>looking to list a place? click here</u></Link>
            </Container>
        </div>
    )
}

export default withRouter(HeroSection);
