import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Image, Accordion, Card, Button } from 'react-bootstrap';
import Layout from '../../components/Layout';
import { getAPI } from '../../../Api';
import { Loader } from '../../../App/components';
import { logger } from '../../../Utils';
import Cookies from 'js-cookie';

import '../style.scss';

const log = logger("HowItWorks");
const HowItWorks = (props) => {
    const [faqList, setFaqList] = useState([]);
    const [accordianId, setAccordionId] = useState(null);

    const clickExterior = (id) => {
        const check = id === accordianId ? null : id
        setAccordionId(check);
    }
    useEffect(() => {
        getAPI('/cms/faq/list').then(res => {
            if (res.status === 200) {
                setFaqList(res.data.data);
            }
            log.info("/cms/faq/list > res ", res);
        })
    }, [])

    return (
        <div className="howItWorksPage">
            <Layout newHeader={true}>
                <section className="sec-website-page-title how-it-work-title-bg cu-container-wrap">
                    <div className="container">
                        <h1 className="website-page-title text-center">rent panda loves a 3 step system.</h1>
                    </div>
                </section>
                <section className="sec-point-list cu-container-wrap">
                    <div className="container">
                        <div className="count-point-number-box">
                            <div className="count-point-number f-secondary-b">1</div>
                            <div className="count-point-number-text f-secondary-b">find it</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mb-4">
                                <div className="count-point-box">
                                    <div className="count-point-box-image">
                                        <Image src={require("../../../_assets/images/how-it-works/icon-how-it-works1.svg")} alt="Advertise Vacancies" />
                                    </div>
                                    <div className="count-point-box-textarea">
                                        <h5 className="count-point-box-title f-secondary-b">sign up</h5>
                                        <div className="count-point-box-disc">Rent panda doesn’t charge you to look, or for anything else for that matter!</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="count-point-box">
                                    <div className="count-point-box-image">
                                        <Image src={require("../../../_assets/images/how-it-works/icon-how-it-works2.svg")} alt="Advertise Vacancies" />
                                    </div>
                                    <div className="count-point-box-textarea">
                                        <h5 className="count-point-box-title f-secondary-b">complete your profile</h5>
                                        <div className="count-point-box-disc">Applications are so 2010. Fill out your profile once and you’ll never have to fill out another application manually again!</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="count-point-box">
                                    <div className="count-point-box-image">
                                        <Image src={require("../../../_assets/images/how-it-works/icon-how-it-works3.svg")} alt="Advertise Vacancies" />
                                    </div>
                                    <div className="count-point-box-textarea">
                                        <h5 className="count-point-box-title f-secondary-b">start searching</h5>
                                        <div className="count-point-box-disc">Use the filters to find the perfect place. And look for the ‘verified’ badge, which lets you know we’ve been in the place to make sure what you see is what you get.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 2 */}
                        <div className="count-point-number-box">
                            <div className="count-point-number f-secondary-b">2</div>
                            <div className="count-point-number-text f-secondary-b">rent it</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mb-4">
                                <div className="count-point-box">
                                    <div className="count-point-box-image">
                                        <Image src={require("../../../_assets/images/how-it-works/icon-how-it-works4.svg")} alt="Advertise Vacancies" />
                                    </div>
                                    <div className="count-point-box-textarea">
                                        <h5 className="count-point-box-title f-secondary-b">apply</h5>
                                        <div className="count-point-box-disc">Let the landlord know that you’re interested by hitting the ‘apply’ button. And with a complete profile, you won’t have to fill out any paperwork!</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="count-point-box">
                                    <div className="count-point-box-image">
                                        <Image src={require("../../../_assets/images/how-it-works/icon-how-it-works5.svg")} alt="Advertise Vacancies" />
                                    </div>
                                    <div className="count-point-box-textarea">
                                        <h5 className="count-point-box-title f-secondary-b">Sign your leases</h5>
                                        <div className="count-point-box-disc">Once your application is approved the landlord will send you a lease through rent panda that you can review and sign digitally. Your lease is stored in your dashboard so you can always refer back to it!</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="count-point-box">
                                    <div className="count-point-box-image">
                                        <Image src={require("../../../_assets/images/how-it-works/icon-how-it-works6.svg")} alt="Advertise Vacancies" />
                                    </div>
                                    <div className="count-point-box-textarea">
                                        <h5 className="count-point-box-title f-secondary-b">arrange a move in</h5>
                                        <div className="count-point-box-disc">Now that you’ve secured a place you can message your landlord through your dashboard, booking a move in date and inspection. The inspection is also stored in your dashboard for future reference.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 3 */}
                        <div className="count-point-number-box">
                            <div className="count-point-number f-secondary-b">3</div>
                            <div className="count-point-number-text f-secondary-b">manage it</div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mb-4">
                                <div className="count-point-box">
                                    <div className="count-point-box-image">
                                        <Image src={require("../../../_assets/images/how-it-works/icon-how-it-works7.svg")} alt="Advertise Vacancies" />
                                    </div>
                                    <div className="count-point-box-textarea">
                                        <h5 className="count-point-box-title f-secondary-b">get insured</h5>
                                        <div className="count-point-box-disc">Tenant insurance is a smart thing to have and some landlords even make it mandatory. Get a quote and buy it online within minutes!</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="count-point-box">
                                    <div className="count-point-box-image">
                                        <Image src={require("../../../_assets/images/how-it-works/icon-how-it-works8.svg")} alt="Advertise Vacancies" />
                                    </div>
                                    <div className="count-point-box-textarea">
                                        <h5 className="count-point-box-title f-secondary-b">pay rent online (coming soon)</h5>
                                        <div className="count-point-box-disc">Cheques and e transfers can be a pain. Now you can pay your rent online in a few easy steps, so you never have to worry about forgetting!</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                                <div className="count-point-box">
                                    <div className="count-point-box-image">
                                        <Image src={require("../../../_assets/images/how-it-works/icon-how-it-works9.svg")} alt="Advertise Vacancies" />
                                    </div>
                                    <div className="count-point-box-textarea">
                                        <h5 className="count-point-box-title f-secondary-b">make yourself at home</h5>
                                        <div className="count-point-box-disc">Once you’ve moved in you can use your dashboard to communicate with your landlord, send and receive notices and let them know of any repairs that need to be done.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-center howItWork-btn-area">
                            <Button href={`${
                                Cookies.get('access_token') ? // if: user is logged in
                                    (parseInt(Cookies.get('role')) === 2 ? '/dashboard' : (parseInt(Cookies.get('role')) === 3 && '/search-listing')) : // else if: logged in user is a tenant
                                    '/search-result' // else: for non logged in users
                                }`} className="btn btn-primary btn-block f-secondary-r">let's get started</Button>
                        </div>
                    </div>
                </section>

                <section className="sec-faq cu-container-wrap">
                    <div className="container">
                        <h5 className="faq-title-text f-secondary-b text-center">frequently asked questions</h5>
                        <div className="faqsContainer">
                            {faqList && <Accordion defaultActiveKey={faqList.length !== 0 && faqList[0].title}>
                                {faqList.length !== 0 ?
                                    <>
                                        {faqList.map((faq, i) => {
                                            return <Card className='mb-3' key={'faq_' + i}>
                                                <Card.Header className='d-flex justify-content-between'>
                                                    <div className='accordian-heading'>{faq.title}</div>
                                                    <Accordion.Toggle as={Button} className='inspection-btn' variant="link" eventKey={faq.title} onClick={() => clickExterior(faq.id)}>
                                                        <img className="next-arrow"
                                                            src={accordianId === faq.id
                                                                ? require('../../../_assets/images/faq-arrow2.svg')
                                                                : require('../../../_assets/images/faq-arrow1.svg')}
                                                            alt="next" />
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={faq.title}>
                                                    <Card.Body className='section-card'>{faq.description}</Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        })}
                                    </>
                                    : <Loader />}
                            </Accordion>
                            }
                        </div>
                    </div>
                </section>

            </Layout>
        </div>
    )
}

export default withRouter(HowItWorks);
