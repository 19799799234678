import React, { useState, useEffect } from "react";
import { SidePanel, LargeButton, ModalBox } from "../../";
import './style.scss';
import NumberFormat from "react-number-format";
import { logger, toaster } from "../../../../Utils";
import { Col, Image, Row, Form } from "react-bootstrap";
import { getAPI, postAPI } from "../../../../Api";
import Moment from 'react-moment';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import { validate } from '../../../../Utils/Validation/validation';
import { ConfirmShowingValidationSchema } from './ConfirmShowingValidationSchema';
import { useSelector, useDispatch } from "react-redux";
import { setViewingRequestId } from "../../../../Store/actions/MessageDetailAction";
import { setShowingCalendarData, setShowingListData } from "../../../../Store/actions/ShowingDetailAction";
import { dateFormat3 } from "../../../../Utils/DateSanitizer/DateSanitizer";
import { createEventId } from "../../Calender/event-utils";

// 'status' => [
// 	0 => 'Need Attention',
// 	1 => 'Schedule Confirmed',
// 	2 => 'Rejected',
// 	3 => 'Delivered to landlord',
// 	4 => 'Delivered to tenant',
// 	5 => 'reschedule',
// 	6 => 'Cancel'
// ]


const log = logger("ConfirmShowing");
const ConfirmShowing = (props) => {
	const { isShow, handleClose, title, setShowingRequest, setReSchedule } = props;
	const role = Number(Cookies.get('role'));
	const [viewingRequestDetails, setViewingRequestDetails] = useState(null);
	const [openConfirmation, setConfirmation] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [reject_reason, setReject_reason] = useState(null);
	const [successReject, setSuccessReject] = useState(false);
	const [schedule_date, setSchedule_date] = useState("");
	const [property_details_id, setProperty_details_id] = useState('');

	const dispatch = useDispatch();
	const receiverData = useSelector(state => state.messageDetailsReducer.receiverData);
	const is_receiver = receiverData && receiverData.is_message_received;
	const viewingRequestId = useSelector(state => state.messageDetailsReducer.viewingRequestId);
	const calenderData = useSelector(state => state.messageDetailsReducer.calenderData);
	const headerContent = useSelector(state => state.messageDetailsReducer.headerContent);
	const name = headerContent && headerContent.name;
	const userType = useSelector(state => state.auth.role)
	const conversation_id = useSelector(state => state.messageDetailsReducer.conversationId);
	const existingShowingRequestId = useSelector(state => state.messageDetailsReducer.existingShowingRequestId);
	const messageData = useSelector(state => state.messageDetailsReducer.messageData);

	const is_receiver_status = calenderData ? calenderData.is_request_received : is_receiver;
	const unitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
	

	log.info('receiverData', receiverData)
	log.info('viewingRequestDetails', viewingRequestDetails)
	log.info('calenderData', calenderData)

	console.log(role);

	useEffect(() => {
        setProperty_details_id(Number(props.match.params.property_details_id));
    }, [props]);

	useEffect(() => {
        if(modalShow){
            setReject_reason("");
        }
    },[modalShow])

	useEffect(() => {
		if (viewingRequestId && isShow) {
			let url = '';
			if (role === 2) {
				url = '/landlord/showing-request-details/' + viewingRequestId;
			} else if (role === 3) {
				url = '/tenant/showing-invitation-details/' + viewingRequestId;
			}
			getAPI(url).then(response => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					const res = response.data.data;
					setViewingRequestDetails(res);
					if (res.status === 2 || res.status === 6){
						dispatch(setViewingRequestId(null));
					}
					if (res.viewingRequestDates[0]) {
						setSchedule_date(res.viewingRequestDates[0]);
					}
					log.info('viewingRequestId Resp', res)
				}
			})
				.catch(error => {
				});
		}
	}, [viewingRequestId, isShow])

	const getCalenderDetails = () => {
		let url = "/landlord/calendar-showings/calendar-view";
		if (property_details_id) {
			url = "/landlord/calendar-showings/calendar-view/" + property_details_id;
		}
		if (role === 3) {
			url = "/tenant/showing-requests/calendar-view";
		}
		getAPI(url)
			.then((response) => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					const res = response.data.data;
					log.info("Landlord Calender res", res);
					//log.info('sample data ',INITIAL_EVENTS);
					let resultArray = [];
					res.map((result, i) => {
						const date = result.start.replace(/\s/g, "");
						let backgroundColor = "#DEDEDE";
						let borderColor = "#DEDEDE";

						let status = result.data.status;
						//log.info('status',status,result)
						if (status && status.includes("Confirmed")) {
							backgroundColor = "#BFE7C3";
							borderColor = "#BFE7C3";
						} else if (status && status.includes("Attention")) {
							backgroundColor = "#F7BFBF";
							borderColor = "#F7BFBF";
						}

						resultArray.push({
							id: createEventId(),
							title: result.title,
							start: date,
							data: result.data,
							backgroundColor: backgroundColor,
							borderColor: borderColor,
						});

						return resultArray;
					});
					dispatch(setShowingCalendarData(resultArray));
				}
			})
			.catch((error) => { });
	}

	const getCalenderListDetails = () => {
		let calendarUrl = "/landlord/calendar-showings/list-view/";
		if (property_details_id) {
			calendarUrl = "/landlord/calendar-showings/list-view/" + property_details_id;
		}
		if (role === 3) {
			calendarUrl = "/tenant/showing-requests/list-view";
		}
		getAPI(calendarUrl)
			.then((response) => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					const res = response.data.data;
					//log.info('list view res',res);
					dispatch(setShowingListData(res));
				}
			})
			.catch((error) => { });
	}

	const onSubmit = () => {
		const data = {
			"conversation_id": conversation_id,
			"viewing_request_id": viewingRequestId,
			"viewing_request_date_id": schedule_date && schedule_date.id
		}

		let url = '';
		if (role === 2) {
			url = '/landlord/confirm-showing-request';
		} else if (role === 3) {
			url = '/tenant/confirm-showing-invitation';
		}

		postAPI(url, data).then(response => {
			if (response.status !== 200) {
				toaster('error', response.response.data.message)
			} else if (response.status === 200) {
				log.info('data', response.data)
				handleClose()
				setConfirmation(true);
				getCalenderDetails();
				getCalenderListDetails();
			}
		})
			.catch(error => {
			});

	}

	const onReject = () => {
		if (validate(ConfirmShowingValidationSchema)) {
			const data = {
				"conversation_id": conversation_id,
				"viewing_request_id": viewingRequestId,
				"reason": reject_reason
			}

			let url = '';
			if (role === 2) {
				url = '/landlord/reject-showing-request';
			} else if (role === 3) {
				url = '/tenant/reject-showing-invitation';
			}

			postAPI(url, data).then(response => {
				if (response.status !== 200) {
					toaster('error', response.response.data.message)
				} else if (response.status === 200) {
					log.info('data', response.data)
					setModalShow(false);
					setSuccessReject(true);
					getCalenderDetails();
					getCalenderListDetails();
				}
			})
			.catch(error => {
			});
		}
	}


	const toggleModal = () => {
		setConfirmation(false);
	}
	const goBack = () => {
		setConfirmation(false);
	}

	const handleChange = (e) => {
		setReject_reason(e.target.value)
	}

	const redirectToMessage = (data) => {
		props.history.push({
			pathname: '/property-details/' + data.property_id + '/' + data.property_detail_id + '/messages',
			tenant_id: data.tenant_id
		});
	}
	return (<>
		<SidePanel isShow={isShow} isHide={handleClose} 
		title={viewingRequestDetails ? 
				((viewingRequestDetails.status === 1) ? (is_receiver_status ? 'Showing Details' : 'Confirmed Showing') 
				:(viewingRequestDetails.status === 3 || viewingRequestDetails.status === 4) ? 'Pending Showings'  
				:'Showing Details') : title}> 
			<div className="sidepanel-body confirm-showing">
				{viewingRequestDetails &&
					<>
						<Row>
							<Col md="4" className="pr-0">
								{viewingRequestDetails.image_url ?
									<Image className="img-responsive" rounded width="108px" height="99px" src={viewingRequestDetails.image_url} />
									: <Image className="img-responsive" rounded width="108px" height="99px" src={require("../../../../_assets/images/placeholder.jpg")} />}
							</Col>
							<Col md="7" className="pl-0">
								<div className="showing-address-unit">
									<h2 className="property-title">{viewingRequestDetails.address}</h2>
									<div className="details-wrapper">
										<div className="full-width">
											<span className="specification">{viewingRequestDetails.unit_no && (isNaN(viewingRequestDetails.unit_no) ? viewingRequestDetails.unit_no + ' - '  : ('Unit ' + viewingRequestDetails.unit_no + ' - ' ))} 
                                            <NumberFormat value={viewingRequestDetails.price_per_month} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per month</span>
                                            <br />
											
											
												{(viewingRequestDetails?.status === 3 && (role === 2 ?													
														<div className="Needs Attention">
															<span className="status-text">needs attention</span>
														</div>
														:<div className="Delivered">
															<span className="status-text">delivered</span>
														</div>))}

												{(viewingRequestDetails?.status === 4 && (role === 3 ?
														<div className="Needs Attention">
															<span className="status-text">needs attention</span>
														</div>
														:<div className="Delivered">
															<span className="status-text">delivered</span>
														</div>))}

												{(viewingRequestDetails?.status === 1) &&
													<div className="Confirmed"><span className="status-text">confirmed</span></div>}

												{(viewingRequestDetails?.status === 2) &&
													<div className="Needs Attention"><span className="status-text">rejected</span></div>}

												{(viewingRequestDetails?.status === 6) &&
												<div className="Needs Attention"><span className="status-text">cancelled</span></div>}
																								
										</div>
									</div>
								</div>
							</Col>
						</Row>

						{((viewingRequestDetails.status === 1) || (calenderData && calenderData.is_request_received === 1)) ?
							<Col className='confirm-section mt-3'>
								<h3>Confirmed showing schedule</h3>
								{viewingRequestDetails.viewingRequestDates.length > 0 &&
									<p><Moment format="MMMM D, YYYY">{viewingRequestDetails.viewingRequestDates[0].date}</Moment> | {viewingRequestDetails.viewingRequestDates[0].time}</p>
								}
							</Col>
						:((viewingRequestDetails.status === 3 && role === 2) || 
							(viewingRequestDetails.status === 4 && role === 3) ||
							(viewingRequestDetails.status === 5 && is_receiver_status) ?
							<>
								<Col className='confirm-section mt-3'>
									{userType === 2 ? <h3>Select a time for the applicant to view the place</h3> : <h3>Select a time of your preference</h3>}
									<Form className='radio-wrapper'>
										<div key={`radio-date`} className="mb-3">
											{viewingRequestDetails.viewingRequestDates.map((viewDate, i) => {
												return (<Form.Check
													key={'radio_' + i}
													type='radio'
													id={`radio-date-` + i}
													name='schedule_date'
													checked={schedule_date.id === viewDate.id && 'checked'}
													onChange={() => {
														setSchedule_date(viewDate)
													}}
													//label={viewDate.date + '-' + viewDate.time}
													label={dateFormat3(viewDate.date) + ' | ' + viewDate.time}
												/>)
											})}
										</div>
									</Form>
									<div className='reschedule'>
										<p><strong>Not available during these times? <br />
											<span className='link' onClick={() => {
												handleClose()
												setShowingRequest(true)
												setReSchedule(true)
											}}>Suggest a different time</span></strong></p>
									</div>
								</Col>

								{viewingRequestDetails.notes &&
									<Col className='confirm-section mt-3'>
										<h3>{(role === 3) ? "Landlord notes" : "Applicant's notes"}</h3>
										<p>{viewingRequestDetails.notes}</p>
									</Col>}

							</>
							:<>
								<Col className='confirm-section mt-3'>
									<h3>Primary Showing Date and Time</h3>
									{viewingRequestDetails.viewingRequestDates.length > 0 &&
										dateFormat3(viewingRequestDetails.viewingRequestDates[0].date) + ' | ' + viewingRequestDetails.viewingRequestDates[0].time
									}
								</Col>

								{viewingRequestDetails.viewingRequestDates.length > 1 &&
									<Col className='confirm-section mt-3'>
										<h3>Alternative Date and Time</h3>
										<ol>
											{viewingRequestDetails.viewingRequestDates.map((viewDate, i) => {
												if (i !== 0) {
													return (<li key={'list_' + i}>
														{dateFormat3(viewDate.date) + ' | ' + viewDate.time}
													</li>)
												} else {
													return null;
												}
											})}
										</ol>
									</Col>}

								<Col className='confirm-section mt-3'>
									<h3>{role === 2 ? 'Tenant' : 'Landlord'}</h3>
									<p className='d-flex justify-content-between align-items-center'>
										<span>{calenderData ? (role === 2 ? calenderData.tenant : calenderData.landlord) : name}</span>
										{calenderData ?
											<span style={{ cursor: "pointer" }} onClick={() => redirectToMessage(calenderData)}>
												<Image className="icon mr-2"
													width="20px"
													height="20px"
													src={require("../../../../_assets/images/chat-icon.svg")}
												/>message
											</span> : ''}
									</p>
								</Col>
							</>)}

					</>}

			</div>

			{viewingRequestDetails && (viewingRequestDetails.status === 2 || viewingRequestDetails.status === 6) ?
				<div className="fixed-bottom">
					<LargeButton title='reschedule showing' onClick={() => {
						handleClose()
						setShowingRequest(true)
						setReSchedule(true)
					}} />
				</div>
			:
			<div className="fixed-bottom mobileflex" >
					{(viewingRequestDetails && 
					((viewingRequestDetails.status === 3 && role === 2) || 
					(viewingRequestDetails.status === 4 && role === 3) ||
					(viewingRequestDetails.status === 5 && is_receiver_status))) ?
						<>
							<LargeButton onClick={() => onSubmit()} title='confirm' />
							<LargeButton variant="secondary" title="reject" onClick={() => {
								handleClose()
								setModalShow(true)
							}} />
						</> :
						<>
							<LargeButton title='reschedule showing' onClick={() => {
								handleClose()
								setShowingRequest(true)
								setReSchedule(true)
							}} />
							<LargeButton variant="secondary" title="cancel showing" onClick={() => {
								handleClose()
								setModalShow(true)
							}} />
						</>}
				</div>
			}
		</SidePanel>

		<ModalBox show={openConfirmation} onHide={toggleModal} size="md" actionbuttontitle={'got it'} buttonaction={goBack} >
			{role === 2 && schedule_date ?
				<>
					<p className="modal-confirmation-text">
						You have a showing scheduled </p>
					<p style={{ fontSize: '18px', width: '80%', margin: 'auto', textAlign: 'center' }} ><Moment format="MMMM D, YYYY">{schedule_date.date}</Moment> on <Moment format="hh:mm a">{schedule_date.date + ' ' + schedule_date.time}</Moment> for your listing {viewingRequestDetails && viewingRequestDetails.address}.</p>
				</>
				: <>
					<p className="modal-confirmation-text">
						we have added a showing schedule
                    </p>
					<p style={{ fontSize: '18px', width: '80%', margin: 'auto', textAlign: 'center' }}>
						<Moment format="MMM D, YYYY">{schedule_date.date}</Moment> at <Moment format="hh:mm a">{schedule_date.date + ' ' + schedule_date.time}</Moment> for {(viewingRequestDetails && (viewingRequestDetails.unit_no ? viewingRequestDetails.unit_no + ', ' + viewingRequestDetails.address : viewingRequestDetails?.address))}.
                    </p>
				</>
			}
		</ModalBox>

		<ModalBox
			show={modalShow}
			onHide={() => {
				setModalShow(false)
			}}
			size="md"
			type="condition"
			actionbuttontitle={is_receiver_status ? 'cancel' : 'no'}
			buttonaction={() => {
				setModalShow(false)
			}}
			backbuttontitle={is_receiver_status ? 'reject' : 'yes'}
			backaction={() => {
				onReject()
			}}
		>
			<Form id="Form">
				<Form.Group controlId="ControlTextarea1">
					{role === 2 ?
						<>
							<p style={{ marginBottom: '30px' }} className="modal-confirmation-text">{is_receiver_status ? "reject " + name + "'s request?" : "are you sure you want to cancel this showing?"}</p>
							<Form.Label style={{textAlign:'left',display: 'block'}}>Please provide reason for the {is_receiver_status ? ' rejection.' : ' cancellation.'}</Form.Label>
						</> :
						<>
							<p style={{ marginBottom: '30px' }} className="modal-confirmation-text">{is_receiver_status ? "are you sure you don’t want to schedule a showing?" : "are you sure you want to cancel this showing?"}</p>
							<Form.Label style={{textAlign:'left',display: 'block'}}>{is_receiver_status ? 'Let the landlord know why you aren’t accepting' : 'Please provide reason'}</Form.Label>
						</>}
					<Form.Control
						type="text"
						as="textarea"
						rows={6}
						id="reject_reason"
						name="reject_reason"
						value={reject_reason}
						placeholder={is_receiver_status ? "reject note..." : "cancel note..."}
						onChange={(e) => handleChange(e)}
					/>
				</Form.Group>
			</Form>
		</ModalBox>

		<ModalBox show={successReject}
			onHide={() => setSuccessReject(false)}
			size="md"
			actionbuttontitle={'got it'}
			buttonaction={() => setSuccessReject(false)}
			backbuttontitle={'reschedule appointment'}
			backaction={() => {
				setSuccessReject(false)
				setReSchedule(true)
				setShowingRequest(true)
			}}>
			{is_receiver_status ?
				<>
					<p className="modal-confirmation-text">
						you have {is_receiver_status ? ' rejected' : ' cancelled'} <br />
						{is_receiver_status ? name + "’s" : 'your'} {role === 2 ? 'request' : 'invitation'}</p>
				</>
				: <>
					<h4>the showing has been cancelled</h4>
					<p>we will notify the applicant for you. You can also reschedule invitation.</p>
				</>}

		</ModalBox>
	</>);
}

export default withRouter(ConfirmShowing);