import React, { useEffect, useState } from 'react';
import { SidePanel, LargeButton, CancelButton } from '../../../components'
import { Form, Row, Col } from 'react-bootstrap';
import { tenant_statusData } from '../../../../Constants/tenantDetails';
import { toaster } from '../../../../Utils'
import { postAPI } from '../../../../Api';
import { validate } from '../../../../Utils/Validation/validation';
import { VehicleInfoValidationSchema } from './ValidationSchema/VehicleInfoValidationSchema';
import Cookies from 'js-cookie';
import './style.scss';

const VehicleInfo = (props) => {
  const { vehicle_info, isShow, handleClose, setRoommateInfo, user_id } = props;
  const role = Number(Cookies.get('role'));
  const [id, setId] = useState(0);
  const [vehicle_status, setVehicle_status] = useState(null);
  const [vehicle_make, setVehicle_make] = useState('');
  const [vehicle_model, setVehicle_model] = useState('');
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  useEffect(() => {
    if (isSubmitClicked) {
      validate(VehicleInfoValidationSchema)
    }
  }, [id, vehicle_status, vehicle_make, vehicle_model, isSubmitClicked])

  useEffect(() => {
    if (vehicle_info && vehicle_info['vehicle_info'] && vehicle_info['vehicle_info'][0]) {
      setId(vehicle_info['vehicle_info'][0].id)
      setVehicle_status(vehicle_info['vehicle_status'])
      setVehicle_make(vehicle_info['vehicle_info'][0].vehicle_make)
      setVehicle_model(vehicle_info['vehicle_info'][0].vehicle_model)
    }
  }, [vehicle_info])

  useEffect(() => {
    vehicle_info && setVehicle_status(vehicle_info.vehicle_status)
  },[vehicle_info])

  const OnChangeInput = (event, setInputState) => {
    setInputState(event.target.value)
  }

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute('name');
    if (name === 'vehicle_status') {
      setVehicle_status(Value);
    }
  }

  const onSubmit = () => {
    setIsSubmitClicked(true);
    if (validate(VehicleInfoValidationSchema)) {
      let url = '';
      if (user_id) {
        url = '/admin/users/tenant/vehicle-info/' + user_id;
      } else {
        url = '/tenant/profile/vehicle-info';
      }

      const data = {
        id: id ? id : 0,
        vehicle_status: vehicle_status,
        vehicle_info: {
          vehicle_make: vehicle_make,
          vehicle_model: vehicle_model
        }
      }
      postAPI(url, data)
      .then(response => {
        if (response === undefined || (response.response && response.response.status === 422)) {
          response.response  ? toaster('error', 'Please select one of the two options') : toaster('error', 'Something went wrong!');
        } else if (response.status === 200) {
          handleClose()
          if (role !== 1) {
            setRoommateInfo(true)
          }
          toaster('success', 'Data Saved Successfully!')
        }
      })
    }
  }
  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={role !== 1 ? 'My Profile' : 'edit tenant profile'}
      subTitle={role !== 1 ? 'This Is Where Your Vehicle Info Goes' : 'Vehicle Info'}>

      <div className='sidepanel-body'>
        <Form id='Form'>
          <Row>
            <Form.Group as={Col} md='12' className="mb-0">
              <Form.Label className="custom-lable">Do you own a vehicle?</Form.Label>
              <ul className='select-options'>
                {tenant_statusData.map((data) =>
                  <li onClick={(e) => OnSingleOptionCheck(e, this)} key={'vehicle_status_' + data.value} value={data.value} name='vehicle_status'
                    // className={(vehicle_status === data.value) ? 'active' : ''}>{data.title}</li>)}
                    className={(vehicle_status === null ? '' : vehicle_status === data.value) ? 'active' : ''}>{data.title}</li>)}
              </ul>
            </Form.Group>
          </Row>

          {vehicle_status === 1 ?
            <>
              <Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label className="custom-lable">Enter vehicle make (e.g. Toyota)</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Type here'
                    name='vehicle_make'
                    id='vehicle_make'
                    value={vehicle_make}
                    onChange={(e) => OnChangeInput(e, setVehicle_make)}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md='12'>
                  <Form.Label className="custom-lable">Enter vehicle model (e.g. Corolla)</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Type here'
                    name='vehicle_model'
                    id='vehicle_model'
                    value={vehicle_model}
                    onChange={(e) => OnChangeInput(e, setVehicle_model)}
                  />
                </Form.Group>
              </Row>
            </> : ''}
        </Form>
      </div>

      <div className={role !== 1 ? 'fixed-bottom d-flex justify-content-between' : 'fixed-bottom'}>
        {role !== 1 ?
          <>
            <CancelButton onClick={() => handleClose()} title='Back' />
            <LargeButton onClick={() => onSubmit()} title={'continue'} style={{ width: '200px', float: 'right' }} />
          </> :
          <Row>
            <Col md={12}>
              <LargeButton onClick={() => onSubmit()} title={'save changes'} style={{ width: '200px', float: 'right' }} />
            </Col>
          </Row>}
      </div>
    </SidePanel>
  </>);
}

export default VehicleInfo;