import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { postAPI } from '../../../../Api';
import { activeStatus, getBackPath } from '../../../../Store/actions/Action';
import { setListingTableData, setListingCurrentPage, setFilters } from '../../../../Store/actions/ListingAction';
import { logger } from '../../../../Utils';
import ListManagementTable from '../ListManagemantTable';
import { useDispatch, useSelector } from 'react-redux';
import TableContentLoader from '../../../components/ContentLoader/ListBlockContentLoader/ListBlockContentLoader/ListBlockContentLoader/TableContentLoader/TableContentLoader';

const log = logger('AllListing');
const AllListing = (props) => {
    const url = '/admin/listing/all';
    const dispatch = useDispatch();
    const activeListStatus = useSelector(state => state.propertyDetailsReducer.activeListStatus);
    const [sort_by_status, setStatus] = useState(activeListStatus !== null ? activeListStatus : ['all']);
    const [isLoading, setIsLoading] = useState(true);
    const filters = useSelector(state => state.listingReducer.filters);
    const pagination = useSelector(state => state.listingReducer.listingCurrentPage);
    const currentComponent = useSelector(state => state.listingReducer.currentComponent);
    const [oldFilter, setOldFilter] = useState({...filters})

    const getData = (url) => {
        const data = {
            'filter': {
                'city': filters.searchCity || '',
                'address': filters.searchAddress || '',
                'landlord': filters.searchLandlord || '',
                'tenant': filters.searchTenant || '',
                'rentpanda_id': filters.searchRentpandaId || '',
            },
            'is_verified': filters.is_verified === false ? 0 : 1,
            'sort_by_price': {
                'order': filters.sort_by_price_order === '' ? 'all' : filters.sort_by_price_order
            },
            'sort_by_status': filters.sort_by_status.includes('all') ? [] : filters.sort_by_status,
            'limit': 10
        }
        postAPI(url, data).then(response => {
            if (response.status === 200) {
                setIsLoading(false)
                dispatch(setListingTableData(response.data.data));
            }
        })
    }
    
    const sortStatus = (event) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let arraydata = [...sort_by_status]
        let returnVal = [];
        if (item === 'all') {
            let arr = []
            if (isChecked === true) {
                arr = ['all']
            } else {
                arr = []
            }
            returnVal = arr;
        } else if (item !== 'all') {
            isChecked === true ? arraydata.push(item) : arraydata.splice(arraydata.indexOf(item), 1);
            if (arraydata.includes('all')) {
                const indexElm = arraydata.indexOf('all');
                arraydata.splice(indexElm, 1);
            }
            returnVal = arraydata;
        }
        dispatch(setFilters({...filters, sort_by_status: returnVal}))
        setStatus(returnVal)
        const data = {
            'filter': {
                'city': filters.searchCity || '',
                'address': filters.searchAddress || '',
                'landlord': filters.searchLandlord || '',
                'tenant': filters.searchTenant || '',
                'rentpanda_id': filters.searchRentpandaId || '',
            },
            'is_verified': filters.is_verified === false ? 0 : 1,
            '$': {
                'order': filters.sort_by_price_order === '' ? 'all' : filters.sort_by_price_order
            },
            'sort_by_status': returnVal,
            'limit': 10
        }
        postAPI(url, data).then(response => {
            if (response.status === 200) {
                setIsLoading(false)
                dispatch(setListingTableData(response.data.data));
            }
        })
    }
    const goToProfileDetail = (data) => {
        log.info(data)
        props.history.push({
            pathname: `/profile-detail/listings/${data.user_type}/${data.user_id}/${data.property_id}/${data.property_details_id}`,
            state: { path: currentComponent }
        });
        dispatch(activeStatus(sort_by_status));
        dispatch(getBackPath(currentComponent))
    }
    const onClickNext = (currentPage) => {
        const nextPage = currentPage + 1;
        dispatch(setListingCurrentPage(nextPage));
    }

    const onClickPrev = (currentPage) => {
        const prevPage = currentPage - 1;
        dispatch(setListingCurrentPage(prevPage));
    }
    // useEffect(() => {
    //     let setUrl = '';
    //     if (pagination !== null && pagination > 1 ) {
    //         setUrl = `/admin/listing/all?page=${pagination}`
    //     } else {
    //         setUrl = `/admin/listing/all`
    //     }
    //     getData(setUrl);
    //     if (activeListStatus !== null) {
    //         dispatch(activeStatus(null));
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dispatch, filters, pagination, activeListStatus]);

    useEffect(() => {
        let setUrl = ''
        if(JSON.stringify(oldFilter) !== JSON.stringify(filters)){
            setUrl = `/admin/listing/all`
            setOldFilter({...filters})
        }else{
            if (pagination !== null && pagination > 1 ) {
                setUrl = `/admin/listing/all?page=${pagination}`
            }else{
                setUrl = `/admin/listing/all`
            }
        }
        if (activeListStatus !== null) {
            dispatch(activeStatus(null));
        }
        getData(setUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pagination, filters, activeListStatus]);
    
    let userColumns = [];

    userColumns = [
        {
            header: 'IsVerify',
            name: 'is_verify',
            width: '3%'
        },
        {
            header: 'Address',
            name: 'address',
            width: '25%'
        },
        {
            header: 'Landlord',
            name: 'landlord',
            width: '15%'
        },
        {
            header: 'Tenant',
            name: 'tenant',
            width: '15%'
        },
        {
            header: 'Price',
            name: 'price',
            width: '10%'
        },
        {
            header: 'Rentpanda Id',
            name: 'rentpanda_id',
            width: '15%'
        },
        {
            header: 'Status',
            name: 'status',
            width: '15%'
        },
    ];

    return (
        <React.Fragment>
            {isLoading === true ? <TableContentLoader columns={userColumns} component={'listingManagement'} /> 
            : 
            <>
                <ListManagementTable
                    columns={userColumns}
                    sortStatus={(data) => sortStatus(data)}
                    sort_by_status={sort_by_status}
                    goToProfileDetail={(data) => goToProfileDetail(data)}
                    clickNext={(data) => onClickNext(data)}
                    clickPrev={(data) => onClickPrev(data)}
                /> 
            </>
            }
        </React.Fragment>
    )
};

export default withRouter(AllListing);