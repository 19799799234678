import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Moment from 'react-moment';
import { EditButton, LargeButton } from "../../";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { bathroomTitle, bedroomTitle, logger, sharedBathroomTitle } from "../../../../Utils";
import { DollorNumberDisplay, ModalBox } from "../..";
import { useSelector } from "react-redux";
import Geocode from "react-geocode";
import { Google_Map_API } from "../../../../Constants/endPoints";


const log = logger("UnitAddress");
const UnitAddress = (props) => {
  const { editDetails, requestShowing, messageLandloard, myFavourite, is_favourite } = props;
  const role = Number(Cookies.get("role"));
  const unitDetails = useSelector(state => state?.propertyDetailsReducer?.unitDetails);
  const propertyDetails = useSelector(state => state?.propertyDetailsReducer?.propertyDetails);
  const unitName = ((propertyDetails && propertyDetails?.property_type === 1) || role === 3) ?
    (propertyDetails && (isNaN(propertyDetails.unit_no) ? propertyDetails.unit_no : propertyDetails.unit_no && ('Unit ' + propertyDetails.unit_no)))
    : (unitDetails && (isNaN(unitDetails.unit_no) ? unitDetails.unit_no : ('Unit ' + unitDetails.unit_no)));
  const [confirmAction, setConfirmAction] = useState(false);
  const history = useHistory();
  const [isTour, setIsTour] = useState(false);
  const [googleAddress, setGoogleAddress] = useState('');

  log.info("propertyDetails", propertyDetails);

  useEffect(() => {
    if (history.location.pathname.indexOf("tour") > 0) {
      setIsTour(true);
    }
  }, [history.location.pathname]);

  const handleClick = (clickFor) => {
    log.info("isTour", isTour)
    if (Cookies.get('access_token') && !isTour) {
      if (clickFor === "myFavourite") {
        myFavourite();
      } else if (clickFor === "requestShowing") {
        requestShowing();
      } else if (clickFor === "messageLandloard") {
        messageLandloard();
      }
    } else {
      setConfirmAction(true);
    }
  }
  const getSignedUp = () => {
    setConfirmAction(false);
    history.push('/signup/tenant');
  }

  useEffect(() => {
    if (propertyDetails) {
      const latValue = propertyDetails?.latitude || '';
      const lngValue = propertyDetails?.longitude || '';
      Geocode.setApiKey(Google_Map_API);
      Geocode.enableDebug();
      Geocode.fromLatLng(latValue, lngValue).then(
        (response) => {
          const address = response?.results[0]?.formatted_address;
          setGoogleAddress(address);
        },
        (error) => { }
      );
    }
  }, [propertyDetails]);

  return (
    <div className={role === 1 ? 'inner-wrapper for-admin' : 'inner-wrapper'}>
      <Row>
        <Col md="11">
          <h2 className="lp-property-details-title">{propertyDetails?.address ? propertyDetails?.address + ' ' : ''}
            <Image
              className="icon"
              width="20px"
              height="20px"
              src={(unitDetails?.is_verified === 'unverified') || (propertyDetails?.is_verified === 'unverified') ? require("../../../../_assets/images/Unverified.png")
                : require("../../../../_assets/images/verified.png")}
            />
          </h2>
        </Col>
        <Col md="1">
          {myFavourite &&
            <div className="icon-wrapper pull-right cursorPointer" onClick={() => handleClick("myFavourite")}>

              <Image
                className="icon"
                width="30px"
                height="28px"
                src={is_favourite ? require("../../../../_assets/images/love-icon.svg") : require("../../../../_assets/images/love-icon-hollow.svg")}
              />
            </div>
          }
        </Col>
      </Row>


      {(role === 2 || role === 1) &&
        <>
          {(editDetails && (role === 1)) ?
            <>
              <div className='button-wrapper for-admin'>
                <EditButton onClick={editDetails} />
              </div>

              {propertyDetails?.hide_exact_address === 1 && <div className="googleAddress mb-2" style={{color: "#4f4f58"}}><strong>Google address:</strong> {googleAddress}</div>}

              {unitDetails ?
                <>
                  <h4 className="lp-property-details-sub-title">{propertyDetails?.property_type === 1 ? 'Full Property' : propertyDetails?.property_type === 2 ? 'Unit' : propertyDetails?.property_type === 3 ? 'Room for Rent' : '' } - <DollorNumberDisplay price={unitDetails?.price_per_month} /> / month</h4>
                  <ul className='features'>
                    {unitDetails && propertyDetails?.property_type !== 3 ?
                      <>
                        {unitDetails?.bedrooms ? <li><Image className="icon" src={require("../../../../_assets/images/features/bed.svg")} alt="bedroom" />
                          {unitDetails && bedroomTitle(unitDetails?.bedrooms)} Bedroom</li> : ''}
                        {unitDetails?.bathrooms ? <li><Image className="icon" src={require("../../../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                          {unitDetails && bathroomTitle(unitDetails?.bathrooms)} Bathroom</li> : ''}
                      </> :

                      <li>
                        <Image className="icon" src={require("../../../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                        {unitDetails && sharedBathroomTitle(unitDetails?.is_shared_bathroom)} Bathroom
                  </li>
                    }

                    <li><Image className="icon" src={require("../../../../_assets/images/features/lease.svg")} alt="lease" />
                      {unitDetails && ((unitDetails?.lease_duration === 1 ? '1 year lease' : '') || (unitDetails?.lease_duration === 2 ? 'Month to month lease' : ''))}
                    </li>
                    <li><Image className="icon" src={require("../../../../_assets/images/features/calender.svg")} alt="available" />Available on <Moment format="MMMM D, YYYY">{unitDetails && unitDetails.available_date}</Moment></li>
                  </ul>
                </> : ''}
            </>
            : ''}
          {(editDetails && (role === 2)) ? <>
            {(unitDetails && (unitDetails?.available_date)) ?
              <>
                <h4 className="lp-property-details-sub-title">{propertyDetails?.property_type === 1 ? 'Full Property' : propertyDetails?.property_type === 2 ? 'Unit' : propertyDetails?.property_type === 3 ? 'Room for Rent' : '' } - <DollorNumberDisplay price={unitDetails?.price_per_month} /> / month</h4>
                <ul className='features'>
                  {unitDetails && propertyDetails?.property_type !== 3 ?
                    <>
                      {unitDetails?.bedrooms ? <li><Image className="icon" src={require("../../../../_assets/images/features/bed.svg")} alt="bedroom" />
                        {unitDetails && bedroomTitle(unitDetails?.bedrooms)} Bedroom</li> : ''}
                      {unitDetails.bathrooms ? <li><Image className="icon" src={require("../../../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                        {unitDetails && bathroomTitle(unitDetails?.bathrooms)} Bathroom</li> : ''}
                    </> :

                    <li>
                      <Image className="icon" src={require("../../../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                      {unitDetails && sharedBathroomTitle(unitDetails?.is_shared_bathroom)} Bathroom
                  </li>
                  }

                  <li><Image className="icon" src={require("../../../../_assets/images/features/lease.svg")} alt="lease" />
                    {unitDetails && ((unitDetails?.lease_duration === 1 ? '1 year lease' : '') || (unitDetails?.lease_duration === 2 ? 'Month to month lease' : ''))}
                  </li>
                  <li><Image className="icon" src={require("../../../../_assets/images/features/calender.svg")} alt="available" />Available on <Moment format="MMMM D, YYYY">{unitDetails && unitDetails?.available_date}</Moment></li>
                </ul>
              </> : ''}
          </> : ''}
        </>}

      {(role === 3 || !role) &&
        <>
          {propertyDetails &&
            <>
              <h4 className="lp-property-details-sub-title">{propertyDetails?.property_type === 1 ? 'Full Property' : propertyDetails?.property_type === 2 ? 'Unit' : propertyDetails?.property_type === 3 ? 'Room for Rent' : '' } - <DollorNumberDisplay price={propertyDetails?.price_per_month} /> / month</h4>
              <ul className='features'>
                {propertyDetails?.property_type !== 3 ?
                  <>
                    {propertyDetails?.bedroom ? <li><Image className="icon" src={require("../../../../_assets/images/features/bed.svg")} alt="bedroom" />
                      {bedroomTitle(propertyDetails?.bedroom)} Bedroom</li> : ''}
                    {propertyDetails?.bathroom ? <li><Image className="icon" src={require("../../../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                      {bathroomTitle(propertyDetails?.bathroom)} Bathroom</li> : ''}
                  </>
                  :
                  <li> <Image className="icon" src={require("../../../../_assets/images/features/bathtub.svg")} alt="bathroom" />
                    {sharedBathroomTitle(propertyDetails?.is_shared_bathroom)} Bathroom
                  </li>
                }

                <li><Image className="icon" src={require("../../../../_assets/images/features/lease.svg")} alt="lease" />
                  {propertyDetails && ((propertyDetails?.lease_duration === 1 ? '1 year lease' : '') || (propertyDetails?.lease_duration === 2 ? 'Month to month lease' : ''))}
                </li>
                <li><Image className="icon" src={require("../../../../_assets/images/features/calender.svg")} alt="available" />Available on <Moment format="MMMM D, YYYY">{propertyDetails?.available_date}</Moment></li>
              </ul>
            </>}
        </>
      }
      {(editDetails && role !== 1) &&
        <div className='button-wrapper'>
          <EditButton onClick={editDetails} />
        </div>}

      <Row>
        {(requestShowing && propertyDetails?.status !== 4) && <Col lg={6}><LargeButton onClick={() => handleClick("requestShowing")} type="button" title="request a showing" /> </Col>}
        {messageLandloard && <Col lg={6}><LargeButton onClick={() => handleClick("messageLandloard")} type="button" title="message landlord" variant="dark" /></Col>}
      </Row>

      <ModalBox
        show={confirmAction}
        onHide={() => setConfirmAction(false)}
        size='sm'
        actionbuttontitle={'get started'}
        buttonaction={() => getSignedUp()}
      >
        <h4>Sign up for free</h4>
        <p>create an account and get all the tools<br />you need to find the perfect home </p>
      </ModalBox>
    </div> 
  );
}
export default UnitAddress;