import React, { useEffect, useState } from "react";
import './style.scss';
import { logger, toaster } from "../../../../Utils";
import { Image, Row, Col, Alert } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { pet_typeData } from "../../../../Constants/tenantDetails";
import moment from "moment";
import { LargeButton, ModalBox } from "../../";
import { getAPI, postAPI } from "../../../../Api";
import { useDispatch, useSelector } from "react-redux";
import { setCreditCheckRequestId } from "../../../../Store/actions/MessageDetailAction";
import Cookies from 'js-cookie';


const log = logger("TenantProfileDisplay");
const TenantProfileDisplay = (props) => {
	const { profileData, tenancyApplicationData, property_details_id, property_id, handleClose, setCertnPurchaseReport } = props;
	const [profile, setProfile] = useState(null);
	const [tenancyApplicationDetails, setTenancyApplicationDetails] = useState(null);
	const [modalShow, setModalShow] = useState(false);
	const [reportModalShow, setReportModalShow] = useState(false);
	const [incompleteProfileModalShow, setIncompleteProfileModalShow] = useState(false);
	const role = Number(Cookies.get('role'));

	const dispatch = useDispatch();
	const receiverData = useSelector(state => state.messageDetailsReducer.receiverData);
	const conversation_id = useSelector(state => state.messageDetailsReducer.conversationId);
	const tenant_id = useSelector(state => state.messageDetailsReducer.tenantId);


	useEffect(() => {
		if (profileData) {
			setProfile(profileData);
		}
	}, [profileData]);

	useEffect(() => {
		if (tenancyApplicationData) {
			setTenancyApplicationDetails(tenancyApplicationData);
		}
	}, [tenancyApplicationData]);

	const formatTime = (time) => {
		return moment(time).format('ll');
	}

	log.info('profileData', profileData)

	const onSubmitGetConsent = () => {
		const url = '/landlord/get-consent-check';
		const data = {
			"property_id": property_id,
			"property_details_id": property_details_id,
			"tenancy_application_id": tenancyApplicationDetails && tenancyApplicationDetails.tenancy_application_id,
			"conversation_id": conversation_id,
			"tenant_id": tenant_id
		}

		postAPI(url, data).then(response => {
			if (response.status === 200) {
				setModalShow(false)
				const res = response.data.data;
				dispatch(setCreditCheckRequestId(res.credit_check_request_id));
			}
		})
			.catch(error => {
			});
	}

	const onSubmitReport = () => {
		// debugger
		if (profile && profile.basic_info && profile.basic_info.is_consent_payment_completed) {
			handleClose();
			if (profile.basic_info.is_certn_verified) {
				getReport();
			} else {
				setReportModalShow(true);
			}

		} else {
			handleClose();
			if (receiverData && !receiverData.credit_check_request_id) {
				onSubmitGetConsent();
			} else if (profileData?.basic_info?.is_profile_completed === 0) {
				setIncompleteProfileModalShow(true)
			} else {
				setCertnPurchaseReport(true);
			}
		}
	}

	const getReport = () => {
		const url = `/landlord/check-certn-report/${tenant_id}/${conversation_id}`;
		getAPI(url).then(response => {
			if (response.status !== 200) {
				toaster('error', response.response.data.message);
			} else if (response.status === 200) {
				//log.info("response",response.data.data.report_url);
				window.open(response.data.data.report_url);
			}
		})
			.catch(error => {
			});
	}

	return (<>
		<div className="profile-showing">
			{role === 2 &&
				<>
					<h3 className="profile-title">{profile && profile.basic_info && profile.basic_info.name}</h3>
					<span className="verification-status">{(profile && profile.basic_info && !profile.basic_info.is_certn_verified) ? <span>unverified</span> : <span className="verified-status">verified</span>}<Image
						className="icon"
						width="16px"
						height="15px"
						// src={require("../../../../_assets/images/verified.png")}
						src={(profile && profile.basic_info && !profile.basic_info.is_certn_verified) ? require("../../../../_assets/images/Unverified.png")
							: require("../../../../_assets/images/verified.png")}
					/></span>

					{(tenancyApplicationDetails && tenancyApplicationDetails.tenant_consent_to_credit_check === 0) ?
						<div className="message-profile-card mt-2 credit-check">
							<h4 className="message-card-heading">background and credit check</h4>
							<hr />
							<p className='info'>The tenant has not given consent to run a credit and background check.</p>
							<LargeButton
								title="get consent"
								variant="outline-light"
								onClick={() => {
									setModalShow(true);
									handleClose();
								}
								}
								style={{ width: '250px' }} />
						</div> :
						((tenancyApplicationDetails && tenancyApplicationDetails.tenant_consent_to_credit_check === 1) ||
							(profile && profile.basic_info && profile.basic_info.is_certn_verified)) ?
							<div className="message-profile-card mt-2 credit-check success">
								<h4 className="message-card-heading">background and credit check</h4>
								<hr />
								<p className='info'>Get peace of mind knowing everything you need to about an applicant’s background and credit.</p>
								<LargeButton
									title={(profile && profile.basic_info && profile.basic_info.is_consent_payment_completed) ? "view the report" : "get the report"}
									variant="outline-light"
									onClick={() => onSubmitReport()}
									style={{ width: '250px' }} />
							</div> : ''}
				</>}

			{profile && profile.basic_info && profile.basic_info.is_profile_completed !== 1 &&
				<Alert variant='warning'>
					<Image className="icon" src={require("../../../../_assets/images/alert-icon.svg")} alt="add" /><br />
                    Protip: You can send a nudge to the tenant to complete their profile details
                </Alert>
			}

			{(role === 3 && tenancyApplicationData?.tenant_consent_to_credit_check === 1) && <div className="message-profile-card mt-2">
				<h4 className="message-card-heading">Credit & background check</h4>
				<hr />
				{profile &&
					<>
						<Row>
							<Col md={6}>
								<span className="card-labels">Date of Birth</span>
								<p className="card-labels-desc">{formatTime(profile?.basic_info?.date_of_birth)}</p>
							</Col>
							<Col md={6}>
								<span className="card-labels">SIN</span>
								<p className="card-labels-desc">{profile?.basic_info?.sin_number}</p>
							</Col>
						</Row>

					</>}
			</div>}

			<div className="message-profile-card mt-2">
				<h4 className="message-card-heading">Current Home Info</h4>
				<hr />
				{profile && profile.current_home ?
					<>
						<Row>
							<Col md={12}>
								<span className="card-labels">Full Address</span>
								<p className="card-labels-desc">{profile.current_home.full_address}</p>
							</Col>
							<Col md={4}>
								<span className="card-labels">Status</span>
								<p className="card-labels-desc">{profile.current_home.is_owned === 1 ? 'Owned' : 'Rented'}</p>
							</Col>
							<Col md={4}>
								<span className="card-labels">Starting From</span>
								<p className="card-labels-desc">{formatTime(profile.current_home.start_date)}</p>
							</Col>
							{profile.current_home.is_owned !== 1 &&
								<Col md={4}>
									<span className="card-labels">Monthly Rent</span>
									<p className="card-labels-desc">
										<NumberFormat value={profile.current_home.monthly_rent} displayType={'text'} thousandSeparator={true} prefix={'$'} />
									</p>
								</Col>}
						</Row>
						{profile.current_home.is_owned !== 1 &&
							<>
								<hr />
								<Row>
									<Col md={12}>
										<span className="card-labels">Owner’s Contact Info</span>
									</Col>
									<Col md={6}>
										<p className="card-labels-desc">{profile.current_home.landlord_name}</p>
									</Col>
									<Col md={6} style={{ textAlign: 'right' }}>
										<p className="card-labels-desc change-color">
											<NumberFormat value={profile.current_home.landlord_phone} format="###-###-####" displayType={'text'} allowEmptyFormatting mask="_" />
										</p>
									</Col>
									<Col md={12}>
										<p className="card-labels-desc change-color">{profile.current_home.landlord_email}</p>
									</Col>
								</Row>
							</>}
					</> : <p className="card-labels-desc text-center">No information provided</p>}
			</div>

			<div className="message-profile-card mt-2">
				<h4 className="message-card-heading">Past Home Info</h4>
				<hr />
				{profile && profile.past_home ?
					<>
						<div className="emp-status">
							{profile.past_home.map((home, i) => {
								return <React.Fragment key={"home" + i}>
									<Row>
										<Col md={12}>
											<span className="card-labels">Full Address</span>
											<p className="card-labels-desc">{home.full_address}</p>
										</Col>
										<Col md={4}>
											<span className="card-labels">Status</span>
											<p className="card-labels-desc">{home.is_owned === 1 ? 'Owned' : 'Rented'}</p>
										</Col>
										<Col md={8}>
											<span className="card-labels">Starting From</span>
											<p className="card-labels-desc">{formatTime(home.start_date)} - {formatTime(home.end_date)}</p>
										</Col>
									</Row>
									<hr></hr>
								</React.Fragment>
							})}
						</div>
					</> : <p className="card-labels-desc text-center">No information provided</p>}
			</div>

			<div className="message-profile-card mt-2">
				<h4 className="message-card-heading">Employment Status</h4>
				<hr />
				{profile && profile.employment_info ?
					<div className="emp-status">
						{profile.employment_info.map((emp, i) => {
							return <React.Fragment key={"empStatus" + i}>
								<Row>
									<Col md={6}>
										<span className="card-labels">Employment Status</span>
										<p className="card-labels-desc">{emp.is_current ? 'Current Job':'Previous Job'}</p>
									</Col>
									<Col md={6}>
										<span className="card-labels">Job Title</span>
										<p className="card-labels-desc">{emp.job_title}</p>
									</Col>
									<Col md={6}>
										<span className="card-labels">Period</span>
										<p className="card-labels-desc">{formatTime(emp.start_date)}</p>
									</Col>
									<Col md={6}>
										<span className="card-labels">Annual income</span>
										<p className="card-labels-desc">
											<NumberFormat value={emp.min_salary} displayType={'text'} thousandSeparator={true} prefix={'$'} /> - <NumberFormat value={emp.max_salary} displayType={'text'} thousandSeparator={true} prefix={'$'} />
										</p>
									</Col>
									<Col md={6}>
										<span className="card-labels">Reference</span>
										<p className="card-labels-desc">{emp.employment_reference_name}</p>
									</Col>
									<Col md={6}>
										<span className="card-labels">Contact</span>
										<p className="card-labels-desc"> <NumberFormat value={emp.employment_reference_phone} format="###-###-####" displayType={'text'} allowEmptyFormatting mask="_" /></p>
									</Col>
								</Row>
								<hr></hr>
							</React.Fragment>
						})}
					</div> : <p className="card-labels-desc text-center">No information provided</p>}
			</div>

			<div className="message-profile-card mt-2">
				<h4 className="message-card-heading">References</h4>
				<hr />
				{profile && profile.personal_reference ?
					<>
						<span className="card-labels">Name</span>
						{profile.personal_reference.map((ref, i) => {
							return <Row key={"refrence" + i}>
								<Col md={4}>
									<p className="card-labels-desc">{ref.name}</p>
								</Col>
								<Col md={4}>
									<p className="card-labels">{ref.relationship}</p>
								</Col>
								<Col md={4}>
									<p className="card-labels-desc change-color">
										<NumberFormat value={ref.phone_number} format="###-###-####" displayType={'text'} allowEmptyFormatting mask="_" />
									</p>
								</Col>
							</Row>
						})}
					</> : <p className="card-labels-desc text-center">No information provided</p>}
			</div>

			<div className="message-profile-card mt-2">
				<h4 className="message-card-heading">Pet Info</h4>
				<hr />
				{profile && (profile.pets_info && profile.pets_info.pet_info ?
					<Row>
						<Col md={4}>
							<span className="card-labels">Pet Type</span>
							<p className="card-labels-desc">
								{pet_typeData.filter((data) => {
									let title = '';
									if (profile.pets_info.pet_info.pet_type.includes(data.value)) {
										title = data.title;
									}
									return title;
								}).map(function (obj) {
									return obj.title;
								}).join(", ")}
							</p>
						</Col>
						<Col md={2}>
							<span className="card-labels">Amount</span>
							<p className="card-labels-desc">{profile.pets_info && profile.pets_info.pet_info && profile.pets_info.pet_info.pet_count}</p>
						</Col>
						<Col md={6}>
							<span className="card-labels">Additional Pet Info</span>
							<p className="card-labels-desc">{profile.pets_info && profile.pets_info.pet_info && profile.pets_info.pet_info.pet_description}</p>
						</Col>
						<Col md={4}>
							<span className="card-labels">Pet Deposit</span>
							<p className="card-labels-desc">
								<NumberFormat value={profile.pets_info && profile.pets_info.pet_info && profile.pets_info.pet_info.pet_deposit_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
							</p>
						</Col>
					</Row> : <p className="card-labels-desc text-center">{profile.pets_info.pet_status === 1 ? 'No information provided' : (role === 2 ? 'Tenant has no pets' : 'You have no pets')}</p>)}
			</div>

			<div className="message-profile-card mt-2">
				<h4 className="message-card-heading">Vehicle Info</h4>
				<hr />
				{profile && (profile.vehicle_info && profile.vehicle_info.vehicle_info ?
					<Row>
						<Col md={4}>
							<span className="card-labels">Make and Model</span>
							{profile.vehicle_info.vehicle_info.map((vehicle, i) => {
								return <p className="card-labels-desc" key={"vehicle" + i}>{vehicle.vehicle_make} {vehicle.vehicle_model}</p>
							})}
						</Col>
					</Row> : <p className="card-labels-desc text-center">{profile.vehicle_info.vehicle_status === 1 ? 'No information provided' : (role === 2 ? 'Tenant has no vehicle' : 'You have no vehicle')}</p>)}
			</div>

			<div className="message-profile-card mt-2">
				<h4 className="message-card-heading">Roommate Info</h4>
				<hr />
				{profile && (profile.roommate_info && profile.roommate_info.roommate_info ?
					<>
						<span className="card-labels">Name and contact info</span>
						{profile.roommate_info.roommate_info.map((person, i) => {
							return <Row key={"person" + i}>
								<Col md={12}>
									<p className="card-labels-desc">{person.name}</p>
								</Col>
								<Col md={12}>
									<p className="card-labels-desc change-color">{person.email}</p>
								</Col>
							</Row>
						})}
					</> : <p className="card-labels-desc text-center">{profile.roommate_info.roommate_status === 1 ? 'No information provided' : (role === 2 ? 'Tenant has no roommates' : 'You have no roommates')}</p>)}
			</div>
		</div>

		<ModalBox
			show={modalShow}
			onHide={() => setModalShow(false)}
			size="md"
			type="condition"
			actionbuttontitle={'Yes'}
			buttonaction={() => {
				onSubmitGetConsent()
			}}
			backbuttontitle={'No'}
			backaction={() => {
				setModalShow(false)
			}}
		>
			<p className="modal-confirmation-text">get the credit check consent</p>
			<p>it looks like the tenant didn’t pre-approve you to pull a credit check when applying. <br />
                would you like to request that they consent to the credit check, so that you can download the report?</p>
		</ModalBox>

		<ModalBox
			show={incompleteProfileModalShow}
			onHide={() => setIncompleteProfileModalShow(false)}
			size="md"
			actionbuttontitle={'got it'}
			buttonaction={() => {
				setIncompleteProfileModalShow(false)
			}}
		>
			<p className="modal-confirmation-text">Please ask tenant to complete profile in order to generate the Certn report</p>
		</ModalBox>

		<ModalBox
			show={reportModalShow}
			onHide={() => setReportModalShow(false)}
			size="md"
			actionbuttontitle={'got it'}
			buttonaction={() => {
				setReportModalShow(false)
			}}
		>
			<p className="modal-confirmation-text">The credit report is currently being generated and may take a few moments. We will email you when it is ready to view.</p>
		</ModalBox>
	</>);
}

export default TenantProfileDisplay;