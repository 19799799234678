import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Reviews from "./components/Reviews";
import { REVIEWS } from "./constant";
// import { Container, Row, Col } from "reactstrap";
import "./style.scss";
class ReviewsSection extends Component {
  gutter = 50;
  responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1500 },
      items: 3,
      partialVisibilityGutter: 33,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 2,
      partialVisibilityGutter: this.gutter,
    },
    tablet: {
      breakpoint: { max: 1200, min: 991 },
      items: 2,
      partialVisibilityGutter: this.gutter,
    },
    tablet2: {
      breakpoint: { max: 991, min: 768 },
      items: 2,
      partialVisibilityGutter: this.gutter,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      partialVisibilityGutter: this.gutter * 2,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      numberOfCards: 3,
    };
  }

  resize = () => {
    let numberOfCards = 2;
    let width = window.screen.width;
    if (width <= 464) {
      numberOfCards = 1;
    } else if (width > 464 && width <= 1024) {
      numberOfCards = 2;
    } else if (width > 1024 && width <= 1500) {
      numberOfCards = 2;
    } else if (width > 1500 && width <= 4000) {
      numberOfCards = 2;
    }
    this.setState({ numberOfCards: numberOfCards });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
    // if (!this.props.reviews || this.props.reviews.length === 0) {
    //     this.props.fetchReviewsContent();
    // }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  renderReviews = () => {
    return REVIEWS.map((review, index) => {
      return (
        <div key={index}>
          <Reviews review={review} numberOfCards={this.state.numberOfCards} />
        </div>
      );
    });
  };
  render() {
    return (
      <React.Fragment>
        <div className="landlord-reviews">
          <div className="container">
            <div className="text-center">                
                <h3 className="landing-title f-secondary-b">rent panda loves making friends.</h3>
            </div>
          </div>
          <div className="review-slider-wrap">
            <Carousel
              responsive={this.responsive}
              infinite={true}
              partialVisible={true}
              swipeable={this.state.numberOfCards === 1}
              showDots={true}
              draggable={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              dotListClass="landlord-reviews-dot-list"
              arrows={this.state.numberOfCards !== 1}
            >
              {this.renderReviews()}
            </Carousel>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReviewsSection;
