import React, { useEffect, useState } from 'react';
import { SidePanel, LargeButton, CancelButton } from '../../../components'
import { Form, Row, Col, Image } from 'react-bootstrap';
import { tenant_statusData } from '../../../../Constants/tenantDetails'
import { logger, toaster } from '../../../../Utils'
import { postAPI } from '../../../../Api';
import { RoommateInfoValidationSchema } from './ValidationSchema/RoommateInfoVaidationSchema';
import { validate } from '../../../../Utils/Validation/validation';
import { Numbering } from '../../../../Utils/Numbering/Numbering';
import Cookies from 'js-cookie';
import './style.scss';

const log = logger('RoommateInfo');
const RoommateInfo = (props) => {
  const { roommate_info, isShow, handleClose, user_id } = props
  const [roommate_status, setRoommate_status] = useState(null);
  const [is_invited, setIs_invited] = useState(0);
  const [isSubmitClicked,setIsSubmitClicked]=useState(false)
  const role = Number(Cookies.get('role'));
  let errorEmail = null
  const [roommate_info_state, setRoommate_info_state] =
    useState([
      {
        id: 0,
        name: '',
        email: ''
      }
    ]);

  useEffect(() => {
    if (roommate_info) {
      setRoommate_status(roommate_info.roommate_status)
      setRoommate_info_state(roommate_info.roommate_info)
    }
    setIsSubmitClicked(false)
  }, [roommate_info])

  useEffect(() => {
    if (roommate_status) {
      addMore()
    } else {
      OnRemove('all')
    }
  }, [roommate_status])

  const addMore = () => {
    const data = {
      id: 0,
      name: '',
      email: ''
    }
    setRoommate_info_state(roommate_info_state => [...roommate_info_state, data])
  }

  const [errorList, setErrorList] = useState(null)

  const OnCheckInput = (event) => {
    if (event.target.name === 'is_invited') {
      setIs_invited(event.target.checked)
    }
    isSubmitClicked && setTimeout(()=>{
      validate(RoommateInfoValidationSchema)
    },300)
  }

  const OnChangeInputArray = (event, index) => {
    if(event.target.name === 'email'){
      setErrorList(null)
    }
    let items = [...roommate_info_state];
    let item = { ...items[index] };
    item[event.target.name==='roommate_name'?'name':event.target.name] = event.target.value;
    items[index] = item;
    setRoommate_info_state(items);
    isSubmitClicked && setTimeout(()=>{
      validate(RoommateInfoValidationSchema)
    },300)
  }

  const OnRemove = (index) => {
    if (index === 'all') {
      setRoommate_info_state([]);
    } else {
      let items = [...roommate_info_state];
      items.splice(index, 1);
      setRoommate_info_state(items);
    }
  }

  const OnSingleOptionCheck = (event) => {
    const Value = event.target.value;
    const name = event.target.getAttribute('name');
    if (name === 'roommate_status') {
      setRoommate_status(Value);
    }
  }

  const onSubmit = () => {
    setIsSubmitClicked(true)

    if (validate(RoommateInfoValidationSchema)) {
      let url = '';
      if (user_id) {
        url = '/admin/users/tenant/roommate-info/' + user_id;
      } else {
        url = '/tenant/profile/roommate-info';
      }
      let roommateDetails = [];
      roommate_info_state && roommate_info_state.map((roommate) => {
        const data = {
          id: 0,
          name: roommate.name,
          email: roommate.email
        }
        roommateDetails.push(data)
      })
      log.info(roommateDetails)
      const data = {
        roommate_status: roommate_status,
        is_invited: is_invited ? 1 : 0,
        roommate_info: roommateDetails
      }
      postData(url, data)
    }
  }

  const postData = (url, data) => {
    setErrorList(null)
    postAPI(url, data)
      .then(response => {
        if (response === undefined || (response.response && response.response.status === 422)) {
          if(response.response.data.errors){
            toaster('error', response.response.errors)
            const errorsObject = response.response.data.errors
            const indexes = []
            for(const error in errorsObject) {
              indexes.push(+error.split('.')[1])
            }
            setErrorList(indexes)
          }
          
        } else if (response.status === 200) {
          handleClose()
          toaster('success', 'Data Saved Successfully!')
        }
      })
  }

  console.log(errorList)

  return (<>
    <SidePanel
      isShow={isShow}
      isHide={handleClose}
      title={role !== 1 ? 'My Profile' : 'edit tenant profile'}
      subTitle={role !== 1 ? 'Tell Us About Your Future Roommates' : 'Roommates'}
    >

      <div className='sidepanel-body'>
        <Form id='Form'>
          <Row>
            <Form.Group as={Col} md='12'>
              <Form.Label className="custom-lable">Is anyone else going to live with you?</Form.Label>
              <ul className='select-options'>
                {tenant_statusData.map((data) =>
                  <li onClick={(e) => OnSingleOptionCheck(e, this)} key={'roommate_status_' + data.value} value={data.value} name='roommate_status'
                    className={roommate_status === null ? '' : roommate_status === data.value ? 'active' : ''}>{data.title}</li>)}
              </ul>
            </Form.Group>
          </Row>

          {roommate_info_state ?
            roommate_info_state.map((data, i) => {
              return (
                <div key={'roommate_info_' + i}>

                  {i > 0 ?
                    <>
                      <hr />
                      {role !== 1 &&
                        <div className='sub-title d-flex justify-content-between align-items-center'>
                          <h3>{ Numbering(i + 1) + ' Roommate'}</h3>
                          <Image
                            className='icon'
                            width='20px'
                            height='20px'
                            title='Remove'
                            onClick={() => OnRemove(i)}
                            src={require('../../../../_assets/images/delete-icon.svg')}
                          />
                        </div>}
                    </> : ''}

                  <Row>
                    <Form.Group as={Col} md='12'>
                      {role !== 1 && <Form.Label className="custom-lable"> Enter Roommate’s Name</Form.Label>}
                      {role === 1 && <div className='sub-title d-flex justify-content-between align-items-center'>
                        <Form.Label className="custom-lable"> {`Enter Roommate ${i + 1}’s Name`}</Form.Label>
                        {i > 0 ? <Image
                          className='icon mb-2'
                          width='20px'
                          height='20px'
                          title='Remove'
                          onClick={() => OnRemove(i)}
                          src={require('../../../../_assets/images/delete-icon.svg')}
                        /> : ''}
                      </div>}
                      <Form.Control
                        type='text'
                        placeholder='Type here'
                        name='roommate_name'
                        id={'roommate_name' + i}
                        value={data.name}
                        onChange={(e) => OnChangeInputArray(e, i)}
                      />
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md='12'>
                      <Form.Label className="custom-lable">{role !== 1 ? 'Enter Roommate’s Email' : `Enter Roommate ${i + 1}’s Email`}</Form.Label>
                      <Form.Control
                        className={errorList && errorList.includes(i) && 'is-invalid'}
                        type='email'
                        placeholder='Type here'
                        name='email'
                        id={'email' + i}
                        value={data.email}
                        onChange={(e) => OnChangeInputArray(e, i)}
                      />
                      {/* { errorEmail === data } */}
                      { errorList && errorList.includes(i) && <div style={{display : 'block'}} className='invalid-feedback' >Please check your email</div> }
                    </Form.Group>
                  </Row>
                </div>)
            }) : ''}
        </Form>

        { roommate_status !== 1 ? '' : 
          <Row className='d-flex justify-content-center'>
            <Col md='10'>
              <LargeButton
                variant='dark'
                title='add more'
                isIcon={true}
                onClick={() => addMore()} />
            </Col>
            <Form.Group controlId='formBasicCheckbox' className='checkbox-left check-status'>
              {/* <Form.Check
                type='checkbox'
                name='is_invited'
                label='Invite your roommates to sign up to Rent Panda'
                checked={is_invited}
                onChange={(e) => OnCheckInput(e)}
              /> */}
              <div className="custom-control custom-checkbox large">
                <input type="checkbox" className="custom-control-input" id={"customCheck"}
                  name='is_invited'
                  checked={is_invited} onChange={(e) => OnCheckInput(e)}
                />
                <label className="custom-control-label capitalize pl-3" htmlFor={"customCheck"}>Invite your roommates to sign up to Rent Panda</label>
              </div>
            </Form.Group>
          </Row>
        }
      </div>

      <div className={role !== 1 ? 'fixed-bottom d-flex justify-content-between' : 'fixed-bottom'}>
        {role !== 1 ?
          <>
            <CancelButton onClick={() => handleClose()} title='Back' />
            <LargeButton onClick={() => onSubmit()} title={'continue'} style={{ width: '200px', float: 'right' }} />
          </> :
          <Row>
            <Col md={12}>
              <LargeButton onClick={() => onSubmit()} title={'save changes'} style={{ width: '200px', float: 'right' }} />
            </Col>
          </Row>}
      </div>
    </SidePanel>
  </>);
}

export default RoommateInfo;