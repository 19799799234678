import React, { useState, useEffect } from "react";
import { SidePanel, LargeButton, ModalBox, TenantProfileDisplay } from "../..";
import './style.scss';
import NumberFormat from "react-number-format";
import { logger, toaster } from "../../../../Utils";
import { Col, Image, Row, Form } from "react-bootstrap";
import { getAPI, postAPI } from "../../../../Api";
import { withRouter } from 'react-router-dom';
import { validate } from '../../../../Utils/Validation/validation';
import { TenancyValidationSchema } from './TenancyValidationSchema';
import { useSelector, useDispatch } from "react-redux";
import { setIsLeaseFromTenantApplication, setCreditCheckRequestId, setIsProfileCompleted } from '../../../../Store/actions/MessageDetailAction';
import Cookies from 'js-cookie';
import DatePickerComponent from '../../DatePickerComponent/DatePickerComponent';

const log = logger("TenancyForm");

const creditCheckValidationSchema = {
    date_of_birth: [
        { rule: "required", message: "Date Of Birth is required" },
    ],
    sin: [
        { rule: "required", message: "SIN is required" },
        { rule: "sinNumber", message: "SIN is invalid" }
    ]
}
const TenancyForm = (props) => {
    const { isShow, handleClose, title, role, name, setLeaseOptionsScreen, setCertnPurchaseReport, setShowingProfile } = props;
    const [notes, setNotes] = useState("");
    const [is_check, setIs_check] = useState(0);
    const [openConfirmation, setConfirmation] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [reject_reason, setReject_reason] = useState("");
    const [successReject, setSuccessReject] = useState(false);
    const [tenancyApplicationDetails, setTenancyApplicationDetails] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [sin, setSin] = useState('');
    const [creditCheckModal, setCreditCheckModal] = useState(false);

    const tenancyApplicationData = tenancyApplicationDetails ? tenancyApplicationDetails.tenancyApplicationData : '';
    const userDetails = tenancyApplicationDetails?.userDetails;
    const conversation_id = useSelector(state => state.messageDetailsReducer.conversationId);
    const creditCheckRequestId = useSelector(state => state.messageDetailsReducer.creditCheckRequestId);

    const dispatch = useDispatch();
    const propertyDetails = useSelector(state => state.propertyDetailsReducer.propertyDetails);
    const propertyUnitDetails = useSelector(state => state.propertyDetailsReducer.unitDetails);
    const unitDetails = (role === 2 ? propertyUnitDetails : propertyDetails);
    let property_id
    let property_details_id


    if (role === 2) {
        property_id = propertyDetails && propertyDetails.id;
        property_details_id = unitDetails && unitDetails.id;
    } else if (role === 3) {
        property_id = propertyDetails && propertyDetails.property_id;
        property_details_id = propertyDetails && propertyDetails.property_details_id;
    }

    const { receiverData, headerContent, existingTenancyApplicationId, isTenancyApplicationReceived, isProfileCompleted } = useSelector(state => state.messageDetailsReducer)
    const is_receiver = receiverData && receiverData.is_message_received;
    const tenant_id = headerContent && headerContent.tenant_id;
    const tenancyApplicationId = existingTenancyApplicationId;

    log.info('tenancyApplicationId', tenancyApplicationId)

    useEffect(() => {
        if (isShow) {
            setNotes("");
        }
    }, [isShow])

    useEffect(() => {
        if (modalShow) {
            setReject_reason("");
        }
    }, [modalShow])

    useEffect(() => {
        if (tenancyApplicationId && Cookies.get('access_token')) {
            let url = '';
            if (role === 2) {
                url = '/landlord/tenant-application-details/' + tenancyApplicationId;
            } else if (role === 3) {
                url = '/tenant/tenancy-application-details/' + tenancyApplicationId;
            }
            getAPI(url).then(response => {
                if (response.status !== 200) {
                    toaster('error', response.response.data.message)
                    log.info("fsdfsjd", response);
                } else if (response.status === 200) {
                    const res = response.data.data;
                    setTenancyApplicationDetails(res);
                    dispatch(setCreditCheckRequestId(res.tenancyApplicationData.credit_check_request_id));
                    dispatch(setIsProfileCompleted(response.data.data.userDetails.basic_info.is_profile_completed))

                    if ((res.tenancyApplicationData.tenant_consent_to_credit_check === 1 && role === 3) ||
                        (res.tenancyApplicationData.landlord_consent_to_credit_check === 1 && role === 2)) {
                        setIs_check('checked');
                    }
                    if (res.tenancyApplicationData.tenant_notes && role === 3) {
                        setNotes(res.tenancyApplicationData.tenant_notes);
                    } else if (res.tenancyApplicationData.landlord_notes && role === 2) {
                        setNotes(res.tenancyApplicationData.landlord_notes);
                    }
                    log.info('tenancyApplicationId Resp', res);
                }
            })
                .catch(error => {
                });
        } else {
            setTenancyApplicationDetails(null);
            setIs_check(0);
        }
    }, [tenancyApplicationId, isShow])


    const handleNotesChange = (e) => {
        setNotes(e.target.value)
    }

    const OnCheckInput = (event) => {
        setIs_check(event.target.checked)
    }

    const handleChange = (e) => {
        setReject_reason(e.target.value)
    }

    const leaseAction = (data, lease_id) => {
        handleClose();
        dispatch(setIsLeaseFromTenantApplication(true));
        setLeaseOptionsScreen(true);
    }

    const handleCreditCheckAction = (status) => {
        if (status === 'accept') {
            setCreditCheckModal(true)
        }
    }

    const creditCheckAction = (status) => {
        let url = '';
        if (status === 'accept') {
            url = '/tenant/accept-credit-check-request';
        } else if (status === 'reject') {
            url = '/tenant/reject-credit-check-request';
        }
        const data = {
            "credit_check_request_id": creditCheckRequestId,
            "conversation_id": conversation_id
        }
        postAPI(url, data).then(res => {
            if (res.status !== 200) {
                toaster('error', res.response.data.message)
            } else if (res.status === 200) {
                toaster('success', res.data.message)
                handleClose();
            }
        })
    }

    const submitUserDOBAndSin = () => {

        let data = {
            'date_of_birth': dateOfBirth,
            'sin_number': sin
        }
        postAPI('/tenant/profile/set-certn-details', data).then(response => {
            if (response.status !== 200) {
                toaster('error', response.response.data.message)
            } else if (response.status === 200) {
                log.info('data', response.data)
                creditCheckAction('accept')
                setCreditCheckModal(false);
            }
        })
            .catch(error => {
            });

    }
    const handleGiveConsent = () => {
        if (validate(creditCheckValidationSchema)) {
            submitUserDOBAndSin();
        }
    }

    const onSubmit = () => {
        if (validate(creditCheckValidationSchema)) {
            let data = {
                "personalize_data": notes,
                "consent_to_credit_check": is_check,
            }

            let url = '';
            if (tenancyApplicationId) {
                if (role === 3) {
                    url = '/tenant/accept-tenancy-invitation';
                    data.tenancy_application_id = tenancyApplicationId;
                }
            } else {
                if (role === 2) {
                    url = '/landlord/invite-to-apply';
                    data.tenant_id = tenant_id;
                    data.property_details_id = property_details_id;
                    data.property_id = property_id;
                } else {
                    url = '/tenant/apply-for-tenancy';
                    data.landlord_id = propertyDetails && propertyDetails.landlord_id;
                    data.property_details_id = property_details_id;
                    data.property_id = property_id;
                }
            }

            postAPI(url, data).then(response => {
                if (response.status !== 200) {
                    toaster('error', response.response.data.message)
                } else if (response.status === 200) {
                    if (role === 3 && is_check) {
                        submitUserDOBAndSin();
                    }
                    log.info('data', response.data)
                    handleClose();
                    setConfirmation(true);
                }
            })
                .catch(error => {
                });
        }
    }

    const onReject = () => {
        if (validate(TenancyValidationSchema)) {
            const data = {
                "reason": reject_reason,
                "tenancy_application_id": tenancyApplicationId
            }

            let url = '';
            if (role === 2) {
                url = '/landlord/reject-tenant-application';
                data.tenant_id = tenant_id;
            } else if (role === 3) {
                url = '/tenant/reject-tenancy-invitation';
            }

            postAPI(url, data).then(response => {
                if (response.status !== 200) {
                    toaster('error', response.response.data.message)
                    log.info("fsdfsjd", response);
                } else if (response.status === 200) {
                    log.info('data', response.data)
                    setModalShow(false);
                    setSuccessReject(true);
                }
            })
                .catch(error => {
                });
        }
    }

    const handleDate = (date) => {
        setDateOfBirth(date)
    }

    const goBack = () => {
        handleClose()
        setConfirmation(false);
    }
    const gotoProfilePage = () => props.history.push({ pathname: '/profile' })

    return (<>
        <SidePanel isShow={isShow} extraClasses={(tenancyApplicationId && userDetails) ? "sidepanel-bg" : ''}
            isHide={() => goBack()}
            title={(tenancyApplicationId && role === 2) ? "tenant's application" : title}>
            <div className={(tenancyApplicationId) ?
                (((role === 3 && isTenancyApplicationReceived) || (role === 2 && userDetails && userDetails.basic_info && userDetails.basic_info.is_consent_payment_completed)) ? "sidepanel-body invite-to-apply single-button" : "sidepanel-body invite-to-apply invite-to-apply-bottom0")
                : "sidepanel-body invite-to-apply"}>
                <Row>
                    <Col md="4" className="pr-0">
                        {unitDetails && unitDetails.images && unitDetails.images[0] ?
                            <Image className="img-responsive" rounded width="108px" height="99px" src={unitDetails.images[0]} />
                            : <Image className="img-responsive" rounded width="108px" height="99px" src={require("../../../../_assets/images/placeholder.jpg")} />}
                    </Col>
                    <Col md="7" className="pl-0">
                        {propertyDetails &&
                            <div className="showing-address-unit">
                                <h2 className="property-title">{propertyDetails.address}</h2>
                                <div className="details-wrapper">
                                    <div className="full-width">
                                        {role === 2 ?
                                            <>
                                                <span className="specification">{unitDetails && unitDetails.unit_no && (isNaN(unitDetails.unit_no) ? unitDetails.unit_no + ' - ' : ('Unit ' + unitDetails.unit_no + ' - '))}
                                                    <NumberFormat value={unitDetails && unitDetails.price_per_month} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per month</span>
                                                <br />
                                            </>
                                            : <>
                                                <span className="specification">{propertyDetails && propertyDetails.unit_no && (isNaN(propertyDetails.unit_no) ? propertyDetails.unit_no + ' - ' : ('Unit ' + propertyDetails.unit_no + ' - '))}
                                                    <NumberFormat value={propertyDetails && propertyDetails.price_per_month} displayType={'text'} thousandSeparator={true} prefix={'$'} /> per month</span>
                                                <br />
                                            </>}
                                    </div>
                                </div>
                            </div>}
                    </Col>
                </Row>

                <Row className='d-flex justify-content-center mt-5'>
                    <Form.Group as={Col} md="12">
                        {role === 3 &&
                            <p className='sub-text'>Your profile acts as your application to rent this home. No need to fill out a form for every place you apply to! By applying, are authorizing the landlord to look at your profile, as well as request a credit and background check, prior to approving your application.</p>
                        }

                        {(tenancyApplicationData && tenancyApplicationData.landlord_notes && role === 3) &&
                            <>
                                <Form.Label className='bold'>Landlord's Message</Form.Label>
                                <p className='sub-text'>{tenancyApplicationData.landlord_notes}</p>
                            </>
                        }

                        {(tenancyApplicationData && tenancyApplicationData.tenant_notes && role === 2) ?
                            <>
                                <Form.Label className='bold'>Tenant's Message</Form.Label>
                                <p className='sub-text'>{tenancyApplicationData.tenant_notes}</p>
                            </> : ''}

                        {tenancyApplicationData.sent_by === 2 ? '' : (!is_receiver || role === 3) &&
                            <>
                                <Form.Label className='bold'>Personalize your {role === 3 ? 'application' : 'application invitation'} below</Form.Label>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    rows={6}
                                    name="notes"
                                    value={notes}
                                    id={notes}
                                    disabled={((tenancyApplicationData.sent_by === 1 && role === 2)) ? true : false}
                                    placeholder="Leave blank if not needed"
                                    onChange={(e) => handleNotesChange(e)}
                                />
                            </>
                        }


                        {(!tenancyApplicationData && role === 2) &&
                            <p className='sub-text mt-2 mb-0'>By inviting this tenant to apply they will be asked to complete their profile, providing you information on credit, employment, current and past tenancies and more!</p>
                        }
                        {(role === 3 && tenancyApplicationData && tenancyApplicationData.landlord_consent_to_credit_check) ?
                            <p className='sub-text mt-2 mb-0 text-center success'>The landlord would like to pull a credit &amp; background check on you. They pull the report directly, but need your consent to do so.</p> : ''
                        }

                    </Form.Group>

                    {((tenancyApplicationData && tenancyApplicationData.landlord_consent_to_credit_check === 1 && !is_receiver && role === 2) ?
                        ''
                        : (!tenancyApplicationData && role === 2) &&
                        <Form.Group as={Col} md="12" controlId="askTenentCheckbox">
                            <Form.Check
                                type="checkbox"
                                name="is_check"
                                label={"Ask the tenant to consent to a credit & background check."}
                                checked={is_check}
                                onChange={(e) => OnCheckInput(e)}
                                className="bold mt-0"
                            />
                        </Form.Group>)}

                    {(role === 3) &&
                        ((tenancyApplicationData && tenancyApplicationData.tenant_consent_to_credit_check === 1) ?
                            <div className='concent-box'>
                                <p>You have consented to a credit & background check.</p>
                                <span onClick={() => creditCheckAction('reject')}>Revoke consent</span>
                            </div>
                            : ((tenancyApplicationData.tenant_consent_to_credit_check === 0) ?
                                <div className='concent-box'>
                                    <p>You have not consented to a credit & background check.</p>
                                    <span onClick={() => handleCreditCheckAction('accept')}>Give consent</span>
                                </div>
                                : <Form.Group as={Col} md="12" controlId="askTenentCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        name="is_check"
                                        label={"Consent to a credit & background check."}
                                        checked={is_check}
                                        onChange={(e) => OnCheckInput(e)}
                                        className="bold mt-0"
                                    />

                                </Form.Group>))}

                </Row>
                {log.info("is_check", is_check)}
                {role === 3 &&
                    <>
                        {is_check === true || (is_check && tenancyApplicationData && tenancyApplicationData?.landlord_consent_to_credit_check === 1 && !tenancyApplicationData?.tenant_consent_to_credit_check) ?
                            <Row>
                                <Form.Group as={Col} md="12">
                                    <Form.Label className='bold'>Date of birth</Form.Label>
                                    <DatePickerComponent
                                        name="date_of_birth"
                                        id="date_of_birth"
                                        value={dateOfBirth}
                                        handleDateChange={(dob) => handleDate(dob)}
                                        displayText={"Date of Birth"}
                                        disableValue={false}
                                        maxDate={new Date()}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="12">
                                    <Form.Label className='bold'>SIN</Form.Label>
                                    <NumberFormat
                                        allowNegative={false}
                                        allowEmptyFormatting={false}
                                        value={sin ? sin : ''}
                                        onChange={(e) => setSin(e.target.value)}
                                        className='form-control'
                                        placeholder={''}
                                        name={'sin'}
                                        id={'sin'}
                                    />
                                </Form.Group>
                                <DisclaimerText />
                            </Row> : null}
                    </>
                }
                {role === 2 && userDetails && tenancyApplicationId &&
                    <>
                        <hr />
                        <p className='text-center'>View Tenant’s Profile
                        <span>
                                <Image
                                    className="icon"
                                    width="16px"
                                    height="15px"
                                    src={require("../../../../_assets/images/double-up-arrow-icon.svg")}
                                />
                            </span>
                        </p>
                        <TenantProfileDisplay
                            profileData={userDetails}
                            tenancyApplicationData={tenancyApplicationData}
                            tenant_id={tenant_id}
                            property_details_id={property_details_id}
                            property_id={property_id}
                            handleClose={handleClose}
                            setCertnPurchaseReport={setCertnPurchaseReport}
                            setShowingProfile={setShowingProfile} />
                    </>}

                {role === 3 && userDetails && tenancyApplicationId &&
                    <>
                        <TenantProfileDisplay
                            profileData={userDetails}
                            tenancyApplicationData={tenancyApplicationData}
                            tenant_id={tenant_id}
                            property_details_id={property_details_id}
                            property_id={property_id}
                            handleClose={handleClose}
                            setCertnPurchaseReport={setCertnPurchaseReport}
                            setShowingProfile={setShowingProfile} />
                    </>}

            </div>

            <div className={tenancyApplicationId && role === 2 ? "fixed-bottom dark-footer" : "fixed-bottom"}>
                {tenancyApplicationId ?
                    <>
                        {role === 2 && (
                            <>
                                <LargeButton onClick={() => leaseAction('create', 0)} title={'create lease'} />
                                {(tenancyApplicationData.status === 0) &&
                                    (userDetails && userDetails.basic_info && !userDetails.basic_info.is_consent_payment_completed) &&
                                    <LargeButton variant="secondary" title="reject" onClick={() => {
                                        handleClose()
                                        setModalShow(true)
                                    }} />
                                }
                            </>)
                        }

                        {(role === 3 && tenancyApplicationData.status === 0 && isTenancyApplicationReceived) && (
                            <>
                                <LargeButton onClick={() => onSubmit()} title={role === 2 ? 'submit invitation' : 'submit application'} />
                                <LargeButton variant="secondary" title="cancel" onClick={() => {
                                    handleClose()
                                    setModalShow(true)
                                }} />
                            </>)
                        }
                    </> :
                    ((tenancyApplicationData.status !== 1) &&
                        <>
                            <LargeButton onClick={() => onSubmit()} title={role === 2 ? 'submit invitation' : 'submit application'} />
                            {role === 3 && <LargeButton variant="secondary" title="cancel" onClick={() => {
                                handleClose()
                            }} />}
                        </>)
                }
            </div>


            {role === 3 && !isProfileCompleted &&
                <div className="fixed-bottom dark-footer">
                    <LargeButton onClick={gotoProfilePage} title={'complete profile'} />
                </div>}

            {role === 3 && tenancyApplicationId && (tenancyApplicationData.status === 1) &&
                <div className="fixed-bottom dark-footer">
                    <LargeButton onClick={gotoProfilePage} title={'edit profile'} />
                </div>}

        </SidePanel>
        <ModalBox show={openConfirmation} onHide={goBack} size="md" actionbuttontitle={'got it'} buttonaction={goBack} >
            {role === 2 ?
                <>
                    <p className="modal-confirmation-text">You have invited {name} to apply for for {propertyDetails && propertyDetails.address} for rent. </p>
                </> :
                <>
                    <p className="modal-confirmation-text">you’ve applied</p>
                    <p> The landlord should get back to you soon</p>
                </>}
        </ModalBox>

        <ModalBox
            show={modalShow}
            onHide={() => {
                setModalShow(false)
            }}
            size="md"
            type="condition"
            actionbuttontitle={'cancel'}
            buttonaction={() => {
                setModalShow(false)
            }}
            backbuttontitle={'reject'}
            backaction={() => {
                onReject()
            }}
        >
            <Form.Group controlId="exampleForm.ControlTextarea1">
                {role === 2 && receiverData ?
                    <>
                        <p className="modal-confirmation-text">{"are you sure you want to reject this tenant’s application?"}</p>
                        <Form.Label style={{ textAlign: 'left', display: 'block' }}>Let the tenant know why they’ve been rejected.</Form.Label>
                    </> :
                    <>
                        <p className="modal-confirmation-text">are you sure you want to reject the application invitation?</p>
                        <Form.Label style={{ textAlign: 'left', display: 'block' }}>{"Let the landlord know why you aren’t accepting"}</Form.Label>
                    </>}
                <Form.Control
                    type="text"
                    as="textarea"
                    rows={10}
                    id="reject_reason"
                    name="reject_reason"
                    value={reject_reason}
                    placeholder="reject Note..."
                    onChange={(e) => handleChange(e)}
                />
            </Form.Group>
        </ModalBox>
        <ModalBox show={successReject}
            onHide={() => setSuccessReject(false)}
            size="md" actionbuttontitle={'got it'}
            buttonaction={() => setSuccessReject(false)}>
            <p className="modal-confirmation-text">
                you have rejected <br />
                {is_receiver ? (name + "’s application") : 'your invitation'}</p>
        </ModalBox>

        <ModalBox show={creditCheckModal}
            onHide={() => setCreditCheckModal(false)}
            size="md" actionbuttontitle={'Submit'}
            buttonaction={() => handleGiveConsent()}>
            <form id="Form">
                <Row style={{ textAlign: 'left' }}>
                    <Form.Group as={Col} md="12">
                        <Form.Label className='bold'>Date of birth</Form.Label>
                        <DatePickerComponent
                            name="date_of_birth"
                            id="date_of_birth"
                            value={dateOfBirth}
                            handleDateChange={(dob) => handleDate(dob)}
                            displayText={"Date of Birth"}
                            disableValue={false}
                            maxDate={new Date()}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md="12">
                        <Form.Label className='bold'>SIN</Form.Label>
                        <NumberFormat
                            allowNegative={false}
                            allowEmptyFormatting={false}
                            value={sin ? sin : ''}
                            onChange={(e) => setSin(e.target.value)}
                            className='form-control'
                            placeholder={''}
                            name={'sin'}
                            id={'sin'}
                        />
                    </Form.Group>
                    <DisclaimerText />
                </Row>
            </form>
        </ModalBox>
    </>);
}

export default withRouter(TenancyForm);


export const DisclaimerText = () => {

    return (
        <Col md={12} className="disclaimerText">
            Your date of birth and SIN are collected in order to accurately run a background and credit check. This information is encrypted and not used for any other purpose. Checks are securely done through our screening partner - Certn.
        </Col>
    )
}