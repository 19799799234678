import React, { useState,useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { logger, toaster, validate } from "../../../../Utils";
import { LargeButton } from "../../";
import "../style.scss";
import { frequencyTypeDisplay } from '../../../../Constants/NoticeConstants';
import Moment from "react-moment";
import SignatureView from "../Signature/SignatureView";
import SignatureForm from "../Signature/SignatureForm";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { postAPI } from "../../../../Api";
import { updateRawData } from "../../../../Store/actions/RawDataAction";
import { useSelector } from "react-redux";


export const N10Schema = { 
  tenant_first_name: [
      { rule: "required", message: "first name required" },
  ], 
  tenant_last_name:[
      { rule: "required", message: "last name required" },

  ],
  tenant_sign_date: [
      { rule: "required", message: "signing date required" },
  ], 
  date:[
      { rule: "required", message: "date required" },

  ]
}


const log = logger("N10DetailsView");
const N10DetailsView = (props) => {
  const { noticeData, downloadPdf, redirectToInfo, onServeNotice, getParalegal, property_id, property_detail_id, cancelNotice } = props;
  const [isSubmitClicked, setisSubmitClicked] = useState(false)
  const role = Number(Cookies.get('role'));
  const [phoneError, setPhoneError] = useState(false);
  //Signature Data
  const [signData, setSignData] = useState(null);
  const isTermsAgree = useSelector((state) => state.rawData.agree)

  log.info("signData", signData);

  useEffect(()=>{
    if(isSubmitClicked){
      validate(N10Schema)
    }

  },[signData])

  const redirectToNoticeInfo = () => {
    props.history.push(`/notice-info/10`);
  };

  const disableError = (data) => {
    setPhoneError(data);
  };

  const onSubmit = () => {
      if(!isTermsAgree)
      {
        toaster("error", 'Please Accept Terms And Condition');
      return
      }
    setisSubmitClicked(true)
    const url = "/tenant/notice/sign-notice";
    const { tenant_first_name, tenant_last_name, tenant_phone_number, tenant_sign_date } = signData
    if (!tenant_phone_number) {
      setPhoneError(true)
    }
    if (!validate(N10Schema)) {
      return
    }
    const data = {
      id: noticeData && noticeData.id,
      tenant_first_name: signData && signData?.tenant_first_name,
      tenant_last_name: signData && signData?.tenant_last_name,
      tenant_phone_number: signData && signData?.tenant_phone_number,
      tenant_sign_date: signData && signData?.tenant_sign_date,
      tenant_signature: signData && signData?.tenant_signature,
      agree_terms_and_conditions: 1,
    };
    updateRawData({ showLoader: true })
    setTimeout(() => {
      if (document.getElementById('notice_loader'))
        document.getElementById('notice_loader').scrollIntoView()

      postAPI(url, data).then((response) => {
        if (response.status === 200) {
          const notice_id = response.data.data.id;
          toaster("success", "Data Saved Successfully!");
          viewNotice(notice_id);
          updateRawData({ showLoader: false })
        }
      }).catch(() => {
        updateRawData({ showLoader: false })
      })
    }, 300)
  };

  const viewNotice = (notice_id) => {
    props.history.push({
      pathname: `/view-notice/${property_id}/${property_detail_id}/${notice_id}`,
    });
  };

  log.info('noticeData', noticeData);
  console.warn(({props}));

  const getTenantSign=()=>{
    return noticeData.notice_tenants?.find((i)=>i.tenant_id===props.auth?.id)
  }
const tenantSign=getTenantSign()
console.warn({tenantSign})

  return (
    <>
      <div className="N-form-card form-pad">
        <div className="d-flex justify-content-between">
          <span className="form-title">Agreement Increasing The Rent Above The Guideline (N10)</span>
          {noticeData && noticeData.is_notice_received
            ?role!==3 && <LargeButton
              title={"get help from our paralegal"}
              style={{ width: "310px", margin: "0" }}
              onClick={getParalegal}
            />
            : <LargeButton
              title={<div>
                <i className='fa fa-print mr-2' />
               Download PDF
            </div>}
              onClick={() => downloadPdf()}
              style={{
                width: "auto",
                height: "48px",
                margin: "0",
                borderRadius: "5px",
              }}
            />}
        </div>
        <Row>
          <Col md={3} className="d-flex flex-column">
            <span className="field-title-text">
              When Is The Rent Increasing?
            </span>
            <span className="field-value-text">{<Moment format="MMMM D, YYYY">{noticeData.when_rent_increase}</Moment>}</span>
          </Col>

          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">
              New Rent Rate
            </span>
            <span className="field-value-text">${noticeData.new_rent_rate}</span>
          </Col>

          <Col md={2} className="d-flex flex-column">
            <span className="field-title-text">
              Frequency
            </span>
            <span className="field-value-text">
              {frequencyTypeDisplay(noticeData.frequency)}
              {noticeData.frequency === 3 && ` - ${noticeData.frequency_text}`}
            </span>
          </Col>
        </Row>
        <div className="border-separator"></div>
        <div className="mt-4">
          <span className="form-title">Details of the work, new or additional service</span>
        </div>
        <Row>
          <Col className="d-flex flex-column">
            <span className="field-title-text mb-2">
              The law allows a landlord and tenant to agree to a rent increase that is more than the rent increase guideline if you as a landlord will: (Please indicate what you will provide)
            </span>
            <div className="d-flex justify-content-start">
              <input
                type="checkbox"
                className="checkit"
                id={"agree_1"}
                name={"do_capital_work"}
                checked={noticeData.do_capital_work === 1 ? true : false}
                hidden
              />
              <label htmlFor={"agree_1"} className="checkmark"></label>
              <span className="checkbox-title">As the landlord you have done or will do capital work</span>
            </div>
            <div className="d-flex justify-content-start">
              <input
                type="checkbox"
                className="checkit"
                id={"agree_2"}
                name={"provide_additional_services"}
                checked={noticeData.provide_additional_services === 1 ? true : false}
                hidden
              />
              <label htmlFor={"agree_2"} className="checkmark"></label>
              <span className="checkbox-title">As the landlord you have provided (or will provide) a new or additional service.</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-column">
            <span className="field-title-text">
              More Details
            </span>
            <span className="field-value-text">
              {noticeData && noticeData.additional_service_notes}</span>
          </Col>
        </Row>

        <div className="border-separator"></div>
        <SignatureView noticeData={noticeData} />

        {role === 2 ?
          <div className="agree-info d-flex justify-content-start mt-4">
            <input
              type="checkbox"
              className="checkit"
              id={"one"}
              name={"one"}
              checked={'checked'}
              hidden
            />
            <label htmlFor={"one"} className="checkmark"></label>
            <span className="checkbox-title">
              I have read and agree with the
            </span>
            <span className="checkbox-subtitle" onClick={redirectToInfo}>
              Important information from the Landlord and Tenant Board
            </span>
          </div>
          : 
          <p className="cursor-pointer">Landlord had read and agreed with the 
          <br/>
          <u onClick={() => redirectToNoticeInfo()}>Important information from the Landlord and Tenant Board</u>
          </p>}

        {(noticeData && tenantSign?.signature) &&
          <>
            <div className="border-separator"></div>
            <SignatureView 
            
            noticeData={{...noticeData,tenant_signature:tenantSign?.signature ,tenant_sign_date: tenantSign.sign_date}}
            type={'tenant'} />
            <div className="agree-info d-flex justify-content-start mt-4">
              <input
                type="checkbox"
                className="checkit"
                id={"one"}
                name={"one"}
                checked={'checked'}
                hidden
              />
              <label htmlFor={"one"} className="checkmark"></label>
              <span className="checkbox-title">
                I have read and agree with the
            </span>
              <span className="checkbox-subtitle" onClick={redirectToInfo}>
                Important information from the Landlord and Tenant Board
            </span>
            </div>
          </>}

        {((role === 3) && (noticeData && !tenantSign?.signature )) &&
          <>
            <div className="border-separator"></div>
            <div className="mt-4 mb-4">
              <span className="form-title">Tenant Signature</span>
            </div>
            <SignatureForm
              phoneError={phoneError}
              disableError={(data) => disableError(data)}
              type={"lastName"}
              signUserType={"tenant"}
              setSignData={(data) => setSignData(data)}
              noticeData={noticeData}
              notice_type={10}
            />
          </>}
      </div>

      <div className="container-footer button-extra-margin">
        {(noticeData.status !== 3 && role === 3) &&
          ((noticeData && (noticeData.tenant_signature || getTenantSign()  )) ?
            <Button variant="success" onClick={() => onServeNotice()}>
              Serve Notice
            </Button>
            : <Button variant="success" onClick={() => onSubmit()}>
              Review
            </Button>
          )}

        {(noticeData.status === 0 && role === 2) &&
          <Button variant="success" onClick={() => onServeNotice()}>
            Serve Notice
          </Button>}

        {(!noticeData.is_notice_received && (noticeData.status === 2)) &&
          <Button variant="dark" onClick={() => cancelNotice()}>
            Cancel Notice
        </Button>}
      </div>
    </>
  );
};

export default withRouter(N10DetailsView);
